import React from 'react';
import { useGetNayaxMdbTemplatesQuery } from 'data/__generated__';
import { Option, TotemSelect } from 'components/TotemSelect';

interface NayaxMDBTemplateSelectProps {
    value: string | null;
    onChange: (value: string | null) => void;
}

export const NayaxMDBTemplateSelect: React.FC<NayaxMDBTemplateSelectProps> = ({ value, onChange }) => {
    const { data: templatesData, loading } = useGetNayaxMdbTemplatesQuery();

    const options = React.useMemo(
        () =>
            (templatesData?.nayaxMDBTemplates || []).map((template) => ({
                label: template.name,
                value: template._id,
            })),
        [templatesData],
    );

    const selectedOption = React.useMemo(
        () => (value ? options.find((option) => option.value === value) || null : null),
        [value, options],
    );

    const handleChange = (option: Option<string> | null | undefined) => {
        onChange(option?.value || null);
    };

    return (
        <TotemSelect
            label="Choisir un template MDB"
            placeholder="template MDB"
            value={selectedOption}
            options={options}
            onChange={handleChange}
            isSearchable={true}
            isMulti={false}
            disabled={loading}
        />
    );
};
