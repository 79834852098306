import React, { useState } from 'react';

import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { deliveryWindowOptions } from './constants/deliveryHours';
import { PAGES } from '../../constants/pages';
import { SITE_STATES_LABELS } from './constants/states';
import { SITE_TYPES_LABELS } from './constants/types';
import { FRENCH_DAYS } from 'constants/dateAndTime';
import { colors } from 'constants/colors';

import { CREATE_SITE_MUTATION } from 'data/mutations/site';
import { GET_TOTEMS_OF_ORGANIZATION_QUERY } from 'data/queries/totem';
import { SITES_UPDATED_SUBSCRIPTION } from 'data/subscriptions/site';
import { ReassortmentStockByCategoriesInput, SiteState, SiteType, useGetOrganizationsQuery } from 'data/__generated__';
import { CREATE_SITE, CREATE_SITEVariables } from 'data/mutations/__generated__/CREATE_SITE';
import {
    GET_TOTEMS_OF_ORGANIZATION,
    GET_TOTEMS_OF_ORGANIZATIONVariables,
} from 'data/queries/__generated__/GET_TOTEMS_OF_ORGANIZATION';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemLabel } from 'components/TotemLabel';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';

import { sitesUpdatedHandler } from './utils/sitesUpdatedHandler';
import {
    getNumericValue,
    stockTargetRatiosCheck,
} from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/utils';

export type ReassortmentStockTargetRatiosFormInput = {
    monday: string | number;
    tuesday: string | number;
    wednesday: string | number;
    thursday: string | number;
    friday: string | number;
};

type FormType = {
    accessTime: number;
    deliveryDays: boolean[];
    deliveryWindowStartOption: SelectedOption<string>;
    deliveryWindowStopOption: SelectedOption<string>;
    estimatedUserCount: number;
    name: string;
    reassortmentStockTargetRatios: ReassortmentStockTargetRatiosFormInput;
    reassortmentStockTargets: ReassortmentStockByCategoriesInput;
    reassortmentOnboardingStockTargets: ReassortmentStockByCategoriesInput;
    stateOption: SelectedOption<SiteState>;
    typeOption: SelectedOption<SiteType>;
    organizationOption: SelectedOption<string>;
    totemOption: SelectedOption<string>;
};

export function SiteCreate() {
    const regularStateOption = { value: SiteState.Regular, label: SITE_STATES_LABELS.Regular };
    const statesOptions = [regularStateOption];

    const brokenProductsWarehouseTypeOption = {
        value: SiteType.BrokenProductsWarehouse,
        label: SITE_TYPES_LABELS[SiteType.BrokenProductsWarehouse],
    };
    const expiredProductsWarehouseTypeOption = {
        value: SiteType.ExpiredProductsWarehouse,
        label: SITE_TYPES_LABELS[SiteType.ExpiredProductsWarehouse],
    };
    const microstoreTypeOption = { value: SiteType.Microstore, label: SITE_TYPES_LABELS[SiteType.Microstore] };
    const warehouseTypeOption = { value: SiteType.Warehouse, label: SITE_TYPES_LABELS[SiteType.Warehouse] };
    const typesOptions = [
        warehouseTypeOption,
        microstoreTypeOption,
        expiredProductsWarehouseTypeOption,
        brokenProductsWarehouseTypeOption,
    ];

    const initialFormData = {
        accessTime: 5,
        deliveryDays: [true, true, true, true, true, false, false],
        deliveryWindowStartOption: deliveryWindowOptions[0],
        deliveryWindowStopOption: deliveryWindowOptions[deliveryWindowOptions.length - 1],
        estimatedUserCount: 0,
        name: '',
        reassortmentStockTargetRatios: {
            monday: 1,
            tuesday: 1,
            wednesday: 1,
            thursday: 1,
            friday: 1,
        },
        reassortmentStockTargets: {
            dessert: 0,
            main: 0,
            smallBites: 0,
            starter: 0,
        },
        reassortmentOnboardingStockTargets: {
            dessert: 0,
            main: 0,
            smallBites: 0,
            starter: 0,
        },
        stateOption: regularStateOption,
        typeOption: microstoreTypeOption,
        organizationOption: null,
        totemOption: null,
    };
    const [formData, setFormData] = useState<FormType>(initialFormData);
    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useGetOrganizationsQuery();
    const [getTotemsOfOrganization, { loading: totemsLoading, data: totemsData, error: totemsError }] = useLazyQuery<
        GET_TOTEMS_OF_ORGANIZATION,
        GET_TOTEMS_OF_ORGANIZATIONVariables
    >(GET_TOTEMS_OF_ORGANIZATION_QUERY);
    const [createSite] = useMutation<CREATE_SITE, CREATE_SITEVariables>(CREATE_SITE_MUTATION);

    useSubscription(SITES_UPDATED_SUBSCRIPTION, {
        onSubscriptionData({
            subscriptionData: {
                data: { siteUpdated },
            },
        }) {
            sitesUpdatedHandler(siteUpdated);
        },
    });

    if (organizationsError) {
        throw new Error('Une erreur est survenue lors de la récupération des organisations');
    }

    if (totemsError) {
        throw new Error('Une erreur est survenue lors de la récupération des totems');
    }

    const handleSelectOrganization = (option: SelectedOption<string>) => {
        setFormData({ ...formData, organizationOption: option, totemOption: undefined });
        if (option) {
            getTotemsOfOrganization({ variables: { organizationId: option.value } });
        }
    };

    const organizationsOptions =
        organizationsData?.organizations
            ?.map((organization) => ({ value: organization._id, label: organization.name }))
            .sort((orgA, orgB) => orgA.label.toLowerCase().localeCompare(orgB.label.toLowerCase())) || [];

    const totemsOptions =
        totemsData?.totemsOfOrganization?.map((totem) => ({
            value: totem._id,
            label: `#${totem.number} - ${totem.name || 'Sans nom'}`,
        })) || [];

    const canSubmit =
        formData.name &&
        formData.stateOption &&
        formData.typeOption &&
        formData.organizationOption &&
        formData.totemOption;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const {
            accessTime,
            deliveryDays,
            deliveryWindowStartOption,
            deliveryWindowStopOption,
            estimatedUserCount,
            name,
            stateOption,
            typeOption,
            organizationOption,
            reassortmentStockTargetRatios,
            reassortmentStockTargets,
            reassortmentOnboardingStockTargets,
            totemOption,
        } = formData;

        const stockTargetRatiosError = stockTargetRatiosCheck(reassortmentStockTargetRatios);
        if (stockTargetRatiosError) {
            toast.warning(stockTargetRatiosError);
            return;
        }

        if (
            !deliveryWindowStartOption ||
            !deliveryWindowStopOption ||
            !organizationOption ||
            !totemOption ||
            !stateOption ||
            !typeOption
        ) {
            toast.error("L'un des champs n'a pas été renseigné");
            return;
        }

        const { value: deliveryWindowStart } = deliveryWindowStartOption;
        const { value: deliveryWindowStop } = deliveryWindowStopOption;
        const { value: organizationId } = organizationOption;
        const { value: totemId } = totemOption;
        const { value: state } = stateOption;
        const { value: type } = typeOption;

        const startHour = deliveryWindowStart.replace(':', '');
        const stopHour = deliveryWindowStop.replace(':', '');
        if (Number(startHour) >= Number(stopHour)) {
            toast.error('Fenêtre de livraison invalide');
            return;
        }

        const { monday, tuesday, wednesday, thursday, friday } = formData.reassortmentStockTargetRatios;

        const mutationVariable = {
            accessTime,
            deliveryDays,
            deliveryWindowStart,
            deliveryWindowStop,
            estimatedUserCount,
            name,
            state,
            type,
            organizationId,
            totemId,
            reassortmentStockTargetRatios: {
                monday: getNumericValue(monday),
                tuesday: getNumericValue(tuesday),
                wednesday: getNumericValue(wednesday),
                thursday: getNumericValue(thursday),
                friday: getNumericValue(friday),
            },
            reassortmentStockTargets,
            reassortmentOnboardingStockTargets,
        };

        const result = await createSite({ variables: mutationVariable });
        const { data } = result;
        if (data) {
            const {
                createSite: { success },
            } = data;
            if (success) {
                toast.success(`Le site "${formData.name}" a bien été créé !`);
                setFormData(initialFormData);
            } else {
                const {
                    createSite: { error },
                } = data;
                if (error) {
                    toast.error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    const updateDeliveryDay = (value: boolean, index: number) => {
        const updatedDeliveryDays = [...formData.deliveryDays];
        updatedDeliveryDays[index] = value;

        setFormData({ ...formData, deliveryDays: updatedDeliveryDays });
    };

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.siteCreate} />
                </HeaderTitle>
                <Link to="/sites">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <Fields>
                        <ColumnsSectionContainer numberOfColumns={1}>
                            <SectionColumn>
                                <TotemInput
                                    label="Nom du site"
                                    placeholder="Entrepôt Pantin, Microstore Airbnb, ..."
                                    value={formData.name}
                                    onChange={(value) => setFormData({ ...formData, name: value })}
                                    dataTest="site-create_name"
                                />
                                <TotemSelect
                                    label="Début de la fenêtre de livraison"
                                    placeholder="Sélectionner un horaire"
                                    value={formData.deliveryWindowStartOption}
                                    options={deliveryWindowOptions}
                                    onChange={(option) =>
                                        setFormData({ ...formData, deliveryWindowStartOption: option })
                                    }
                                    dataTest="site-create_window-start"
                                />
                                <TotemSelect
                                    label="Fin de la fenêtre de livraison"
                                    placeholder="Sélectionner un horaire"
                                    value={formData.deliveryWindowStopOption}
                                    options={deliveryWindowOptions}
                                    onChange={(option) =>
                                        setFormData({ ...formData, deliveryWindowStopOption: option })
                                    }
                                    dataTest="site-create_window-stop"
                                />
                                <TotemInput
                                    label="Temps d'accès au site"
                                    name="accessTime"
                                    value={formData.accessTime}
                                    type="number"
                                    step="1"
                                    dataTest="site-create_access-time"
                                    onChange={(value) => setFormData({ ...formData, accessTime: parseFloat(value) })}
                                />
                                {!organizationsLoading && organizationsData ? (
                                    <TotemSelect
                                        label="Organisation"
                                        placeholder="Sélectionner une organisation"
                                        value={formData.organizationOption}
                                        options={organizationsOptions}
                                        onChange={handleSelectOrganization}
                                        dataTest="site-create_organization"
                                    />
                                ) : null}
                                {!totemsLoading && totemsData ? (
                                    <TotemSelect
                                        label="Totem"
                                        placeholder="Sélectionner un totem"
                                        value={formData.totemOption}
                                        options={totemsOptions}
                                        onChange={(option) => setFormData({ ...formData, totemOption: option })}
                                        dataTest="site-create_totem"
                                    />
                                ) : null}
                                <TotemInput
                                    label="Estimation du nombre d'utilisateurs sur site"
                                    name="estimatedUserCount"
                                    value={formData.estimatedUserCount}
                                    type="number"
                                    step="1"
                                    dataTest="site-create_estimated-user-count"
                                    onChange={(value) =>
                                        setFormData({ ...formData, estimatedUserCount: parseInt(value) })
                                    }
                                />
                                <div>
                                    <Title>
                                        Consommation quotidienne moyenne manuelle (à mettre à la main pour l'onboarding)
                                    </Title>
                                    <InputsContainer>
                                        <TotemInput
                                            label="Entrées"
                                            type="number"
                                            value={formData.reassortmentOnboardingStockTargets.starter}
                                            onChange={(value) => {
                                                const newStarter = parseInt(value) || 0;
                                                setFormData({
                                                    ...formData,
                                                    reassortmentOnboardingStockTargets: {
                                                        ...formData.reassortmentOnboardingStockTargets,
                                                        starter: newStarter,
                                                    },
                                                });
                                            }}
                                            step="1"
                                            min="0"
                                            required={true}
                                        />
                                        <TotemInput
                                            label="Petites faims"
                                            type="number"
                                            value={formData.reassortmentOnboardingStockTargets.smallBites}
                                            onChange={(value) => {
                                                const newSmallBites = parseInt(value) || 0;
                                                setFormData({
                                                    ...formData,
                                                    reassortmentOnboardingStockTargets: {
                                                        ...formData.reassortmentOnboardingStockTargets,
                                                        smallBites: newSmallBites,
                                                    },
                                                });
                                            }}
                                            step="1"
                                            min="0"
                                            required={true}
                                        />
                                        <TotemInput
                                            label="Plats"
                                            type="number"
                                            value={formData.reassortmentOnboardingStockTargets.main}
                                            onChange={(value) => {
                                                const newMain = parseInt(value) || 0;
                                                setFormData({
                                                    ...formData,
                                                    reassortmentOnboardingStockTargets: {
                                                        ...formData.reassortmentOnboardingStockTargets,
                                                        main: newMain,
                                                    },
                                                });
                                            }}
                                            step="1"
                                            min="0"
                                            required={true}
                                        />
                                        <TotemInput
                                            label="Désserts"
                                            type="number"
                                            value={formData.reassortmentOnboardingStockTargets.dessert}
                                            onChange={(value) => {
                                                const newDessert = parseInt(value) || 0;
                                                setFormData({
                                                    ...formData,
                                                    reassortmentOnboardingStockTargets: {
                                                        ...formData.reassortmentOnboardingStockTargets,
                                                        dessert: newDessert,
                                                    },
                                                });
                                            }}
                                            step="1"
                                            min="0"
                                            required={true}
                                        />
                                    </InputsContainer>
                                </div>
                                <div>
                                    <Title>
                                        Ratio de consommation de 0 (rien consommé) à 1 (consommation max) (à mettre à la
                                        main pour l'onboarding)
                                    </Title>
                                    <InputsContainer>
                                        <TotemInput
                                            label="Lundi"
                                            type="number"
                                            value={formData.reassortmentStockTargetRatios.monday}
                                            onChange={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    reassortmentStockTargetRatios: {
                                                        ...formData.reassortmentStockTargetRatios,
                                                        monday: value,
                                                    },
                                                });
                                            }}
                                            min="0"
                                            step="0.01"
                                            required={true}
                                        />
                                        <TotemInput
                                            label="Mardi"
                                            type="number"
                                            value={formData.reassortmentStockTargetRatios.tuesday}
                                            onChange={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    reassortmentStockTargetRatios: {
                                                        ...formData.reassortmentStockTargetRatios,
                                                        tuesday: value,
                                                    },
                                                });
                                            }}
                                            min="0"
                                            step="0.01"
                                            required={true}
                                        />
                                        <TotemInput
                                            label="Mercredi"
                                            type="number"
                                            value={formData.reassortmentStockTargetRatios.wednesday}
                                            onChange={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    reassortmentStockTargetRatios: {
                                                        ...formData.reassortmentStockTargetRatios,
                                                        wednesday: value,
                                                    },
                                                });
                                            }}
                                            min="0"
                                            step="0.01"
                                            required={true}
                                        />
                                        <TotemInput
                                            label="Jeudi"
                                            type="number"
                                            value={formData.reassortmentStockTargetRatios.thursday}
                                            onChange={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    reassortmentStockTargetRatios: {
                                                        ...formData.reassortmentStockTargetRatios,
                                                        thursday: value,
                                                    },
                                                });
                                            }}
                                            min="0"
                                            step="0.01"
                                            required={true}
                                        />
                                        <TotemInput
                                            label="Vendredi"
                                            type="number"
                                            value={formData.reassortmentStockTargetRatios.friday}
                                            onChange={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    reassortmentStockTargetRatios: {
                                                        ...formData.reassortmentStockTargetRatios,
                                                        friday: value,
                                                    },
                                                });
                                            }}
                                            min="0"
                                            step="0.01"
                                            required={true}
                                        />
                                    </InputsContainer>
                                </div>
                                <TotemSelect<SiteType>
                                    label="Type"
                                    placeholder="Sélectionner un type"
                                    value={formData.typeOption}
                                    options={typesOptions}
                                    onChange={(option) => setFormData({ ...formData, typeOption: option })}
                                    dataTest="site-create_type"
                                />
                                <TotemSelect<SiteState>
                                    label="Statut"
                                    placeholder="Sélectionner un statut"
                                    value={formData.stateOption}
                                    options={statesOptions}
                                    onChange={(option) => setFormData({ ...formData, stateOption: option })}
                                    dataTest="site-create_state"
                                />
                                <TotemLabel>Jours de livraison</TotemLabel>
                                <List>
                                    <tbody>
                                        {FRENCH_DAYS.map((day, index) => (
                                            <tr key={index}>
                                                <Cell isBold>{day}</Cell>
                                                <Cell>
                                                    <Switch
                                                        onChange={(value) => updateDeliveryDay(value, index)}
                                                        checked={formData.deliveryDays[index]}
                                                        onColor={colors.green}
                                                        offColor={colors.pantinGrey}
                                                    />
                                                </Cell>
                                            </tr>
                                        ))}
                                    </tbody>
                                </List>
                            </SectionColumn>
                        </ColumnsSectionContainer>
                    </Fields>

                    <TotemPrimaryButton disabled={!canSubmit} data-test="site-create_submit-button">
                        Créer le site
                    </TotemPrimaryButton>
                </Form>
            </Content>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Form = styled.form`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const List = styled.table`
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;

    border-spacing: 0;
    border-collapse: collapse;
`;

const Cell = styled.td`
    text-align: left;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-spacing: 1px;
    padding-left: 10px;
    font-weight: ${({ isBold }: { isBold?: boolean }) => (isBold ? 800 : 500)};
    color: ${({ theme }) => theme.textColor};
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const Title = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 20px;
`;
