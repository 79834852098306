import React from 'react';
import { CommonStatusTag } from 'components/CommonStatusTag';
import { colors } from 'constants/colors';

export type StatusOptions = {
    backgroundColor: string;
    labelColor: string;
    label: string;
};

export const DetailFormStatusValue = <T extends string>({
    state,
    options,
    showHeading,
    sublabel,
}: {
    state: T;
    options: { [key in T]: StatusOptions };
    showHeading?: boolean;
    sublabel?: string;
}) => {
    return (
        <CommonStatusTag
            backgroundColor={options[state].backgroundColor}
            labelColor={options[state]?.labelColor || colors.black}
            label={options[state].label}
            showHeading={showHeading}
            sublabel={sublabel}
        />
    );
};
