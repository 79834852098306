import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { UPDATE_COLUMN_INFO_MUTATION } from 'data/mutations/microstoreColumn';

import { UPDATE_COLUMN_INFO, UPDATE_COLUMN_INFOVariables } from 'data/mutations/__generated__/UPDATE_COLUMN_INFO';
import { GetMicrostoreSitesQuery, MicrostoreColumnTemplateType } from 'data/__generated__';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';
import { TotemCheckbox } from 'components/TotemCheckbox';

export function EditColumnPopup({
    isOpen,
    setMicrostoreColumnToEdit,
    column,
}: {
    isOpen: boolean;
    setMicrostoreColumnToEdit: (
        column:
            | GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'][number]['microstoreColumnGroups'][number]['columns'][number]
            | null,
    ) => void;
    column: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'][number]['microstoreColumnGroups'][number]['columns'][number];
}) {
    const [startingDate, setStartingDate] = useState<Date | null>(
        column.startingDate ? dateFromString(column.startingDate, true) : null,
    );
    const [removalDate, setRemovalDate] = useState<Date | null>(
        column.removalDate ? dateFromString(column.removalDate, true) : null,
    );
    const [isPaused, setIsPaused] = useState<boolean>(column.isPaused);

    const [updateColumnInfo, { loading: updateLoading }] = useMutation<UPDATE_COLUMN_INFO, UPDATE_COLUMN_INFOVariables>(
        UPDATE_COLUMN_INFO_MUTATION,
    );

    // only display warnings for columns with fresh products
    const shouldDisplayDelaysWarning = column.type === MicrostoreColumnTemplateType.Fridge;

    const mondayInThreeWeeks = new Date();
    mondayInThreeWeeks.setDate(mondayInThreeWeeks.getDate() + 21 - ((mondayInThreeWeeks.getDay() + 6) % 7));
    mondayInThreeWeeks.setHours(0, 0, 0, 0);

    async function handleSubmit() {
        if (removalDate && startingDate && removalDate <= startingDate) {
            toast.error(<span>Vous ne pouvez pas mettre une date de retrait avant la date de début</span>, {
                autoClose: false,
            });
            return;
        }

        if (shouldDisplayDelaysWarning && startingDate && mondayInThreeWeeks > startingDate) {
            toast.error(
                <span>
                    "Non respect des délais nécessaires pour les achats en vue du lancement - le restocking ne sera pas
                    automatique"
                </span>,
                {
                    autoClose: false,
                },
            );
        }

        if (shouldDisplayDelaysWarning && removalDate && mondayInThreeWeeks > removalDate) {
            toast.error(
                <span>
                    "Non respect des délais nécessaires pour les achats - ce retrait va générer de la Démarque Connue"
                </span>,
                {
                    autoClose: false,
                },
            );
        }

        const { data } = await updateColumnInfo({
            variables: {
                columnId: column._id,
                isPaused,
                removalDate: removalDate
                    ? formatDateAsAnniversary({ dateTime: removalDate, useNewFormat: true })
                    : null,
                startingDate: startingDate
                    ? formatDateAsAnniversary({ dateTime: startingDate, useNewFormat: true })
                    : null,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la creation du template de colonne');
        }

        const {
            updateColumnInfoMutation: { errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        setMicrostoreColumnToEdit(null);
    }

    return (
        <TotemPopup
            title="Mettre à jour une colonne"
            isOpen={isOpen}
            setIsOpen={() => setMicrostoreColumnToEdit(null)}
            contentOverflow="visible"
        >
            <SubmitContainer>
                <TotemDatePicker
                    label="Date de début"
                    selected={startingDate}
                    isClearable={true}
                    onChange={(date) => {
                        if (shouldDisplayDelaysWarning && date && mondayInThreeWeeks > date) {
                            window.alert(
                                "Non respect de la règle :\n\nLa date de lancement d'une colonne doit être renseignée strictement avant le lundi de la semaine en J-14 de l'ouverture pour permettre les achats.\n\nVeuillez valider avec data & supply sinon il n'y aura pas de restocking",
                            );
                        }
                        setStartingDate(date);
                    }}
                />
                <TotemDatePicker
                    label="Date de retrait"
                    selected={removalDate}
                    isClearable={true}
                    onChange={(date) => {
                        if (shouldDisplayDelaysWarning && date && mondayInThreeWeeks > date) {
                            window.alert(
                                "Non respect de la règle :\n\nLa date retrait d'une colonne doit être renseignée strictement avant le lundi de la semaine en J-14 de l'ouverture pour permettre les achats.\n\nVeuillez valider avec data & supply sinon il n'y aura pas de restocking",
                            );
                        }
                        setRemovalDate(date);
                    }}
                />
                <TotemCheckbox label="Est en pause ?" checked={isPaused} onChange={() => setIsPaused(!isPaused)} />
                <TotemPrimaryButton onClick={handleSubmit}>
                    {updateLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;

    > :not(:first-child) {
        margin-top: 15px;
    }
`;
