import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { GetMicrostoreColumnQuery } from 'data/__generated__';

import { SectionContainer } from 'components/DetailsView/Section';
import { colors } from 'constants/colors';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { ImportNayaxMDBTemplate } from './ImportNayaxMDBTemplate';

type MicrostoreLocation = NonNullable<
    NonNullable<GetMicrostoreColumnQuery['microstoreColumn']>['shelves'][number]['locationTable'][number][number]
>;

export const NayaxMDBSection = ({
    microstoreColumn,
}: {
    microstoreColumn: GetMicrostoreColumnQuery['microstoreColumn'];
}) => {
    const { register, setValue } = useFormContext();

    const handleTemplateImport = (template: Record<string, number>) => {
        microstoreColumn.shelves.forEach((shelf) => {
            shelf.locationTable.forEach((locationRow) => {
                locationRow.forEach((location) => {
                    const fieldName = `shelves.${shelf.positionInColumn}.locationTable.${location.positionInShelf.row}.${location.positionInShelf.column}.nayaxMDBCode`;
                    const key = `${shelf.positionInColumn}-${location.positionInShelf.row}-${location.positionInShelf.column}`;

                    if (template[key] !== undefined) {
                        setValue(fieldName, template[key], { shouldValidate: true });
                    }
                });
            });
        });
    };

    return (
        <SectionContainer title="Codes Nayax MDB" isInitiallyOpen>
            <Container>
                <Header>
                    <ImportNayaxMDBTemplate onImport={handleTemplateImport} />
                </Header>
                <Table>
                    {microstoreColumn.shelves.map((shelf, shelfIndex) => (
                        <React.Fragment key={shelf._id}>
                            {shelf.locationTable.map((locationRow, locationRowIndex) => (
                                <Tr key={`${shelf._id}-row-${locationRowIndex}`}>
                                    {locationRow.map((location: MicrostoreLocation, locationColumnIndex) => {
                                        const fieldName = `shelves.${shelfIndex}.locationTable.${locationRowIndex}.${locationColumnIndex}.nayaxMDBCode`;
                                        const productArrangementName =
                                            location.microstoreLocationTemplate?.productArrangement?.name ||
                                            location.microstoreLocationTemplate?.productArrangementSingleProductInfo
                                                ?.product?.name ||
                                            '-';

                                        return (
                                            <Td key={`${shelf._id}-${locationColumnIndex}-mdb`}>
                                                <ButtonLabel>{productArrangementName}</ButtonLabel>
                                                <ButtonContainer>
                                                    <DetailFormValue
                                                        {...register(fieldName, {
                                                            required: false,
                                                            valueAsNumber: true,
                                                        })}
                                                        placeholder="Code MDB"
                                                        defaultValue={location.nayaxMDBCode}
                                                        type="number"
                                                        step="1"
                                                        centerText={true}
                                                        min="0"
                                                        style={{ width: '110px' }}
                                                    />
                                                </ButtonContainer>
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </React.Fragment>
                    ))}
                </Table>
            </Container>
        </SectionContainer>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: auto;
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 4px;
`;

const ButtonLabel = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.textColor};
    padding-bottom: 2px;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Tr = styled.div`
    display: flex;
    width: 100%;
`;

const Td = styled.div`
    padding: 2px;
    border-bottom: 1px solid ${colors.lightGrey};
    color: ${({ theme }) => theme.textColor};
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
