import React from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Loader } from 'components/Loader';
import { TotemDetails } from './TotemDetails';
import { CreateTotemButton } from './CreateTotemButton';
import { Option } from 'components/TotemSelect';
import { useGetDetailedTotemsOfOrganizationQuery, useGetEquipmentsQuery } from 'data/__generated__';

type ParamTypes = {
    organizationId: string;
};

export const TotemsDetails = ({ mainUserOptions }: { mainUserOptions: Option<string>[] }) => {
    const { organizationId = '' } = useParams<ParamTypes>();

    const {
        loading: totemsLoading,
        error: totemsError,
        data: totemsData,
        refetch: refetchTotems,
    } = useGetDetailedTotemsOfOrganizationQuery({
        variables: { organizationId },
    });

    const { data: equipmentData, loading: equipmentLoading, error: equipmentError } = useGetEquipmentsQuery();

    if (totemsLoading || equipmentLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (totemsError || !totemsData || equipmentError || !equipmentData) {
        throw new Error('Une erreur est survenue lors de la récupération des totems');
    }

    const totems = totemsData.totemsOfOrganization;
    const { adminEquipments } = equipmentData;

    return (
        <Container>
            <Header>
                <CreateTotemButton organizationId={organizationId} refetchTotems={refetchTotems} />
            </Header>
            <Totems>
                {totems.map((totem) => {
                    return (
                        <TotemDetails
                            key={totem._id}
                            totem={totem}
                            mainUserOptions={mainUserOptions}
                            equipments={adminEquipments}
                        />
                    );
                })}
            </Totems>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 5px;
`;

const Totems = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 5px;
    overflow-x: scroll;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;
