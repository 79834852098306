import React from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { useGetMicrostoreColumnQuery } from 'data/__generated__';

import { Loader } from 'components/Loader';
import { MicrostoreColumnFormContent } from './MicrostoreColumnFormContent';

type ParamTypes = {
    microstoreColumnId: string;
};

export const MicrostoreColumnDetails = () => {
    const { microstoreColumnId = '' } = useParams<ParamTypes>();

    const {
        data: microstoreColumnData,
        loading: microstoreColumnLoading,
        error: microstoreColumnError,
    } = useGetMicrostoreColumnQuery({
        variables: {
            columnId: microstoreColumnId,
        },
    });

    if (microstoreColumnLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (microstoreColumnError || !microstoreColumnData) {
        throw new Error('Une erreur est survenue lors de la récupération de la colonne');
    }

    const { microstoreColumn } = microstoreColumnData;

    if (!microstoreColumn) {
        throw new Error(`Aucune colonne avec l'id ${microstoreColumnId} n'a été trouvée`);
    }

    return <MicrostoreColumnFormContent microstoreColumn={microstoreColumn} />;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;
