import React, { FunctionComponent, useState } from 'react';
import { SectionContainer } from 'components/DetailsView/Section';
import { GetDetailedProductQuery, useUpdateProductArrangementMutation } from 'data/__generated__';
import styled from 'styled-components';
import { AddProductArrangementPopup } from './components/AddProductArrangementPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GET_DETAILED_PRODUCT_QUERY } from 'data/queries/product';

type ProductArrangementsSectionProps = {
    product: NonNullable<GetDetailedProductQuery['detailedProduct']>;
};

export const ProductArrangementsSection: FunctionComponent<ProductArrangementsSectionProps> = ({ product }) => {
    const { productArrangements } = product;

    const [updateProductArrangement] = useUpdateProductArrangementMutation({
        refetchQueries: [{ query: GET_DETAILED_PRODUCT_QUERY, variables: { productId: product._id } }],
        awaitRefetchQueries: true,
    });

    const [showAddProductArrangementPopup, setShowAddProductArrangementPopup] = useState(false);

    const onRemoveFromProductArrangement = (
        productArrangement: NonNullable<GetDetailedProductQuery['detailedProduct']>['productArrangements'][number],
    ) => {
        const newProductIdsWithStockMax = productArrangement.productIdsWithStockMax
            .filter(({ productId }) => productId !== product._id)
            .map(({ productId, stockMaxSensei, stockMaxUnsecured }) => ({
                productId,
                stockMaxSensei,
                stockMaxUnsecured,
            }));

        updateProductArrangement({
            variables: {
                productArrangement: {
                    _id: productArrangement._id,
                    name: productArrangement.name,
                    reassortmentType: productArrangement.reassortmentType,
                    productIdsWithStockMax: newProductIdsWithStockMax,
                },
            },
        });
    };

    const getContent = () => {
        if (!productArrangements.length) {
            return <p>Aucune gamme n'est associée à ce produit.</p>;
        }

        return (
            <TableContainer>
                <table>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Stock max confiance</th>
                            <th>Stock max sécurisé</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...productArrangements]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((arrangement) => {
                                const stockMax = arrangement.productIdsWithStockMax.find(
                                    (el) => el.productId === product._id,
                                );
                                return (
                                    <tr key={arrangement._id}>
                                        <Name
                                            onClick={() =>
                                                window.open(`/productArrangement/${arrangement._id}`, '_blank')
                                            }
                                        >
                                            {arrangement.name}
                                        </Name>
                                        <td>{stockMax?.stockMaxUnsecured}</td>
                                        <td>{stockMax?.stockMaxSensei}</td>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={() => onRemoveFromProductArrangement(arrangement)}
                                            >
                                                Retirer de la gamme
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </TableContainer>
        );
    };

    return (
        <SectionContainer title="Gammes de produit">
            <Container>
                {getContent()}
                <TotemPrimaryButton onClick={() => setShowAddProductArrangementPopup(true)} type="button">
                    Ajouter à une gamme de produit
                </TotemPrimaryButton>
                <AddProductArrangementPopup
                    isOpen={showAddProductArrangementPopup}
                    product={product}
                    setIsOpen={(isOpen) => setShowAddProductArrangementPopup(isOpen)}
                />
            </Container>
        </SectionContainer>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const TableContainer = styled.div`
    display: flex;

    table {
        border-spacing: 0;
        border-collapse: collapse;
        color: ${({ theme }) => theme.textColor};
        th,
        td {
            text-align: left;
            border: 1px solid ${({ theme }) => theme.lightBorderColor};
            padding: 16px;
        }
    }
`;

const Name = styled.td`
    font-weight: bold;
    cursor: pointer;
`;
