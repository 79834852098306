import { StatusOptions } from 'components/DetailsView/DetailFormStatusValue';
import { colors } from 'constants/colors';
import { MicrostoreElementState } from 'data/__generated__';

export const MICROSTORE_COLUMN_STATES_OPTIONS: Record<MicrostoreElementState, StatusOptions> = {
    [MicrostoreElementState.Regular]: {
        backgroundColor: colors.green,
        labelColor: colors.black,
        label: 'Régulier',
    },
    [MicrostoreElementState.Archived]: {
        backgroundColor: colors.lightGrey,
        labelColor: colors.black,
        label: 'Archivé',
    },
};
