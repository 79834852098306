import React, { useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { TransactionState, useGetTransactionQuery, useRefundTransactionMutation } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { TotemCheckbox } from 'components/TotemCheckbox';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { StateHistory } from 'components/DetailsView/StateHistory';
import { DetailValue } from 'components/DetailsView/DetailValue';
import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { TotemInput } from 'components/TotemInput';
import { StatusTag } from './components/StatusTag';
import { PageTitle } from 'components/PageTitle';
import { PaymentMethods } from './components/PaymentMethods';
import { DetailLink } from 'components/DetailsView';
import { CopyValue } from 'components/CopyValue';

import { getExtendedFormattedDatetime } from '../../helpers/dateTimes';
import { formatPrice } from '@totem/constants';

type ParamTypes = {
    transactionId: string;
};

export const TransactionDetails = () => {
    const { transactionId = '' } = useParams<ParamTypes>();
    const [shouldUpdateProductStock, setShouldUpdateProductStock] = useState(true);
    const [refundReasonInput, setRefundReasonInput] = useState('');
    const {
        loading: transactionLoading,
        data: transactionData,
        error: transactionError,
    } = useGetTransactionQuery({
        variables: { transactionId },
    });
    const [refundTransaction] = useRefundTransactionMutation();

    // TODO: Add subscriptions

    if (transactionLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (transactionError || !transactionData) {
        throw new Error('Une erreur est survenue lors de la récupération de la transaction');
    }

    const { transaction } = transactionData;

    const {
        _id,
        basketId,
        createdAt,
        paymentMethods,
        priceHT,
        productId,
        productName,
        refundReason,
        siteId,
        siteName,
        microstoreColumnId,
        microstoreColumnName,
        nayaxMDBCode,
        newState,
        mealPlan,
        stateHistory,
        tva,
        userId,
        userFullName,
    } = transaction;

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.transactionDetails} />
                </HeaderTitle>
                <CTAsContainer>
                    <Link to="/transactions">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </CTAsContainer>
            </Header>
            <Content>
                <ScrollableContent>
                    <SectionContainer title="Informations générales" keepOpen>
                        <SectionColumn>
                            <CopyValue value={_id} />
                            <DetailLink label="Site" name={siteName} value={siteId} path="site" />
                            {microstoreColumnId ? (
                                <DetailLink
                                    label="Colonne"
                                    name={microstoreColumnName}
                                    value={microstoreColumnId}
                                    path="microstoreColumn"
                                />
                            ) : null}
                            <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                            <DetailLink label="Utilisateur" name={userFullName} value={userId} path="user" />
                            <DetailLink label="Produit" name={productName} value={productId} path="product" />
                            <DetailValue label="Prix" value={formatPrice(priceHT * (1 + tva))} />
                            {basketId ? (
                                <DetailLink
                                    label="Panier Sensei"
                                    name={basketId}
                                    value={basketId}
                                    path="sensei-basket"
                                />
                            ) : null}
                            <DetailValue
                                label="Acheté en formule ?"
                                value={mealPlan ? `Oui (-${mealPlan?.discountRatio * 100}%)` : 'Non'}
                            />
                            {nayaxMDBCode ? <DetailValue label="Code MDB nayax" value={nayaxMDBCode} /> : null}
                            <DetailValue
                                label="Moyens de paiement utilisés"
                                value={
                                    paymentMethods && paymentMethods.length > 0 ? (
                                        <PaymentMethods paymentMethods={paymentMethods} />
                                    ) : (
                                        'Erreur : aucun moyen de paiment enregistré'
                                    )
                                }
                            />
                            <DetailValue label="Statut" value={<StatusTag state={newState} />} />
                            {refundReason ? <DetailValue label="Motif de remboursement" value={refundReason} /> : null}
                            <StateHistory label="Historique de statuts" records={formattedStateHistory} />
                            {[
                                TransactionState.Paid,
                                TransactionState.Processing,
                                TransactionState.Pending,
                                TransactionState.Failed,
                            ].includes(newState) ? (
                                <RefundContainer>
                                    <Title>
                                        {[TransactionState.Pending, TransactionState.Failed].includes(newState)
                                            ? 'Annuler le paiement'
                                            : 'Procéder au remboursement'}
                                    </Title>
                                    <TotemInput
                                        label="Commentaire (facultatif)"
                                        placeholder={'"L\'utilisateur a effectué cet achat en double"'}
                                        onChange={(value) => setRefundReasonInput(value)}
                                        value={refundReasonInput}
                                        data-test="transaction-details_refund-reason"
                                    />
                                    <TotemCheckbox
                                        label="Réinitialiser le stock avant transaction (par exemple, si la transaction est un doublon ou une erreur)"
                                        checked={shouldUpdateProductStock}
                                        onChange={() => setShouldUpdateProductStock(!shouldUpdateProductStock)}
                                    />
                                    <div>
                                        <TotemPrimaryButton
                                            data-test="transaction-details_refund-button"
                                            onClick={async () =>
                                                refundTransaction({
                                                    variables: {
                                                        transactionId,
                                                        refundReason: refundReasonInput,
                                                        shouldUpdateProductStock,
                                                    },
                                                })
                                            }
                                        >
                                            {[TransactionState.Pending, TransactionState.Failed].includes(newState)
                                                ? 'Annuler'
                                                : 'Rembourser'}{' '}
                                            cette transaction
                                        </TotemPrimaryButton>
                                    </div>
                                </RefundContainer>
                            ) : null}
                        </SectionColumn>
                    </SectionContainer>
                </ScrollableContent>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const RefundContainer = styled.div`
    margin-top: 15px;
    border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding-top: 15px;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const Title = styled.span`
    font-size: 28px;
    font-weight: 800;
    color: ${({ theme }) => theme.textColor};
`;
