import React, { useState } from 'react';

import styled from 'styled-components';

import { GetMicrostoreSitesQuery } from 'data/__generated__';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemCheckbox } from 'components/TotemCheckbox';
import { PrintComponent } from '../components/PrintComponent';
import { TotemLabel } from 'components/TotemLabel';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';

export function PrintPopup({
    isOpen,
    microstoreSites,
    nextMSDeliveryDate,
    setIsOpen,
}: {
    isOpen: boolean;
    microstoreSites: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'] | null;
    nextMSDeliveryDate: string;
    setIsOpen: (arg: boolean) => void;
}) {
    const nextMSDeliveryDayIndex = (dateFromString(nextMSDeliveryDate, true).getDay() + 6) % 7;
    const openAndActiveSiteIdsForNextDayDelivery =
        microstoreSites
            ?.filter(
                ({ deliveryDays, launchDate }) =>
                    deliveryDays[nextMSDeliveryDayIndex] &&
                    launchDate &&
                    nextMSDeliveryDate >=
                        formatDateAsAnniversary({ dateTime: dateFromString(launchDate), useNewFormat: true }),
            )
            .map(({ _id }) => _id) || [];

    const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);
    const [isSelectionValidated, setIsSelectionValidated] = useState<boolean>(false);

    function toggleSiteSelection(siteIdToToggle: string) {
        if (selectedSiteIds.includes(siteIdToToggle)) {
            setSelectedSiteIds(selectedSiteIds.filter((siteId) => siteId !== siteIdToToggle));
        } else {
            setSelectedSiteIds([...selectedSiteIds, siteIdToToggle]);
        }
    }

    return (
        <TotemPopup title="Impression" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="auto" position="fixed">
            <TotemLabel>Site grisé - pas de livraison le prochain jour de livraison de sites</TotemLabel>
            <Content>
                <Section>
                    <SectionTitle>Impression des plans</SectionTitle>
                    {!isSelectionValidated ? (
                        microstoreSites ? (
                            <>
                                <TotemCheckbox
                                    checked={openAndActiveSiteIdsForNextDayDelivery.every((siteId) =>
                                        selectedSiteIds.includes(siteId),
                                    )}
                                    onChange={() => {
                                        if (selectedSiteIds.length !== openAndActiveSiteIdsForNextDayDelivery.length) {
                                            setSelectedSiteIds(openAndActiveSiteIdsForNextDayDelivery);
                                        } else {
                                            setSelectedSiteIds([]);
                                        }
                                    }}
                                    label="Sélectionner tous les sites ouverts"
                                />
                                <CheckboxesContainer>
                                    {microstoreSites.map((site) => (
                                        <CheckboxContainer key={site._id}>
                                            <TotemCheckbox
                                                checked={selectedSiteIds.some((siteId) => siteId === site._id)}
                                                onChange={() => toggleSiteSelection(site._id)}
                                                label={`${site.name}${
                                                    openAndActiveSiteIdsForNextDayDelivery.includes(site._id)
                                                        ? ''
                                                        : ' (fermé)'
                                                }`}
                                                disabled={!site.deliveryDays[nextMSDeliveryDayIndex]}
                                            />
                                        </CheckboxContainer>
                                    ))}
                                </CheckboxesContainer>
                                <TotemPrimaryButton type="button" onClick={() => setIsSelectionValidated(true)}>
                                    Valider la sélection.
                                </TotemPrimaryButton>
                            </>
                        ) : (
                            <Loader mode={LoaderModeType.Spin} />
                        )
                    ) : (
                        <>
                            <PrintComponent siteIds={selectedSiteIds} />
                            <TotemPrimaryButton type="button" onClick={() => setIsSelectionValidated(false)}>
                                Retourner à la sélection.
                            </TotemPrimaryButton>
                        </>
                    )}
                </Section>
            </Content>
        </TotemPopup>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > :not(:first-child) {
        margin-top: 10px;
        border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const SectionTitle = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 800;
`;

const CheckboxesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const CheckboxContainer = styled.div`
    flex-basis: 25%;
`;
