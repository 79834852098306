import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';
import { defaultProduct, DefaultProductType } from 'pages/Products/constants/defaultProduct';

import { GET_ACTIVE_CATEGORIES_QUERY } from 'data/queries/category';
import { GET_ACTIVE_CATEGORIES } from 'data/queries/__generated__/GET_ACTIVE_CATEGORIES';
import { GET_ACTIVE_ORGANIZATIONS } from 'data/queries/__generated__/GET_ACTIVE_ORGANIZATIONS';
import { GET_ACTIVE_ORGANIZATIONS_QUERY } from 'data/queries/organization';
import { GET_TAGS_QUERY } from 'data/queries/tag';
import { GET_SUPPLIERS } from 'data/queries/__generated__/GET_SUPPLIERS';
import { GET_TAGS } from 'data/queries/__generated__/GET_TAGS';
import { CREATE_PRODUCTVariables, CREATE_PRODUCT } from 'data/mutations/__generated__/CREATE_PRODUCT';
import { BarcodeType, PriceRangeType, ProductSupplierUpdate, useGetProductPriceRangesQuery } from 'data/__generated__';
import { CREATE_PRODUCT_MUTATION } from 'data/mutations/product';
import { GET_SUPPLIERS_QUERY } from 'data/queries/supplier';

import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { DimensionsSection } from './ProductDetails/DimensionsSection';
import { GeneralInfoSection } from './ProductDetails/GeneralInfoSection';
import { SalesInfoSection } from './ProductDetails/SalesInfoSection';
import { NutritionalInfoSection } from './ProductDetails/NutritionalInfoSection';
import { StockAndSupplierInfoSection } from './ProductDetails/StockAndSupplierInfoSection';
import { TagsAndBadgesSection } from './ProductDetails/TagsAndBadgesSection';
import { formatFormArray } from 'pages/Products/utils';
import { ProductForm } from './ProductDetails/types';

type RangePrice = {
    rangeId: string;
    price: number;
    type: PriceRangeType;
};

type FormData = {
    access: { value: string; label: string }[];
    barcodes: { barcode: string; type: BarcodeType }[];
    brand: string;
    canExpire: boolean;
    checkSwollenLid: boolean;
    diets: { value: string; label: string }[];
    ingredients?: string | null;
    isBeingTested: boolean;
    isFreefood: boolean;
    isMicrostore: boolean;
    isPunctual: boolean;
    name: string;
    onboardedForSensei: boolean;
    preferences: { value: string; label: string }[];
    rangePrices: RangePrice[];
    shouldBeHiddenInStore: boolean;
    suppliers: ProductSupplierUpdate[];
    tagOptions: { value: string; label: string }[]; //object type used with UI Select, stocking as strings in DB
    trackExpiryDate: boolean;
    volume: string;
};

export const ProductCreate = () => {
    const navigate = useNavigate();
    const [createProduct, { loading: creationLoading }] = useMutation<CREATE_PRODUCT, CREATE_PRODUCTVariables>(
        CREATE_PRODUCT_MUTATION,
    );
    const initialProduct: DefaultProductType = { ...defaultProduct, nayaxProductId: null };

    const {
        loading: activeCategoriesLoading,
        data: activeCategoriesData,
        error: activeCategoriesError,
    } = useQuery<GET_ACTIVE_CATEGORIES>(GET_ACTIVE_CATEGORIES_QUERY);

    const {
        loading: productRangesLoading,
        data: productRangesData,
        error: productRangesError,
    } = useGetProductPriceRangesQuery();

    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useQuery<GET_ACTIVE_ORGANIZATIONS>(GET_ACTIVE_ORGANIZATIONS_QUERY);

    const { loading: tagsLoading, data: tagsData, error: tagsError } = useQuery<GET_TAGS>(GET_TAGS_QUERY);

    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
    } = useQuery<GET_SUPPLIERS>(GET_SUPPLIERS_QUERY);

    // This aims at progressively replacing obnoxious react-hook-form
    const initialForm = {
        categoryId: null,
        subcategoryId: null,
    };
    const [productForm, setProductForm] = useState<ProductForm>(initialForm);

    const methods = useForm<FormData>();
    const {
        handleSubmit,
        formState: { isDirty },
    } = methods;

    if (productRangesLoading || activeCategoriesLoading || tagsLoading || organizationsLoading || suppliersLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        organizationsError ||
        !organizationsData ||
        productRangesError ||
        !productRangesData ||
        activeCategoriesError ||
        !activeCategoriesData ||
        tagsError ||
        !tagsData ||
        suppliersError ||
        !suppliersData
    ) {
        throw new Error('Une erreur est survenue lors de la récupération des paramètres');
    }

    const { activeCategories: categories } = activeCategoriesData;
    const { activeOrganizations: organizations } = organizationsData;
    const { tags } = tagsData;

    const onSubmit = handleSubmit(async (fields) => {
        const tagIds = formatFormArray(fields.tagOptions);
        const formattedBarcodes = fields.barcodes.map(({ barcode, type }) => ({ barcode, type }));
        const formattedAccess = formatFormArray(fields.access);
        const formattedDiets = formatFormArray(fields.diets);
        const formattedPreferences = formatFormArray(fields.preferences);

        const { tagOptions, ...fieldsWithoutTags } = fields;

        if (!productForm.categoryId) {
            toast.error('Un produit doit être associé à une catégorie.');
            return;
        }

        const { data } = await createProduct({
            variables: {
                fields: {
                    ...fieldsWithoutTags,
                    access: formattedAccess,
                    barcodes: formattedBarcodes,
                    diets: formattedDiets,
                    preferences: formattedPreferences,
                    tagIds,
                    ...productForm,
                },
            },
        });

        if (data) {
            const {
                createProductResolver: { createdProductId, success, formErrors },
            } = data;
            if (success && createdProductId) {
                toast.success(`Le produit "${fields.name}" a bien été créé !`);
                navigate(`/product/${createdProductId}`);
            } else {
                if (formErrors) {
                    formErrors.forEach(({ sectionName, sectionErrors }, index) => {
                        toast.error(
                            <span key={index}>
                                Erreur dans la section "{sectionName}" :
                                {sectionErrors.map(({ fieldName, fieldError }, index) => (
                                    <span key={index}>
                                        <br />- {fieldName} : "{fieldError}"
                                    </span>
                                ))}
                            </span>,
                            { autoClose: false },
                        );
                    });

                    if (formErrors.length > 1) {
                        toast.info('Cliquez pour fermer toutes les notifications', {
                            autoClose: false,
                            onClick: () => toast.dismiss(),
                        });
                    }
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    const { productPriceRanges } = productRangesData;
    const { suppliers } = suppliersData;

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.productCreate} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton
                                data-test="create-button"
                                type="submit"
                                minWidth="78px"
                                disabled={!isDirty}
                            >
                                {creationLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Créer</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            <Link to="/products">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection
                                product={initialProduct}
                                categories={categories}
                                productForm={productForm}
                                setProductForm={setProductForm}
                            />
                            <SalesInfoSection
                                organizations={organizations}
                                product={initialProduct}
                                productPriceRanges={productPriceRanges}
                                isIncomingFromCreation={true}
                            />
                            <TagsAndBadgesSection tags={tags} isIncomingFromCreation={true} />
                            <NutritionalInfoSection product={initialProduct} isIncomingFromCreation={true} />
                            <DimensionsSection product={initialProduct} isIncomingFromCreation={true} />
                            <StockAndSupplierInfoSection
                                product={initialProduct}
                                isIncomingFromCreation={true}
                                suppliers={suppliers}
                            />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;
