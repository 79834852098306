export function renderAsPrice(price: number | null) {
    if (price === null) {
        return '-';
    }
    const eurosPart = Math.floor(price);
    const centsPart = Math.round((price - eurosPart) * 100);
    const formattedEuros = eurosPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
    return `${formattedEuros},${centsPart}${centsPart === 0 ? '0' : ''}\u00A0€`;
}

export function round(number: number, numberOfDecimals = 0): number {
    const multiplier = Math.pow(10, numberOfDecimals);
    return Math.round(number * multiplier) / multiplier;
}

export function roundPrice(number: number): number {
    return round(number, 2);
}
