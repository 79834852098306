import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { GetMicrostoreSiteQuery, GetMicrostoreSitesQuery } from 'data/__generated__';

import {
    UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOW_MUTATION,
    UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW_MUTATION,
} from 'data/mutations/microstoreColumn';
import {
    UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOW,
    UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOWVariables,
} from 'data/mutations/__generated__/UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOW';
import {
    UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW,
    UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOWVariables,
} from 'data/mutations/__generated__/UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemCheckbox } from 'components/TotemCheckbox';

import { microstoreUpdatedHandler } from 'pages/Sites/utils/sitesUpdatedHandler';
import { TotemLabel } from 'components/TotemLabel';
import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';

export function QuantityGenerationPopup({
    isOpen,
    microstoreSites,
    nextMSDeliveryDate,
    setIsOpen,
    site,
}: {
    isOpen: boolean;
    microstoreSites: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'] | null;
    nextMSDeliveryDate: string;
    setIsOpen: (arg: boolean) => void;
    site: GetMicrostoreSiteQuery['siteWithLocationInfo'];
}) {
    const nextMSDeliveryDayIndex = (dateFromString(nextMSDeliveryDate, true).getDay() + 6) % 7;
    const openAndActiveSiteIdsForNextDayDelivery =
        microstoreSites
            ?.filter(
                ({ deliveryDays, launchDate }) =>
                    deliveryDays[nextMSDeliveryDayIndex] &&
                    launchDate &&
                    nextMSDeliveryDate >=
                        formatDateAsAnniversary({ dateTime: dateFromString(launchDate), useNewFormat: true }),
            )
            .map(({ _id }) => _id) || [];

    const currentSiteDeliveryDays = microstoreSites?.find((msSite) => msSite._id === site._id)?.deliveryDays || [];

    // current version is 2
    const [shouldUseNewRestockingVersion, setShouldUseNewRestockingVersion] = useState<boolean>(true);
    const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);

    const [
        updateMicrostoresReassortmentForFreshFlowMutation,
        { loading: updateMicrostoresReassortmentForFreshFlowMutationLoading },
    ] = useMutation<
        UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOW,
        UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOWVariables
    >(UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOW_MUTATION);

    const [
        updateMicrostoresReassortmentForDryFlowMutation,
        { loading: updateMicrostoresReassortmentForDryFlowMutationLoading },
    ] = useMutation<
        UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW,
        UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOWVariables
    >(UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW_MUTATION);

    function toggleSiteSelection(siteIdToToggle: string) {
        if (selectedSiteIds.includes(siteIdToToggle)) {
            setSelectedSiteIds(selectedSiteIds.filter((siteId) => siteId !== siteIdToToggle));
        } else {
            setSelectedSiteIds([...selectedSiteIds, siteIdToToggle]);
        }
    }

    async function updateMicrostoreReassortmentForFreshFlow(siteIds: string[]) {
        const hasConfirmed = window.confirm(
            'Vous allez remplacer tout le réassort manuel du J+1 du/des site(s) avec les prédictions, êtes-vous sûr ?',
        );
        if (!hasConfirmed) {
            return;
        }

        const { data } = await updateMicrostoresReassortmentForFreshFlowMutation({
            variables: {
                shouldUseNewRestockingVersion,
                siteIds,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la mise à jour du/des microstore(s) avec les prédictions');
        }

        const {
            updateMicrostoresReassortmentForFreshFlowMutation: { errors, newSites, warnings },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });
        }

        if (warnings.length) {
            warnings.forEach((warning, index) => {
                toast.warning(<span key={index}>Warning : {warning}</span>, { autoClose: false });
            });
        }

        if (errors.length + warnings.length > 1) {
            toast.info('Cliquez pour fermer toutes les notifications', {
                autoClose: false,
                onClick: () => toast.dismiss(),
            });
        }

        if (newSites) {
            toast.success('Le(s) microstore(s) a/ont bien été modifié(s) !');
            microstoreUpdatedHandler({ siteId: site._id, updatedSites: newSites });
        }
    }

    async function updateMicrostoreReassortmentForDryFlow(siteIds: string[]) {
        const hasConfirmed = window.confirm(
            'Vous allez remplacer tout le réassort par stock cible du J+1 du/des site(s), êtes-vous sûr ?',
        );
        if (!hasConfirmed) {
            return;
        }

        const { data } = await updateMicrostoresReassortmentForDryFlowMutation({
            variables: {
                siteIds,
            },
        });

        if (!data) {
            throw new Error(
                'Une erreur est survenue lors de la mise à jour du/des microstore(s) avec les stocks cibles',
            );
        }

        const {
            updateMicrostoresReassortmentForDryFlowMutation: { errors, newSites },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        } else if (newSites) {
            toast.success('Le(s) microstore(s) a/ont bien été modifié(s) !');
            microstoreUpdatedHandler({ siteId: site._id, updatedSites: newSites });
        }
    }

    if (!microstoreSites) {
        return (
            <TotemPopup
                title="Génération des quantités"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentOverflow="auto"
                position="fixed"
            >
                <Loader mode={LoaderModeType.Spin} />
            </TotemPopup>
        );
    }

    return (
        <TotemPopup
            title="Génération des quantités"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="auto"
            position="fixed"
        >
            <TotemLabel>Site grisé - pas de livraison le prochain jour de livraison de sites</TotemLabel>
            <Content>
                <Section>
                    <SectionTitle>Sélection des sites</SectionTitle>
                    <TotemCheckbox
                        checked={openAndActiveSiteIdsForNextDayDelivery.every((siteId) =>
                            selectedSiteIds.includes(siteId),
                        )}
                        onChange={() => {
                            if (selectedSiteIds.length !== openAndActiveSiteIdsForNextDayDelivery.length) {
                                setSelectedSiteIds(openAndActiveSiteIdsForNextDayDelivery);
                            } else {
                                setSelectedSiteIds([]);
                            }
                        }}
                        label="Sélectionner tous les sites ouverts"
                    />
                    <CheckboxesContainer>
                        {microstoreSites.map((site) => (
                            <CheckboxContainer key={site._id}>
                                <TotemCheckbox
                                    checked={selectedSiteIds.some((siteId) => siteId === site._id)}
                                    onChange={() => toggleSiteSelection(site._id)}
                                    label={`${site.name}${
                                        openAndActiveSiteIdsForNextDayDelivery.includes(site._id) ? '' : ' (fermé)'
                                    }`}
                                    disabled={!site.deliveryDays[nextMSDeliveryDayIndex]}
                                />
                            </CheckboxContainer>
                        ))}
                    </CheckboxesContainer>
                </Section>
                <Section>
                    <SectionTitle>Flow DRY</SectionTitle>
                    <TotemPrimaryButton
                        type="button"
                        title="Générer les quantités pour flow DRY du/des site(s) sélectionné(s)"
                        disabled={updateMicrostoresReassortmentForDryFlowMutationLoading}
                        onClick={() => updateMicrostoreReassortmentForDryFlow(selectedSiteIds)}
                    >
                        {updateMicrostoresReassortmentForDryFlowMutationLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <span>Générer les quantités pour flow DRY du/des site(s) sélectionné(s)</span>
                        )}
                    </TotemPrimaryButton>
                    <TotemPrimaryButton
                        type="button"
                        title="Générer les quantités"
                        disabled={
                            updateMicrostoresReassortmentForDryFlowMutationLoading ||
                            !currentSiteDeliveryDays[nextMSDeliveryDayIndex]
                        }
                        onClick={() => updateMicrostoreReassortmentForDryFlow([site._id])}
                    >
                        {updateMicrostoresReassortmentForDryFlowMutationLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <span>Générer les quantités de {site.name}</span>
                        )}
                    </TotemPrimaryButton>
                </Section>
                <Section>
                    <SectionTitle>Flow FRESH</SectionTitle>
                    <TotemCheckbox
                        checked={shouldUseNewRestockingVersion}
                        onChange={() => setShouldUseNewRestockingVersion(!shouldUseNewRestockingVersion)}
                        label="Utiliser la nouvelle version du restocking"
                    />
                    <TotemPrimaryButton
                        type="button"
                        title="Générer les quantités pour flow FRESH pour le(s) site(s) sélectionné(s) (prédictions + stock cible)"
                        disabled={updateMicrostoresReassortmentForFreshFlowMutationLoading}
                        onClick={() => updateMicrostoreReassortmentForFreshFlow(selectedSiteIds)}
                    >
                        {updateMicrostoresReassortmentForFreshFlowMutationLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <span>
                                Générer les quantités pour flow FRESH pour le(s) site(s) sélectionné(s) (prédictions +
                                stock cible)
                            </span>
                        )}
                    </TotemPrimaryButton>
                    <TotemPrimaryButton
                        type="button"
                        title="Générer les quantités"
                        disabled={
                            updateMicrostoresReassortmentForFreshFlowMutationLoading ||
                            !currentSiteDeliveryDays[nextMSDeliveryDayIndex]
                        }
                        onClick={() => updateMicrostoreReassortmentForFreshFlow([site._id])}
                    >
                        {updateMicrostoresReassortmentForFreshFlowMutationLoading ? (
                            <Loader size="18px" mode={LoaderModeType.Spin} />
                        ) : (
                            <span>Générer les quantités pour {site.name}</span>
                        )}
                    </TotemPrimaryButton>
                </Section>
            </Content>
        </TotemPopup>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > :not(:first-child) {
        margin-top: 10px;
        border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const SectionTitle = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 800;
`;

const CheckboxesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const CheckboxContainer = styled.div`
    flex-basis: 25%;
`;
