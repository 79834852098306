import React, { useEffect } from 'react';

import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { FaSave, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    TotemState,
    GetDetailedTotemsOfOrganizationQuery,
    GetEquipmentsQuery,
    useReactivateTotemMutation,
    useUpdateTotemMutation,
    useArchiveTotemMutation,
} from 'data/__generated__';

import { LOCATION_COLUMN_LABELS } from 'pages/Locations';

import {
    DetailFormCheckbox,
    DetailFormSelect,
    DetailFormTextArea,
    DetailFormValue,
    DetailLink,
    DetailValue,
    StateHistory,
} from 'components/DetailsView';
import { TotemLabel } from 'components/TotemLabel';
import { SectionColumn } from 'components/DetailsView/Section';
import { StatusTag } from './StatusTag';
import { TotemImage } from 'components/TotemImage';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Option } from 'components/TotemSelect';
import { CopyValue } from 'components/CopyValue';
import { AdresseInfoForm } from './AdresseInfoForm';
import { EquipmentSelector } from 'pages/Organizations/OrganizationDetails/TotemsDetails/EquipmentSelector';
import { colors } from 'constants/colors';

function getSalesforceLink(salesforceId?: string | null): string {
    return `https://thetotem.lightning.force.com/lightning/r/Totem__c/${salesforceId}/view`;
}

type FormData = {
    name: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
    latitude: number;
    longitude: number;
    address_details: string;
    consigneDelivery: string;
    accessTime: number;
    equipments: {
        equipmentId: string;
        quantity: number;
        comment: string;
    }[];
    mainUserId: string;
    minimumOrderAmountInfo: {
        minimumBakeryAmount: number;
        minimumDryAmount: number;
        minimumFreshAmount: number;
        minimumFruitAmount: number;
        minimumTotalAmount: number;
    };
    needSignature: boolean;
};

export const TotemInfo = ({
    totem,
    mainUserOptions,
    equipments,
}: {
    totem: GetDetailedTotemsOfOrganizationQuery['totemsOfOrganization'][number];
    mainUserOptions: Option<string>[];
    equipments: GetEquipmentsQuery['adminEquipments'];
}) => {
    const [archiveTotem] = useArchiveTotemMutation();
    const [reactivateTotem] = useReactivateTotemMutation();
    const [updateTotem] = useUpdateTotemMutation();

    const {
        _id: totemId,
        accessTime,
        address,
        address_details,
        city,
        zipcode,
        consigneDelivery,
        country,
        equipments: totemEquipments,
        latitude,
        longitude,
        mainUser,
        minimumOrderAmountInfo,
        name,
        needSignature,
        number,
        salesforceId,
        state,
        stateHistory,
    } = totem;

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    const methods = useForm<FormData>();
    const {
        setValue,
        handleSubmit,
        control,
        register,
        watch,
        formState: { isDirty },
    } = methods;

    const {
        fields: equipmentFields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'equipments' as const,
    });

    useEffect(() => {
        if (totemEquipments) {
            const totemEquipmentFields = totemEquipments.map(({ equipmentId, quantity, comment }) => ({
                equipmentId,
                quantity,
                comment: comment ?? '',
            }));
            setValue('equipments', totemEquipmentFields);
        }

        // Initialisation des valeurs d'adresse
        setValue('address', address ?? '');
        setValue('city', city ?? '');
        setValue('zipcode', zipcode ?? '');
        setValue('country', country ?? '');
        setValue('latitude', latitude ?? 0);
        setValue('longitude', longitude ?? 0);
    }, [totemEquipments, setValue, city, zipcode, country, latitude, longitude, address]);

    const totemName = name ? `${name} (#${number})` : `TOTEM #${number}`;

    const onSubmit = handleSubmit(async (fields) => {
        console.log('fields', fields);
        const { data } = await updateTotem({
            variables: { totemId, fields },
        });
        if (data) {
            const {
                updateTotem: { error, formErrors, updatedTotem },
            } = data;
            if (updatedTotem) {
                toast.success(`Le totem "${totemName}" a bien été modifié !`);
            } else if (formErrors) {
                formErrors.forEach(({ sectionName, sectionErrors }, index) => {
                    toast.error(
                        <span key={index}>
                            Erreur dans la section "{sectionName}" :
                            {sectionErrors.map(({ fieldName, fieldError }, index) => (
                                <span key={index}>
                                    <br />- {fieldName} : "{fieldError}"
                                </span>
                            ))}
                        </span>,
                        { autoClose: false },
                    );
                });
                if (formErrors.length > 1) {
                    toast.info('Cliquez pour fermer toutes les notifications', {
                        autoClose: false,
                        onClick: () => toast.dismiss(),
                    });
                }
            } else if (error) {
                toast.error(error);
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        }
    });

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Title>{totemName}</Title>
                <InnerForm>
                    <SectionColumn style={{ position: 'relative' }}>
                        <CopyValue value={totemId} />
                        <DetailFormValue
                            defaultValue={name}
                            {...register('name', { required: true })}
                            label="Nom du totem"
                        />
                        <ExternalResource>
                            <ExternalResourceLink
                                href={getSalesforceLink(salesforceId)}
                                target="_blank"
                                rel="noopener noreferrer"
                                disabled={!salesforceId}
                            >
                                <TotemImage
                                    src="https://thetotem.my.salesforce.com/img/logo214.svg"
                                    alt="Logo salesforce"
                                    size={30}
                                />
                                <span>{salesforceId || 'Pas de salesforceId'}</span>
                            </ExternalResourceLink>
                        </ExternalResource>
                        <DetailFormCheckbox
                            name="needSignature"
                            label="Signature à la livraison"
                            defaultChecked={needSignature}
                        />
                        <DetailFormValue
                            defaultValue={accessTime}
                            type="number"
                            step="1"
                            {...register('accessTime', { required: true, valueAsNumber: true })}
                            label="Temps d'accès"
                        />
                        <DetailFormValue
                            defaultValue={minimumOrderAmountInfo.minimumTotalAmount}
                            type="number"
                            step="1"
                            {...register('minimumOrderAmountInfo.minimumTotalAmount', {
                                required: true,
                                valueAsNumber: true,
                            })}
                            label="Montant minimal de commande (Total)"
                        />
                        <DetailFormValue
                            defaultValue={minimumOrderAmountInfo.minimumBakeryAmount}
                            type="number"
                            step="1"
                            {...register('minimumOrderAmountInfo.minimumBakeryAmount', {
                                required: true,
                                valueAsNumber: true,
                            })}
                            label={`Montant minimal de commande pour flux ${LOCATION_COLUMN_LABELS.bakery}`}
                        />
                        <DetailFormValue
                            defaultValue={minimumOrderAmountInfo.minimumDryAmount}
                            type="number"
                            step="1"
                            {...register('minimumOrderAmountInfo.minimumDryAmount', {
                                required: true,
                                valueAsNumber: true,
                            })}
                            label={`Montant minimal de commande pour flux ${LOCATION_COLUMN_LABELS.dry}`}
                        />
                        <DetailFormValue
                            defaultValue={minimumOrderAmountInfo.minimumFreshAmount}
                            type="number"
                            step="1"
                            {...register('minimumOrderAmountInfo.minimumFreshAmount', {
                                required: true,
                                valueAsNumber: true,
                            })}
                            label={`Montant minimal de commande pour flux ${LOCATION_COLUMN_LABELS.fresh}`}
                        />
                        <DetailFormValue
                            defaultValue={minimumOrderAmountInfo.minimumFruitAmount}
                            type="number"
                            step="1"
                            {...register('minimumOrderAmountInfo.minimumFruitAmount', {
                                required: true,
                                valueAsNumber: true,
                            })}
                            label={`Montant minimal de commande pour flux ${LOCATION_COLUMN_LABELS.fruit}`}
                        />
                        <DetailFormTextArea
                            defaultValue={minimumOrderAmountInfo.minimumFruitAmount}
                            {...register('minimumOrderAmountInfo.minimumFruitAmount', {
                                required: true,
                                valueAsNumber: true,
                            })}
                            label={`Montant minimal de commande pour flux ${LOCATION_COLUMN_LABELS.fruit}`}
                        />
                        <div>
                            <TotemLabel>Adresse</TotemLabel>
                            <div>
                                {watch('address')}
                                <br />
                                {watch('zipcode')} {watch('city')} {watch('country')}
                                <br />
                                <br />
                                Lat: {watch('latitude')}, Long: {watch('longitude')}
                            </div>
                        </div>
                        <AdresseInfoForm totem={totem} />
                        <DetailFormTextArea
                            defaultValue={address_details}
                            {...register('address_details', {
                                required: true,
                            })}
                            label="Complément d'adresse"
                        />
                        <DetailFormTextArea
                            defaultValue={consigneDelivery}
                            {...register('consigneDelivery', {
                                required: true,
                            })}
                            label="Instructions de livraison"
                        />
                        <DetailFormSelect<string>
                            label="Utilisateur principal"
                            placeholder="Sélectionner un admin"
                            defaultValue={mainUser?._id}
                            name="mainUserId"
                            options={mainUserOptions}
                        />
                        {mainUser ? (
                            <DetailLink
                                path="user"
                                name={`Plus d'infos sur ${mainUser.fullName}`}
                                value={mainUser._id}
                            />
                        ) : null}
                        <StatusTag state={state} showHeading />
                        <StateHistory records={formattedStateHistory} />
                        <TotemLabel>Équipements</TotemLabel>
                        {equipmentFields?.length ? (
                            <EquipmentsSection>
                                {equipmentFields.map((item, index) => {
                                    const equipment = equipments.find(({ _id }) => _id === item.equipmentId);
                                    const equipmentName = equipment?.fullname || item.equipmentId;
                                    return (
                                        <ItemContainer key={item.equipmentId}>
                                            <DetailValue value={equipmentName} label="Nom" />
                                            <DetailFormValue
                                                defaultValue={item.quantity}
                                                label="Quantité"
                                                type="number"
                                                step="1"
                                                min="1"
                                                {...register(`equipments.${index}.quantity`, {
                                                    required: true,
                                                    valueAsNumber: true,
                                                })}
                                                style={{ marginTop: 10 }}
                                            />
                                            <DetailFormTextArea
                                                label="Commentaires"
                                                defaultValue={item.comment}
                                                height="100px"
                                                style={{ marginTop: 10 }}
                                                {...register(`equipments.${index}.comment`)}
                                            />
                                            <IconContainer onClick={() => remove(index)}>
                                                <TrashIcon size={16} color={colors.pureWhite} />
                                            </IconContainer>
                                        </ItemContainer>
                                    );
                                })}
                            </EquipmentsSection>
                        ) : null}
                        <AddContainer>
                            <EquipmentSelector
                                equipmentFields={equipmentFields}
                                equipments={equipments}
                                append={append}
                            />
                        </AddContainer>
                        {state === TotemState.Archived ? (
                            <TotemPrimaryButton
                                type="button"
                                onClick={() => reactivateTotem({ variables: { totemId } })}
                            >
                                Réactiver le totem
                            </TotemPrimaryButton>
                        ) : (
                            <TotemPrimaryButton type="button" onClick={() => archiveTotem({ variables: { totemId } })}>
                                Archiver le totem
                            </TotemPrimaryButton>
                        )}
                    </SectionColumn>
                    <SaveButtonContainer>
                        <TotemPrimaryButton type="submit" disabled={!isDirty}>
                            <FaSave size="13" />
                        </TotemPrimaryButton>
                    </SaveButtonContainer>
                </InnerForm>
            </Form>
        </FormProvider>
    );
};

const Form = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: 800;
    text-align: center;
`;

const InnerForm = styled.div`
    height: 100%;
    overflow-y: scroll;
    padding: 10px;
    margin-top: 20px;
`;

const SaveButtonContainer = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
`;

const ExternalResource = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    color: ${({ theme }) => theme.ctaPrimaryColor};

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const ExternalResourceLink = styled.a<{ disabled: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
        text-decoration: underline;
    }

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const EquipmentsSection = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const ItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const AddContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100px;
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const IconContainer = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${({ theme }) => theme.ctaPrimaryColor};
    cursor: pointer;
`;
