import React from 'react';
import { MicrostoreColumnTemplateType } from 'data/__generated__';
import { Option } from 'components/TotemSelect';
import { SelectFilter } from './SelectFilter';

interface TypeFilterProps {
    value: MicrostoreColumnTemplateType | null;
    onChange: (value: MicrostoreColumnTemplateType | null) => void;
}

export const TypeFilter: React.FC<TypeFilterProps> = ({ value, onChange }) => {
    const options = React.useMemo(
        () =>
            Object.values(MicrostoreColumnTemplateType).map((type) => ({
                label: type,
                value: type,
            })),
        [],
    );

    const selectedOption = React.useMemo(
        () => (value ? options.find((option) => option.value === value) || null : null),
        [value, options],
    );

    const handleChange = (option: Option<MicrostoreColumnTemplateType> | null) => {
        onChange(option?.value || null);
    };

    return (
        <SelectFilter<MicrostoreColumnTemplateType>
            placeholder="Type de colonne"
            value={selectedOption}
            options={options}
            onChange={handleChange}
        />
    );
};
