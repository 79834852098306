import React, { useEffect, useState } from 'react';

import { unstable_usePrompt, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImArrowLeft2, ImArrowUp2 } from 'react-icons/im';
import styled from 'styled-components';

import {
    SiteState,
    useGetMicrostoreProductsStockInfoQuery,
    useGetMicrostoreSiteQuery,
    useGetMicrostoreSitesQuery,
    useGetNextMsDeliveryDateQuery,
    useUpdateMicrostoreColumnMutation,
} from 'data/__generated__';

import { FullPageLoader } from 'components/FullPageLoader';
import { Loader } from 'components/Loader';
import { ColumnSelector } from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/ColumnSelector';
import { ColumnPanel } from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/ColumnPanel';
import { SplitPanels } from 'components/SplitPanels';
import { LocationEditor } from './LocationEditor';
import { ExpiryDateEditor } from './ExpiryDateEditor';
import { MicrostoreEditor } from './MicrostoreEditor';

import {
    mapMicrostoreColumnFormValuesToMicrostoreColumnUpdateInput,
    MicrostoreColumnFormValues,
    MicrostoreLocationFormValues,
    mapMicrostoreColumnGroupToMicrostoreColumnGroupFormValues,
    MicrostoreShelfFormValues,
    mapMicrostoreProductsStockInfoQueryToFormValues,
    MicrostoreColumnGroupFormValues,
} from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/FormHelper/DataMapper';
import { getNoStockInLocationWarnings } from './FormHelper/stockInLocationHelper';

type ParamTypes = {
    siteId: string;
};

const tomorrowDate = new Date();
tomorrowDate.setDate(tomorrowDate.getDate() + 1);

export const MicrostoreColumnGroupDetails = () => {
    const { siteId = '' } = useParams<ParamTypes>();

    // form data
    const [isMicrostoreEditorOpen, setIsMicrostoreEditorOpen] = useState<boolean>(false);
    const [microstoreColumnGroupFormValuesArray, setMicrostoreColumnGroupFormValuesArray] = useState<
        MicrostoreColumnGroupFormValues[] | null
    >(null);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

    // location selection
    const [isToday, setIsToday] = useState<boolean>(true);
    const [selectedColumnGroupId, setSelectedColumnGroupId] = useState<string | null>(null);
    const [selectedColumnId, setSelectedColumnId] = useState<string | null>(null);
    const [selectedShelfIndex, setSelectedShelfIndex] = useState<number | null>(null);
    const [selectedLocationPosition, setSelectedLocationPosition] = useState<{ column: number; row: number } | null>(
        null,
    );

    const [revertUpdateStack, setRevertUpdateStack] = useState<(() => void)[]>([]);

    const selectedColumnGroup: MicrostoreColumnGroupFormValues | null =
        microstoreColumnGroupFormValuesArray?.find(({ _id }) => _id === selectedColumnGroupId) ?? null;

    const selectedColumn: MicrostoreColumnFormValues | null =
        selectedColumnGroup?.columns?.find(({ _id }) => _id === selectedColumnId) ?? null;

    const selectedShelf: MicrostoreShelfFormValues | null =
        selectedShelfIndex !== null ? selectedColumn?.shelves?.[selectedShelfIndex] ?? null : null;

    const selectedLocation: MicrostoreLocationFormValues | null =
        selectedLocationPosition !== null
            ? selectedShelf?.locationTable?.[selectedLocationPosition.row]?.[selectedLocationPosition.column] ?? null
            : null;

    const switchColumn =
        (selectedColumn?.columnIdToReplace
            ? selectedColumnGroup?.columns?.find(({ _id }) => _id === selectedColumn.columnIdToReplace)
            : selectedColumnGroup?.columns?.find(
                  ({ columnIdToReplace }) => columnIdToReplace === selectedColumn?._id,
              )) ?? null;

    const {
        loading: nextMSDeliveryDateLoading,
        data: nextMSDeliveryDateData,
        error: nextMSDeliveryDateError,
    } = useGetNextMsDeliveryDateQuery();

    const {
        loading: microstoreSiteLoading,
        data: microstoreSiteData,
        error: microstoreSiteError,
    } = useGetMicrostoreSiteQuery({
        variables: {
            siteId,
        },
    });

    const {
        loading: getMicrostoreProductsStockInfoLoading,
        data: getMicrostoreProductsStockInfoData,
        error: getMicrostoreProductsStockInfoError,
    } = useGetMicrostoreProductsStockInfoQuery({
        variables: {
            siteId,
        },
    });

    // we pass siteId as a variable to make the query refetch on page change
    const {
        loading: sitesLoading,
        data: sitesData,
        error: sitesError,
    } = useGetMicrostoreSitesQuery({
        fetchPolicy: 'cache-and-network',
    });

    const [updateMicrostoreColumn, { loading: updateLoading }] = useUpdateMicrostoreColumnMutation();

    useEffect(() => {
        const newMicrostoreColumnGroupFormValuesArray =
            microstoreSiteData?.siteWithLocationInfo.microstoreColumnGroups.map((microstoreColumnGroup) =>
                mapMicrostoreColumnGroupToMicrostoreColumnGroupFormValues({
                    microstoreColumnGroup,
                }),
            ) ?? null;
        setMicrostoreColumnGroupFormValuesArray(newMicrostoreColumnGroupFormValuesArray);

        if (newMicrostoreColumnGroupFormValuesArray?.length) {
            setSelectedColumnId(newMicrostoreColumnGroupFormValuesArray[0]._id);
        }
    }, [microstoreSiteData, setMicrostoreColumnGroupFormValuesArray, setSelectedColumnId]);

    unstable_usePrompt({
        message: 'Êtes-vous sûr.e de vouloir quitter cette page sans enregistrer ?',
        when: ({ currentLocation, nextLocation }) => isFormDirty && currentLocation.pathname !== nextLocation.pathname,
    });

    if (microstoreSiteLoading || nextMSDeliveryDateLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        microstoreSiteError ||
        !microstoreSiteData ||
        nextMSDeliveryDateError ||
        !nextMSDeliveryDateData ||
        (!sitesLoading && (sitesError || !sitesData)) ||
        (!getMicrostoreProductsStockInfoLoading &&
            (getMicrostoreProductsStockInfoError || !getMicrostoreProductsStockInfoData))
    ) {
        throw new Error('Une erreur est survenue lors de la récupération du template de colonne');
    }

    if (!microstoreColumnGroupFormValuesArray) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const { getNextMSDeliveryDate: nextMSDeliveryDate } = nextMSDeliveryDateData;
    const { siteWithLocationInfo: site } = microstoreSiteData;
    const unsortedMicrostoreSites = sitesLoading ? null : sitesData?.microstoreSitesWithColumnGroups ?? null;
    const microstoreSites = unsortedMicrostoreSites
        ? [...unsortedMicrostoreSites].sort((siteA, siteB) => siteA.name.localeCompare(siteB.name))
        : null;
    const activeMicrostoreSites = microstoreSites?.filter(({ state }) => state !== SiteState.Archived) || null;

    const microstoreProductsStockInfo = getMicrostoreProductsStockInfoLoading
        ? null
        : mapMicrostoreProductsStockInfoQueryToFormValues({
              microstoreColumnGroupFormValuesArray,
              microstoreProductsStockInfoQueryData:
                  getMicrostoreProductsStockInfoData?.getMicrostoreProductsStockInfoQuery,
          });

    function changeSelectedColumnId(columnId: string | null) {
        if (isFormDirty) {
            return;
        }
        setSelectedColumnId(columnId);
        setSelectedShelfIndex(null);
        setSelectedLocationPosition(null);
    }

    function updateSelectedColumn(
        values: Partial<MicrostoreColumnFormValues>,
        isRevertUpdate?: boolean,
        revertActionSelectedShelfIndex?: number | null,
        revertActionSelectedLocationPosition?: { column: number; row: number } | null,
        wasRevertActionToday?: boolean,
    ) {
        if (
            microstoreColumnGroupFormValuesArray === null ||
            selectedColumnGroupId === null ||
            selectedColumnGroup === null ||
            selectedColumnId === null ||
            selectedColumn === null
        ) {
            return;
        }

        const selectedColumnIndex = selectedColumnGroup.columns.findIndex(({ _id }) => _id === selectedColumnId);

        const newColumn: MicrostoreColumnFormValues = {
            ...selectedColumn,
            ...values,
        };
        const newColumnGroup: MicrostoreColumnGroupFormValues = {
            ...selectedColumnGroup,
            columns: [
                ...selectedColumnGroup.columns.slice(0, selectedColumnIndex),
                newColumn,
                ...selectedColumnGroup.columns.slice(selectedColumnIndex + 1),
            ],
        };

        const selectedColumnGroupIndex = microstoreColumnGroupFormValuesArray.findIndex(
            ({ _id }) => _id === selectedColumnGroupId,
        );

        const newMicrostoreColumnGroupFormValuesArray = [
            ...microstoreColumnGroupFormValuesArray.slice(0, selectedColumnGroupIndex),
            newColumnGroup,
            ...microstoreColumnGroupFormValuesArray.slice(selectedColumnGroupIndex + 1),
        ];

        setMicrostoreColumnGroupFormValuesArray(newMicrostoreColumnGroupFormValuesArray);
        setIsFormDirty(true);

        if (!isRevertUpdate) {
            const newRevertUpdate = () =>
                updateSelectedColumn(selectedColumn, true, selectedShelfIndex, selectedLocationPosition, isToday);
            setRevertUpdateStack([newRevertUpdate, ...revertUpdateStack]);
        } else {
            setSelectedShelfIndex(revertActionSelectedShelfIndex ?? null);
            setSelectedLocationPosition(revertActionSelectedLocationPosition ?? null);
            if (wasRevertActionToday) {
                setIsToday(true);
            } else {
                setIsToday(false);
            }
        }
    }

    function updateSelectedLocation(values: Partial<MicrostoreLocationFormValues>) {
        if (
            selectedColumn === null ||
            selectedShelfIndex === null ||
            selectedShelf === null ||
            selectedLocationPosition === null ||
            selectedLocation === null
        ) {
            return;
        }

        const newLocation = {
            ...selectedLocation,
            ...values,
        };
        const selectedRow = selectedShelf.locationTable[selectedLocationPosition.row];
        const newLocationRow = [
            ...selectedRow.slice(0, selectedLocationPosition.column),
            newLocation,
            ...selectedRow.slice(selectedLocationPosition.column + 1),
        ];
        const newShelf: MicrostoreShelfFormValues = {
            ...selectedShelf,
            locationTable: [
                ...selectedShelf.locationTable.slice(0, selectedLocationPosition.row),
                newLocationRow,
                ...selectedShelf.locationTable.slice(selectedLocationPosition.row + 1),
            ],
        };
        const newColumn: MicrostoreColumnFormValues = {
            ...selectedColumn,
            shelves: [
                ...selectedColumn.shelves.slice(0, selectedShelfIndex),
                newShelf,
                ...selectedColumn.shelves.slice(selectedShelfIndex + 1),
            ],
        };

        updateSelectedColumn(newColumn);
    }

    function revertLastUpdate() {
        const [revertUpdate, ...otherRevertUpdate] = revertUpdateStack;
        if (revertUpdate) {
            revertUpdate();
        }
        setRevertUpdateStack([...otherRevertUpdate]);
    }

    async function submitColumnUpdate() {
        if (!selectedColumn || !microstoreColumnGroupFormValuesArray) {
            return;
        }

        const microstoreColumnUpdateInput = mapMicrostoreColumnFormValuesToMicrostoreColumnUpdateInput({
            microstoreColumnFormValues: selectedColumn,
        });
        const { data } = await updateMicrostoreColumn({
            variables: { microstoreColumnUpdateInput },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la modification du template de colonne');
        }

        const {
            updateMicrostoreColumnMutation: { newMicrostoreColumn, errors },
        } = data;

        const allSiteLocations = microstoreColumnGroupFormValuesArray.flatMap((columnGroup) =>
            columnGroup.columns.flatMap((column) =>
                column.shelves.flatMap((shelf) => shelf.locationTable.flatMap((locationRow) => locationRow)),
            ),
        );

        const warnings = getNoStockInLocationWarnings({
            allSiteLocations,
            microstoreColumnFormValue: selectedColumn,
            microstoreProductsStockInfo,
        });

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });
        }

        if (warnings.length) {
            warnings.forEach((warning, index) => {
                toast.warning(<span key={index}>Attention : {warning}</span>, { autoClose: false });
            });
        }

        if (errors.length + warnings.length > 1) {
            toast.info('Cliquez pour fermer toutes les notifications', {
                autoClose: false,
                onClick: () => toast.dismiss(),
            });
        }

        if (newMicrostoreColumn) {
            toast.success('La colonne a bien été modifiée !');
            setIsFormDirty(false);
            setRevertUpdateStack([]);
        }
    }

    return (
        <>
            <Container>
                <MicrostoreEditor
                    changeSelectedColumnId={changeSelectedColumnId}
                    currentSite={site}
                    isOpen={isMicrostoreEditorOpen}
                    microstoreSites={microstoreSites}
                    setIsOpen={setIsMicrostoreEditorOpen}
                />
                <SplitPanels
                    displayEnlarge={[false, false, true, false]}
                    displayShrink={[false, true, false, false]}
                    haveMargins={[false, false, false, false]}
                    initialSizes={[5, 5, 65, 25]}
                    name="column-panels-new"
                >
                    <PanelContainer>
                        <ColumnSelector
                            changeSelectedColumnId={changeSelectedColumnId}
                            microstoreColumnGroupFormValuesArray={microstoreColumnGroupFormValuesArray}
                            isFormDirty={isFormDirty}
                            microstoreProductsStockInfo={microstoreProductsStockInfo}
                            activeMicrostoreSites={activeMicrostoreSites}
                            setIsMicrostoreEditorOpen={setIsMicrostoreEditorOpen}
                            selectedColumnId={selectedColumnId}
                            selectedColumnGroupId={selectedColumnGroupId}
                            setSelectedColumnGroupId={setSelectedColumnGroupId}
                            site={site}
                            nextMSDeliveryDate={nextMSDeliveryDate}
                        />
                    </PanelContainer>
                    <PanelContainer>
                        {selectedColumnGroup && switchColumn ? (
                            <ColumnPanel
                                canBeUpdated={false}
                                isFormDirty={isFormDirty}
                                isToday={isToday}
                                earliestDLCDateToKeepForStockCalculation={
                                    site.earliestDLCDateToKeepForStockCalculation || null
                                }
                                microstoreColumnGroupFormValuesArray={microstoreColumnGroupFormValuesArray}
                                microstoreProductsStockInfo={microstoreProductsStockInfo}
                                nextMSDeliveryDate={nextMSDeliveryDate}
                                nextSiteDeliveryDate={site.nextDeliveryDate || null}
                                revertLastUpdate={revertLastUpdate}
                                selectedColumnGroup={selectedColumnGroup}
                                selectedColumn={switchColumn}
                                selectedLocationPosition={selectedLocationPosition}
                                selectedShelfIndex={selectedShelfIndex}
                                setIsToday={setIsToday}
                                setSelectedLocationPosition={setSelectedLocationPosition}
                                setSelectedShelfIndex={setSelectedShelfIndex}
                                site={site}
                                submitColumnUpdate={submitColumnUpdate}
                                switchColumn={selectedColumn}
                                updateSelectedColumn={updateSelectedColumn}
                                updateSelectedLocation={updateSelectedLocation}
                            />
                        ) : (
                            <SelectColumnInfo>
                                <VerticalText>PAS DE SWITCH PRÉVU</VerticalText>
                            </SelectColumnInfo>
                        )}
                    </PanelContainer>
                    <PanelContainer>
                        {selectedColumnGroup && selectedColumn ? (
                            <ColumnPanel
                                isFormDirty={isFormDirty}
                                isToday={isToday}
                                microstoreColumnGroupFormValuesArray={microstoreColumnGroupFormValuesArray}
                                microstoreProductsStockInfo={microstoreProductsStockInfo}
                                getMicrostoreProductsStockInfoLoading={getMicrostoreProductsStockInfoLoading}
                                nextMSDeliveryDate={nextMSDeliveryDate}
                                revertLastUpdate={revertLastUpdate}
                                selectedColumnGroup={selectedColumnGroup}
                                selectedColumn={selectedColumn}
                                selectedLocationPosition={selectedLocationPosition}
                                selectedShelfIndex={selectedShelfIndex}
                                setIsToday={setIsToday}
                                setSelectedLocationPosition={setSelectedLocationPosition}
                                setSelectedShelfIndex={setSelectedShelfIndex}
                                site={site}
                                nextSiteDeliveryDate={site.nextDeliveryDate || null}
                                earliestDLCDateToKeepForStockCalculation={
                                    site.earliestDLCDateToKeepForStockCalculation || null
                                }
                                submitColumnUpdate={submitColumnUpdate}
                                switchColumn={switchColumn}
                                updateSelectedColumn={updateSelectedColumn}
                                updateSelectedLocation={updateSelectedLocation}
                            />
                        ) : (
                            <SelectColumnInfo>
                                <ImArrowLeft2 size={30} />
                                <span>VEUILLEZ SÉLECTIONNER UNE COLONNE</span>
                            </SelectColumnInfo>
                        )}
                    </PanelContainer>
                    <SplitPanels
                        isVertical={true}
                        displayEnlarge={[false, false]}
                        displayShrink={[false, false]}
                        haveMargins={[false, false]}
                        initialSizes={[50, 50]}
                        name="location-details-panels"
                    >
                        <PanelContainerWithSmallMargin>
                            {selectedColumn === null || selectedLocation === null ? (
                                <SelectLocationInfo>
                                    <ImArrowLeft2 size={30} />
                                    <span>VEUILLEZ SÉLECTIONNER UN EMPLACEMENT</span>
                                </SelectLocationInfo>
                            ) : (
                                <LocationEditor
                                    isToday={isToday}
                                    microstoreProductsStockInfo={microstoreProductsStockInfo}
                                    selectedColumn={selectedColumn}
                                    selectedLocation={selectedLocation}
                                    updateSelectedLocation={updateSelectedLocation}
                                />
                            )}
                        </PanelContainerWithSmallMargin>
                        <PanelContainerWithSmallMargin>
                            {selectedColumn === null || selectedLocation === null ? (
                                <SelectLocationInfo>
                                    <ImArrowLeft2 size={30} />
                                    <span>VEUILLEZ SÉLECTIONNER UN EMPLACEMENT</span>
                                </SelectLocationInfo>
                            ) : (isToday && selectedLocation.productId === null) ||
                              (!isToday && selectedLocation.replacementProductId === null) ? (
                                <SelectProductInfo>
                                    <ImArrowUp2 size={30} />
                                    <span>VEUILLEZ SÉLECTIONNER UN PRODUIT</span>
                                </SelectProductInfo>
                            ) : (
                                <ExpiryDateEditor
                                    isToday={isToday}
                                    microstoreProductsStockInfo={microstoreProductsStockInfo}
                                    selectedLocation={selectedLocation}
                                    updateSelectedLocation={updateSelectedLocation}
                                />
                            )}
                        </PanelContainerWithSmallMargin>
                    </SplitPanels>
                </SplitPanels>
            </Container>

            {updateLoading ? <FullPageLoader /> : null}
        </>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

const PanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    overflow: hidden;
`;

const PanelContainerWithSmallMargin = styled(PanelContainer)`
    margin: 10px 20px;
`;

const SelectInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
`;

const SelectColumnInfo = styled(SelectInfo)`
    flex-direction: row;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const VerticalText = styled.div`
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    white-space: nowrap;
    display: block;
`;

const SelectLocationInfo = SelectColumnInfo;

const SelectProductInfo = styled(SelectInfo)`
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 20px;
    }
`;
