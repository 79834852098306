import React from 'react';
import { useQuery } from '@apollo/client';
import ReactTooltip from 'react-tooltip';

import styled from 'styled-components';

import { GET_PRODUCT_PREPARATION_DELAY_QUERY } from 'data/queries/product';
import {
    GET_PRODUCT_PREPARATION_DELAY,
    GET_PRODUCT_PREPARATION_DELAYVariables,
} from 'data/queries/__generated__/GET_PRODUCT_PREPARATION_DELAY';
import { colors } from 'constants/colors';

import { TotemPopup } from 'components/TotemPopup';
import { DetailLink, DetailValue, DocumentationLink } from 'components/DetailsView';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { ProductWithInfoType } from '../supplyOrderDetailsFormHelper';
import { CopyValue } from 'components/CopyValue';
import {
    GetAllProductsQuery,
    GetProductGroupsContainingProductsQuery,
    SupplyOrderProductStockVariationFragmentFragment,
} from 'data/__generated__';
function addParentheses(input: string): string {
    return '(' + input + ')';
}

export function SupplyOrderProductStockVariationDetailsPopup({
    dayLabels,
    isOpen,
    isComputed,
    productInfo,
    productStockVariation,
    setIsOpen,
    stock,
    stockVariation,
}: {
    dayLabels: { label: string; orderDayLabels: string[] }[];
    isOpen: boolean;
    isComputed: boolean;
    productInfo:
        | ProductWithInfoType
        | GetAllProductsQuery['getAllProducts'][number]
        | GetProductGroupsContainingProductsQuery['getProductGroupsContainingProducts'][number]['product'];
    productStockVariation: SupplyOrderProductStockVariationFragmentFragment;
    setIsOpen: (arg: boolean) => void;
    stock: number;
    stockVariation: number[];
}) {
    const {
        productId,
        computedStockThreshold,
        initialStock,
        forecasts: {
            forecastsDaysToIgnoreForOrders, // we store values in backend, but ignore in calculations, as the SO is supposed to be already passed for these days
            forecastsFreefoodPunctual,
            forecastsFreefoodRecurring,
            forecastsStoreDry,
            lastUpdateDateFreefoodPunctual,
            lastUpdateDateFreefoodRecurring,
            lastUpdateDateStoreDry,
        },
        microstoreDetails: { targetStockDetails, totalStock, missingStockByDay, targetStockByDay },
        missingStockBetweenDeliveries,
        operations: {
            punctualOrdersNotPrepared,
            recurrentOrdersNotPrepared,
            validatedStockTransferNotPrepared,
            validatedStockTransferPrepared,
        },
        outboundStockVariation,
        productSwitchDetails,
        stockUpdateDayIndexIfOrderedOnNextOrderingDay,
        stockUpdateDayIndexIfOrderedToday,
        stockVariationFromSupplyOrders,
    } = productStockVariation;

    const { data: preparationDelayData, error: preparationDelayError } = useQuery<
        GET_PRODUCT_PREPARATION_DELAY,
        GET_PRODUCT_PREPARATION_DELAYVariables
    >(GET_PRODUCT_PREPARATION_DELAY_QUERY, {
        variables: { productId },
        skip: !isOpen,
    });

    const targetStockDetailsSorted = [...targetStockDetails]
        .sort(({ siteName: siteNameA }, { siteName: siteNameB }) => siteNameB.localeCompare(siteNameA))
        .sort(({ planogramDate: planogramDateA }, { planogramDate: planogramDateB }) =>
            planogramDateA ? (planogramDateB ? planogramDateA.localeCompare(planogramDateB) : 1) : -1,
        );
    const targetStockDetailsByPlanogramDate: Record<
        string,
        SupplyOrderProductStockVariationFragmentFragment['microstoreDetails']['targetStockDetails']
    > = {};
    targetStockDetailsSorted.forEach((targetStockInfo) => {
        const date = targetStockInfo.planogramDate ?? 'noDate';
        if (!targetStockDetailsByPlanogramDate[date]) {
            targetStockDetailsByPlanogramDate[date] = [];
        }
        targetStockDetailsByPlanogramDate[date].push(targetStockInfo);
    });

    const { name, brand, volume, isToBeArchived } = productInfo;

    return (
        <TotemPopup
            title="Détails des variations de stock"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="hidden"
        >
            <ScrollContainer>
                <ColumnsSectionContainer numberOfColumns={4}>
                    <SectionColumn>
                        <DetailValue label="Stock initial" value={initialStock} />
                        <DetailValue label="Stock actuel" value={stock} />
                        <DetailValue label="Stock de reserve" value={computedStockThreshold} />
                        <DocumentationLink
                            src="https://www.notion.so/thetotem/Stock-de-R-serve-d2c491f605ff44a1a1a8db55305881ec"
                            name="comprendre le stock de réserve"
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailValue label="Stock sites + entrepôt" value={totalStock} />
                        <TargetStockDetails>
                            <DetailValue label="Stock cible par jour" value={targetStockByDay.join(', ')} />
                            <DetailValue label="Stock manquant par jour" value={missingStockByDay.join(', ')} />
                            <TargetStockSummary>Détails stock cible total</TargetStockSummary>
                            Nom Site : Stock Cible Total =&gt; Stock Cible Diminué (Date Plano)
                            {targetStockDetailsSorted.map(
                                ({ locationTarget, siteName, planogramDate, usedLocationTarget }, index) => (
                                    <TargetStockEntry key={index}>
                                        {siteName} : {locationTarget} =&gt; {usedLocationTarget} ({planogramDate})
                                    </TargetStockEntry>
                                ),
                            )}
                        </TargetStockDetails>
                    </SectionColumn>
                    <SectionColumn>
                        <DetailValue label="Valeurs enregistrées à la création" value={isComputed ? 'Non' : 'Oui'} />
                        {preparationDelayData?.productPreparationDelay ? (
                            <DetailValue
                                label="Jours à prévoir pour la préparation"
                                value={`[ ${preparationDelayData.productPreparationDelay.join(', ')} ]`}
                            />
                        ) : null}
                        {preparationDelayError ? (
                            <DetailValue
                                label="Jours à prévoir pour la préparation"
                                value="Erreur pendant la récupération des jours"
                            />
                        ) : null}
                        <CopyValue value={productId} label="Id produit" />
                        <DetailLink
                            label="Produit"
                            path="product"
                            value={productId}
                            name={`${name} - ${brand} - ${volume}`}
                        />
                    </SectionColumn>
                    {!isToBeArchived ? (
                        <SectionColumn>
                            <DetailValue
                                label="Date prévision Com. Récurrentes"
                                value={new Date(lastUpdateDateFreefoodRecurring).toLocaleString()}
                            />
                            <DetailValue
                                label="Date prévision Com. Ponctuelles"
                                value={new Date(lastUpdateDateFreefoodPunctual).toLocaleString()}
                            />
                            <DetailValue
                                label="Date prévision Store Dry"
                                value={new Date(lastUpdateDateStoreDry).toLocaleString()}
                            />
                        </SectionColumn>
                    ) : null}
                </ColumnsSectionContainer>
                <TableContainer>
                    <Table>
                        <TableRow>
                            <RowTitle>Jour (grisé = livraison impossible ce jour)</RowTitle>
                            {dayLabels.map(({ label, orderDayLabels }, index: number) => {
                                return (
                                    <ProductsInfoStockVariationTableCell
                                        key={index}
                                        canBeDelivered={!!orderDayLabels.length}
                                        data-for={`stock-variation-${productId}-${index}`}
                                        data-tip={`Livré ce jour-là si commandé: ${orderDayLabels.join(' ou ')}`}
                                    >
                                        {label}
                                        <ReactTooltip
                                            id={`stock-variation-${productId}-${index}`}
                                            disable={!orderDayLabels.length}
                                        />
                                    </ProductsInfoStockVariationTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Commandes récurrentes</RowTitle>
                            </b>
                        </TableRow>
                        <TableRow>
                            <RowTitle>prévisions</RowTitle>
                            {forecastsFreefoodRecurring.map((stockDelta: number, index: number) => {
                                const totalRecurring = recurrentOrdersNotPrepared[index];
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                        {index >= forecastsDaysToIgnoreForOrders && stockDelta
                                            ? '-' +
                                              (stockDelta >= totalRecurring
                                                  ? stockDelta.toFixed(1)
                                                  : addParentheses(stockDelta.toFixed(1)))
                                            : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>existantes</RowTitle>
                            {/* mapping any of the arrays */}
                            {recurrentOrdersNotPrepared.map((_, index: number) => {
                                const totalRecurring = recurrentOrdersNotPrepared[index];
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {totalRecurring
                                            ? '-' +
                                              (index < forecastsDaysToIgnoreForOrders ||
                                              totalRecurring >= forecastsFreefoodRecurring[index]
                                                  ? totalRecurring.toFixed(0)
                                                  : addParentheses(totalRecurring.toFixed(0)))
                                            : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Commandes ponctuelles</RowTitle>
                            </b>
                        </TableRow>
                        <TableRow>
                            <RowTitle>prévisions</RowTitle>
                            {forecastsFreefoodPunctual.map((stockDelta: number, index: number) => {
                                const totalPunctual = punctualOrdersNotPrepared[index];
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                        {index >= forecastsDaysToIgnoreForOrders && stockDelta
                                            ? '-' +
                                              (stockDelta >= totalPunctual
                                                  ? stockDelta.toFixed(1)
                                                  : addParentheses(stockDelta.toFixed(1)))
                                            : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>existantes</RowTitle>
                            {/* mapping any of the arrays */}
                            {punctualOrdersNotPrepared.map((_, index: number) => {
                                const totalPunctual = punctualOrdersNotPrepared[index];

                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {totalPunctual
                                            ? '-' +
                                              (index < forecastsDaysToIgnoreForOrders ||
                                              totalPunctual >= forecastsFreefoodPunctual[index]
                                                  ? totalPunctual.toFixed(0)
                                                  : addParentheses(totalPunctual.toFixed(0)))
                                            : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Transferts de stock</RowTitle>
                            </b>
                        </TableRow>
                        <TableRow>
                            <RowTitle>prévisions Store Dry</RowTitle>
                            {forecastsStoreDry.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                        {stockDelta ? '-' + stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>Validés non Préparés</RowTitle>
                            {validatedStockTransferNotPrepared.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(0) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>Validés Préparés</RowTitle>
                            {validatedStockTransferPrepared.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.red}>
                                        {stockDelta ? '-' + stockDelta.toFixed(0) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <b>
                                <RowTitle>Modification pour switch</RowTitle>
                            </b>
                            {productSwitchDetails.variations.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.blue}>
                                        {stockDelta ? stockDelta.toFixed(1) : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>

                        <Separator />
                        <Separator />
                        <TableRow>
                            <RowTitle>Total sorties (jour de prépa)</RowTitle>
                            {outboundStockVariation.map((stockDelta: number, index: number) => {
                                const productSwitchDelta = productSwitchDetails.variations?.[index] ?? 0;
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index}>
                                        {stockDelta + productSwitchDelta ? stockDelta + productSwitchDelta : null}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <RowTitle>Manquants Store (avant prochaine livraison)</RowTitle>
                            <ProductsInfoStockDeltaTableCell color={colors.red}>
                                {missingStockBetweenDeliveries > 0 ? `-${missingStockBetweenDeliveries}` : ''}
                            </ProductsInfoStockDeltaTableCell>
                        </TableRow>
                        <TableRow>
                            <RowTitle>Com. Fournisseurs</RowTitle>
                            {stockVariationFromSupplyOrders.map((stockDelta: number, index: number) => {
                                return (
                                    <ProductsInfoStockDeltaTableCell key={index} color={colors.greenDarker}>
                                        {stockDelta ? `+${stockDelta}` : ''}
                                    </ProductsInfoStockDeltaTableCell>
                                );
                            })}
                        </TableRow>
                        <Separator />
                        <TableRow>
                            <RowTitle>Stock en fin de journée</RowTitle>
                            {stockVariation.map((stockVariationValue: number, index: number) => {
                                const opsDay =
                                    index >= stockUpdateDayIndexIfOrderedToday &&
                                    index < stockUpdateDayIndexIfOrderedOnNextOrderingDay;
                                return (
                                    <ProductsInfoStockVariationTableCell
                                        key={index}
                                        negative={stockVariationValue < 0}
                                        warning={stockVariationValue < computedStockThreshold}
                                        opsDay={opsDay}
                                    >
                                        {index === stockUpdateDayIndexIfOrderedToday && '['} {stockVariationValue}{' '}
                                        {index === stockUpdateDayIndexIfOrderedOnNextOrderingDay - 1 && ']'}
                                    </ProductsInfoStockVariationTableCell>
                                );
                            })}
                        </TableRow>
                    </Table>
                </TableContainer>
                <ExplanationContainer>
                    • Les chiffres <ColorSpan color={colors.red}>en rouge</ColorSpan> sont calculés à partir des{' '}
                    <b>données sur les commandes et livraisons qu'on a actuellement</b> (ou au moment de création de
                    carte achat si 'Valeurs enregistrées à la création' est Oui).
                    <br /> • Les chiffres <ColorSpan color={colors.blue}>en bleu</ColorSpan> sont des <b>prédictions</b>{' '}
                    (calculées par la team data).
                    <br /> • Tous les chiffres (Commandes, ST, Total sorties) sont affichés le jour de préparation.
                    <br /> • Total sorties = récurrentes + ponctuelles + ST. Pour chaque element on prend la valeur
                    maximale entre le chiffre rouge (somme de tous les chiffres noirs) de la section et le chiffre bleu
                    de la section.
                    <br /> • Stock en fin de journée = stock en prenant en compte le stock de la veille, la préparation
                    et les réceptions (en considérant qu'elles seront utilisées pour la prépa du jour, quitte à
                    surestimer les sorties)
                    <br /> • Stock en fin de journée <ColorSpan color={colors.orange}>en orange</ColorSpan> : inférieur
                    au stock de réserve, <ColorSpan color={colors.red}>en rouge</ColorSpan> : quantités manquantes
                    cumulées si on ne passe pas commande
                    <br /> • les valeurs entre [ ... ] correspondent aux jours de préparation impactés par cette
                    commande fournisseur avant qu'elle soit augmentée pour respecter les conditions de commande.
                    <br /> • le manquant store correspond à la différence entre le stock total sur site et entrepôt et
                    le stock cible total des stores. Il permet de rattraper des écarts aux prédictions. On calcule un
                    stock cible diminué pour prendre en compte les transitions entre produits. Si je passe d'un produit
                    A à un produit B, et que je veux une transition smooth, alors je ne vais commander qu'un pourcentage
                    du stock cible du produit B pour ne pas avoir trop de stock en entrepôt le temps d'écouler le
                    produit A.
                </ExplanationContainer>
            </ScrollContainer>
        </TotemPopup>
    );
}

const ScrollContainer = styled.div`
    overflow: auto;
`;

const TableContainer = styled.div`
    margin: 15px 0;
`;

const ExplanationContainer = styled.div`
    font-style: normal;
    display: inline-block;
`;

const ColorSpan = styled.span`
    color: ${({ color }) => color};
    margin: 0 2px;
`;

const Table = styled.tbody`
    display: table;
    width: 100%;
`;

const TableRow = styled.tr`
    height: 25px;
`;

const RowTitle = styled.td`
    color: ${({ theme }) => theme.textColor};
`;

const ProductsInfoStockDeltaTableCell = styled.td`
    text-align: center;
    padding: 0 10px;
    color: ${({ theme, color }) => color || theme.textColor};
`;

const ProductsInfoStockVariationTableCell = styled.td<{
    negative?: boolean;
    warning?: boolean;
    canBeDelivered?: boolean;
    opsDay?: boolean;
}>`
    text-align: center;
    padding: 0 10px;
    color: ${({ theme, negative, canBeDelivered, warning }) =>
        negative
            ? theme.errorColor
            : warning
              ? theme.warningColor
              : canBeDelivered
                ? theme.textColor
                : theme.infoTextColor};
    font-weight: ${({ negative, warning, opsDay }) => (negative || warning || opsDay) && 600};
`;

const Separator = styled.tr`
    width: 100%;
    height: 5px;
    border-top: 1px solid ${({ theme }) => theme.textColor};
`;

const TargetStockDetails = styled.details`
    margin-top: 5px;
`;

const TargetStockSummary = styled.summary`
    cursor: pointer;
`;

const TargetStockEntry = styled.div``;
