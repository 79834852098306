import gql from 'graphql-tag';
import { MICROSTORE_LOCATION_FRAGMENT } from 'data/fragments/microstoreLocation';

export const MICROSTORE_COLUMN_FRAGMENT = gql`
    fragment MicrostoreColumnFragment on MicrostoreColumn {
        _id
        createdAt
        updatedAt
        columnGroupId
        columnIdToReplace
        isPaused
        microstoreColumnTemplateId
        name
        state
        positionInColumnGroup
        removalDate
        externalColumnId
        externalColumnType
        startingDate
        templateName
        type
        siteId
        siteName
        isRegular
    }
`;

export const MICROSTORE_COLUMN_WITH_SHELVES_FRAGMENT = gql`
    fragment MicrostoreColumnWithShelvesFragment on MicrostoreColumn {
        _id
        createdAt
        updatedAt
        siteId
        siteName
        isSiteSecured
        canBeSecured
        columnGroupId
        columnIdToReplace
        isPaused
        shouldUpdateStockOnTransaction
        shouldSendNayaxPrices
        microstoreColumnTemplateId
        name
        state
        stateHistory {
            createdAt
            createdBy
            createdByName
            state
        }
        positionInColumnGroup
        externalColumnId
        externalColumnType
        shelves {
            _id
            locationTable {
                ...MicrostoreLocationFragment
            }
            positionInColumn
        }
        startingDate
        removalDate
        templateName
        type
    }
    ${MICROSTORE_LOCATION_FRAGMENT}
`;
