import React from 'react';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { useCreateNewTotemFromOrganizationMutation } from 'data/__generated__';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

type Props = {
    organizationId: string;
    refetchTotems: () => void;
};

export const CreateTotemButton = ({ organizationId, refetchTotems }: Props) => {
    const [createNewTotem] = useCreateNewTotemFromOrganizationMutation();

    const handleClick = async () => {
        try {
            const { data } = await createNewTotem({
                variables: { organizationId },
            });

            if (data?.createNewTotemFromOrganization) {
                toast.success('Le totem a bien été créé !');
                refetchTotems();
            }
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la création du totem");
        }
    };

    return (
        <TotemPrimaryButton onClick={handleClick}>
            <FaPlus size="13" />
            <Label>Nouveau totem</Label>
        </TotemPrimaryButton>
    );
};

const Label = styled.span`
    margin-left: 5px;
`;
