import React from 'react';
import { ExternalColumnType } from 'data/__generated__';
import { Option } from 'components/TotemSelect';
import { SelectFilter } from './SelectFilter';

interface ExternalTypeFilterProps {
    value: ExternalColumnType | null;
    onChange: (value: ExternalColumnType | null) => void;
}

export const ExternalTypeFilter: React.FC<ExternalTypeFilterProps> = ({ value, onChange }) => {
    const options = React.useMemo(
        () =>
            Object.values(ExternalColumnType).map((type) => ({
                label: type,
                value: type,
            })),
        [],
    );

    const selectedOption = React.useMemo(
        () => (value ? options.find((option) => option.value === value) || null : null),
        [value, options],
    );

    const handleChange = (option: Option<ExternalColumnType> | null) => {
        onChange(option?.value || null);
    };

    return (
        <SelectFilter<ExternalColumnType>
            placeholder="Type d'intégration"
            value={selectedOption}
            options={options}
            onChange={handleChange}
        />
    );
};
