import React from 'react';

import { FcShop } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TotemInfo } from 'pages/Organizations/OrganizationDetails/TotemsDetails/TotemInfo';
import { RecentOrders } from 'pages/Organizations/OrganizationDetails/TotemsDetails/RecentOrders';
import { Option } from 'components/TotemSelect';
import { GetDetailedTotemsOfOrganizationQuery, GetEquipmentsQuery } from 'data/__generated__';

export const TotemDetails = ({
    totem,
    mainUserOptions,
    equipments,
}: {
    totem: GetDetailedTotemsOfOrganizationQuery['totemsOfOrganization'][number];
    mainUserOptions: Option<string>[];
    equipments: GetEquipmentsQuery['adminEquipments'];
}) => {
    const { _id: totemId, microstore, organizationId, recentOrders } = totem;

    return (
        <Container>
            <ScrollableContent>
                <TotemInfo totem={totem} mainUserOptions={mainUserOptions} equipments={equipments} />
            </ScrollableContent>
            <InternalResources>
                <RecentOrders orders={recentOrders} totemId={totemId} organizationId={organizationId} />
                {microstore ? (
                    <Microstore>
                        <MicrostoreLink to={`/site/${microstore._id}`} target="_blank" rel="noopener noreferrer">
                            <FcShop />
                            <LinkLabel>Store {microstore.name}</LinkLabel>
                        </MicrostoreLink>
                    </Microstore>
                ) : null}
            </InternalResources>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 400px;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    color: ${({ theme }) => theme.textColor};
    padding: 10px;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

const InternalResources = styled.div`
    margin-top: 10px;
`;

const Microstore = styled.div`
    margin-top: 10px;
    display: flex;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding: 10px;
    font-size: 36px;
`;

const MicrostoreLink = styled(Link)`
    display: flex;
    text-decoration: none;
    align-items: center;

    &:hover {
        text-decoration: underline;
        text-decoration-color: ${({ theme }) => theme.ctaPrimaryColor};
    }
`;

const LinkLabel = styled.span`
    color: ${({ theme }) => theme.ctaPrimaryColor};
    margin-left: 5px;
`;
