import React, { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { useFormContext, get } from 'react-hook-form';

import { getFormError } from 'constants/formErrors';

import { TotemLabel } from '../TotemLabel';
import { BasicTextArea } from '../BasicTextArea';

type DetailFormTextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'defaultValue'> & {
    label?: string;
    sublabel?: string;
    name: string;
    centerText?: boolean;
    defaultValue?: string | number | null;
    width?: string;
    height?: string;
};

export const DetailFormTextArea = React.forwardRef<HTMLTextAreaElement, DetailFormTextAreaProps>(
    ({ label, sublabel, name, defaultValue, width, height, style, ...props }, ref) => {
        const { placeholder } = props;
        const {
            formState: { errors },
        } = useFormContext();
        const errorObject = get(errors, name);
        const error = errorObject ? getFormError(errorObject.type, errorObject.message) : '';

        return (
            <Container style={style}>
                <LabelContainer>
                    <TotemLabel>{label}</TotemLabel>
                    {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
                </LabelContainer>
                <ValueContainer>
                    <BasicTextArea
                        name={name}
                        placeholder={placeholder || 'Aucune valeur'}
                        defaultValue={defaultValue ?? ''}
                        width={width}
                        height={height}
                        ref={ref}
                        {...props}
                    />
                    {error ? <Error>{error}</Error> : null}
                </ValueContainer>
            </Container>
        );
    },
);

DetailFormTextArea.displayName = 'DetailFormTextArea';

const Error = styled.div`
    position: absolute;
    right: -2px;
    top: -16px;
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
    width: max-content;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
    width: 100%;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    position: relative;
    margin-top: 5px;
`;
