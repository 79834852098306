import React, { useEffect } from 'react';

import { toast } from 'react-toastify';

import { FaLongArrowAltRight, FaTrash } from 'react-icons/fa';
import { IoWarning } from 'react-icons/io5';
import styled from 'styled-components';

import { colors } from 'constants/colors';

import { TotemLabel } from 'components/TotemLabel';
import { TotemInput } from 'components/TotemInput';
import { Loader, LoaderModeType } from 'components/Loader';
import { DetailLink } from 'components/DetailsView';

import { FrenchDate } from 'helpers/dateTimes';
import { ProductSelect } from 'components/Select/ProductSelect';
import { GetTransferableProductsQuery, useGetTransferableProductsQuery } from 'data/__generated__';
import { round } from 'lodash';

export const ProductsToTransferTable = ({
    originSiteId,
    destinationSiteId,
    handleProductSelect,
    productsToTransfer,
    handleQuantityToTransferUpdate,
    handleChangedAvailableQuantitiesAfterDateChange,
    handleProductDelete,
    isTransferValidated,
    transferDate,
}: {
    originSiteId: string;
    destinationSiteId: string;
    handleProductSelect: (product: GetTransferableProductsQuery['transferableProducts'][number]) => void;
    productsToTransfer: GetTransferableProductsQuery['transferableProducts'];
    handleQuantityToTransferUpdate: (
        value: string,
        conditionningCrossPackagesPerBatch: number,
        productToTransfer: GetTransferableProductsQuery['transferableProducts'][number],
    ) => void;
    handleChangedAvailableQuantitiesAfterDateChange: (
        products: GetTransferableProductsQuery['transferableProducts'],
    ) => void;
    handleProductDelete: (productToTransfer: GetTransferableProductsQuery['transferableProducts'][number]) => void;
    isTransferValidated?: boolean;
    transferDate?: FrenchDate;
}) => {
    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
    } = useGetTransferableProductsQuery({
        skip: isTransferValidated,
        variables: {
            originSiteId,
            destinationSiteId,
            transferDate,
        },
    });

    useEffect(() => {
        if (productsData) {
            handleChangedAvailableQuantitiesAfterDateChange(productsData.transferableProducts);
        }
    }, [productsData]);

    if (productsLoading) {
        return (
            <LoaderContainer>
                <Loader mode={LoaderModeType.Spin} />
            </LoaderContainer>
        );
    }

    if (!isTransferValidated && (productsError || !productsData)) {
        toast.error('Une erreur est survenue lors de la récupération des produits transférables');
        return null;
    }

    const onSelect = (productId?: string | null) => {
        if (productId) {
            const product = productsData?.transferableProducts.find((product) => product.productId === productId);
            if (product) {
                handleProductSelect(product);
            } else {
                toast.error("Le produit selectionné n'est pas un transferable dans ce site");
            }
        }
    };

    return (
        <Container>
            <div data-test="product-selector">
                <ProductSelect dataTest="select-product" value={null} onChange={(product) => onSelect(product?._id)} />
            </div>
            {productsToTransfer.length ? (
                <>
                    <TotemLabel>Produits à transférer</TotemLabel>
                    <ProductsTable>
                        <thead>
                            <tr>
                                <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>Colisage</ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>Palettisation</ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    {!isTransferValidated ? 'Palettes à transférer' : 'Palettes transférées'}
                                </ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    {!isTransferValidated ? 'Colis à transférer' : 'Colis transférés'}
                                </ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    {!isTransferValidated ? 'Quantité à transférer' : 'Quantité transférée'}
                                </ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    Stock dispo à l'origine
                                </ProductsTableHeaderCell>
                                <ProductsTableHeaderCell isDataCentered>
                                    Stock dispo à destination
                                </ProductsTableHeaderCell>
                                {!isTransferValidated ? (
                                    <ProductsTableHeaderCell isDataCentered>Supprimer</ProductsTableHeaderCell>
                                ) : null}
                            </tr>
                        </thead>
                        <ProductsTableBody>
                            {productsToTransfer.map((productToTransfer) => {
                                const {
                                    productId,
                                    fullname,
                                    conditionning,
                                    packagesPerBatch,
                                    quantity,
                                    originStockAvailable,
                                    destinationStockAvailable,
                                } = productToTransfer;
                                const transferableProduct = productsData?.transferableProducts.find(
                                    (product) => productId === product.productId,
                                );

                                return (
                                    <tr key={productId}>
                                        <ProductsTableBodyCell>
                                            <ProductNameContainer>
                                                <DetailLink name={fullname} value={productId} path="product" />
                                                {!isTransferValidated && !transferableProduct ? (
                                                    <ErrorLabel data-test="product-not-transferable-error">
                                                        Le produit selectionné n'est pas un produit microstore
                                                    </ErrorLabel>
                                                ) : null}
                                            </ProductNameContainer>
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>{conditionning}</ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>{packagesPerBatch}</ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            {packagesPerBatch > 1 ? (
                                                <QuantityInputOuterContainer>
                                                    <QuantityInputInnerContainer>
                                                        <TotemInput
                                                            type="number"
                                                            step="1"
                                                            min="0"
                                                            value={
                                                                round(quantity / conditionning / packagesPerBatch, 2) ||
                                                                0
                                                            }
                                                            disabled={isTransferValidated}
                                                            onChange={(value) =>
                                                                handleQuantityToTransferUpdate(
                                                                    value,
                                                                    conditionning * packagesPerBatch,
                                                                    productToTransfer,
                                                                )
                                                            }
                                                            centerText
                                                            data-test="product-palette"
                                                        />
                                                    </QuantityInputInnerContainer>
                                                </QuantityInputOuterContainer>
                                            ) : null}
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            {conditionning > 1 ? (
                                                <QuantityInputOuterContainer>
                                                    <QuantityInputInnerContainer>
                                                        <TotemInput
                                                            type="number"
                                                            step="1"
                                                            min="0"
                                                            value={round(quantity / conditionning, 2) || 0}
                                                            disabled={isTransferValidated}
                                                            onChange={(value) =>
                                                                handleQuantityToTransferUpdate(
                                                                    value,
                                                                    conditionning,
                                                                    productToTransfer,
                                                                )
                                                            }
                                                            centerText
                                                            data-test="product-colis"
                                                        />
                                                    </QuantityInputInnerContainer>
                                                </QuantityInputOuterContainer>
                                            ) : null}
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <QuantityInputOuterContainer>
                                                <QuantityInputInnerContainer>
                                                    <TotemInput
                                                        type="number"
                                                        step="1"
                                                        min="0"
                                                        value={quantity || 0}
                                                        disabled={isTransferValidated}
                                                        onChange={(value) =>
                                                            handleQuantityToTransferUpdate(value, 1, productToTransfer)
                                                        }
                                                        centerText
                                                        data-test="product-quantity"
                                                    />
                                                </QuantityInputInnerContainer>
                                            </QuantityInputOuterContainer>
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <StockDelta>
                                                {!isTransferValidated ? (
                                                    transferableProduct ? (
                                                        <>
                                                            <span>{transferableProduct.originStockAvailable}</span>
                                                            <FaLongArrowAltRight size={25} color={colors.red} />
                                                            <span>
                                                                {transferableProduct.originStockAvailable - quantity}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <ErrorLabel>Non transférable, pas d'info</ErrorLabel>
                                                    )
                                                ) : (
                                                    <>
                                                        <span>{originStockAvailable + quantity}</span>
                                                        <FaLongArrowAltRight size={25} color={colors.red} />
                                                        <span>{originStockAvailable}</span>
                                                    </>
                                                )}
                                                {originStockAvailable - quantity < 0 ? (
                                                    <WarningIcon data-test="warning-icon" size={25} />
                                                ) : null}
                                            </StockDelta>
                                        </ProductsTableBodyCell>
                                        <ProductsTableBodyCell isDataCentered>
                                            <StockDelta>
                                                {!isTransferValidated ? (
                                                    transferableProduct ? (
                                                        <>
                                                            <span>{transferableProduct.destinationStockAvailable}</span>
                                                            <FaLongArrowAltRight size={25} color={colors.green} />
                                                            <span>
                                                                {transferableProduct.destinationStockAvailable +
                                                                    quantity}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <ErrorLabel>Non transférable, pas d'info</ErrorLabel>
                                                    )
                                                ) : (
                                                    <>
                                                        <span>{destinationStockAvailable - quantity}</span>
                                                        <FaLongArrowAltRight size={25} color={colors.green} />
                                                        <span>{destinationStockAvailable}</span>
                                                    </>
                                                )}
                                            </StockDelta>
                                        </ProductsTableBodyCell>
                                        {!isTransferValidated ? (
                                            <ProductsTableBodyCell isDataCentered>
                                                <DeleteIconContainer
                                                    onClick={() => handleProductDelete(productToTransfer)}
                                                >
                                                    <FaTrash data-test="trash-icon" size={20} color={colors.red} />
                                                </DeleteIconContainer>
                                            </ProductsTableBodyCell>
                                        ) : null}
                                    </tr>
                                );
                            })}
                        </ProductsTableBody>
                    </ProductsTable>
                </>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${({ theme }) => theme.textColor};

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const ErrorLabel = styled.div`
    font-weight: 800;
    color: ${colors.red};
    text-align: center;
`;

const ProductsTable = styled.table`
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBody = styled.thead`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const QuantityInputOuterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const QuantityInputInnerContainer = styled.div`
    width: 100px;
`;

const WarningIcon = styled(IoWarning)`
    color: ${({ theme }) => theme.warningColor};
`;

const StockDelta = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const DeleteIconContainer = styled.div`
    display: inline;
    padding: 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

const LoaderContainer = styled.div`
    padding: 15px;
`;
