type UrlParams<T> = { [P in keyof T]?: string[] };

export function parseUrlSearchParams<T>(searchParams: URLSearchParams): UrlParams<T> {
    const params: UrlParams<T> = {};
    searchParams.forEach((value: string, key: string) => {
        if (!params[key as keyof T]) {
            params[key as keyof T] = [value];
        } else {
            (params[key as keyof T] as string[]).push(value);
        }
    });
    return params;
}

export function buildUrlSearchParams<T>(params: UrlParams<T>): URLSearchParams {
    return Object.entries(params).reduce((urlSearchParams, [key, values]) => {
        (values as string[]).forEach((value) => urlSearchParams.append(key, value));
        return urlSearchParams;
    }, new URLSearchParams());
}

export function updateSearchParams<T>({
    params,
    setSearchParams,
    key,
    value,
}: {
    params: UrlParams<T>;
    setSearchParams: (searchParams: URLSearchParams) => void;
    key: keyof T;
    value: string | null;
}) {
    if (!value) {
        const { [key]: _, ...updatedParams } = params;
        const searchParams = buildUrlSearchParams(updatedParams);
        setSearchParams(searchParams);
    } else {
        const searchParams = buildUrlSearchParams({
            ...params,
            [key]: [value],
        });
        setSearchParams(searchParams);
    }
}

export function updateChipsSearchParams<T>({
    params,
    setSearchParams,
    key,
    value,
    defaultChips,
}: {
    params: UrlParams<T>;
    setSearchParams: (searchParams: URLSearchParams) => void;
    key: keyof T;
    value: string;
    defaultChips: string[];
}) {
    if (params[key] === defaultChips) {
        const newState = defaultChips.includes(value)
            ? defaultChips.filter((currentState) => currentState !== value)
            : [value];
        const updatedParams = {
            ...params,
            state: newState,
        };
        const searchParams = buildUrlSearchParams(updatedParams);
        setSearchParams(searchParams);
        return;
    }
    if (params?.[key]?.includes(value)) {
        const updatedParams = { ...params, [key]: params[key]?.filter((currentValue) => currentValue !== value) };
        const searchParams = buildUrlSearchParams(updatedParams);
        setSearchParams(searchParams);
    } else {
        const updatedParams = { ...params, [key]: [...(params?.[key] || []), value] };
        const searchParams = buildUrlSearchParams(updatedParams);
        setSearchParams(searchParams);
    }
}
