import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import styled from 'styled-components';

import { SupplierType } from '../constants/defaultProduct';

import { SupplierSelector } from 'pages/Products/ProductDetails/components/SupplierSelector';

import { TotemLabel } from 'components/TotemLabel';
import { SupplierInfo } from 'pages/Products/ProductDetails/components/SupplierInfo';
import { SupplierState, GetSuppliersQuery } from 'data/__generated__';

export const Suppliers = ({
    productSuppliers,
    suppliers,
}: {
    productSuppliers: SupplierType[];
    suppliers: GetSuppliersQuery['suppliers'];
}) => {
    const { setValue } = useFormContext();

    //need to provide default values in **useForm** to automatically type supplierFields
    const {
        fields: supplierFields,
        prepend,
        remove,
        move,
    } = useFieldArray<
        {
            suppliers: SupplierType[];
        },
        'suppliers',
        'id'
    >({
        name: 'suppliers' as const,
    });

    useEffect(() => {
        if (productSuppliers) {
            const productSupplierFields = productSuppliers.map(({ _id, code, prices }) => {
                const supplierPrices = prices.map(({ value, conditionning, packagesPerBatch }) => ({
                    value,
                    conditionning,
                    packagesPerBatch,
                }));
                return {
                    _id,
                    code,
                    prices: supplierPrices,
                };
            });
            setValue('suppliers', productSupplierFields);
        }
    }, [productSuppliers, setValue]);

    return (
        <>
            <PricesTitle>Informations fournisseur</PricesTitle>
            {supplierFields?.length ? (
                <PricesSection>
                    <SupplierContainer>
                        {supplierFields.map((item, index) => {
                            const supplier = suppliers.find(({ _id }) => _id === item?._id);
                            const supplierName = supplier?.name || '';
                            const isArchived = supplier?.state === SupplierState.Archived;
                            if (item) {
                                return (
                                    <ItemContainer key={item.id}>
                                        <SupplierInfo
                                            index={index}
                                            isArchived={isArchived}
                                            supplierItem={item}
                                            supplierName={supplierName}
                                            remove={remove}
                                            move={move}
                                        />
                                    </ItemContainer>
                                );
                            }
                            return null;
                        })}
                    </SupplierContainer>
                </PricesSection>
            ) : null}
            <AddContainer>
                <SupplierSelector supplierFields={supplierFields} suppliers={suppliers} prepend={prepend} />
            </AddContainer>
        </>
    );
};

const PricesSection = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

const AddContainer = styled.div`
    display: flex;
    width: 100%;
    height: 180px; // selector + selector menuList heights (to see select results inside collapsible)
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const PricesTitle = styled(TotemLabel)`
    font-size: 16px;
`;

const SupplierContainer = styled.div`
    display: flex;
    flex: 2 1 0;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 15px;
    }
`;

const ItemContainer = styled.div`
    position: relative;
    display: flex;
    padding: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;
