import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';
import { useGetNayaxMdbTemplateQuery, useUpdateNayaxMdbTemplateMutation } from 'data/__generated__';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { BasicInput } from 'components/BasicInput';
import { TotemInput } from 'components/TotemInput';
import { SectionContainer, ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { toast } from 'react-toastify';

export const NayaxMDBTemplateDetails = () => {
    const { nayaxMDBTemplateId } = useParams<{ nayaxMDBTemplateId: string }>();
    const { loading, data, error } = useGetNayaxMdbTemplateQuery({
        variables: { nayaxMDBTemplateId: nayaxMDBTemplateId! },
    });
    const [updateTemplate] = useUpdateNayaxMdbTemplateMutation();
    const [templateName, setTemplateName] = useState<string>('');

    // Configuration du tableau
    const [numRows, setNumRows] = useState<number>(1);
    const [numColumns, setNumColumns] = useState<number>(1);
    const [numFloors, setNumFloors] = useState<number>(1);

    // Matrice 3D pour stocker les codes MDB
    const [mdbMatrix, setMdbMatrix] = useState<(number | null)[][][]>([[[null]]]);

    useEffect(() => {
        if (data?.nayaxMDBTemplate) {
            setTemplateName(data.nayaxMDBTemplate.name || '');

            // Trouver les dimensions maximales du template
            const maxFloor = Math.max(...data.nayaxMDBTemplate.associations.map((a) => a.positionInColumn)) + 1;
            const maxRow = Math.max(...data.nayaxMDBTemplate.associations.map((a) => a.positionInShelf.row)) + 1;
            const maxColumn = Math.max(...data.nayaxMDBTemplate.associations.map((a) => a.positionInShelf.column)) + 1;

            setNumFloors(maxFloor || 1);
            setNumRows(maxRow || 1);
            setNumColumns(maxColumn || 1);

            // Initialiser la matrice avec les valeurs existantes
            const newMatrix = Array(maxFloor || 1)
                .fill(null)
                .map(() =>
                    Array(maxRow || 1)
                        .fill(null)
                        .map(() => Array(maxColumn || 1).fill(null)),
                );

            // Remplir la matrice avec les associations existantes
            data.nayaxMDBTemplate.associations.forEach((assoc) => {
                newMatrix[assoc.positionInColumn][assoc.positionInShelf.row][assoc.positionInShelf.column] =
                    assoc.nayaxMDBCode;
            });

            setMdbMatrix(newMatrix);
        }
    }, [data?.nayaxMDBTemplate]);

    const updateMatrixDimensions = (floors: number, rows: number, cols: number) => {
        const newMatrix = Array(floors)
            .fill(null)
            .map((_, f) =>
                Array(rows)
                    .fill(null)
                    .map((_, r) =>
                        Array(cols)
                            .fill(null)
                            .map((_, c) => {
                                // Conserver les valeurs existantes si possible
                                if (f < mdbMatrix.length && r < mdbMatrix[0].length && c < mdbMatrix[0][0].length) {
                                    return mdbMatrix[f][r][c];
                                }
                                return null;
                            }),
                    ),
            );
        setMdbMatrix(newMatrix);
    };

    const handleDimensionChange = (dimension: 'floors' | 'rows' | 'columns', value: number) => {
        const newValue = Math.max(1, value);
        switch (dimension) {
            case 'floors':
                setNumFloors(newValue);
                updateMatrixDimensions(newValue, numRows, numColumns);
                break;
            case 'rows':
                setNumRows(newValue);
                updateMatrixDimensions(numFloors, newValue, numColumns);
                break;
            case 'columns':
                setNumColumns(newValue);
                updateMatrixDimensions(numFloors, numRows, newValue);
                break;
        }
    };

    const handleMdbCodeChange = (floor: number, row: number, col: number, value: string) => {
        const newMatrix = [...mdbMatrix];
        newMatrix[floor][row][col] = value === '' ? null : parseInt(value);
        setMdbMatrix(newMatrix);
    };

    const handleSaveAll = async () => {
        try {
            // Convertir la matrice en associations en ignorant les valeurs nulles
            const validAssociations = mdbMatrix.flatMap((floor, floorIndex) =>
                floor.flatMap((row, rowIndex) =>
                    row.flatMap((code, colIndex) =>
                        code !== null
                            ? [
                                  {
                                      nayaxMDBCode: code,
                                      positionInShelf: {
                                          row: rowIndex,
                                          column: colIndex,
                                      },
                                      positionInColumn: floorIndex,
                                  },
                              ]
                            : [],
                    ),
                ),
            );

            // Vérification des doublons
            const duplicateMDBs = validAssociations.filter(
                (assoc, index) => validAssociations.findIndex((a) => a.nayaxMDBCode === assoc.nayaxMDBCode) !== index,
            );

            if (duplicateMDBs.length > 0) {
                toast.warning('Attention : Des codes MDB en doublon ont été détectés');
                return;
            }

            await updateTemplate({
                variables: {
                    nayaxMDBTemplateId: nayaxMDBTemplateId!,
                    input: {
                        name: templateName,
                        associations: validAssociations,
                    },
                },
            });

            toast.success('Le template MDB a été sauvegardé avec succès');
        } catch (error) {
            toast.error('Erreur lors de la sauvegarde du template MDB');
        }
    };

    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!data?.nayaxMDBTemplate || error) {
        throw new Error('Une erreur est survenue lors de la récupération du template');
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.nayaxMDBTemplateDetails} />
                </HeaderTitle>
                <HeaderActions>
                    <TotemPrimaryButton onClick={handleSaveAll}>Sauvegarder</TotemPrimaryButton>
                </HeaderActions>
            </Header>
            <Content>
                <SectionContainer title="Informations générales" isInitiallyOpen>
                    <ColumnsSectionContainer numberOfColumns={2}>
                        <SectionColumn>
                            <TotemInput
                                label="Nom du template"
                                placeholder="Template MDB Nayax"
                                onChange={(value: string) => setTemplateName(value)}
                                value={templateName}
                                width="100%"
                            />
                        </SectionColumn>
                    </ColumnsSectionContainer>
                </SectionContainer>

                <SectionContainer title="Configuration des dimensions du template" isInitiallyOpen>
                    <DimensionsContainer>
                        <DimensionInput>
                            <label>Nombre d'étages:</label>
                            <BasicInput
                                type="number"
                                min="1"
                                value={numFloors}
                                onChange={(e) => handleDimensionChange('floors', parseInt(e.target.value))}
                            />
                        </DimensionInput>
                        <DimensionInput>
                            <label>Nombre de lignes:</label>
                            <BasicInput
                                type="number"
                                min="1"
                                value={numRows}
                                onChange={(e) => handleDimensionChange('rows', parseInt(e.target.value))}
                            />
                        </DimensionInput>
                        <DimensionInput>
                            <label>Nombre de colonnes:</label>
                            <BasicInput
                                type="number"
                                min="1"
                                value={numColumns}
                                onChange={(e) => handleDimensionChange('columns', parseInt(e.target.value))}
                            />
                        </DimensionInput>
                    </DimensionsContainer>
                </SectionContainer>

                <SectionContainer title="Template MDB Nayax" isInitiallyOpen>
                    <TableContainer>
                        {mdbMatrix.map((floor, floorIndex) => (
                            <FloorSection key={floorIndex}>
                                <FloorTitle>Étage {floorIndex + 1}</FloorTitle>
                                <Table>
                                    {floor.map((row, rowIndex) => (
                                        <TableRow key={`${floorIndex}-${rowIndex}`}>
                                            {row.map((code, colIndex) => (
                                                <TableCell key={`${floorIndex}-${rowIndex}-${colIndex}`}>
                                                    <BasicInput
                                                        type="number"
                                                        value={code === null ? '' : code}
                                                        onChange={(e) =>
                                                            handleMdbCodeChange(
                                                                floorIndex,
                                                                rowIndex,
                                                                colIndex,
                                                                e.target.value,
                                                            )
                                                        }
                                                        placeholder="Code MDB"
                                                        min="0"
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </Table>
                            </FloorSection>
                        ))}
                    </TableContainer>
                </SectionContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: auto;
    gap: 20px;
`;

const HeaderActions = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const DimensionsContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
`;

const DimensionInput = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    label {
        color: ${({ theme }) => theme.textColor};
    }

    input {
        width: 80px;
    }
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
`;

const FloorSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    padding: 15px;
    border-radius: 4px;
    width: 100%;
`;

const FloorTitle = styled.h3`
    margin: 0;
    color: ${({ theme }) => theme.textColor};
    font-size: 16px;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
`;

const TableRow = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
`;

const TableCell = styled.div`
    flex: 1;
    min-width: 0;

    input {
        width: 100%;
        max-width: 120px;
        height: 42px;
        text-align: center;
        padding: 4px 8px;
    }
`;
