import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { useGenerateQuantitiesMutation, useGetSuppliersQuery } from 'data/__generated__';
import { Loader, LoaderModeType } from 'components/Loader';
import { SiteDetailFormCheckboxes } from './components/SiteDetailFormCheckboxes';

type FormData = {
    selectedSiteIds: string[];
    selectedSupplierIds: string[];
};

export function GenerateQuantitiesButton({ date }: { date: Date }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [generateQuantities, { loading }] = useGenerateQuantitiesMutation();

    const methods = useForm<FormData>({
        defaultValues: {
            selectedSiteIds: [],
            selectedSupplierIds: [],
        },
    });

    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
    } = useGetSuppliersQuery({
        fetchPolicy: 'cache-and-network',
    });

    if (suppliersLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!suppliersLoading && (suppliersError || !suppliersData)) {
        throw new Error('Une erreur est survenue lors de la récupération des fournisseurs');
    }

    // TODO: Uncomment when menu can be generated for a specific supplier
    // const supplierOptions = (suppliersData?.suppliers || []).map(({ _id, name }) => ({
    //     label: name,
    //     value: _id,
    // }));

    const onSubmit = methods.handleSubmit(async (formData) => {
        try {
            await generateQuantities({
                variables: {
                    mondayDate: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                    siteIds: formData.selectedSiteIds,
                    supplierIds: formData.selectedSupplierIds,
                },
                onCompleted: ({ generateQuantities }) => {
                    if (generateQuantities?.success) {
                        toast.success('La génération des quantités a bien été relancée. Patientez');
                        setIsOpen(false);
                    } else {
                        toast.error(`Une erreur s'est produite : ${generateQuantities?.error || 'erreur inconnue'}`);
                    }
                },
                onError: (error) => {
                    toast.error(`Impossible de générer les quantités : ${error}`);
                },
            });
        } catch (error) {
            toast.error(`Impossible de générer les quantités : ${error}`);
            throw Error('Impossible de générer les quantités');
        }
    });

    return (
        <>
            <TotemPopup
                title="Re-génération des quantités"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentOverflow="visible"
            >
                <FormProvider {...methods}>
                    <form onSubmit={onSubmit}>
                        <FormContent>
                            <InfoText>
                                En confirmant vous remplacerez les quantités existantes pour la semaine du{' '}
                                {formatDateAsAnniversary({ dateTime: date })}.
                                <br />
                                Cette opération peut prendre un certain temps, ne la relancez pas en parallèle ;)
                            </InfoText>
                            <SiteDetailFormCheckboxes label="Site(s) concerné(s)" name="selectedSiteIds" />
                            {/* TODO: Uncomment when menu can be generated for a specific supplier */}
                            {/* <DetailFormSelect<string>
                                label="Fournisseur(s) (tous par défaut)"
                                placeholder="Sélectionnez les fournisseurs concernés"
                                isMulti
                                isClearable
                                name="selectedSupplierIds"
                                options={supplierOptions}
                            /> */}
                            <TotemPrimaryButton type="submit" disabled={loading}>
                                {loading ? <Loader size="18px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                            </TotemPrimaryButton>
                        </FormContent>
                    </form>
                </FormProvider>
            </TotemPopup>
            <TotemPrimaryButton disabled={loading} onClick={() => setIsOpen(true)}>
                Quantités
            </TotemPrimaryButton>
        </>
    );
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const InfoText = styled.div`
    padding: 20px 0px;
`;
