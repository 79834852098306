import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import type { Props as SelectProps, GroupBase, StylesConfig } from 'react-select';

import { getFormError } from 'constants/formErrors';

import { Option, OptionValue } from 'components/TotemSelect';
import { TotemLabel } from '../TotemLabel';

type DetailFormSelectProps<T extends OptionValue> = Omit<
    SelectProps<Option<T>, boolean, GroupBase<Option<T>>>,
    'defaultValue'
> & {
    label?: string;
    sublabel?: string;
    name: string;
    defaultValue?: T | T[];
    width?: string;
    required?: boolean;
    isClearable?: boolean;
    dataTest?: string;
    placeholder?: string;
    options: Option<T>[];
};

export const DetailFormSelect = <T extends OptionValue>({
    label,
    sublabel,
    name,
    defaultValue,
    width,
    dataTest = '',
    required = false,
    isClearable = false,
    placeholder = '',
    options,
    ...props
}: DetailFormSelectProps<T>) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        rules: { required },
        defaultValue,
    });

    useEffect(() => {
        setValueAsOption(options.find((option: Option<T>) => option.value === value));
    }, [value, options]);

    const [valueAsOption, setValueAsOption] = useState(
        props.isMulti
            ? options.filter(
                  (option: Option<T>) =>
                      defaultValue && Array.isArray(defaultValue) && defaultValue?.includes(option.value),
              )
            : options.find((option: Option<T>) => option.value === defaultValue),
    );

    // @ts-expect-error to fix
    const error = required && getFormError(errors?.[name]?.type);

    const onSingleChange = (selectedOption: Option<T>) => {
        onChange(selectedOption?.value || null);
    };

    const onMultiChange = (selectedOption: readonly Option<T>[]) => {
        onChange(selectedOption?.map((option) => option.value));
    };

    const selectStyles: StylesConfig<Option<T>> = {
        control: (base) => {
            return {
                ...base,
                minHeight: 45,
            };
        },
    };

    return (
        <Container width={width}>
            {label || sublabel ? (
                <LabelContainer>
                    {label ? <TotemLabel>{label}</TotemLabel> : null}
                    {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
                </LabelContainer>
            ) : null}
            <ValueContainer data-test={dataTest}>
                <Select<Option<T>, boolean, GroupBase<Option<T>>>
                    placeholder={placeholder || 'Aucune valeur'}
                    value={valueAsOption}
                    onChange={(selectedOption) => {
                        props.isMulti
                            ? onMultiChange(selectedOption as readonly Option<T>[])
                            : onSingleChange(selectedOption as Option<T>);
                    }}
                    closeMenuOnSelect={!props.isMulti}
                    options={options}
                    isClearable={isClearable}
                    styles={selectStyles}
                    {...props}
                />
                {error ? <Error>{error}</Error> : null}
            </ValueContainer>
        </Container>
    );
};

const Container = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width || '100%'};
    gap: 5px;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    color: black;
    position: relative;
`;

const Error = styled.div`
    position: absolute;
    right: -2px;
    top: -16px;
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
    width: max-content;
`;
