import React, { FunctionComponent, useState } from 'react';
import { TotemPopup } from 'components/TotemPopup';
import { TotemSelect } from 'components/TotemSelect';
import {
    GetDetailedProductQuery,
    useGetProductArrangementsQuery,
    useUpdateProductArrangementMutation,
} from 'data/__generated__';
import styled from 'styled-components';
import { TotemInput } from 'components/TotemInput';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GET_DETAILED_PRODUCT_QUERY } from 'data/queries/product';

type AddProductArrangementPopupProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    product: NonNullable<GetDetailedProductQuery['detailedProduct']>;
};

export const AddProductArrangementPopup: FunctionComponent<AddProductArrangementPopupProps> = ({
    isOpen,
    setIsOpen,
    product,
}) => {
    const { data } = useGetProductArrangementsQuery();

    const [selectedProductArrangementId, setSelectedProductArrangementId] = useState<string | null>(null);
    const [stockMaxUnsecured, setStockMaxUnsecured] = useState<number | null>(null);
    const [stockMaxSensei, setStockMaxSensei] = useState<number | null>(null);

    const [updateProductArrangement, { loading }] = useUpdateProductArrangementMutation({
        onCompleted: (data) => {
            if (!data.updateProductArrangementMutation) {
                return;
            }
            setIsOpen(false);
        },
        // refetching queries instead of cache update because shape of productArrangements in query and mutation are different
        // which would lead to a hard to maintain cache update.
        refetchQueries: [{ query: GET_DETAILED_PRODUCT_QUERY, variables: { productId: product._id } }],
        awaitRefetchQueries: true,
    });

    if (!data?.productArrangementsQuery) {
        return null;
    }

    const allOptions = data.productArrangementsQuery.map((pa) => ({ label: pa.name, value: pa._id }));
    const options = allOptions.filter((option) => !product.productArrangements.some((pa) => pa._id === option.value));

    const selectedOption = options.find((option) => option.value === selectedProductArrangementId);
    const selectedProductArrangement = data.productArrangementsQuery.find(
        (pa) => pa._id === selectedProductArrangementId,
    );

    const onSubmit = () => {
        if (!selectedProductArrangementId || !selectedProductArrangement) {
            return;
        }

        const newProductIdsWithStockMax = [
            ...selectedProductArrangement.productIdsWithStockMax.map(
                ({ productId, stockMaxSensei, stockMaxUnsecured }) => ({
                    productId,
                    stockMaxSensei,
                    stockMaxUnsecured,
                }),
            ),
            {
                productId: product._id,
                stockMaxUnsecured: stockMaxUnsecured ?? 0,
                stockMaxSensei: stockMaxSensei ?? 0,
            },
        ];

        updateProductArrangement({
            variables: {
                productArrangement: {
                    _id: selectedProductArrangementId,
                    name: selectedProductArrangement.name,
                    reassortmentType: selectedProductArrangement.reassortmentType,
                    productIdsWithStockMax: newProductIdsWithStockMax,
                },
            },
        });
    };

    return (
        <TotemPopup
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Ajouter à une gamme de produit"
            contentOverflow="visible"
        >
            <Container>
                <TotemSelect
                    label="Gamme de produit"
                    options={options}
                    value={selectedOption}
                    onChange={(option) => {
                        setSelectedProductArrangementId(option?.value || null);
                        const pa = data.productArrangementsQuery.find((el) => el._id === option?.value);
                        const defaultStockMaxValues = pa?.defaultStockMaxValues;
                        setStockMaxUnsecured(defaultStockMaxValues?.stockMaxUnsecured ?? null);
                        setStockMaxSensei(defaultStockMaxValues?.stockMaxSensei ?? null);
                    }}
                />
                {selectedOption && (
                    <TotemInput
                        label="Stock max confiance"
                        type="number"
                        onChange={(value) => {
                            const parsedValue = parseInt(value, 10);
                            setStockMaxUnsecured(parsedValue);
                        }}
                        value={stockMaxUnsecured ?? undefined}
                    />
                )}
                {selectedOption && (
                    <TotemInput
                        label="Stock max sécurisé"
                        type="number"
                        onChange={(value) => {
                            const parsedValue = parseInt(value, 10);
                            setStockMaxSensei(parsedValue);
                        }}
                        value={stockMaxSensei ?? undefined}
                    />
                )}
                <TotemPrimaryButton
                    onClick={onSubmit}
                    disabled={
                        !selectedProductArrangementId ||
                        stockMaxUnsecured === null ||
                        stockMaxSensei === null ||
                        loading
                    }
                >
                    Ajouter
                </TotemPrimaryButton>
            </Container>
        </TotemPopup>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
