import { useState, useEffect } from 'react';

declare global {
    interface Window {
        google: typeof google;
    }
}

const GOOGLE_MAPS_API_KEY = 'AIzaSyDvsaf_vEOaRlVeQkKwbRXrrGL8riN8G8I';
const GOOGLE_MAPS_LIBRARIES = ['places'];

export const useGoogleMaps = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (window.google) {
            setIsLoaded(true);
            return;
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=${GOOGLE_MAPS_LIBRARIES.join(',')}`;

        script.onload = () => {
            setIsLoaded(true);
        };

        script.onerror = () => {
            setError(new Error('Failed to load Google Maps API'));
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return { isLoaded, error };
};
