import React from 'react';

import { useFormContext } from 'react-hook-form';

import { GET_DETAILED_ORDER_detailedOrder } from 'data/queries/__generated__/GET_DETAILED_ORDER';

import { DetailFormSelect, DetailFormValue, DetailValue } from 'components/DetailsView';
import { OrderDiscountType } from 'data/__generated__';
import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import styled from 'styled-components';
import { renderAsPrice } from 'helpers/price';
export const PricesSection = ({ order }: { order: GET_DETAILED_ORDER_detailedOrder }) => {
    const {
        delivery,
        discount,
        prices: {
            discount: discountAmount,
            discount055,
            discount10,
            discount20,
            price,
            price055,
            price10,
            price20,
            priceDelivery,
            priceHT,
            priceHT055,
            priceHT10,
            priceHT20,
            priceTTC,
            priceTTC055,
            priceTTC10,
            priceTTC20,
            tva,
            tva055,
            tva10,
            tva20,
        },
    } = order;

    const { register } = useFormContext();

    const discountTypeOptions = [
        { label: 'Fixe', value: OrderDiscountType.Fixed },
        { label: 'Pourcentage', value: OrderDiscountType.Percentage },
    ];

    return (
        <SectionContainer title="Prix et réduction" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <SectionTitle>Prix</SectionTitle>
                    <DetailValue label="Prix des produits" value={renderAsPrice(price)} />
                    <DetailValue label="Prix total HT" value={renderAsPrice(priceHT)} />
                    <DetailValue label="Prix total TTC" value={renderAsPrice(priceTTC)} />
                    <DetailFormValue
                        label="Frais de livraison total €"
                        sublabel="Frais de livraison de base et mise en place cumulés"
                        defaultValue={priceDelivery}
                        {...register('priceDelivery', { required: true, valueAsNumber: true })}
                        type="number"
                        width="50%"
                        disabled={!!delivery}
                    />
                </SectionColumn>
                <SectionColumn>
                    <SectionTitle>Réduction</SectionTitle>
                    <DetailFormValue
                        label="Description"
                        defaultValue={discount?.description}
                        {...register('discount.description')}
                        disabled={!!delivery}
                    />
                    <DetailFormValue
                        label="Valeur"
                        sublabel="Pour un pourcentage, utiliser une valeur entre 0 et 1"
                        defaultValue={discount?.amount}
                        type="number"
                        step="0.01"
                        {...register('discount.amount', {
                            valueAsNumber: true,
                        })}
                        disabled={!!delivery}
                    />
                    <DetailFormSelect
                        label="Type de réduction"
                        placeholder="Sélectionner"
                        defaultValue={discount?.type}
                        name="discount.type"
                        options={discountTypeOptions}
                        isDisabled={!!delivery}
                    />
                </SectionColumn>
                <SectionColumn>
                    <SectionTitle>Détails</SectionTitle>
                    <Table>
                        <TableHeader>
                            <tr>
                                <TableCell />
                                <TableCell>Prix</TableCell>
                                <TableCell>Remise</TableCell>
                                <TableCell>Livraison</TableCell>
                                <TableCell>Prix HT</TableCell>
                                <TableCell>TVA</TableCell>
                                <TableCell>Prix TTC</TableCell>
                            </tr>
                        </TableHeader>
                        <tbody>
                            <tr>
                                <TableCell>Produits à TVA 5,5%</TableCell>
                                <TableCell>{renderAsPrice(price055)}</TableCell>
                                <TableCell>-{renderAsPrice(discount055)}</TableCell>
                                <TableCell />
                                <TableCell>{renderAsPrice(priceHT055)}</TableCell>
                                <TableCell>{renderAsPrice(tva055)}</TableCell>
                                <TableCell>{renderAsPrice(priceTTC055)}</TableCell>
                            </tr>
                            <tr>
                                <TableCell>Produits à TVA 10%</TableCell>
                                <TableCell>{renderAsPrice(price10)}</TableCell>
                                <TableCell>-{renderAsPrice(discount10)}</TableCell>
                                <TableCell />
                                <TableCell>{renderAsPrice(priceHT10)}</TableCell>
                                <TableCell>{renderAsPrice(tva10)}</TableCell>
                                <TableCell>{renderAsPrice(priceTTC10)}</TableCell>
                            </tr>
                            <tr>
                                <TableCell>Produits à TVA 20%</TableCell>
                                <TableCell>{renderAsPrice(price20)}</TableCell>
                                <TableCell>-{renderAsPrice(discount20)}</TableCell>
                                <TableCell>{renderAsPrice(priceDelivery)}</TableCell>
                                <TableCell>{renderAsPrice(priceHT20)}</TableCell>
                                <TableCell>{renderAsPrice(tva20)}</TableCell>
                                <TableCell>{renderAsPrice(priceTTC20)}</TableCell>
                            </tr>
                        </tbody>
                        <TableFooter>
                            <tr>
                                <TableCell>Total</TableCell>
                                <TableCell>{renderAsPrice(price)}</TableCell>
                                <TableCell>-{renderAsPrice(discountAmount)}</TableCell>
                                <TableCell>{renderAsPrice(priceDelivery)}</TableCell>
                                <TableCell>{renderAsPrice(priceHT)}</TableCell>
                                <TableCell>{renderAsPrice(tva)}</TableCell>
                                <TableCell>{renderAsPrice(priceTTC)}</TableCell>
                            </tr>
                        </TableFooter>
                    </Table>
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const SectionTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    color: ${({ theme }) => theme.textColor};
`;

const Table = styled.table`
    border-spacing: 0px;
    color: ${({ theme }) => theme.textColor};
`;

const TableHeader = styled.thead`
    font-weight: 800;
`;

const TableCell = styled.td`
    text-align: center;
`;

const TableFooter = styled.tfoot`
    font-weight: 800;

    & ${TableCell} {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;
