import React, { useEffect, useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { FaSave, FaUndoAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { apolloClient } from 'App';

import { PAGES } from '../../../constants/pages';

import { SUPPLY_ORDERS_WITH_SUPPLIER_PRODUCTS_INFO_UPDATED_OR_CREATED_SUBSCRIPTION } from 'data/subscriptions/supplyOrder';

import { ActionSection } from './ActionSection';
import { Header, HeaderTitle } from 'components/Header';
import { FullPageLoader } from 'components/FullPageLoader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GeneralInfoSection } from './GeneralInfoSection';
import { StatesSection } from './StatesSection';
import { ReceiptInfoSection } from './ReceiptInfoSection';
import { SupplierInfoSection } from './SupplierInfoSection';
import {
    mapDetailsDisplayValuesToSupplyOrderUpdateInput,
    mapSupplyOrderToDetailsDisplayValues,
    SupplyOrderDetailsDisplayValues,
} from './supplyOrderDetailsFormHelper';
import { ProductsInfoSection } from './ProductsInfoSection';

import { AbortSupplyOrderPopup } from './Popups/AbortSupplyOrderPopup';
import { AdditionalDeliveryPopup } from 'pages/SupplyOrders/SupplyOrderDetails/Popups/AdditionalDeliveryPopup';

import { supplyOrdersWithSupplierProductsInfoUpdatedOrCreatedHandler } from 'pages/SupplyOrders/cacheHandlers/supplyOrdersCacheHandler';
import {
    GetSupplyOrderSupplierProductsStockVariationQuery,
    GetSupplyOrderWithSupplierProductsInfoQuery,
    GetProductsToOrderOnceForSupplyOrderQuery,
    useUpdateSupplyOrderMutation,
    Exact,
    Scalars,
    SupplyOrdersWithSupplierProductsInfoUpdatedOrCreatedSubscription,
} from 'data/__generated__';
import type { QueryResult } from '@apollo/client';

export const SupplyOrderDetailsFormPage = ({
    supplyOrder,
    productsStockVariationQueryState,
    productsToOrderOnce,
}: {
    supplyOrder: GetSupplyOrderWithSupplierProductsInfoQuery['supplyOrderWithSupplierProductsInfo'];
    productsStockVariationQueryState: QueryResult<
        GetSupplyOrderSupplierProductsStockVariationQuery,
        Exact<{
            supplyOrderId: Scalars['String']['input'];
        }>
    >;
    productsToOrderOnce: GetProductsToOrderOnceForSupplyOrderQuery['productsToOrderOnceForSupplyOrder'];
}) => {
    const [showAbortPopup, setShowAbortPopup] = useState<boolean>(false);
    const [showAdditionalDeliveryPopup, setShowAdditionalDeliveryPopup] = useState<boolean>(false);
    const [updateSupplyOrder, { loading: updateLoading }] = useUpdateSupplyOrderMutation();

    const supplyOrderDetailsDisplayValues = mapSupplyOrderToDetailsDisplayValues(supplyOrder, productsToOrderOnce);

    const methods = useForm<SupplyOrderDetailsDisplayValues>({ defaultValues: supplyOrderDetailsDisplayValues });
    const {
        handleSubmit,
        formState: { isDirty },
        reset,
    } = methods;

    // we need to reset form values in a separate useEffect because I guess that doing it while modifying the cache lead to some concurrency issue.
    useEffect(() => {
        const newFormValues = mapSupplyOrderToDetailsDisplayValues(supplyOrder, productsToOrderOnce);
        reset(newFormValues);
    }, [reset, supplyOrder]);

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: SUPPLY_ORDERS_WITH_SUPPLIER_PRODUCTS_INFO_UPDATED_OR_CREATED_SUBSCRIPTION,
        });

        const subscription = observer.subscribe(({ data }) => {
            const {
                supplyOrdersUpdatedOrCreated,
            }: {
                supplyOrdersUpdatedOrCreated: SupplyOrdersWithSupplierProductsInfoUpdatedOrCreatedSubscription['supplyOrdersUpdatedOrCreated'][number][];
            } = data;

            const updatedOrCreatedSupplyOrderWithProductsInfo = supplyOrdersUpdatedOrCreated.find(
                ({ _id }) => _id === supplyOrder._id,
            );

            if (!updatedOrCreatedSupplyOrderWithProductsInfo) {
                return;
            }

            toast.warning(
                "Quelqu'un vient de modifier l'achat ! Nous avons réinitialisé le formulaire avec les valeurs en base",
            );

            supplyOrdersWithSupplierProductsInfoUpdatedOrCreatedHandler(
                updatedOrCreatedSupplyOrderWithProductsInfo,
                supplyOrder._id,
            );
        });

        return () => subscription.unsubscribe();
    }, [reset, supplyOrder._id]);

    const onSubmit = handleSubmit(async (formValues) => {
        const supplyOrderUpdateInput = mapDetailsDisplayValuesToSupplyOrderUpdateInput(formValues);
        const { data } = await updateSupplyOrder({
            variables: { supplyOrderId: supplyOrder._id, fields: supplyOrderUpdateInput },
        });

        if (data) {
            const {
                updateSupplyOrderMutation: { success, formErrors, errors, warnings, updatedSupplyOrder },
            } = data;
            if (success && updatedSupplyOrder) {
                toast.success(
                    `L'achat n°${supplyOrder.number} au fournisseur "${supplyOrder.supplier.name}" a bien été modifié !`,
                );
            }
            if (formErrors && formErrors.length) {
                formErrors.forEach(({ sectionName, sectionErrors }) => {
                    toast.error(
                        <span>
                            Erreur dans la section "{sectionName}" :
                            {sectionErrors.map(({ fieldName, fieldError }, index) => (
                                <span key={index}>
                                    <br />- {fieldName} : "{fieldError}"
                                </span>
                            ))}
                        </span>,
                        { autoClose: false },
                    );
                });
            }

            if (errors.length) {
                errors.forEach((error) => {
                    toast.error(<span>Erreur : {error}</span>, { autoClose: false });
                });
            }

            if (warnings.length) {
                warnings.forEach((warning) => {
                    toast.warning(<span>Warning : {warning}</span>);
                });
            }

            if (errors.length + warnings.length + ((formErrors && formErrors.length) || 0) > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
            if (!success && !errors.length && !formErrors?.length) {
                throw Error("Une erreur inconnue s'est produite");
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    });

    return (
        <>
            <Container>
                <FormProvider {...methods}>
                    <Form onSubmit={onSubmit}>
                        <Header>
                            <HeaderTitle>
                                <PageTitle page={PAGES.supplyOrderDetails} />
                            </HeaderTitle>
                            <CTAsContainer>
                                {isDirty ? (
                                    <TotemPrimaryButton isSecondaryStyle type="button" onClick={() => reset()}>
                                        <FaUndoAlt size={13} />
                                        <SaveLabel>Réinitialiser</SaveLabel>
                                    </TotemPrimaryButton>
                                ) : null}
                                {isDirty ? (
                                    <TotemPrimaryButton type="submit">
                                        <FaSave size={13} />
                                        <SaveLabel>Mettre à jour</SaveLabel>
                                    </TotemPrimaryButton>
                                ) : null}
                                <TotemPrimaryButton
                                    disabled={isDirty}
                                    type="button"
                                    onClick={() => setShowAdditionalDeliveryPopup(true)}
                                >
                                    Livraison additionnelle
                                </TotemPrimaryButton>
                                <Link to={PAGES.supplyOrders.url}>
                                    <TotemPrimaryButton isSecondaryStyle type="button">
                                        Retour
                                    </TotemPrimaryButton>
                                </Link>
                            </CTAsContainer>
                        </Header>
                        <Content>
                            <ScrollableContent>
                                <ActionSection
                                    supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues}
                                    setShowAbortPopup={setShowAbortPopup}
                                    isDirty={isDirty}
                                />
                                <GeneralInfoSection supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues} />
                                <StatesSection supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues} />
                                <ReceiptInfoSection supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues} />
                                <SupplierInfoSection
                                    supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues}
                                />
                                <ProductsInfoSection
                                    orderingTimes={supplyOrder.supplier.orderingTimes}
                                    supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues}
                                    productsStockVariationQueryState={productsStockVariationQueryState}
                                    productsToOrderOnce={productsToOrderOnce}
                                />
                            </ScrollableContent>
                        </Content>
                    </Form>
                </FormProvider>
            </Container>
            {updateLoading ? <FullPageLoader /> : null}
            <AbortSupplyOrderPopup
                isOpen={showAbortPopup}
                setIsOpen={setShowAbortPopup}
                supplyOrderId={supplyOrder._id}
            />
            <AdditionalDeliveryPopup
                isOpen={showAdditionalDeliveryPopup}
                setIsOpen={setShowAdditionalDeliveryPopup}
                supplyOrderId={supplyOrder._id}
                supplierProducts={supplyOrder.supplier.productsInfoWithStock ?? []}
            />
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const CTAsContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;
