import React from 'react';
import styled from 'styled-components';

import {
    DESSERTS_CATEGORY_ID,
    MEALS_CATEGORY_ID,
    SMALL_BITES_CATEGORY_ID,
    STARTERS_CATEGORY_ID,
} from 'pages/Products/constants/categories';

import { GetMicrostoreSiteQuery } from 'data/__generated__';
import {
    MicrostoreLocationFormValues,
    MicrostoreProductsStockInfoFormValues,
} from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/FormHelper/DataMapper';
import {
    getProductStockInLocation,
    getReplacementProductStockInLocation,
    getStockToRemoveInLocation,
} from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/FormHelper/stockInLocationHelper';

function updateAccumulatorCategories({
    accumulator: { dessert, main, starter, smallBites },
    categoryId,
    quantityToAdd,
}: {
    accumulator: {
        dessert: number;
        main: number;
        starter: number;
        smallBites: number;
    };
    categoryId: string;
    quantityToAdd: number;
}) {
    if (categoryId === STARTERS_CATEGORY_ID) {
        return {
            dessert,
            main,
            smallBites,
            starter: starter + quantityToAdd,
        };
    }
    if (categoryId === MEALS_CATEGORY_ID) {
        return {
            dessert,
            main: main + quantityToAdd,
            smallBites,
            starter,
        };
    }
    if (categoryId === DESSERTS_CATEGORY_ID) {
        return {
            dessert: dessert + quantityToAdd,
            main,
            smallBites,
            starter,
        };
    }
    if (categoryId === SMALL_BITES_CATEGORY_ID) {
        return {
            dessert,
            main,
            smallBites: smallBites + quantityToAdd,
            starter,
        };
    }

    return { dessert, main, starter, smallBites };
}

function getTomorrowStockByCategories({
    microstoreProductsStockInfo,
    allSiteLocations,
    site,
}: {
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    allSiteLocations: MicrostoreLocationFormValues[];
    site: GetMicrostoreSiteQuery['siteWithLocationInfo'];
}) {
    if (!microstoreProductsStockInfo) {
        return {
            dessert: 0,
            main: 0,
            smallBites: 0,
            starter: 0,
        };
    }

    const hasStartedReassortment = microstoreProductsStockInfo.some(
        (productStockInfo) => productStockInfo.isPresentTomorrow,
    );

    return allSiteLocations.reduce(
        (acc, location) => {
            const { _id: locationId, productId, stockToAdd, replacementProductId } = location;

            if (!hasStartedReassortment) {
                const locationProduct = microstoreProductsStockInfo.find(({ _id }) => _id === productId);
                if (!locationProduct) return acc;

                const quantityToAdd = getProductStockInLocation({
                    locationId,
                    allSiteLocations,
                    microstoreProductsStockInfo,
                    productId: productId || null,
                });

                return updateAccumulatorCategories({
                    accumulator: acc,
                    quantityToAdd,
                    categoryId: locationProduct.categoryId,
                });
            } else {
                const replacementProduct = microstoreProductsStockInfo.find(({ _id }) => _id === replacementProductId);
                if (!replacementProduct) return acc;

                const replacementProductStockInLocation = getReplacementProductStockInLocation({
                    locationId,
                    allSiteLocations,
                    microstoreProductsStockInfo,
                    replacementProductId: replacementProductId || null,
                });

                const stockToRemoveInLocation = getStockToRemoveInLocation({
                    locationId,
                    allSiteLocations,
                    microstoreProductsStockInfo,
                    earliestDLCDateToKeepForStockCalculation: site.earliestDLCDateToKeepForStockCalculation || null,
                    replacementProductId: replacementProductId || null,
                });

                const quantityToAdd = replacementProductStockInLocation + stockToAdd - stockToRemoveInLocation;

                return updateAccumulatorCategories({
                    accumulator: acc,
                    quantityToAdd,
                    categoryId: replacementProduct.categoryId,
                });
            }
        },
        {
            dessert: 0,
            main: 0,
            starter: 0,
            smallBites: 0,
        },
    );
}

export const TargetsInfo = ({
    microstoreProductsStockInfo,
    allSiteLocations,
    site,
}: {
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    allSiteLocations: MicrostoreLocationFormValues[];
    site: GetMicrostoreSiteQuery['siteWithLocationInfo'];
}) => {
    const tomorrowStockByCategories = getTomorrowStockByCategories({
        microstoreProductsStockInfo,
        allSiteLocations,
        site,
    });

    return (
        <TargetsInfoContainer>
            <TargetsInfoRow>
                <TargetsInfoLabel>Entrées :</TargetsInfoLabel>
                <div>
                    {tomorrowStockByCategories.starter}/{site.reassortmentStockTargets.starter}
                </div>
            </TargetsInfoRow>
            <TargetsInfoRow>
                <TargetsInfoLabel>Petites faims :</TargetsInfoLabel>
                <div>
                    {tomorrowStockByCategories.smallBites}/{site.reassortmentStockTargets.smallBites}
                </div>
            </TargetsInfoRow>
            <TargetsInfoRow>
                <TargetsInfoLabel>Plats :</TargetsInfoLabel>
                <div>
                    {tomorrowStockByCategories.main}/{site.reassortmentStockTargets.main}
                </div>
            </TargetsInfoRow>
            <TargetsInfoRow>
                <TargetsInfoLabel>Desserts :</TargetsInfoLabel>
                <div>
                    {tomorrowStockByCategories.dessert}/{site.reassortmentStockTargets.dessert}
                </div>
            </TargetsInfoRow>
        </TargetsInfoContainer>
    );
};

const TargetsInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TargetsInfoRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const TargetsInfoLabel = styled.span`
    font-size: 13px;
`;
