import { GetMenuQuantitiesQuery, MenuInstructionType } from 'data/__generated__';
import { MenuInstruction, QuantityTotals } from './types';
import { MENU_CATEGORY_IDS } from './constants';

export const calculateDateTotals = (
    column: GetMenuQuantitiesQuery['menuQuantities'][0]['columns'][0],
    date: string,
): QuantityTotals => {
    const quantityTotals = MENU_CATEGORY_IDS.reduce(
        (
            acc: QuantityTotals & {
                isQuantityInstructionDefined: boolean;
                isQuantityFacingInstructionDefined: boolean;
            },
            categoryId,
        ) => {
            const categoryData = column.categoryQuantities.find((cat) => cat.category._id === categoryId);
            const dateData = categoryData?.dateQuantities.find((d) => d.date === date);
            return {
                quantity: acc.quantity + (dateData?.quantity ?? 0),
                quantityFacing: acc.quantityFacing + (dateData?.quantityFacing ?? 0),
                quantityInstruction:
                    (acc.quantityInstruction ?? 0) + (dateData?.quantityInstruction ?? dateData?.quantity ?? 0),
                isQuantityInstructionDefined:
                    acc.isQuantityInstructionDefined ||
                    (dateData?.quantityInstruction !== null && dateData?.quantityInstruction !== undefined),
                quantityFacingInstruction:
                    (acc.quantityFacingInstruction ?? 0) +
                    (dateData?.quantityFacingInstruction ?? dateData?.quantityFacing ?? 0),
                isQuantityFacingInstructionDefined:
                    acc.isQuantityFacingInstructionDefined ||
                    (dateData?.quantityFacingInstruction !== null && dateData?.quantityFacingInstruction !== undefined),
            };
        },
        {
            quantity: 0,
            quantityFacing: 0,
            quantityInstruction: 0,
            quantityFacingInstruction: 0,
            isQuantityInstructionDefined: false,
            isQuantityFacingInstructionDefined: false,
        },
    );
    return {
        quantity: quantityTotals.quantity,
        quantityFacing: quantityTotals.quantityFacing,
        quantityInstruction: quantityTotals.isQuantityInstructionDefined ? quantityTotals.quantityInstruction : null,
        quantityFacingInstruction: quantityTotals.isQuantityFacingInstructionDefined
            ? quantityTotals.quantityFacingInstruction
            : null,
    };
};

export const calculateRowTotals = (
    column: GetMenuQuantitiesQuery['menuQuantities'][0]['columns'][0],
    dates: string[],
): {
    quantity: number;
    quantityFacing: number;
    quantityInstruction: number | null;
    quantityFacingInstruction: number | null;
} => {
    return dates.reduce(
        (acc, date) => {
            const dateTotals = calculateDateTotals(column, date);
            return {
                quantity: acc.quantity + (dateTotals.quantity ?? 0),
                quantityFacing: acc.quantityFacing + (dateTotals.quantityFacing ?? 0),
                quantityInstruction:
                    acc.quantityInstruction + (dateTotals.quantityInstruction ?? dateTotals.quantity ?? 0),
                quantityFacingInstruction:
                    acc.quantityFacingInstruction +
                    (dateTotals.quantityFacingInstruction ?? dateTotals.quantityFacing ?? 0),
            };
        },
        { quantity: 0, quantityFacing: 0, quantityInstruction: 0, quantityFacingInstruction: 0 },
    );
};

export const createMenuInstructions = (
    menuQuantitiesData: GetMenuQuantitiesQuery,
    menuInstruction: MenuInstruction,
): (Omit<MenuInstruction, 'categoryId'> & { categoryId: string })[] => {
    if (menuInstruction.categoryId) {
        return [{ ...menuInstruction, categoryId: menuInstruction.categoryId }];
    }

    const categoryQuantities = menuQuantitiesData?.menuQuantities
        .flatMap((site) => site.columns)
        .find((column) => column.column._id === menuInstruction.microstoreColumnId)?.categoryQuantities;

    const totalInitialQuantity =
        categoryQuantities?.reduce((acc, category) => {
            const dateQuantity = category.dateQuantities.find((date) => date.date === menuInstruction.date);
            if (menuInstruction.type === MenuInstructionType.SetupQuantities) {
                return acc + (dateQuantity?.quantity ?? 0);
            }
            if (menuInstruction.type === MenuInstructionType.SetupFacing) {
                return acc + (dateQuantity?.quantityFacing ?? 0);
            }
            return acc;
        }, 0) ?? 0;

    return MENU_CATEGORY_IDS.map((categoryId) => {
        const dateQuantity = categoryQuantities
            ?.find((category) => category.category._id === categoryId)
            ?.dateQuantities.find((date) => date.date === menuInstruction.date);

        const quantity =
            menuInstruction.type === MenuInstructionType.SetupQuantities
                ? dateQuantity?.quantity ?? 0
                : dateQuantity?.quantityFacing ?? 0;

        const weight = totalInitialQuantity ? quantity / totalInitialQuantity : 1 / MENU_CATEGORY_IDS.length;
        return {
            ...menuInstruction,
            categoryId,
            value:
                menuInstruction.value !== null && menuInstruction.value !== undefined
                    ? Math.round(menuInstruction.value * weight)
                    : null,
        };
    });
};
