import { Header } from 'components/Header';
import { HeaderTitle } from 'components/Header/HeaderTitle';
import { PageTitle } from 'components/PageTitle';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { PAGES } from 'constants/pages';
import {
    MenuInstructionInput,
    MenuInstructionType,
    useGetMenuQuantitiesQuery,
    GetMenuQuantitiesQuery,
} from 'data/__generated__';
import { UpdateMenuQuantitiesButton } from './UpdateMenuQuantitiesButton';
import { formatDateAsAnniversary, getNextMondayDate, getWeekNumber } from 'helpers/dateTimes';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'components/Loader';
import { TotemSwitch } from 'components/TotemSwitch';
import { DaySelector } from './components/DaySelector';
import { QuantitiesTable } from './components/QuantitiesTable';
import { MenuInstruction, ParamTypes } from './types';
import { createMenuInstructions } from './utils';
import styled from 'styled-components';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { GenerateQuantitiesButton } from '../HeaderButtons/GenerateQuantitiesButton';
export const MicrostoreMenuQuantities = () => {
    const navigate = useNavigate();
    const { selectedDate: selectedDateFromUrl } = useParams<ParamTypes>();
    const [selectedDays, setSelectedDays] = useState<boolean[]>([true, true, true, true, true, false, false]);
    const [showCategories, setShowCategories] = useState(false);
    const [menuInstructions, setMenuInstructions] = useState<MenuInstructionInput[]>([]);
    const [localMenuQuantitiesData, setLocalMenuQuantitiesData] = useState<GetMenuQuantitiesQuery | undefined>(
        undefined,
    );

    const selectedDate = selectedDateFromUrl ? new Date(selectedDateFromUrl) : getNextMondayDate();

    const {
        data: menuQuantitiesData,
        loading: menuQuantitiesLoading,
        error: menuQuantitiesError,
    } = useGetMenuQuantitiesQuery({
        variables: {
            date: selectedDate.toISOString(),
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    // Initialiser les données locales quand les données du serveur arrivent
    useEffect(() => {
        if (menuQuantitiesData) {
            setLocalMenuQuantitiesData(menuQuantitiesData);
        }
    }, [menuQuantitiesData]);

    const setSelectedDate = useCallback(
        (selectedDate: Date) => {
            navigate(
                `/menuQuantities/${formatDateAsAnniversary({
                    dateTime: selectedDate,
                    useNewFormat: true,
                })}`,
            );
        },
        [navigate],
    );

    const groupedDates = useMemo(() => {
        if (!localMenuQuantitiesData?.menuQuantities) return [];

        // Get unique dates
        const dates = Array.from(
            new Set(
                localMenuQuantitiesData.menuQuantities
                    .flatMap((site) => site.columns)
                    .flatMap((column) => column.categoryQuantities)
                    .flatMap((cat) => cat.dateQuantities)
                    .map((date) => date.date),
            ),
        ).sort();

        // Filter dates based on selected days
        return dates.filter((date, index) => selectedDays[index]);
    }, [localMenuQuantitiesData, selectedDays]);

    const updateLocalData = useCallback(
        (newInstructions: MenuInstruction[]) => {
            if (!localMenuQuantitiesData) return;

            const updatedData = {
                ...localMenuQuantitiesData,
                menuQuantities: localMenuQuantitiesData.menuQuantities.map((site) => ({
                    ...site,
                    columns: site.columns.map((column) => {
                        const columnInstructions = newInstructions.filter(
                            (instruction) => instruction.microstoreColumnId === column.column._id,
                        );
                        if (columnInstructions.length === 0) return column;

                        return {
                            ...column,
                            categoryQuantities: column.categoryQuantities.map((categoryQuantity) => {
                                const categoryInstructions = columnInstructions.filter(
                                    (instruction) =>
                                        instruction.categoryId === categoryQuantity.category._id ||
                                        !instruction.categoryId,
                                );
                                if (categoryInstructions.length === 0) return categoryQuantity;

                                return {
                                    ...categoryQuantity,
                                    dateQuantities: categoryQuantity.dateQuantities.map((dateQuantity) => {
                                        const dateInstructions = categoryInstructions.filter(
                                            (instruction) => instruction.date === dateQuantity.date,
                                        );
                                        if (dateInstructions.length === 0) return dateQuantity;

                                        const quantityInstruction = dateInstructions.find(
                                            (i) => i.type === MenuInstructionType.SetupQuantities,
                                        );
                                        const facingInstruction = dateInstructions.find(
                                            (i) => i.type === MenuInstructionType.SetupFacing,
                                        );

                                        return {
                                            ...dateQuantity,
                                            quantityInstruction:
                                                quantityInstruction !== null && quantityInstruction !== undefined
                                                    ? quantityInstruction.value
                                                    : dateQuantity.quantityInstruction,
                                            quantityFacingInstruction:
                                                facingInstruction !== null && facingInstruction !== undefined
                                                    ? facingInstruction.value
                                                    : dateQuantity.quantityFacingInstruction,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                })),
            };

            setLocalMenuQuantitiesData(updatedData);
        },
        [localMenuQuantitiesData],
    );

    const handleUpdateInstruction = useCallback(
        (menuInstruction: MenuInstruction) => {
            if (!localMenuQuantitiesData) return;

            const newInstructions = createMenuInstructions(localMenuQuantitiesData, menuInstruction);
            updateLocalData(newInstructions);

            setMenuInstructions((prevInstructions) => {
                const filteredInstructions = prevInstructions.filter(
                    (instruction) =>
                        !newInstructions.some(
                            (newInstruction) =>
                                instruction.microstoreColumnId === newInstruction.microstoreColumnId &&
                                instruction.categoryId === newInstruction.categoryId &&
                                instruction.date === newInstruction.date &&
                                instruction.type === newInstruction.type,
                        ),
                );
                return [
                    ...filteredInstructions,
                    ...newInstructions.map((instruction) => ({
                        ...instruction,
                        categoryId: instruction.categoryId,
                    })),
                ];
            });
        },
        [localMenuQuantitiesData, updateLocalData],
    );

    if (menuQuantitiesLoading) {
        return (
            <MainContainer>
                <Loader />
            </MainContainer>
        );
    }

    if (menuQuantitiesError || !menuQuantitiesData) {
        throw new Error('Une erreur est survenue lors de la récupération des quantités de menus');
    }

    return (
        <MainContainer>
            <StyledHeader>
                <HeaderTitle>
                    <PageTitle page={PAGES.menuQuantities} />
                </HeaderTitle>
                <FiltersContainer>
                    <UpdateMenuQuantitiesButton menuInstructions={menuInstructions} />
                    <GenerateQuantitiesButton date={selectedDate} />
                    <Link to="/menus">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                    <WeekNumberContainer
                        value={'Semaine ' + getWeekNumber(selectedDate)}
                        onChange={() => {}}
                        disabled
                    />
                    <TotemDatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date || getNextMondayDate());
                        }}
                    />
                    <DaySelector
                        selectedDays={selectedDays}
                        onDaySelect={(dayIndex) => {
                            const newSelectedDays = [...selectedDays];
                            newSelectedDays[dayIndex] = !newSelectedDays[dayIndex];
                            setSelectedDays(newSelectedDays);
                        }}
                    />
                    <FilterSwitchContainer>
                        <span>Détail par catégorie</span>
                        <TotemSwitch checked={showCategories} onChange={() => setShowCategories(!showCategories)} />
                    </FilterSwitchContainer>
                </FiltersContainer>
            </StyledHeader>
            <ScrollableContainer>
                <QuantitiesTable
                    menuQuantitiesData={localMenuQuantitiesData || menuQuantitiesData}
                    groupedDates={groupedDates}
                    showCategories={showCategories}
                    onUpdateInstruction={handleUpdateInstruction}
                />
            </ScrollableContainer>
        </MainContainer>
    );
};

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

const StyledHeader = styled(Header)`
    z-index: 1000;
`;

const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;
    flex-shrink: 0;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const ScrollableContainer = styled.div`
    margin: 20px;
    overflow: auto;
    flex: 1;
    display: flex;
    min-width: 0;
    max-width: 100%;

    &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.backgroundColor};
    }

    &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.lightBorderColor};
        border-radius: 5px;
    }
`;

const FilterSwitchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const WeekNumberContainer = styled(TotemInput)`
    width: 110px;
`;
