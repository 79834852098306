import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import {
    GetStockTransferQuery,
    useValidateStockTransfersMutation,
    useAbortStockTransfersMutation,
    StockTransferState,
    StockTransferFragmentFragment,
} from 'data/__generated__';

import { StatusTag } from 'pages/StockTransfers/components/StatusTag';

import { StateHistory } from 'components/DetailsView/StateHistory';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';

export const StatesSection = ({
    stateHistory,
    stockTransfer,
    updatedStockTransferDetails,
}: {
    stateHistory: GetStockTransferQuery['stockTransfer']['stateHistory'];
    stockTransfer: GetStockTransferQuery['stockTransfer'];
    updatedStockTransferDetails: GetStockTransferQuery['stockTransfer'] | null;
}) => {
    const { _id: stockTransferId, state } = stockTransfer;

    const [validateStockTransfers, { loading: validateStockTransferLoading }] = useValidateStockTransfersMutation();
    const [abortStockTransfers, { loading: abortStockTransferLoading }] = useAbortStockTransfersMutation();

    const STATE_CHANGE_ACTIONS = [
        {
            label: 'Valider le transfert de stock',
            stateToSet: StockTransferState.Validated,
            isDisabled: state !== StockTransferState.Created,
        },
        {
            label: 'Annuler le transfert de stock',
            stateToSet: StockTransferState.Aborted,
            isDisabled: state !== StockTransferState.Created,
        },
    ];
    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    const handleStateUpdate = async (stateToSet: StockTransferState) => {
        if (stateToSet === StockTransferState.Validated) {
            if (
                stockTransfer.shouldUpdateStockImmediately &&
                !window.confirm(
                    'Vous allez mettre à jour les stocks immédiatement sans livraison ni RE, ok pour vous ?',
                )
            ) {
                return;
            }
            const { data: validationResponse } = await validateStockTransfers({
                variables: { stockTransferIds: [stockTransferId] },
            });
            if (validationResponse?.validateStockTransfers) {
                handleToast({
                    errors: validationResponse.validateStockTransfers.errors,
                    stockTransfer: validationResponse.validateStockTransfers.stockTransfers[0],
                });
            }
        } else {
            const { data: abortionResponse } = await abortStockTransfers({
                variables: { stockTransferIds: [stockTransferId] },
            });
            if (abortionResponse?.abortStockTransfers) {
                handleToast({
                    errors: abortionResponse.abortStockTransfers.errors,
                    stockTransfer: abortionResponse.abortStockTransfers.stockTransfers[0],
                });
            }
        }
    };

    function handleToast({
        errors,
        stockTransfer,
    }: {
        errors: string[];
        stockTransfer: StockTransferFragmentFragment | undefined;
    }) {
        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }

        if (stockTransfer) {
            toast.success('Le statut du transfert de stock a été modifié !');
        }
    }

    return (
        <ColumnsSectionContainer numberOfColumns={3}>
            <StateHistory label="Historique de statuts" records={formattedStateHistory} />
            <StatusTag state={state} showHeading={true} />
            <SectionColumn>
                {updatedStockTransferDetails ? (
                    <WarningText>
                        ⚠️ Veuillez vous assurer d'avoir sauvegardé ou annulé vos ajustements avant de mettre à jour le
                        statut du ST
                    </WarningText>
                ) : null}
                <OverlaySectionColumn isDirty={!!updatedStockTransferDetails}>
                    {STATE_CHANGE_ACTIONS.map((stateChange, index) => {
                        const { label, stateToSet, isDisabled } = stateChange;

                        function updateState(e: React.MouseEvent<HTMLElement, MouseEvent>) {
                            e.preventDefault();
                            const hasConfirmed = window.confirm(`Êtes-vous sûr.e de vouloir ${label.toLowerCase()} ?`);
                            if (hasConfirmed) {
                                handleStateUpdate(stateToSet);
                            }
                        }

                        return (
                            <TotemPrimaryButton
                                key={index}
                                onClick={updateState}
                                isSecondaryStyle={stateToSet !== StockTransferState.Validated}
                                disabled={
                                    isDisabled ||
                                    !!updatedStockTransferDetails ||
                                    validateStockTransferLoading ||
                                    abortStockTransferLoading
                                }
                                data-test="stock-transfer-action"
                            >
                                {validateStockTransferLoading || abortStockTransferLoading ? (
                                    <Loader size="20px" mode={LoaderModeType.Spin} />
                                ) : (
                                    label
                                )}
                            </TotemPrimaryButton>
                        );
                    })}
                </OverlaySectionColumn>
            </SectionColumn>
        </ColumnsSectionContainer>
    );
};

const WarningText = styled.div`
    color: ${({ theme }) => theme.textColor};
    font-weight: 800;
`;

const OverlaySectionColumn = styled(SectionColumn)`
    opacity: ${({ isDirty }: { isDirty: boolean }) => (isDirty ? 0.4 : 1)};
`;
