import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FaTrash, FaPlus, FaArrowUp } from 'react-icons/fa';
import styled from 'styled-components';

import { ErrorType, FORM_ERRORS, MIN } from 'constants/formErrors';

import { TotemLabel } from 'components/TotemLabel';
import { DetailFormValue } from 'components/DetailsView';
import { colors } from 'constants/colors';

type ProductSupplierPrice = {
    value: number;
    conditionning: number;
    packagesPerBatch: number;
};

type FormValues = {
    suppliers: {
        prices: ProductSupplierPrice[];
    }[];
};

type PriceError = {
    type: ErrorType;
};

export const PricesArray = ({ supplierIndex, supplierName }: { supplierIndex: number; supplierName: string }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext<FormValues>();
    const {
        fields: priceFields,
        prepend,
        remove,
        move,
    } = useFieldArray<FormValues, `suppliers.${number}.prices`, 'id'>({
        name: `suppliers.${supplierIndex}.prices` as const,
    });

    return (
        <Container>
            <ArrayLabel>Les prix de {supplierName}</ArrayLabel>
            <List>
                {!!priceFields?.length &&
                    priceFields.map((priceField, index) => {
                        const priceErrorType = (
                            errors?.suppliers?.[supplierIndex]?.prices?.[index]?.value as PriceError
                        )?.type;
                        const conditionningErrorType = (
                            errors?.suppliers?.[supplierIndex]?.prices?.[index]?.conditionning as PriceError
                        )?.type;
                        const packagesPerBatchErrorType = (
                            errors?.suppliers?.[supplierIndex]?.prices?.[index]?.packagesPerBatch as PriceError
                        )?.type;

                        const priceNumberValue = priceErrorType === MIN ? '0.01' : '';
                        const conditionningNumberValue = conditionningErrorType === MIN ? '1' : '';
                        const packagesPerBatchNumberValue = packagesPerBatchErrorType === MIN ? '1' : '';

                        return (
                            <Entry key={priceField.id} hasBorderTop={index > 0}>
                                <DetailFormValue
                                    label={`Prix Nᵒ${index + 1}`}
                                    sublabel="Prix HT à l'unité"
                                    defaultValue={priceField.value || 0}
                                    {...register(`suppliers.${supplierIndex}.prices.${index}.value` as const, {
                                        min: 0.01,
                                        valueAsNumber: true,
                                        required: true,
                                    })}
                                    placeholder="Prix"
                                    customError={priceErrorType && FORM_ERRORS[priceErrorType] + priceNumberValue}
                                    data-test="supplier-price"
                                />
                                <DetailFormValue
                                    label={`Colisage Nᵒ${index + 1}`}
                                    sublabel="Nb d'unités vendues dans un colis"
                                    placeholder="Colisage"
                                    defaultValue={priceField.conditionning || 0}
                                    step="1"
                                    {...register(`suppliers.${supplierIndex}.prices.${index}.conditionning` as const, {
                                        min: 1,
                                        valueAsNumber: true,
                                        required: true,
                                    })}
                                    customError={
                                        conditionningErrorType &&
                                        FORM_ERRORS[conditionningErrorType] + conditionningNumberValue
                                    }
                                    data-test="supplier-conditionning"
                                />
                                <DetailFormValue
                                    label={`Palettisation Nᵒ${index + 1}`}
                                    sublabel="Nb de colis dans une palette"
                                    placeholder="Nb de colis"
                                    defaultValue={priceField.packagesPerBatch || 0}
                                    step="1"
                                    {...register(
                                        `suppliers.${supplierIndex}.prices.${index}.packagesPerBatch` as const,
                                        {
                                            min: 1,
                                            valueAsNumber: true,
                                            required: true,
                                        },
                                    )}
                                    customError={
                                        packagesPerBatchErrorType &&
                                        FORM_ERRORS[packagesPerBatchErrorType] + packagesPerBatchNumberValue
                                    }
                                    data-test="supplier-packages-per-batch"
                                />
                                <div>
                                    <IconContainer onClick={() => move(index, 0)}>
                                        <FaArrowUp color={colors.pureWhite} />
                                    </IconContainer>
                                    <IconContainer onClick={() => remove(index)}>
                                        <TrashIcon color={colors.pureWhite} />
                                    </IconContainer>
                                </div>
                            </Entry>
                        );
                    })}

                <Entry hasBorderTop={priceFields.length > 0}>
                    <IconContainer
                        data-test="supplier-price-button"
                        onClick={() => prepend({ value: 0, conditionning: 0, packagesPerBatch: 0 })}
                    >
                        <PlusIcon color={colors.pureWhite} />
                    </IconContainer>
                </Entry>
            </List>
        </Container>
    );
};

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex: 1;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    min-width: 200px;
`;

const Entry = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 5px;
    border-top: ${({ hasBorderTop }: { hasBorderTop: boolean }) => (hasBorderTop ? 1 : 0)}px solid
        ${({ theme }) => theme.lightBorderColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const IconContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: ${({ theme }) => theme.ctaPrimaryColor};
    cursor: pointer;
    margin-top: 5px;
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const PlusIcon = styled(FaPlus)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const ArrayLabel = styled(TotemLabel)`
    margin: 5px 0;
`;
