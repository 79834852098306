import React from 'react';

import styled from 'styled-components';

import { TotemLabel } from 'components/TotemLabel';
import { TotemImage } from 'components/TotemImage';
import { StatusTag } from 'pages/Products/components/StatusTag';
import { ProductArrangementWithProductsFragment } from 'data/fragments/__generated__/ProductArrangementWithProductsFragment';

export const ProductsArrangement = ({
    productArrangement,
}: {
    productArrangement: ProductArrangementWithProductsFragment;
}) => {
    return (
        <Container>
            <TotemLabel>Produits de la gamme</TotemLabel>
            <ProductTabelContainer>
                <ProductsTable>
                    <thead>
                        <tr>
                            <ProductsTableHeaderCell />
                            <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell>Est un produit microstore</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell>Statut du produit</ProductsTableHeaderCell>
                            <ProductsTableHeaderCell isDataCentered>
                                Stock max (confiance | sécurisé)
                            </ProductsTableHeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        {productArrangement.productsWithStockMax.map((productWithStockMax) => {
                            const {
                                product: { _id: productId, brand, imageUrl, isMicrostore, name, state, volume },
                                stockMaxUnsecured,
                                stockMaxSensei,
                            } = productWithStockMax;

                            return (
                                <tr key={productId}>
                                    <ProductsTableBodyCell>
                                        <TotemImage src={imageUrl} alt={name} size={50} />
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell>
                                        {name} - {brand} - {volume}
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell>{isMicrostore ? 'Oui' : 'Non'}</ProductsTableBodyCell>
                                    <ProductsTableBodyCell>
                                        <StatusTag state={state} />
                                    </ProductsTableBodyCell>
                                    <ProductsTableBodyCell>
                                        {stockMaxUnsecured} | {stockMaxSensei}
                                    </ProductsTableBodyCell>
                                </tr>
                            );
                        })}
                    </tbody>
                </ProductsTable>
            </ProductTabelContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const ProductTabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-top: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTable = styled.table`
    border-spacing: 0px;
    position: relative;
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.cardBackgroundColor};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;
