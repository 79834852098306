/// <reference types="@types/google.maps" />

interface AddressDetails {
    address: string;
    zipcode: string;
    city: string;
    country: string;
    latitude: number;
    longitude: number;
}

interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

interface PlaceDetail {
    name: string;
    geometry: {
        location: {
            lat(): number;
            lng(): number;
        };
    };
    address_components: AddressComponent[];
}

interface AutocompleteSuggestion {
    reference: string;
    label: string;
}

export const addressFields = ['address', 'zipcode', 'city', 'country', 'latitude', 'longitude'];

export const getSelectionAddressDetails = (detail: PlaceDetail): AddressDetails => {
    return {
        address: detail.name,
        latitude: detail.geometry.location.lat(),
        longitude: detail.geometry.location.lng(),
        city: detail.address_components[2].long_name,
        zipcode: detail.address_components[6].long_name,
        country: detail.address_components[5].long_name,
    };
};

export function formatAddressToShortString(addressDetails: AddressDetails): string {
    return `${addressDetails.address}, ${addressDetails.city}, ${addressDetails.country}`;
}

declare global {
    interface Window {
        google: typeof google;
    }
}

export function getAutocompleteSuggestions(
    enteredValue: string,
    setAutocompleteSuggestions: (suggestions: AutocompleteSuggestion[]) => void,
): void {
    const autocomplete = new google.maps.places.AutocompleteService();
    autocomplete.getPlacePredictions(
        { input: enteredValue, types: ['address'] },
        (predictions: google.maps.places.AutocompletePrediction[] | null) => {
            setAutocompleteSuggestions(
                (predictions || []).map(({ description, place_id }) => ({
                    reference: place_id,
                    label: description,
                })),
            );
        },
    );
}
