import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';
import { useSyncPlanogramWithNuMutation } from 'data/__generated__';

export const SynchronizePlanogramWithNuButton = ({
    microstoreColumnId,
    disabled = false,
}: {
    microstoreColumnId: string;
    disabled: boolean;
}) => {
    const [synchronizePlanogramWithNu, { loading }] = useSyncPlanogramWithNuMutation();

    const onClick = useCallback(async () => {
        const { data } = await synchronizePlanogramWithNu({
            variables: { microstoreColumnId },
        });
        if (!data?.synchronizePlanogramWithNu?.success) {
            data?.synchronizePlanogramWithNu?.errors.forEach((error) => toast.error(error));
            toast.error('Impossible de synchroniser le planograme avec Nu');
            return;
        }
        toast.success('Le planograme a bien été envoyé à Nu');
    }, [microstoreColumnId]);

    return (
        <>
            <TotemPrimaryButton disabled={loading || disabled} onClick={onClick}>
                {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Sync Nu'}
            </TotemPrimaryButton>
        </>
    );
};
