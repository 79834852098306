import { GET_DELIVERY, GET_DELIVERY_delivery } from 'data/queries/__generated__/GET_DELIVERY';
import { apolloClient } from '../../../App';

import { GET_DELIVERIES } from 'data/queries/__generated__/GET_DELIVERIES';
import { GET_DELIVERIES_QUERY, GET_DELIVERY_QUERY } from 'data/queries/delivery';
import { DELIVERIES_UPDATED_deliveriesUpdated } from 'data/subscriptions/__generated__/DELIVERIES_UPDATED';
import { GetDeliveriesQuery } from 'data/__generated__';

export const deliveriesUpdatedHandler = (updatedDeliveries: DELIVERIES_UPDATED_deliveriesUpdated[]) => {
    let currentDeliveries = [] as GetDeliveriesQuery['deliveries'][number][];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_DELIVERIES>({ query: GET_DELIVERIES_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_DELIVERIES_QUERY');
        }
        currentDeliveries = result.deliveries;
    } catch {
        // do nothing
    }

    const updateDeliveryIds = updatedDeliveries.map(({ _id }) => _id);
    const nonUpdatedDeliveries = currentDeliveries.filter(({ _id }) => !updateDeliveryIds.includes(_id));
    const data = { deliveries: [...updatedDeliveries, ...nonUpdatedDeliveries] };

    apolloClient.writeQuery({
        query: GET_DELIVERIES_QUERY,
        data,
    });
};

export function deliveryUpdatedHandler(
    delivery: GET_DELIVERY_delivery,
    updatedDeliveries: DELIVERIES_UPDATED_deliveriesUpdated[],
) {
    let currentDelivery: GET_DELIVERY_delivery | null = delivery;
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_DELIVERY>({ query: GET_DELIVERY_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_DELIVERY_QUERY');
        }
        currentDelivery = result.delivery;
    } catch {
        // do nothing
    }

    const updatedDelivery = updatedDeliveries.find(({ _id }) => _id === delivery._id);
    if (!updatedDelivery) {
        return;
    }

    const data = { delivery: { ...currentDelivery, ...updatedDelivery } };

    apolloClient.writeQuery({
        query: GET_DELIVERY_QUERY,
        data,
    });
}
