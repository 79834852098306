import { gql } from '@apollo/client';

export const UPDATE_NAYAX_MDB_TEMPLATE = gql`
    mutation UpdateNayaxMDBTemplate($nayaxMDBTemplateId: ID!, $input: UpdateNayaxMDBTemplateInput!) {
        updateNayaxMDBTemplate(nayaxMDBTemplateId: $nayaxMDBTemplateId, input: $input) {
            success
            nayaxMDBTemplate {
                _id
                createdAt
                updatedAt
                name
                associations {
                    nayaxMDBCode
                    positionInShelf {
                        row
                        column
                    }
                    positionInColumn
                }
            }
        }
    }
`;

export const CREATE_NAYAX_MDB_TEMPLATE = gql`
    mutation CreateNayaxMDBTemplate($input: CreateNayaxMDBTemplateInput!) {
        createNayaxMDBTemplate(input: $input) {
            success
            nayaxMDBTemplate {
                _id
                createdAt
                updatedAt
                name
                associations {
                    nayaxMDBCode
                    positionInShelf {
                        row
                        column
                    }
                    positionInColumn
                }
            }
        }
    }
`;

export const DELETE_NAYAX_MDB_TEMPLATE = gql`
    mutation DeleteNayaxMDBTemplate($nayaxMDBTemplateId: ID!) {
        deleteNayaxMDBTemplate(nayaxMDBTemplateId: $nayaxMDBTemplateId) {
            success
        }
    }
`;
