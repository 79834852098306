import React from 'react';
import {
    MicrostoreElementState,
    ExternalColumnType,
    MicrostoreColumnTemplateType,
    useGetMicrostoreColumnsQuery,
} from 'data/__generated__';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { MICROSTORE_COLUMN_STATES_OPTIONS } from '../constants/states';
import { MICROSTORE_COLUMN_TEMPLATE_TYPES_OPTIONS } from '../constants/template-types';
import { MICROSTORE_COLUMN_EXTERNAL_TYPES_OPTIONS } from '../constants/external-column-types';
import { DetailFormStatusValue } from 'components/DetailsView';

type FormattedColumnType = {
    _id: string;
    name: string;
    siteName: string;
    templateName: string;
    state: JSX.Element;
    type: JSX.Element;
    externalType: JSX.Element;
};

const MICROSTORE_COLUMNS_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedColumnType>[] = [
    {
        fieldName: 'name',
        label: 'Nom',
    },
    { fieldName: 'siteName', label: 'Site' },
    { fieldName: 'templateName', label: 'Template' },
    { fieldName: 'state', label: 'Statut' },
    { fieldName: 'type', label: 'Type' },
    { fieldName: 'externalType', label: 'Type externe' },
];

export const ColumnsList = ({
    filterString,
    siteFilter,
    templateFilter,
    typeFilter,
    externalTypeFilter,
    stateFilter,
    isRegularFilter,
}: {
    filterString?: string;
    siteFilter?: string[];
    templateFilter?: string[];
    typeFilter?: MicrostoreColumnTemplateType[];
    externalTypeFilter?: ExternalColumnType[];
    stateFilter?: MicrostoreElementState[];
    isRegularFilter?: boolean;
}) => {
    const { loading: columnsLoading, data: columnsData, error: columnsError } = useGetMicrostoreColumnsQuery();

    if (columnsLoading) {
        return <Loader />;
    }

    if (!columnsData || columnsError) {
        throw new Error('Une erreur est survenue lors de la récupération des colonnes');
    }

    const { microstoreColumns } = columnsData;

    const filteredColumns = microstoreColumns.filter((column) => {
        if (filterString && !column.name.toLowerCase().includes(filterString.toLowerCase())) {
            return false;
        }

        if (siteFilter?.length && !siteFilter.includes(column.siteId)) {
            return false;
        }

        if (templateFilter?.length && !templateFilter.includes(column.microstoreColumnTemplateId)) {
            return false;
        }

        if (typeFilter?.length && !typeFilter.includes(column.type)) {
            return false;
        }

        if (externalTypeFilter?.length && !externalTypeFilter.includes(column.externalColumnType)) {
            return false;
        }

        if (stateFilter?.length && !stateFilter.includes(column.state)) {
            return false;
        }

        if (isRegularFilter && !column.isRegular) {
            return false;
        }

        return true;
    });

    const sortedColumns = [...filteredColumns].sort((columnA, columnB) => columnA.name.localeCompare(columnB.name));

    const formattedColumns: FormattedColumnType[] = sortedColumns.map((column) => ({
        _id: column._id,
        name: column.name,
        siteName: column.siteName || '',
        templateName: column.templateName || '',
        state: <DetailFormStatusValue state={column.state} options={MICROSTORE_COLUMN_STATES_OPTIONS} />,
        type: column.type ? (
            <DetailFormStatusValue state={column.type} options={MICROSTORE_COLUMN_TEMPLATE_TYPES_OPTIONS} />
        ) : (
            <></>
        ),
        externalType: (
            <DetailFormStatusValue
                state={column.externalColumnType}
                options={MICROSTORE_COLUMN_EXTERNAL_TYPES_OPTIONS}
            />
        ),
    }));

    return (
        <ListView<FormattedColumnType>
            fieldsToDisplay={MICROSTORE_COLUMNS_FIELDS_TO_DISPLAY}
            items={formattedColumns}
            linkBasePath="/microstoreColumn/"
            keyExtractor={(item) => item._id}
        />
    );
};
