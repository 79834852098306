import React, { useState } from 'react';
import { FaPencilAlt, FaPlus, FaSave, FaTrash, FaExchangeAlt, FaArrowsAltV } from 'react-icons/fa';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { GetMicrostoreSitesQuery, MicrostoreColumnTemplateType } from 'data/__generated__';
import {
    UPDATE_MICROSTORE_COLUMN_GROUP_NAME,
    UPDATE_MICROSTORE_COLUMN_GROUP_NAMEVariables,
} from 'data/mutations/__generated__/UPDATE_MICROSTORE_COLUMN_GROUP_NAME';
import { UPDATE_MICROSTORE_COLUMN_GROUP_NAME_MUTATION } from 'data/mutations/microstoreColumnGroup';

import { FridgeColumnImage, FridgeDrinksColumnImage, SnackColumnImage } from 'assets/images/column';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';
import { Loader, LoaderModeType } from 'components/Loader';
import { CopyValue } from 'components/CopyValue';

function getColumnImage(
    microstoreColumn: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'][number]['microstoreColumnGroups'][number]['columns'][number],
) {
    switch (microstoreColumn.type) {
        case MicrostoreColumnTemplateType.Fridge:
            return FridgeColumnImage;
        case MicrostoreColumnTemplateType.Drinks:
            return FridgeDrinksColumnImage;
        default:
            return SnackColumnImage;
    }
}

export const MicrostoreColumnGroupEditor = ({
    addColumn,
    editColumn,
    deleteColumnGroup,
    deleteColumn,
    microstoreColumnGroup,
    moveColumn,
    replaceColumn,
}: {
    addColumn: () => void;
    editColumn: (
        microstoreColumn: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'][number]['microstoreColumnGroups'][number]['columns'][number],
    ) => void;
    deleteColumnGroup: () => void;
    deleteColumn: (
        microstoreColumn: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'][number]['microstoreColumnGroups'][number]['columns'][number],
    ) => void;
    microstoreColumnGroup: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'][number]['microstoreColumnGroups'][number];
    moveColumn: (
        microstoreColumn: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'][number]['microstoreColumnGroups'][number]['columns'][number],
    ) => void;
    replaceColumn: (
        microstoreColumn: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'][number]['microstoreColumnGroups'][number]['columns'][number],
    ) => void;
}) => {
    const [groupName, setGroupName] = useState<string>(microstoreColumnGroup.name);

    const [updateMicrostoreColumnGroupName, { loading: updateLoading }] = useMutation<
        UPDATE_MICROSTORE_COLUMN_GROUP_NAME,
        UPDATE_MICROSTORE_COLUMN_GROUP_NAMEVariables
    >(UPDATE_MICROSTORE_COLUMN_GROUP_NAME_MUTATION);

    async function updateName() {
        if (!groupName) {
            return;
        }

        const { data } = await updateMicrostoreColumnGroupName({
            variables: {
                columnGroupId: microstoreColumnGroup._id,
                name: groupName,
            },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la mise à jour du regroupement de colonne');
        }

        const {
            updateMicrostoreColumnGroupNameMutation: { errors },
        } = data;

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }
    }

    const currentColumns = microstoreColumnGroup.columns.filter(({ columnIdToReplace }) => !columnIdToReplace);
    const replacementColumns = microstoreColumnGroup.columns.filter(({ columnIdToReplace }) => columnIdToReplace);

    return (
        <ColumnGroupContainer>
            <SmallButton
                type="button"
                onClick={() => {
                    deleteColumnGroup();
                }}
            >
                <FaTrash size={15} />
            </SmallButton>

            <ColumnGroupNameContainer>
                <TotemInput
                    placeholder="3e étage, cuisine etc..."
                    value={groupName}
                    onChange={(value) => setGroupName(value)}
                />

                <SmallButton
                    type="button"
                    onClick={() => {
                        updateName();
                    }}
                >
                    {updateLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : <FaSave size={15} />}
                </SmallButton>
            </ColumnGroupNameContainer>
            {currentColumns.map((microstoreColumn) => {
                const replacementColumn = replacementColumns.find(
                    ({ columnIdToReplace }) => microstoreColumn._id === columnIdToReplace,
                );
                return (
                    <ColumnContainer key={microstoreColumn._id}>
                        <ColumnDisplay>
                            Template :<br />
                            <ColumnName>{microstoreColumn.templateName}</ColumnName>
                            <CopyValue value={microstoreColumn.microstoreColumnTemplateId} style={{ maxWidth: 100 }} />
                            Colonne :<br />
                            <ColumnName>{microstoreColumn.name}</ColumnName>
                            <CopyValue value={microstoreColumn._id} style={{ maxWidth: 100 }} />
                            <ColumnImageContainer>
                                <ColumnImage src={getColumnImage(microstoreColumn)} alt={microstoreColumn.name} />
                                <ColumnNumber>{microstoreColumn.positionInColumnGroup + 1}</ColumnNumber>
                                <ColumnActionContainer>
                                    <ColumnActionButton
                                        type="button"
                                        disabled={!!replacementColumn}
                                        onClick={() => {
                                            moveColumn(microstoreColumn);
                                        }}
                                    >
                                        <FaArrowsAltV size={15} />
                                    </ColumnActionButton>
                                    <ColumnActionButton
                                        type="button"
                                        onClick={() => {
                                            editColumn(microstoreColumn);
                                        }}
                                    >
                                        <FaPencilAlt size={15} />
                                    </ColumnActionButton>
                                    <ColumnActionButton
                                        type="button"
                                        disabled={!!replacementColumn}
                                        onClick={() => {
                                            deleteColumn(microstoreColumn);
                                        }}
                                    >
                                        <FaTrash size={15} />
                                    </ColumnActionButton>
                                    <ColumnActionButton
                                        type="button"
                                        disabled={!!replacementColumn}
                                        onClick={() => {
                                            replaceColumn(microstoreColumn);
                                        }}
                                    >
                                        <FaExchangeAlt size={15} />
                                    </ColumnActionButton>
                                </ColumnActionContainer>
                            </ColumnImageContainer>
                            <ColumnInfo>
                                Début :<br />
                                {microstoreColumn.startingDate ?? 'NA'}
                            </ColumnInfo>
                            <ColumnInfo>
                                Retrait :
                                <br />
                                {microstoreColumn.removalDate ?? 'NA'}
                            </ColumnInfo>
                            {microstoreColumn.isPaused ? <ColumnInfo>Colonne mise en pause</ColumnInfo> : null}
                        </ColumnDisplay>
                        {replacementColumn ? (
                            <ColumnDisplay key={replacementColumn._id}>
                                Template :<br />
                                <ColumnName>{replacementColumn.templateName}</ColumnName>
                                <CopyValue
                                    value={replacementColumn.microstoreColumnTemplateId}
                                    style={{ maxWidth: 100 }}
                                />
                                Colonne :<br />
                                <ColumnName>{replacementColumn.name}</ColumnName>
                                <CopyValue value={replacementColumn._id} style={{ maxWidth: 100 }} />
                                <ColumnImageContainer>
                                    <ColumnImage src={getColumnImage(replacementColumn)} alt={replacementColumn.name} />
                                    <ColumnActionContainer>
                                        <ColumnActionButton
                                            type="button"
                                            onClick={() => {
                                                editColumn(replacementColumn);
                                            }}
                                        >
                                            <FaPencilAlt size={15} />
                                        </ColumnActionButton>
                                        <ColumnActionButton
                                            type="button"
                                            onClick={() => {
                                                deleteColumn(replacementColumn);
                                            }}
                                        >
                                            <FaTrash size={15} />
                                        </ColumnActionButton>
                                    </ColumnActionContainer>
                                </ColumnImageContainer>
                                <ColumnInfo>
                                    Début :<br />
                                    {replacementColumn.startingDate ?? 'NA'}
                                </ColumnInfo>
                                <ColumnInfo>
                                    Retrait :
                                    <br />
                                    {replacementColumn.removalDate ?? 'NA'}
                                </ColumnInfo>
                                {replacementColumn.isPaused ? <ColumnInfo>Colonne mise en pause</ColumnInfo> : null}
                            </ColumnDisplay>
                        ) : null}
                    </ColumnContainer>
                );
            })}
            <AddButton
                type="button"
                onClick={() => {
                    addColumn();
                }}
            >
                <FaPlus size={20} />
            </AddButton>
        </ColumnGroupContainer>
    );
};

const ColumnGroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-bottom: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};

    & > :not(:first-child) {
        margin-left: 60px;
    }
`;

const ColumnGroupNameContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-self: stretch;
`;

const ColumnDisplay = styled.div`
    position: relative;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
`;

const IMAGE_WIDTH = 100;
const IMAGE_HEIGHT = 300;

const ColumnName = styled.div`
    width: ${IMAGE_WIDTH}px;
    text-align: center;
    color: ${({ theme }) => theme.infoTextColor};
`;

const ColumnInfo = styled.div`
    width: ${IMAGE_WIDTH}px;
    text-align: center;
`;

const ColumnImageContainer = styled.div`
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const ColumnImage = styled.img`
    width: ${IMAGE_WIDTH}px;
    height: ${IMAGE_HEIGHT}px;
`;

const NUMBER_SIZE = 70;

const ColumnNumber = styled.div`
    position: absolute;
    top: calc(50% - ${NUMBER_SIZE / 2}px);
    left: calc(50% - ${NUMBER_SIZE / 2}px);
    height: ${NUMBER_SIZE}px;
    width: ${NUMBER_SIZE}px;
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.darkBorderColor};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const ICON_WIDTH = 25;

const ColumnActionContainer = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    flex-wrap: wrap-reverse;
    justify-content: center;
    bottom: -${ICON_WIDTH * 0.3}px;
`;

const ColumnActionButton = styled(TotemPrimaryButton)`
    width: ${ICON_WIDTH}px;
    margin-right: 5px;
    margin-top: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
`;

const AddButton = styled.button`
    cursor: pointer;
    height: ${NUMBER_SIZE}px;
    width: ${NUMBER_SIZE}px;
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.darkBorderColor};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
`;

const SmallButton = styled(TotemPrimaryButton)`
    padding: 10px;
`;
