import React from 'react';
import { GetMenuQuantitiesQuery } from 'data/__generated__';
import { MENU_CATEGORIES, MENU_CATEGORY_IDS } from '../constants';
import { calculateDateTotals, calculateRowTotals } from '../utils';
import { QuantityInputs } from './QuantityInputs';
import { MenuInstruction } from '../types';
import styled from 'styled-components';

interface QuantitiesTableProps {
    menuQuantitiesData: GetMenuQuantitiesQuery;
    groupedDates: string[];
    showCategories: boolean;
    onUpdateInstruction: (instruction: MenuInstruction) => void;
}

export const QuantitiesTable: React.FC<QuantitiesTableProps> = ({
    menuQuantitiesData,
    groupedDates,
    showCategories,
    onUpdateInstruction,
}) => {
    const renderDateCell = (column: GetMenuQuantitiesQuery['menuQuantities'][0]['columns'][0], date: string) => {
        if (showCategories) {
            return MENU_CATEGORY_IDS.map((categoryId) => {
                const categoryData = column.categoryQuantities.find((cat) => cat.category._id === categoryId);
                const cellData = categoryData?.dateQuantities.find((d) => d.date === date);
                if (!cellData) {
                    return <EmptyCell key={`${date}-${categoryId}`} />;
                }
                return (
                    <QuantityCell key={`${date}-${categoryId}`}>
                        <QuantityInputs
                            cellData={cellData}
                            microstoreColumnId={column.column._id}
                            categoryId={categoryId}
                            onUpdateInstruction={onUpdateInstruction}
                        />
                    </QuantityCell>
                );
            });
        }

        const dateTotals = calculateDateTotals(column, date);
        return (
            <QuantityCell key={date}>
                <QuantityInputs
                    cellData={{
                        date,
                        quantity: dateTotals.quantity,
                        quantityFacing: dateTotals.quantityFacing,
                        quantityInstruction: dateTotals.quantityInstruction,
                        quantityFacingInstruction: dateTotals.quantityFacingInstruction,
                    }}
                    microstoreColumnId={column.column._id}
                    onUpdateInstruction={onUpdateInstruction}
                />
            </QuantityCell>
        );
    };

    return (
        <Table>
            <thead>
                <tr>
                    <th>Site</th>
                    <th>Colonne</th>
                    {groupedDates.map((date) => (
                        <th key={date} colSpan={showCategories ? MENU_CATEGORY_IDS.length : 1}>
                            {new Date(date).toLocaleDateString('fr-FR', {
                                weekday: 'short',
                                day: 'numeric',
                            })}
                            <br />
                            <br />
                            Quantités
                            <br />
                            dont facing
                        </th>
                    ))}
                    <th>
                        Total quantités
                        <br />
                        dont facing
                    </th>
                </tr>
                {showCategories && (
                    <tr>
                        <th />
                        <th />
                        {groupedDates.map((date) =>
                            MENU_CATEGORY_IDS.map((categoryId) => (
                                <CategoryCell key={`${date}-${categoryId}`}>
                                    {MENU_CATEGORIES[categoryId] || ''}
                                </CategoryCell>
                            )),
                        )}
                        <th />
                    </tr>
                )}
            </thead>
            <tbody>
                {menuQuantitiesData.menuQuantities.map((site) =>
                    site.columns.map((column, columnIndex) => {
                        const rowTotals = calculateRowTotals(column, groupedDates);
                        return (
                            <tr key={`${site.site._id}-${column.column._id}`}>
                                {columnIndex === 0 && (
                                    <SiteCell rowSpan={site.columns.length}>{site.site.name}</SiteCell>
                                )}
                                <ColumnCell>{column.column.name}</ColumnCell>
                                {groupedDates.map((date) => renderDateCell(column, date))}
                                <TotalCell>
                                    <QuantityInputs
                                        cellData={{
                                            date: '',
                                            quantity: rowTotals.quantity,
                                            quantityFacing: rowTotals.quantityFacing,
                                            quantityInstruction: rowTotals.quantityInstruction,
                                            quantityFacingInstruction: rowTotals.quantityFacingInstruction,
                                        }}
                                        isReadOnly
                                    />
                                </TotalCell>
                            </tr>
                        );
                    }),
                )}
            </tbody>
        </Table>
    );
};

const Table = styled.table`
    border-collapse: separate;
    border-spacing: 0;
    width: max-content;
    min-width: 100%;

    th,
    td {
        border: 1px solid ${({ theme }) => theme.lightBorderColor};
        padding: 8px;
        background-color: ${({ theme }) => theme.backgroundColor};
    }

    thead {
        position: sticky;
        top: 0;
        z-index: 2;

        tr:first-child th {
            background-color: ${({ theme }) => theme.cardBackgroundColor};
            position: sticky;
            top: 0;
            z-index: 3;
            height: 40px;
            box-shadow: 0 1px 0 0 ${({ theme }) => theme.lightBorderColor};

            &:first-child {
                left: 0;
                z-index: 4;
                width: 200px;
                box-shadow:
                    0 1px 0 0 ${({ theme }) => theme.lightBorderColor},
                    1px 0 0 0 ${({ theme }) => theme.lightBorderColor};
            }

            &:nth-child(2) {
                left: 200px;
                z-index: 4;
                width: 200px;
                box-shadow:
                    0 1px 0 0 ${({ theme }) => theme.lightBorderColor},
                    1px 0 0 0 ${({ theme }) => theme.lightBorderColor};
            }

            &:last-child {
                right: 0;
                z-index: 4;
                width: 100px;
                box-shadow:
                    0 1px 0 0 ${({ theme }) => theme.lightBorderColor},
                    -1px 0 0 0 ${({ theme }) => theme.lightBorderColor};
            }
        }

        tr:nth-child(2) th {
            background-color: ${({ theme }) => theme.cardBackgroundColor};
            position: sticky;
            top: 41px;
            z-index: 3;
            height: 100px;
            box-shadow: 0 1px 0 0 ${({ theme }) => theme.lightBorderColor};

            &:first-child {
                left: 0;
                z-index: 4;
                width: 200px;
                box-shadow:
                    0 1px 0 0 ${({ theme }) => theme.lightBorderColor},
                    1px 0 0 0 ${({ theme }) => theme.lightBorderColor};
            }

            &:nth-child(2) {
                left: 200px;
                z-index: 4;
                width: 200px;
                box-shadow:
                    0 1px 0 0 ${({ theme }) => theme.lightBorderColor},
                    1px 0 0 0 ${({ theme }) => theme.lightBorderColor};
            }

            &:last-child {
                right: 0;
                z-index: 4;
                width: 100px;
                box-shadow:
                    0 1px 0 0 ${({ theme }) => theme.lightBorderColor},
                    -1px 0 0 0 ${({ theme }) => theme.lightBorderColor};
            }
        }
    }
`;

const SiteCell = styled.td`
    background-color: ${({ theme }) => theme.cardBackgroundColor} !important;
    font-weight: bold;
    position: sticky;
    left: 0;
    z-index: 1;
    width: 200px;
    min-width: 200px;
    box-shadow: 1px 0 0 0 ${({ theme }) => theme.lightBorderColor};
`;

const ColumnCell = styled.td`
    background-color: ${({ theme }) => theme.backgroundColor} !important;
    position: sticky;
    left: 200px;
    z-index: 1;
    width: 200px;
    box-shadow: 1px 0 0 0 ${({ theme }) => theme.lightBorderColor};
`;

const CategoryCell = styled.th`
    font-size: 0.9em;
    padding: 4px !important;
    text-align: center;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    height: 100px;
    background-color: ${({ theme }) => theme.cardBackgroundColor} !important;
`;

const QuantityCell = styled.td`
    padding: 2px !important;
    text-align: center;
    min-width: 80px;
    background-color: ${({ theme }) => theme.backgroundColor} !important;
`;

const EmptyCell = styled.td`
    padding: 2px !important;
    text-align: center;
    min-width: 80px;
    background-color: ${({ theme }) => theme.backgroundColor} !important;
`;

const TotalCell = styled.td`
    background-color: ${({ theme }) => theme.cardBackgroundColor} !important;
    font-weight: bold;
    text-align: center;
    padding: 8px !important;
    position: sticky;
    right: 0;
    z-index: 1;
    width: 100px;
    box-shadow: -1px 0 0 0 ${({ theme }) => theme.lightBorderColor};

    thead tr:first-child & {
        z-index: 4;
    }

    span {
        display: block;
        min-width: 60px;
        text-align: center;

        &:not(:first-child) {
            margin-top: 4px;
        }
    }
`;
