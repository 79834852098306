import gql from 'graphql-tag';
import { MICROSTORE_LOCATION_TEMPLATE_FRAGMENT } from './microstoreLocationTemplate';

export const MICROSTORE_LOCATION_FRAGMENT = gql`
    fragment MicrostoreLocationFragment on MicrostoreLocation {
        _id
        expiryDates {
            expiryDate
            quantity
            shouldRemoveFromSite
        }
        locationTemplateId
        microstoreLocationTemplate {
            ...MicrostoreLocationTemplateFragment
        }
        productId
        replacementProductId
        positionInShelf {
            column
            row
        }
        nayaxMDBCode
        stockToAdd
        targetStock
    }
    ${MICROSTORE_LOCATION_TEMPLATE_FRAGMENT}
`;
