import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { CREATE_NAYAX_MDB_TEMPLATE } from 'data/mutations/nayaxMDBTemplate';
import { CreateNayaxMdbTemplateMutation, CreateNayaxMdbTemplateMutationVariables } from 'data/__generated__';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemInput } from 'components/TotemInput';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export function CreateNayaxMDBTemplatePopup({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const navigate = useNavigate();
    const [templateName, setTemplateName] = useState<string>('');

    const [createNayaxMDBTemplate, { loading: creationLoading }] = useMutation<
        CreateNayaxMdbTemplateMutation,
        CreateNayaxMdbTemplateMutationVariables
    >(CREATE_NAYAX_MDB_TEMPLATE, {
        variables: {
            input: {
                name: templateName,
                associations: [],
            },
        },
    });

    const handleSubmit = async () => {
        try {
            const result = await createNayaxMDBTemplate();
            if (result.data?.createNayaxMDBTemplate.success) {
                const newTemplateId = result.data.createNayaxMDBTemplate.nayaxMDBTemplate?._id;
                if (newTemplateId) {
                    toast.success('Template créé avec succès');
                    setIsOpen(false);
                    setTemplateName('');
                    navigate(`/nayax-mdb-template/${newTemplateId}`);
                } else {
                    toast.error('Une erreur est survenue lors de la création du template');
                }
            } else {
                toast.error('Une erreur est survenue lors de la création du template');
            }
        } catch (error) {
            toast.error('Une erreur est survenue lors de la création du template');
        }
    };

    return (
        <TotemPopup title="Créer un template Nayax MDB" isOpen={isOpen} setIsOpen={setIsOpen} contentOverflow="visible">
            Veuillez donner un nom unique à votre template Nayax MDB
            <SubmitContainer>
                <TotemInput
                    label="Nom du template"
                    onChange={setTemplateName}
                    placeholder="Nom"
                    value={templateName}
                    autoFocus={true}
                />
                <TotemPrimaryButton onClick={handleSubmit} disabled={!templateName}>
                    {creationLoading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;

    > :not(:first-child) {
        margin-top: 15px;
    }
`;
