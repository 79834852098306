import type { QueryResult } from '@apollo/client';
import React from 'react';

import {
    GetSupplyOrdersQuery,
    GetProductsToOrderOnceForSupplyOrderQuery,
    Exact,
    GetSupplyOrderSupplierProductsStockVariationQuery,
    Scalars,
} from 'data/__generated__';

import { DetailValue } from 'components/DetailsView/DetailValue';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { ProductsInfoTable } from './ProductsInfoTable';

import { SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';

export const ProductsInfoSection = ({
    supplyOrderDetailsDisplayValues,
    productsStockVariationQueryState,
    productsToOrderOnce,
    orderingTimes,
}: {
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
    productsStockVariationQueryState: QueryResult<
        GetSupplyOrderSupplierProductsStockVariationQuery,
        Exact<{
            supplyOrderId: Scalars['String']['input'];
        }>
    >;
    productsToOrderOnce: GetProductsToOrderOnceForSupplyOrderQuery['productsToOrderOnceForSupplyOrder'];
    orderingTimes: GetSupplyOrdersQuery['supplyOrders'][number]['supplier']['orderingTimes'];
}) => {
    const {
        totalVolume,
        totalWeight,
        orderingTermsValidity: { isValid, deliveryPrice },
    } = supplyOrderDetailsDisplayValues;

    return (
        <SectionContainer title="Informations produits" isInitiallyOpen>
            <>
                <ColumnsSectionContainer numberOfColumns={3}>
                    <SectionColumn>
                        <DetailValue
                            isValid={isValid}
                            label="Coût de la livraison"
                            value={isValid ? `${deliveryPrice} €` : 'Conditions de commande non remplies'}
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailValue label="Poids total des produits demandés" value={`${totalWeight / 1000} kg`} />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailValue label="Volume total des produits demandés" value={`${totalVolume} cm3`} />
                    </SectionColumn>
                </ColumnsSectionContainer>
                <ProductsInfoTable
                    supplyOrderDetailsDisplayValues={supplyOrderDetailsDisplayValues}
                    productsStockVariationQueryState={productsStockVariationQueryState}
                    orderingTimes={orderingTimes}
                    productsToOrderOnce={productsToOrderOnce}
                />
            </>
        </SectionContainer>
    );
};
