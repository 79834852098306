import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemCheckbox } from 'components/TotemCheckbox';
import { TotemLabel } from 'components/TotemLabel';
import { getFormError } from 'constants/formErrors';

import { SiteState, useGetMicrostoreSitesQuery } from 'data/__generated__';

type SiteDetailFormCheckboxesProps = {
    label?: string;
    sublabel?: string;
    name: string;
    defaultValue?: string[];
    required?: boolean;
    width?: string;
};

export function SiteDetailFormCheckboxes({
    label,
    sublabel,
    name,
    defaultValue = [],
    width,
    required = false,
}: SiteDetailFormCheckboxesProps) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        rules: { required },
        defaultValue,
    });

    const error = required && errors?.[name] ? getFormError(errors[name]?.type?.toString()) : undefined;

    const {
        loading: sitesLoading,
        data: sitesData,
        error: sitesError,
    } = useGetMicrostoreSitesQuery({
        fetchPolicy: 'cache-and-network',
    });

    const activeMicrostoreSites = useMemo(() => {
        if (!sitesData) return [];
        const sites = [...sitesData.microstoreSitesWithColumnGroups]
            .sort((siteA, siteB) => siteA.name.localeCompare(siteB.name))
            .filter(({ state, launchDate }) => state !== SiteState.Archived && launchDate);
        onChange(sites.map(({ _id }) => _id));
        return sites;
    }, [sitesData]);

    if (!sitesLoading && (sitesError || !sitesData)) {
        const error = new Error('Une erreur est survenue lors de la récupération des sites');
        throw error;
    }

    if (!sitesData) {
        return (
            <Container width={width}>
                <Loader mode={LoaderModeType.Spin} />
            </Container>
        );
    }

    function toggleSiteSelection(siteIdToToggle: string) {
        const newValue = value.includes(siteIdToToggle)
            ? value.filter((siteId: string) => siteId !== siteIdToToggle)
            : [...value, siteIdToToggle];
        onChange(newValue);
    }

    return (
        <Container width={width}>
            {label || sublabel ? (
                <LabelContainer>
                    {label ? <TotemLabel>{label}</TotemLabel> : null}
                    {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
                </LabelContainer>
            ) : null}
            <ValueContainer>
                <TotemCheckbox
                    checked={value.length === activeMicrostoreSites.length}
                    onChange={() => {
                        if (value.length !== activeMicrostoreSites.length) {
                            onChange(activeMicrostoreSites.map(({ _id }) => _id));
                        } else {
                            onChange([]);
                        }
                    }}
                    label="Sélectionner tous les sites"
                />
                <CheckboxesContainer>
                    {activeMicrostoreSites.map((site) => (
                        <CheckboxContainer key={site._id}>
                            <TotemCheckbox
                                checked={value.includes(site._id)}
                                onChange={() => toggleSiteSelection(site._id)}
                                label={site.name}
                            />
                        </CheckboxContainer>
                    ))}
                </CheckboxesContainer>
                {error ? <ErrorMessage>{error}</ErrorMessage> : null}
            </ValueContainer>
        </Container>
    );
}

const Container = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width || '100%'};
    gap: 5px;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const ValueContainer = styled.div`
    color: black;
    position: relative;
`;

const CheckboxesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
`;

const CheckboxContainer = styled.div`
    flex-basis: 25%;
`;

const ErrorMessage = styled.div`
    position: absolute;
    right: -2px;
    top: -16px;
    font-size: 14px;
    color: ${({ theme }) => theme.errorColor};
    width: max-content;
`;
