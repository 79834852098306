import React, { useEffect, useState, useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { IconClipboardList } from '@tabler/icons';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components';

import { UPDATE_PAYMENT, UPDATE_PAYMENTVariables } from 'data/mutations/__generated__/UPDATE_PAYMENT';
import { UPDATE_PAYMENT_MUTATION } from 'data/mutations/payment';
import { GET_PAYMENT, GET_PAYMENTVariables, GET_PAYMENT_payment } from 'data/queries/__generated__/GET_PAYMENT';
import { GET_PAYMENT_QUERY } from 'data/queries/payment';

import { Loader } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { DetailFormValue, DetailValue } from 'components/DetailsView';
import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';
import { CopyValue } from 'components/CopyValue';
import { renderAsPrice } from 'helpers/price';
import { PAGES } from 'constants/pages';

type ParamTypes = {
    paymentId: string;
};

type FormData = {
    invoiceId: string | null;
};

export const PaymentDetails = () => {
    const theme = useContext(ThemeContext);
    const { paymentId = '' } = useParams<ParamTypes>();
    const [paymentDetails, setPaymentDetails] = useState<GET_PAYMENT_payment | null>(null);
    const [updatePayment] = useMutation<UPDATE_PAYMENT, UPDATE_PAYMENTVariables>(UPDATE_PAYMENT_MUTATION);

    const {
        loading: paymentLoading,
        error: paymentError,
        data: paymentData,
    } = useQuery<GET_PAYMENT, GET_PAYMENTVariables>(GET_PAYMENT_QUERY, { variables: { paymentId } });

    useEffect(() => {
        if (paymentData) {
            const { payment } = paymentData;
            setPaymentDetails(payment);
        }
    }, [paymentData]);

    const methods = useForm<FormData>();
    const {
        handleSubmit,
        formState: { isDirty },
        register,
    } = methods;

    if (paymentLoading || !paymentDetails) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (paymentError || !paymentData) {
        throw new Error('Une erreur est survenue lors de la récupération de ce paiement');
    }

    const { payment } = paymentData;

    const onSubmit = handleSubmit(async (fields) => {
        const { data } = await updatePayment({
            variables: { paymentId, fields },
        });
        if (data) {
            const {
                updatePayment: { error, updatedPayment },
            } = data;
            if (updatedPayment) {
                toast.success('Le paiement a bien été modifié !');
            } else if (error) {
                toast.error(error);
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        }
    });

    if (!payment) {
        throw new Error(`Impossible de récupérer un paiement avec cet identifiant (${paymentId})`);
    }

    const { invoiceId, orders, organizationName, totalAmountDisplay } = payment;

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.paymentDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton type="submit" disabled={!isDirty}>
                                <FaSave size="13" />
                                <SaveLabel>Mettre à jour</SaveLabel>
                            </TotemPrimaryButton>
                            <Link to="/payments">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </Link>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ColumnsSectionContainer numberOfColumns={2}>
                            <SectionColumn>
                                <CopyValue value={paymentId} />
                                <DetailValue label="Organisation" value={organizationName} />
                                <DetailValue label="Total" value={totalAmountDisplay} />
                                <DetailFormValue
                                    label="Id de facturation octobat"
                                    defaultValue={invoiceId}
                                    {...register('invoiceId')}
                                />
                            </SectionColumn>
                            <SectionColumn>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date de livraison</th>
                                            <th>Montant TTC (livré)</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order) => {
                                            const { _id, amountDelivered, deliveryDate } = order;

                                            return (
                                                <tr key={_id}>
                                                    <TableCell>{deliveryDate}</TableCell>
                                                    <TableCell>{renderAsPrice(amountDelivered)}</TableCell>
                                                    <TableCell>
                                                        <Link to={`/order/${_id}`}>
                                                            <IconClipboardList size={22} color={theme.textColor} />
                                                        </Link>
                                                    </TableCell>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </SectionColumn>
                        </ColumnsSectionContainer>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const CTAsContainer = styled.div`
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
    overflow: hidden;
    color: ${({ theme }) => theme.textColor};
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
`;

const TableCell = styled.td`
    text-align: center;
`;
