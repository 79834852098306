import React from 'react';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-toastify';

import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import {
    ExternalColumnType,
    GetMicrostoreColumnQuery,
    useGetMicrostoreColumnProductsStockInfoQuery,
    useUpdateMicrostoreColumnMutation,
} from 'data/__generated__';

import { Loader, LoaderModeType } from 'components/Loader';
import { Header, HeaderTitle } from 'components/Header';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { PageTitle } from 'components/PageTitle';
import { GeneralInfoSection } from './GeneralInfoSection';
import { StatesSection } from './StatesSection';
import { IntegrationsSection } from './IntegrationsSection';
import { NayaxMDBSection } from './NayaxMDBSection';
import {
    mapMicrostoreColumnFormValuesToMicrostoreColumnUpdateInput,
    mapMicrostoreProductsStockInfoQueryToFormValues,
    MicrostoreColumnFormValues,
} from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/FormHelper/DataMapper';
import { getNoStockInLocationWarnings } from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/FormHelper/stockInLocationHelper';

export const MicrostoreColumnFormContent = ({
    microstoreColumn,
}: {
    microstoreColumn: GetMicrostoreColumnQuery['microstoreColumn'];
}) => {
    const methods = useForm<MicrostoreColumnFormValues>({ defaultValues: microstoreColumn });
    const {
        formState: { isDirty },
        handleSubmit,
        reset,
        watch,
    } = methods;

    // Split in two because stock take time is too long
    const { data: microstoreProductsStockInfoData, loading: microstoreProductsStockInfoLoading } =
        useGetMicrostoreColumnProductsStockInfoQuery({
            variables: { microstoreColumnId: microstoreColumn._id },
            fetchPolicy: 'no-cache',
        });

    const [updateMicrostoreColumn, { loading: updateLoading }] = useUpdateMicrostoreColumnMutation();

    const microstoreProductsStockInfo = microstoreProductsStockInfoLoading
        ? null
        : mapMicrostoreProductsStockInfoQueryToFormValues({
              microstoreColumnGroupFormValuesArray: [],
              microstoreProductsStockInfoQueryData:
                  microstoreProductsStockInfoData?.getMicrostoreColumnProductsStockInfoQuery,
          });

    const handleFormSubmit = handleSubmit(async (fields) => {
        if (!microstoreColumn) {
            return;
        }

        const microstoreColumnUpdateInput = mapMicrostoreColumnFormValuesToMicrostoreColumnUpdateInput({
            microstoreColumnFormValues: fields,
        });
        const { data } = await updateMicrostoreColumn({
            variables: { microstoreColumnUpdateInput },
        });

        if (!data) {
            throw new Error('Une erreur est survenue lors de la modification du template de colonne');
        }

        const {
            updateMicrostoreColumnMutation: { newMicrostoreColumn, errors },
        } = data;

        const allSiteLocations = microstoreColumn.shelves.flatMap((shelf) =>
            shelf.locationTable.flatMap((locationRow) => locationRow),
        );

        const warnings = getNoStockInLocationWarnings({
            allSiteLocations,
            microstoreColumnFormValue: microstoreColumn,
            microstoreProductsStockInfo,
        });

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });
        }

        if (warnings.length) {
            warnings.forEach((warning, index) => {
                toast.warning(<span key={index}>Attention : {warning}</span>, { autoClose: false });
            });
        }

        if (errors.length + warnings.length > 1) {
            toast.info('Cliquez pour fermer toutes les notifications', {
                autoClose: false,
                onClick: () => toast.dismiss(),
            });
        }

        if (newMicrostoreColumn) {
            toast.success('La colonne a bien été modifiée !');
            reset(fields);
            //setIsFormDirty(false);
            //setRevertUpdateStack([]);
        }
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Form onSubmit={handleFormSubmit}>
                    <Header>
                        <HeaderTitle>
                            <PageTitle page={PAGES.microstoreColumnDetails} />
                        </HeaderTitle>
                        <CTAsContainer>
                            <TotemPrimaryButton minWidth="140px" type="submit" disabled={!isDirty}>
                                {updateLoading ? (
                                    <Loader size="18px" mode={LoaderModeType.Spin} />
                                ) : (
                                    <>
                                        <FaSave size={13} />
                                        <SaveLabel>Mettre à jour</SaveLabel>
                                    </>
                                )}
                            </TotemPrimaryButton>
                            {microstoreColumn.siteId && (
                                <ButtonLink to={`/microstoreColumnGroups/${microstoreColumn.siteId}`}>
                                    <TotemPrimaryButton isSecondaryStyle>Voir Groupe</TotemPrimaryButton>
                                </ButtonLink>
                            )}
                            <ButtonLink to="/microstoreColumns">
                                <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                            </ButtonLink>
                        </CTAsContainer>
                    </Header>
                    <Content>
                        <ScrollableContent>
                            <GeneralInfoSection microstoreColumn={microstoreColumn} />
                            <IntegrationsSection microstoreColumn={microstoreColumn} isDirty={isDirty} />
                            {watch('externalColumnType') === ExternalColumnType.NayaxNeocorner && (
                                <NayaxMDBSection microstoreColumn={microstoreColumn} />
                            )}
                            <StatesSection microstoreColumn={microstoreColumn} isDirty={isDirty} />
                        </ScrollableContent>
                    </Content>
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ScrollableContent = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const ButtonLink = styled(Link)`
    margin-left: 5px;
`;

const CTAsContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.textColor};

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SaveLabel = styled.span`
    margin-left: 5px;
`;
