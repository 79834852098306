import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Switch from 'react-switch';

import { MicrostoreColumnTemplateType, MicrostoreElementState, ExternalColumnType } from 'data/__generated__';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemInput } from 'components/TotemInput';
import { ColumnsList } from './components/ColumnsList';
import { SiteFilter } from 'components/Filter/SiteFilter';
import { TemplateFilter } from 'components/Filter/TemplateFilter';
import { TypeFilter } from 'components/Filter/TypeFilter';
import { ExternalTypeFilter } from 'components/Filter/ExternalTypeFilter';
import { TotemLabel } from 'components/TotemLabel';
import { colors } from 'constants/colors';

import { PAGES } from 'constants/pages';
import { parseUrlSearchParams, updateSearchParams } from 'helpers/SearchParams/utils';

type ParamTypes = {
    filterString?: string;
    site?: string;
    template?: string;
    type?: MicrostoreColumnTemplateType;
    externalType?: ExternalColumnType;
    state?: MicrostoreElementState;
};

export const MicrostoreColumns = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [shouldHideArchived, setShouldHideArchived] = useState(true);
    const params = parseUrlSearchParams<ParamTypes>(searchParams);

    const {
        filterString = [],
        site: siteFilter,
        template: templateFilter,
        type: typeFilter,
        externalType: externalTypeFilter,
    } = params;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.microstoreColumns} />
                </HeaderTitle>
                <HeaderRight>
                    <LabelContainer>
                        <Label>Cacher les sites et colonnes archivés</Label>
                    </LabelContainer>
                    <Switch
                        onChange={() => setShouldHideArchived(!shouldHideArchived)}
                        checked={shouldHideArchived}
                        onColor={colors.green}
                        offColor={colors.pantinGrey}
                    />
                </HeaderRight>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={(value) => updateSearchParams({ params, setSearchParams, key: 'filterString', value })}
                    placeholder="Nom de la colonne"
                    value={filterString[0]}
                    autoFocus={true}
                />
                <Filters>
                    <Title>Filtres</Title>
                    <SiteFilter
                        value={siteFilter?.[0] || null}
                        onChange={(value) => updateSearchParams({ params, setSearchParams, key: 'site', value })}
                    />
                    <TemplateFilter
                        value={templateFilter?.[0] || null}
                        onChange={(value) => updateSearchParams({ params, setSearchParams, key: 'template', value })}
                    />
                    <TypeFilter
                        value={(typeFilter?.[0] as MicrostoreColumnTemplateType) || null}
                        onChange={(value) => updateSearchParams({ params, setSearchParams, key: 'type', value })}
                    />
                    <ExternalTypeFilter
                        value={(externalTypeFilter?.[0] as ExternalColumnType) || null}
                        onChange={(value) =>
                            updateSearchParams({ params, setSearchParams, key: 'externalType', value })
                        }
                    />
                </Filters>
                <ListContainer>
                    <ColumnsList
                        filterString={filterString[0]}
                        siteFilter={siteFilter}
                        templateFilter={templateFilter}
                        typeFilter={typeFilter as MicrostoreColumnTemplateType[]}
                        externalTypeFilter={externalTypeFilter as ExternalColumnType[]}
                        isRegularFilter={shouldHideArchived}
                    />
                </ListContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const ListContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Title = styled.h3`
    margin: 0 10px 0 0;
    font-size: 20px;
    font-weight: 800;
`;

const HeaderRight = styled.div`
    display: flex;
    align-items: center;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;

const Label = styled(TotemLabel)`
    margin-right: 5px;
`;
