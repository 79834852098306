import React from 'react';

import { formatPrice } from '@totem/constants';
import styled from 'styled-components';

import { TransactionState, useGetTransactionsQuery } from 'data/__generated__';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { StatusTag } from './StatusTag';

import { getExtendedFormattedDatetime } from '../../../helpers/dateTimes';
import { AllowedProviders } from './ProviderTag';
import { DetailLink } from 'components/DetailsView/DetailLink';

type FormattedTransactionType = {
    _id: string;
    createdAt: string;
    siteName: JSX.Element;
    microstoreColumnName: JSX.Element;
    state: JSX.Element;
    userFullName: string;
    productName: string;
    price: string;
};

export const TransactionsList = ({
    filterString,
    stateFilter,
    providerFilter,
    siteFilter,
    columnFilter,
    userId = null,
}: {
    filterString: string;
    stateFilter: TransactionState[];
    providerFilter: AllowedProviders[];
    siteFilter: string[];
    columnFilter?: string[];
    userId?: string | null;
}) => {
    const {
        loading: transactionsLoading,
        data: transactionsData,
        error: transactionsError,
        fetchMore: fetchMoreTransactions,
    } = useGetTransactionsQuery({
        variables: { offset: 0, filterString, stateFilter, providerFilter, siteFilter, columnFilter, userId },
        fetchPolicy: 'cache-and-network',
    });

    // TODO: Add some subscriptions

    if (transactionsLoading && !transactionsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (transactionsError || !transactionsData) {
        throw new Error('Une erreur est survenue lors de la récupération des transactions');
    }

    const {
        transactions: { hasMore, transactions },
    } = transactionsData;

    const loadMore = async () => {
        await fetchMoreTransactions({
            variables: {
                offset: transactions.length,
            },
        });
    };

    const TRANSACTIONS_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedTransactionType>[] = [
        {
            fieldName: 'createdAt',
            label: 'Date',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
        {
            fieldName: 'siteName',
            label: 'Site',
        },
        {
            fieldName: 'microstoreColumnName',
            label: 'Colonne',
        },
        {
            fieldName: 'userFullName',
            label: 'Utilisateur',
        },
        {
            fieldName: 'productName',
            label: 'Produit',
        },
        {
            fieldName: 'price',
            label: 'Montant',
        },
    ];

    const sortedTransactions = [...transactions].sort(
        (transactionA, transactionB) => transactionB.createdAt - transactionA.createdAt,
    );

    const formattedTransactions: FormattedTransactionType[] = sortedTransactions.map(
        ({
            _id,
            createdAt,
            productName,
            siteName,
            siteId,
            microstoreColumnId,
            microstoreColumnName,
            userFullName,
            priceHT,
            newState,
            tva,
        }) => ({
            _id,
            siteName: <DetailLink path="site" name={siteName} value={siteId ?? 'Pas de site'} />,
            microstoreColumnName: (
                <DetailLink
                    path="microstoreColumn"
                    name={microstoreColumnName}
                    value={microstoreColumnId ?? 'Pas de colonne'}
                />
            ),
            state: <StatusTag state={newState} />,
            userFullName,
            productName,
            price: formatPrice(priceHT * (1 + tva)),
            createdAt: getExtendedFormattedDatetime(createdAt),
        }),
    );

    return (
        <ListView<FormattedTransactionType>
            fieldsToDisplay={TRANSACTIONS_FIELDS_TO_DISPLAY}
            items={formattedTransactions}
            keyExtractor={(item) => item._id}
            loadMore={loadMore}
            linkBasePath="/transaction/"
            hasMore={hasMore}
        />
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
