import React from 'react';
import styled from 'styled-components';
import { GenerateSiteMenusButton } from './GenerateSiteMenusButton';
import { GenerateQuantitiesButton } from './GenerateQuantitiesButton';
import { GenerateMenusSupplyOrdersButton } from './GenerateMenusSupplyOrdersButton';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Link } from 'react-router-dom';
import { formatDateAsAnniversary } from 'helpers/dateTimes';

export function HeaderButtons({ selectedDate }: { selectedDate: Date }) {
    return (
        <Filters>
            <GenerateSiteMenusButton date={selectedDate} />
            <Link
                to={`/menuQuantities/${formatDateAsAnniversary({
                    dateTime: selectedDate,
                    useNewFormat: true,
                })}`}
            >
                <TotemPrimaryButton minWidth="300px">Editer les quantités</TotemPrimaryButton>
            </Link>
            <GenerateQuantitiesButton date={selectedDate} />
            <GenerateMenusSupplyOrdersButton date={selectedDate} />
        </Filters>
    );
}

const Filters = styled.div`
    display: flex;
    align-items: center;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;
