import React from 'react';
import { useQuery } from '@apollo/client';

import { Link, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';
import { GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK } from 'data/queries/__generated__/GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK';
import {
    GET_FRESH_PRODUCTS_WAREHOUSE_STOCK_INFO_QUERY,
    GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK_QUERY,
    GET_NEXT_MS_DELIVERY_DATE_QUERY,
} from 'data/queries/site';

import { ReassortmentStockByCategoriesProgressDisplay } from './components/ReassortmentStockByCategoriesProgressDisplay';
import { Loader, LoaderModeType } from 'components/Loader';
import { PercentageBar } from 'components/PercentageBar';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { GET_NEXT_MS_DELIVERY_DATE } from 'data/queries/__generated__/GET_NEXT_MS_DELIVERY_DATE';
import { GET_FRESH_PRODUCTS_WAREHOUSE_STOCK_INFO } from 'data/queries/__generated__/GET_FRESH_PRODUCTS_WAREHOUSE_STOCK_INFO';

export const MicrostoreColumnsGroupTargetsInfo = () => {
    const {
        loading: sitesLoading,
        data: sitesData,
        error: sitesError,
    } = useQuery<GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK>(GET_MICROSTORE_SITES_WITH_REASSORTMENT_STOCK_QUERY, {
        fetchPolicy: 'no-cache',
    });

    const {
        loading: getFreshProductsWarehouseStockInfoLoading,
        data: getFreshProductsWarehouseStockInfoData,
        error: getFreshProductsWarehouseStockInfoError,
    } = useQuery<GET_FRESH_PRODUCTS_WAREHOUSE_STOCK_INFO>(GET_FRESH_PRODUCTS_WAREHOUSE_STOCK_INFO_QUERY, {
        fetchPolicy: 'no-cache',
    });

    const {
        loading: nextMSDeliveryDateLoading,
        data: nextMSDeliveryDateData,
        error: nextMSDeliveryDateError,
    } = useQuery<GET_NEXT_MS_DELIVERY_DATE>(GET_NEXT_MS_DELIVERY_DATE_QUERY);

    if (sitesLoading || nextMSDeliveryDateLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (sitesError || !sitesData || nextMSDeliveryDateError || !nextMSDeliveryDateData) {
        throw new Error('Une erreur est survenue lors de la récupération des informations des sites');
    }

    const { microstoreSitesWithReassortmentInfo } = sitesData;

    const unsortedMicrostoreSites = microstoreSitesWithReassortmentInfo.filter(
        ({ freshReassortmentInfos: { stockByCategories: rc }, reassortmentStockTargets: rt }) =>
            rc.dessert !== 0 ||
            rc.main !== 0 ||
            rc.starter !== 0 ||
            rc.smallBites !== 0 ||
            rt.dessert !== 0 ||
            rt.main !== 0 ||
            rt.starter !== 0 ||
            rt.smallBites !== 0,
    );

    const microstoreSites = [...unsortedMicrostoreSites].sort((siteA, siteB) => siteA.name.localeCompare(siteB.name));

    const totalReassortmentStockByCategories = microstoreSites.reduce(
        ({ dessert, main, starter, smallBites }, { freshReassortmentInfos: { stockByCategories } }) => {
            return {
                dessert: dessert + stockByCategories.dessert,
                main: main + stockByCategories.main,
                smallBites: smallBites + stockByCategories.smallBites,
                starter: starter + stockByCategories.starter,
            };
        },
        { dessert: 0, main: 0, starter: 0, smallBites: 0 },
    );

    const totalReassortmentStockTargetByCategories = microstoreSites.reduce(
        ({ dessert, main, starter, smallBites }, { reassortmentStockTargets }) => {
            return {
                dessert: dessert + reassortmentStockTargets.dessert,
                main: main + reassortmentStockTargets.main,
                smallBites: smallBites + reassortmentStockTargets.smallBites,
                starter: starter + reassortmentStockTargets.starter,
            };
        },
        { dessert: 0, main: 0, starter: 0, smallBites: 0 },
    );

    return (
        <OuterContainer>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.microstoreColumnGroupTargetsInfo} />
                </HeaderTitle>
                <Link to="/microstoreColumnGroups">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Container>
                <Title>Pour que les informations soient à jour, n'oubliez pas de rafraichir la page</Title>
                <Title>
                    Informations générales - Prochaine date de livraison: {nextMSDeliveryDateData.getNextMSDeliveryDate}
                </Title>
                <Label>Targets</Label>
                <ReassortmentStockByCategoriesProgressDisplay
                    reassortmentStockByCategories={totalReassortmentStockByCategories}
                    reassortmentStockTargetByCategories={totalReassortmentStockTargetByCategories}
                />
                {getFreshProductsWarehouseStockInfoLoading ? (
                    <LoaderContainer>
                        <Loader size="30px" mode={LoaderModeType.Spin} />
                    </LoaderContainer>
                ) : getFreshProductsWarehouseStockInfoError || !getFreshProductsWarehouseStockInfoData ? (
                    <LoaderContainer>
                        Une erreur est survenue lors de la récupération des informations des stocks
                    </LoaderContainer>
                ) : getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo ? (
                    <>
                        <Label>Stocks dispo en prenant en compte le réassort</Label>
                        <StockInfoContainer>
                            <StockInfo>
                                <StockInfoLabel>Entrées :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .starter.stockAvailableWithReassortment
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Petites faims :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .smallBites.stockAvailableWithReassortment
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Plats :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo.main
                                            .stockAvailableWithReassortment
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Desserts :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .dessert.stockAvailableWithReassortment
                                    }
                                </StockInfoValue>
                            </StockInfo>
                        </StockInfoContainer>
                        <Label>Stocks dispo entrepôt</Label>
                        <StockInfoContainer>
                            <StockInfo>
                                <StockInfoLabel>Entrées :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .starter.availableWarehouseStock
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Petites faims :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .smallBites.availableWarehouseStock
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Plats :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo.main
                                            .availableWarehouseStock
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Desserts :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .dessert.availableWarehouseStock
                                    }
                                </StockInfoValue>
                            </StockInfo>
                        </StockInfoContainer>
                        <Label>Dont stocks attendu par achat fournisseur</Label>
                        <StockInfoContainer>
                            <StockInfo>
                                <StockInfoLabel>Entrées :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .starter.stockExpectedInSupplyOrders
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Petites faims :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .smallBites.stockExpectedInSupplyOrders
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Plats :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo.main
                                            .stockExpectedInSupplyOrders
                                    }
                                </StockInfoValue>
                            </StockInfo>
                            <StockInfo>
                                <StockInfoLabel>Desserts :</StockInfoLabel>
                                <StockInfoValue>
                                    {
                                        getFreshProductsWarehouseStockInfoData.getFreshProductsWarehouseStockInfo
                                            .dessert.stockExpectedInSupplyOrders
                                    }
                                </StockInfoValue>
                            </StockInfo>
                        </StockInfoContainer>
                    </>
                ) : null}
                <Title>Informations par store</Title>
                {microstoreSites.map((site) => {
                    return (
                        <React.Fragment key={site._id}>
                            <Link
                                to={generatePath(PAGES.microstoreColumnGroupDetails.url, {
                                    siteId: site._id,
                                })}
                            >
                                <Label isClickable={true} isUnderline={false}>
                                    <span>{site.name}</span>
                                </Label>
                            </Link>
                            <Label isUnderline={false}>
                                Prochaine date de livraison de site: {site.nextDeliveryDate}
                            </Label>

                            <PercentageContainer>
                                <PercentageBar
                                    label={`${(site.freshReassortmentInfos.filledLocationsRatio * 100).toFixed(
                                        0,
                                    )}% emplacements non vides${
                                        site.freshReassortmentInfos.hasStartedReassortment ? ' (Réassort commencé)' : ''
                                    }`}
                                    percent={site.freshReassortmentInfos.filledLocationsRatio * 100}
                                />
                            </PercentageContainer>
                            <ReassortmentStockByCategoriesProgressDisplay
                                reassortmentStockByCategories={site.freshReassortmentInfos.stockByCategories}
                                reassortmentStockTargetByCategories={site.reassortmentStockTargets}
                            />
                        </React.Fragment>
                    );
                })}
                <Title>Pour que les informations soient à jour, n'oubliez pas de rafraichir la page</Title>
            </Container>
        </OuterContainer>
    );
};

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: auto;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    padding: 50px;
`;

const Title = styled.div`
    font-size: 22px;
    font-weight: 800;
    text-align: center;
    margin-top: 20px;
`;

const Label = styled.div<{ isClickable?: boolean; isUnderline?: boolean }>`
    color: ${({ theme }) => theme.textColor};
    text-decoration-color: ${({ theme }) => theme.textColor};
    font-weight: 800;
    margin-top: 20px;
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
    text-decoration: ${({ isUnderline }) => (isUnderline ? 'underline' : 'initial')};

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const StockInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const StockInfo = styled.div`
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
`;

const StockInfoLabel = styled.div`
    font-style: italic;
`;

const StockInfoValue = styled.div`
    margin-left: 10px;
`;

const PercentageContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 30px;
`;

const LoaderContainer = styled.div`
    display: flex;
    margin-top: 10px;
`;
