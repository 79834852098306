import { apolloClient } from '../../../App';
import { GET_SUPPLY_ORDERS_QUERY, GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_QUERY } from 'data/queries/supplyOrder';
import { GET_SUPPLY_ORDERS, GET_SUPPLY_ORDERS_supplyOrders } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { SUPPLY_ORDERS_UPDATED_OR_CREATED_supplyOrdersUpdatedOrCreated } from 'data/subscriptions/__generated__/SUPPLY_ORDERS_UPDATED_OR_CREATED';

import {
    GetSupplyOrderWithSupplierProductsInfoQuery,
    GetSupplyOrderWithSupplierProductsInfoQueryVariables,
    SupplyOrdersWithSupplierProductsInfoUpdatedOrCreatedSubscription,
} from 'data/__generated__';

export const deletedSupplyOrdersHandler = (deletedSupplyOrderIds: string[]) => {
    let currentSupplyOrders = [] as GET_SUPPLY_ORDERS_supplyOrders[];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_SUPPLY_ORDERS>({ query: GET_SUPPLY_ORDERS_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_SITES_QUERY');
        }
        currentSupplyOrders = result.supplyOrders;
    } catch {
        // do nothing
    }

    const newSupplyOrders = currentSupplyOrders.filter(
        (supplyOrder) => !deletedSupplyOrderIds.includes(supplyOrder._id),
    );

    apolloClient.writeQuery({
        query: GET_SUPPLY_ORDERS_QUERY,
        data: { supplyOrders: newSupplyOrders },
    });
};

export const supplyOrdersUpdatedOrCreatedHandler = (
    supplyOrdersUpdatedOrCreated: SUPPLY_ORDERS_UPDATED_OR_CREATED_supplyOrdersUpdatedOrCreated[],
) => {
    let currentSupplyOrders = [] as GET_SUPPLY_ORDERS_supplyOrders[];
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GET_SUPPLY_ORDERS>({ query: GET_SUPPLY_ORDERS_QUERY });
        if (!result) {
            throw Error('An error occurred when reading GET_SUPPLY_ORDERS_QUERY');
        }
        currentSupplyOrders = result.supplyOrders;
    } catch {
        // do nothing
    }

    const updatedOrCreatedSupplyOrderIds = supplyOrdersUpdatedOrCreated.map(({ _id }) => _id);
    const nonUpdatedOrCreatedSupplyOrders = currentSupplyOrders.filter(
        ({ _id }) => !updatedOrCreatedSupplyOrderIds.includes(_id),
    );
    const newSupplyOrders = [...supplyOrdersUpdatedOrCreated, ...nonUpdatedOrCreatedSupplyOrders];

    apolloClient.writeQuery({
        query: GET_SUPPLY_ORDERS_QUERY,
        data: { supplyOrders: newSupplyOrders },
    });
};

export const supplyOrdersWithSupplierProductsInfoUpdatedOrCreatedHandler = (
    updatedOrCreatedSupplyOrderWithProductsInfo: SupplyOrdersWithSupplierProductsInfoUpdatedOrCreatedSubscription['supplyOrdersUpdatedOrCreated'][number],
    supplyOrderId: string,
) => {
    try {
        apolloClient.writeQuery<
            GetSupplyOrderWithSupplierProductsInfoQuery,
            GetSupplyOrderWithSupplierProductsInfoQueryVariables
        >({
            query: GET_SUPPLY_ORDER_WITH_SUPPLIER_PRODUCTS_INFO_QUERY,
            variables: {
                supplyOrderId,
            },
            data: { supplyOrderWithSupplierProductsInfo: updatedOrCreatedSupplyOrderWithProductsInfo },
        });
    } catch {
        // do nothing
    }
};
