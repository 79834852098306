import React from 'react';

import { OrderingRequirementUnit, GetSupplyOrderWithSupplierProductsInfoQuery } from 'data/__generated__';
import { DetailValue } from 'components/DetailsView';
import { renderAsPrice } from 'helpers/price';

export function displayOrderingRequirementAsString(
    minRequirement: GetSupplyOrderWithSupplierProductsInfoQuery['supplyOrderWithSupplierProductsInfo']['supplier']['orderingTerms'][number]['minRequirements'][number][number],
) {
    const { unit, value } = minRequirement;
    if (value) {
        switch (unit) {
            case OrderingRequirementUnit.Eur:
                return renderAsPrice(value);
            case OrderingRequirementUnit.G:
                return `${value / 1000} kg`;
            case OrderingRequirementUnit.Box:
                return `${value} colis`;
            case OrderingRequirementUnit.Quantity:
                return `${value} unité${value > 1 ? 's' : ''}`;
            case OrderingRequirementUnit.Ml:
                return `${value / 100}L`;
        }
    }
}

export function getMinRequirementAsString(
    minRequirements: GetSupplyOrderWithSupplierProductsInfoQuery['supplyOrderWithSupplierProductsInfo']['supplier']['orderingTerms'][number]['minRequirements'],
): string {
    const minRequirementsList = minRequirements
        .map((minRequirementGroup) =>
            minRequirementGroup
                .filter((minRequirement) => minRequirement.value && minRequirement.unit)
                .map((minRequirement) => displayOrderingRequirementAsString(minRequirement))
                .join(' et '),
        )
        .filter(Boolean);
    return minRequirementsList.join(' OU ');
}

export const SupplierOrderingTermsSummary = ({
    orderingTerms,
}: {
    orderingTerms: GetSupplyOrderWithSupplierProductsInfoQuery['supplyOrderWithSupplierProductsInfo']['supplier']['orderingTerms'];
}) => {
    const franco = orderingTerms.find((orderingTerm) => orderingTerm.deliveryPrice === 0);
    const minimumToOrder = orderingTerms.find(
        (orderingTerm) => orderingTerm.deliveryPrice !== null && orderingTerm.deliveryPrice !== 0,
    );
    return (
        <>
            <DetailValue
                label="Franco"
                value={
                    franco
                        ? `Min ${
                              getMinRequirementAsString(franco.minRequirements) !== ''
                                  ? getMinRequirementAsString(franco.minRequirements)
                                  : 'Pas de condition'
                          }`
                        : 'Pas de franco pour ce fournisseur'
                }
            />
            <DetailValue
                label="Minimum de commande"
                value={
                    minimumToOrder
                        ? `${
                              getMinRequirementAsString(minimumToOrder.minRequirements) !== ''
                                  ? getMinRequirementAsString(minimumToOrder.minRequirements)
                                  : 'Pas de condition'
                          }  (livraison à ${minimumToOrder.deliveryPrice} €)`
                        : 'Pas de minimum de commande pour ce fournisseur'
                }
            />
        </>
    );
};
