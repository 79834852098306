import React from 'react';
import { useGetMicrostoreSitesQuery } from 'data/__generated__';
import { Option } from 'components/TotemSelect';
import { SelectFilter } from './SelectFilter';

interface SiteFilterProps {
    value: string | null;
    onChange: (value: string | null) => void;
}

export const SiteFilter: React.FC<SiteFilterProps> = ({ value, onChange }) => {
    const { data: sitesData, loading } = useGetMicrostoreSitesQuery();

    const options = React.useMemo(
        () =>
            (sitesData?.microstoreSitesWithColumnGroups || []).map((site) => ({
                label: site.name,
                value: site._id,
            })),
        [sitesData],
    );

    const selectedOption = React.useMemo(
        () => (value ? options.find((option) => option.value === value) || null : null),
        [value, options],
    );

    const handleChange = (option: Option<string> | null) => {
        onChange(option?.value || null);
    };

    return (
        <SelectFilter<string>
            placeholder="Site"
            value={selectedOption}
            options={options}
            onChange={handleChange}
            disabled={loading}
        />
    );
};
