import React, { useState } from 'react';

import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { FieldToDisplay, ListView, AcceptedFieldElement } from 'components/ListView';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { TotemCheckbox } from 'components/TotemCheckbox';

import { PageTitle } from 'components/PageTitle';

import { useGetNayaxMdbTemplatesQuery, useDeleteNayaxMdbTemplateMutation } from 'data/__generated__';
import { CreateNayaxMDBTemplatePopup } from './Popups/CreateNayaxMDBTemplatePopup';

type FormattedTemplateType = {
    _id: string;
    name: string;
    select: AcceptedFieldElement;
};

export const NayaxMDBTemplates = () => {
    const [isCreateNayaxMDBTemplatePopupOpen, setIsCreateNayaxMDBTemplatePopupOpen] = useState<boolean>(false);
    const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
    const {
        loading: templatesLoading,
        data: templatesData,
        error: templatesError,
        refetch,
    } = useGetNayaxMdbTemplatesQuery();
    const [deleteNayaxMdbTemplateMutation] = useDeleteNayaxMdbTemplateMutation();

    const handleDeleteSelected = async () => {
        for (const templateId of selectedTemplates) {
            await deleteNayaxMdbTemplateMutation({
                variables: {
                    nayaxMDBTemplateId: templateId,
                },
            });
        }
        setSelectedTemplates([]);
        refetch();
    };

    const handleSelectTemplate = (templateId: string) => {
        setSelectedTemplates((prev) =>
            prev.includes(templateId) ? prev.filter((id) => id !== templateId) : [...prev, templateId],
        );
    };

    if (templatesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!templatesData || templatesError) {
        throw new Error('Une erreur est survenue lors de la récupération des templates');
    }

    const { nayaxMDBTemplates } = templatesData;

    const formattedTemplates: FormattedTemplateType[] = nayaxMDBTemplates.map(({ _id, name }) => ({
        _id,
        name,
        select: <TotemCheckbox checked={selectedTemplates.includes(_id)} onChange={() => handleSelectTemplate(_id)} />,
    }));

    const TEMPLATE_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedTemplateType>[] = [
        {
            fieldName: 'select',
            label: 'Sélectionner',
            minWidth: '50px',
        },
        {
            fieldName: 'name',
            label: 'Nom',
            minWidth: '500px',
        },
    ];

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.nayaxMDBTemplates} />
                </HeaderTitle>
                <ButtonsContainer>
                    {selectedTemplates.length > 0 && (
                        <TotemPrimaryButton onClick={handleDeleteSelected}>
                            Supprimer les templates sélectionnés
                        </TotemPrimaryButton>
                    )}
                    <TotemPrimaryButton onClick={() => setIsCreateNayaxMDBTemplatePopupOpen(true)}>
                        Créer un template
                    </TotemPrimaryButton>
                </ButtonsContainer>
            </Header>
            <Content>
                <ListView<FormattedTemplateType>
                    fieldsToDisplay={TEMPLATE_FIELDS_TO_DISPLAY}
                    items={formattedTemplates}
                    linkBasePath="/nayax-mdb-template/"
                    keyExtractor={(item) => item._id}
                />
            </Content>
            <CreateNayaxMDBTemplatePopup
                isOpen={isCreateNayaxMDBTemplatePopupOpen}
                setIsOpen={setIsCreateNayaxMDBTemplatePopupOpen}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;
