import React, { useState, useRef } from 'react';

import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { getAutocompleteSuggestions } from 'helpers/googleMapsAddress';

import { DetailFormValue } from 'components/DetailsView';
import { GetDetailedTotemsOfOrganizationQuery } from 'data/__generated__';
import { useGoogleMaps } from 'hooks/useGoogleMaps';

type AutocompleteSuggestion = {
    label: string;
    reference: string;
};

export const AdresseInfoForm = ({
    totem,
}: {
    totem: GetDetailedTotemsOfOrganizationQuery['totemsOfOrganization'][number];
}) => {
    const { isLoaded } = useGoogleMaps();
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<AutocompleteSuggestion[]>([]);
    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const { register, setValue, setError, clearErrors } = useFormContext();

    const handleAddressSelection = (selectedSuggestion: AutocompleteSuggestion) => () => {
        const placesService = new google.maps.places.PlacesService(document.createElement('div'));
        setAutocompleteOpen(false);

        placesService.getDetails(
            {
                placeId: selectedSuggestion.reference,
            },
            (detail, status) => {
                if (status !== 'OK') {
                    setError('address', {
                        type: 'manual',
                        message: "Une erreur est survenue lors de la récupération des détails de l'adresse",
                    });
                    console.log('error', 'Une erreur est surve');
                } else if (detail?.address_components?.[0]?.types[0] === 'street_number') {
                    const cityComponent = detail.address_components.find((address_component) =>
                        address_component.types.includes('locality'),
                    );
                    const countryComponent = detail.address_components.find((address_component) =>
                        address_component.types.includes('country'),
                    );
                    const zipCodeComponent = detail.address_components.find((address_component) =>
                        address_component.types.includes('postal_code'),
                    );

                    if (!cityComponent || !countryComponent || !zipCodeComponent) {
                        setError('address', {
                            type: 'manual',
                            message:
                                "L'adresse sélectionnée est incomplète. Veuillez sélectionner une adresse avec une ville, un code postal et un pays.",
                        });
                        return;
                    }

                    const latitude = detail?.geometry?.location?.lat();
                    const longitude = detail?.geometry?.location?.lng();

                    const address = detail.name || detail.formatted_address || '';
                    setValue('address', address);
                    setValue('latitude', latitude);
                    setValue('longitude', longitude);
                    setValue('zipcode', zipCodeComponent.long_name);
                    setValue('city', cityComponent.long_name);
                    setValue('country', countryComponent.long_name);
                    if (inputRef.current) {
                        inputRef.current.value =
                            address +
                            ', ' +
                            (cityComponent.long_name ?? '') +
                            ', ' +
                            (countryComponent.long_name ?? '');
                    }
                    console.log('setup', address, latitude, longitude);
                } else {
                    setError('address', {
                        type: 'manual',
                        message: 'Veuillez choisir une adresse complète avec un numéro de rue',
                    });
                    console.log('error');
                }
            },
        );
    };

    const handleAddressAutocompleteChange = ({ target: { value } }: { target: { value: string } }) => {
        if (value) {
            getAutocompleteSuggestions(value, setAutocompleteSuggestions);
            clearErrors('address');
            setAutocompleteOpen(true);
        } else {
            setAutocompleteOpen(false);
        }
    };

    const handleBlur = () => {
        // Délai pour permettre la sélection d'une suggestion avant la fermeture
        setTimeout(() => {
            setAutocompleteOpen(false);
        }, 200);
    };

    const handleFocus = () => {
        if (inputRef.current?.value) {
            setAutocompleteOpen(true);
        }
    };

    return (
        <Container>
            <DetailFormValue
                defaultValue={totem.address ? totem.address + ', ' + totem.city + ', ' + totem.country : ''}
                type="string"
                {...register('address', {
                    required: true,
                })}
                onChange={handleAddressAutocompleteChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                ref={inputRef}
                label="Adresse"
            />
            {isLoaded && autocompleteOpen && autocompleteSuggestions.length > 0 && (
                <SettingsAutocompleteContainer>
                    <SettingsAutocompleteItems>
                        {autocompleteSuggestions.map((suggestion, index) => (
                            <SettingsAutocompleteItem key={index} onMouseDown={handleAddressSelection(suggestion)}>
                                {suggestion.label}
                            </SettingsAutocompleteItem>
                        ))}
                    </SettingsAutocompleteItems>
                </SettingsAutocompleteContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
`;

const SettingsAutocompleteContainer = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
`;

const SettingsAutocompleteItems = styled.div`
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 0;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SettingsAutocompleteItem = styled.div`
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
`;
