import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';
import { formatDateAsAnniversary, getNextMondayDate, getWeekNumber } from 'helpers/dateTimes';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { HeaderButtons } from './HeaderButtons/HeaderButtons';
import { TotemInput } from 'components/TotemInput';

const TMP_SITE_MENU_DETAILS = [
    ['Frigo 6 étagères', 'de0abfd9-8b37-4d1d-ba40-6902e50a58cc'],
    ['Frigo 5 étagères', '292b681d-2375-4170-a27a-f5628e9d48a2'],
    ['Frigo Mi-Boisson', '8568346c-54b5-4ab9-bcf9-b7df159ebd03'],
    ['Frigo TV5Monde', '7fa841ce-c9f7-49e5-bb98-c22d28ace48f'],
    ['Frigo Barclays', '6143df50-7fdc-49a7-b0e8-e24330ea5491'],
    ['Frigo Sensei Boisson / Petite Faim', '28ab3b54-a50a-49d0-83ad-22da93791c98'],
    ['Frigo Sensei Restauration ISS ADAMAS', 'a540cea2-5199-4d7d-aef1-41a467654df6'],
    ['Petites Faims & Desserts', '31ef8b8c-43dd-459d-9f60-b9953cf82b94'],
];

const TMP_SITE_NU_MENU_DETAILS = [
    ['NU ASPP 12è Biodeg', '8853e722-5c70-4915-aa24-93ebe23ed84b'],
    ['NU ASPP 20è Biodeg', '53cd8ccb-7865-4a02-9b36-cc47b70842a0'],
    ['NU Balas Biodeg', 'b532f4b0-b9e4-4997-adab-ae3d9aedb5a1'],
    ['NU Cerba Actuel ZD', '5bf9ceed-a437-40f8-a1bc-183ff67e5fb9'],
    ['NU CNAV Biodeg', '3a3f708e-6aac-4bba-a914-c87c3095d9fd'],
    ['NU Neo ZD', 'ee4b2e20-3a01-4d79-9e30-f65ce2259fef'],
    ['NU RATP Biodeg', '31de47d0-5607-4bb7-a849-5949ef4754a4'],
    ['NU Restauration ZD', '4abdc0f6-2242-4d86-916e-9e4379d51553'],
];

export const MicrostoreMenus = () => {
    const [selectedDate, setSelectedDate] = useState<Date>(getNextMondayDate());

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.menus} />
                </HeaderTitle>
                <HeaderContainer>
                    <HeaderButtons selectedDate={selectedDate} />
                    <WeekNumberContainer
                        value={'Semaine ' + getWeekNumber(selectedDate)}
                        onChange={() => {}}
                        disabled
                    />
                    <TotemDatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date || getNextMondayDate());
                        }}
                    />
                </HeaderContainer>
            </Header>
            <Content>
                <TitleContainer>
                    <Title>TOTEM</Title>
                </TitleContainer>
                <ButtonContainer>
                    {TMP_SITE_MENU_DETAILS.map(([siteName, siteId]) => (
                        <Link
                            key={siteId}
                            to={`/menusDetails/${siteId}/${formatDateAsAnniversary({
                                dateTime: selectedDate,
                                useNewFormat: true,
                            })}`}
                        >
                            <TotemPrimaryButton>{siteName}</TotemPrimaryButton>
                        </Link>
                    ))}
                </ButtonContainer>
                <TitleContainer>
                    <Title>NU</Title>
                </TitleContainer>
                <ButtonContainer>
                    {TMP_SITE_NU_MENU_DETAILS.map(([siteName, siteId]) => (
                        <Link
                            key={siteId}
                            to={`/menusDetails/${siteId}/${formatDateAsAnniversary({
                                dateTime: selectedDate,
                                useNewFormat: true,
                            })}`}
                        >
                            <TotemPrimaryButton>{siteName}</TotemPrimaryButton>
                        </Link>
                    ))}
                </ButtonContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    padding: 50px;
    flex: 1;
    overflow: hidden;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    & > * {
        margin-top: 20px;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const Title = styled.span`
    text-align: center;
    font-size: 40px;
`;

const HeaderContainer = styled.div`
    display: flex;
    min-width: 300px;
    margin-right: 40px;
    justify-self: end;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const WeekNumberContainer = styled(TotemInput)`
    width: 110px;
`;
