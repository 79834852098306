import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';
import { useSyncPlanogramWithNayaxMutation } from 'data/__generated__';

export const SynchronizePlanogramWithNayaxButton = ({
    microstoreColumnId,
    disabled = false,
}: {
    microstoreColumnId: string;
    disabled: boolean;
}) => {
    const [synchronizePlanogramWithNayax, { loading }] = useSyncPlanogramWithNayaxMutation();

    const onClick = useCallback(async () => {
        const { data } = await synchronizePlanogramWithNayax({
            variables: { microstoreColumnId },
        });
        if (!data?.synchronizePlanogramWithNayax?.success) {
            data?.synchronizePlanogramWithNayax?.errors.forEach((error) => toast.error(error));
            toast.error('Impossible de synchroniser le planograme avec Nayax');
            return;
        }
        toast.success('Le planograme a bien été envoyé à Nayax');
    }, [microstoreColumnId]);

    return (
        <>
            <TotemPrimaryButton disabled={loading || disabled} onClick={onClick}>
                {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Sync Nayax'}
            </TotemPrimaryButton>
        </>
    );
};
