import React from 'react';
import { toast } from 'react-toastify';
import {
    MenuInstructionInput,
    MenuInstructionType,
    useDeleteMenuInstructionsMutation,
    useUpdateMenuInstructionsMutation,
} from 'data/__generated__';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

export type MenuInstruction = {
    siteId: string;
    categoryId: string;
    date: string;
    value: number;
    type: MenuInstructionType;
};

export function UpdateMenuQuantitiesButton({ menuInstructions }: { menuInstructions: MenuInstructionInput[] }) {
    const [updateMenuInstructions, { loading: updateLoading }] = useUpdateMenuInstructionsMutation();
    const [deleteMenuInstructions, { loading: deleteLoading }] = useDeleteMenuInstructionsMutation();

    async function handleClick() {
        try {
            const menuInstructionsToDelete = menuInstructions
                .filter((instruction) => instruction.value == null || instruction.value === undefined)
                .map((instruction) => ({
                    date: instruction.date,
                    microstoreColumnId: instruction.microstoreColumnId,
                    categoryId: instruction.categoryId,
                    type: instruction.type,
                }));
            const menuInstructionsToUpdate = menuInstructions.filter(
                (instruction) => instruction.value !== null && instruction.value !== undefined,
            );
            if (menuInstructionsToUpdate.length > 0) {
                await updateMenuInstructions({
                    variables: {
                        menuInstructionInputs: menuInstructionsToUpdate,
                    },
                });
            }
            if (menuInstructionsToDelete.length > 0) {
                await deleteMenuInstructions({
                    variables: {
                        menuInstructionDeleteInputs: menuInstructionsToDelete,
                    },
                });
            }
            toast.success('Les instructions de menu ont bien été mises à jour');
        } catch (error) {
            toast.error(`Impossible de mettre à jour les instructions de menu: ${error}`);
        }
    }

    return (
        <TotemPrimaryButton
            disabled={updateLoading || deleteLoading || menuInstructions.length === 0}
            onClick={handleClick}
        >
            Enregistrer
        </TotemPrimaryButton>
    );
}
