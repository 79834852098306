import React from 'react';

import { useMutation } from '@apollo/client';
import { FcCalendar, FcRight } from 'react-icons/fc';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { MARK_DELIVERIES_AS_READY_MUTATION } from 'data/mutations/delivery';
import {
    MARK_DELIVERIES_AS_READY,
    MARK_DELIVERIES_AS_READYVariables,
} from 'data/mutations/__generated__/MARK_DELIVERIES_AS_READY';

import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';

import { formatDateAsAnniversary } from '../../../helpers/dateTimes';
import { GetDeliveriesQuery } from 'data/__generated__';

export function MarkAsReadyPopup({
    deliveries,
    selectedDeliveryIds,
    isOpen,
    setIsOpen,
}: {
    deliveries: GetDeliveriesQuery['deliveries'][number][];
    selectedDeliveryIds: string[];
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}) {
    const [markDeliveriesAsReady, { loading }] = useMutation<
        MARK_DELIVERIES_AS_READY,
        MARK_DELIVERIES_AS_READYVariables
    >(MARK_DELIVERIES_AS_READY_MUTATION, { variables: { deliveryIds: selectedDeliveryIds } });

    const selectedDeliveries = deliveries.filter((delivery) => selectedDeliveryIds.includes(delivery._id));

    async function handleSubmit() {
        const { data } = await markDeliveriesAsReady();

        if (!data) {
            throw new Error('Une erreur est survenue lors du passage de la/des livraison(s) en prête(s)');
        }

        const {
            markDeliveriesAsReady: { updatedDeliveries, errors },
        } = data;

        if (updatedDeliveries.length) {
            toast.success(`Livraison(s) marquée(s) comme prête(s) : ${updatedDeliveries.length}`);
            setIsOpen(false);
        }

        if (errors.length) {
            errors.forEach((error, index) => {
                toast.error(<span key={index}>Erreur : {error}</span>, { autoClose: false });
            });

            if (errors.length > 1) {
                toast.info('Cliquez pour fermer toutes les notifications', {
                    autoClose: false,
                    onClick: () => toast.dismiss(),
                });
            }
        }
    }

    return (
        <TotemPopup title="Êtes-vous sûr.e ?" isOpen={isOpen} setIsOpen={setIsOpen}>
            Vous vous apprêtez à marquer {selectedDeliveries.length} livraison(s) comme prête(s). Souhaitez-vous
            vraiment marquer la/les livraison(s) suivante(s) comme prête(s) ?
            <Deliverylist>
                {selectedDeliveries.map((delivery) => {
                    const { _id: deliveryId, deliveryDate, label } = delivery;

                    return (
                        <Delivery key={deliveryId}>
                            <DeliveryDate>
                                <FcCalendar size={26} />
                                {formatDateAsAnniversary({
                                    dateTime: deliveryDate,
                                    displayDay: true,
                                })}
                            </DeliveryDate>
                            <DeliveryOrganization>
                                <FcRight size={26} />
                                {label}
                            </DeliveryOrganization>
                        </Delivery>
                    );
                })}
            </Deliverylist>
            <SubmitContainer>
                <TotemPrimaryButton onClick={handleSubmit}>
                    {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                </TotemPrimaryButton>
            </SubmitContainer>
        </TotemPopup>
    );
}

const Deliverylist = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-left: 50px;
    overflow: auto;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const Delivery = styled.span`
    display: inline-flex;
    align-items: center;
`;

const DeliveryDate = styled.span`
    display: inline-flex;
    align-items: center;
`;

const DeliveryOrganization = styled.span`
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;
