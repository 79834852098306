import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TotemLabel } from '../TotemLabel';

export const DetailLink = ({
    label,
    sublabel,
    path,
    name,
    value,
    fontSize = '16px',
    color,
    shouldWrap = false,
}: {
    label?: string;
    sublabel?: string;
    path: string;
    name?: string;
    fontSize?: string;
    value?: string | null;
    color?: string;
    shouldWrap?: boolean;
}) => {
    return (
        <Container>
            <TotemLabel>{label}</TotemLabel>
            {sublabel ? <Sublabel>{sublabel}</Sublabel> : null}
            {value ? (
                <LinkWrapper shouldWrap={shouldWrap}>
                    <StyledLink color={color} fontSize={fontSize} to={`/${path}/${value}`}>
                        {name || value}
                    </StyledLink>
                </LinkWrapper>
            ) : (
                <Value>-</Value>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    color: ${({ theme }) => theme.textColor};
`;

const Sublabel = styled.span`
    color: ${({ theme }) => theme.infoTextColor};
    font-size: 13px;
`;

const LinkWrapper = styled.div<{ shouldWrap: boolean }>`
    white-space: ${({ shouldWrap }) => (shouldWrap ? 'normal' : 'nowrap')};
    overflow: hidden;
`;

const StyledLink = styled(Link)<{ fontSize: string }>`
    margin-top: 5px;
    color: ${({ theme, color }) => (color ? color : theme.ctaPrimaryHoveredColor)};
    text-decoration: none;
    font-size: ${({ fontSize }) => fontSize};
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
        text-decoration: underline;
    }
`;

const Value = styled.span`
    margin-top: 5px;
    color: ${({ theme }) => theme.textColor};
`;
