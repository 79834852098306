import React from 'react';

import { IconEdit } from '@tabler/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TotemCheckbox } from 'components/TotemCheckbox';

import { SupplyOrderState } from 'data/__generated__';
import { GET_SUPPLY_ORDERS_supplyOrders } from 'data/queries/__generated__/GET_SUPPLY_ORDERS';

import { getColorsFromState } from '../constants/states';
import { colors } from 'constants/colors';
import { renderAsPrice } from '../../../helpers/price';

import { getMinRequirementAsString } from '../SupplyOrderDetails/SupplierOrderingTermsSummary';
import { formatDateAsAnniversary, dateFromString } from '../../../helpers/dateTimes';

export const SupplyOrderTrelloItem = ({
    supplyOrder,
    isSelected,
    toggleSelection,
}: {
    supplyOrder: GET_SUPPLY_ORDERS_supplyOrders;
    isSelected: boolean;
    toggleSelection: () => void;
}) => {
    const {
        _id: supplyOrderId,
        description,
        isAtSoldOutRisk,
        isMerged,
        issues,
        state,
        orderDate,
        orderingTermsValidity,
        dateDeliveryScheduled,
        dateDelivery,
        number,
        priceHT,
        products,
        scheduledDeliveryDateValidity,
        supplier,
        weeklyMenusStartDate,
    } = supplyOrder;

    const { backgroundColor, labelColor } = getColorsFromState(state, weeklyMenusStartDate, isMerged);
    const today = new Date();
    const dateDeliveryScheduledDateObject = dateDeliveryScheduled ? dateFromString(dateDeliveryScheduled) : null;
    const isDateDeliveryScheduledToday =
        dateDeliveryScheduledDateObject?.getFullYear() === today.getFullYear() &&
        dateDeliveryScheduledDateObject?.getMonth() === today.getMonth() &&
        dateDeliveryScheduledDateObject?.getDate() === today.getDate();
    const isDateDeliveryScheduledInPast =
        !!dateDeliveryScheduledDateObject && !isDateDeliveryScheduledToday && dateDeliveryScheduledDateObject < today;
    const isWaitingForDelivery = [SupplyOrderState.Ordered, SupplyOrderState.Confirmed].includes(state);

    const franco = supplier.orderingTerms.find((orderingTerm) => orderingTerm.deliveryPrice === 0);
    const minimumToOrder = supplier.orderingTerms.find(
        (orderingTerm) => orderingTerm.deliveryPrice !== null && orderingTerm.deliveryPrice !== 0,
    );
    const numberOfUnsatisfiedProductTerms = supplier.orderingProductTerms.filter(({ minQuantity, productId }) => {
        const supplyOrderProduct = products.find(({ _id }) => _id === productId);
        return supplyOrderProduct && supplyOrderProduct.quantity && supplyOrderProduct.quantity < minQuantity;
    }).length;

    return (
        <ItemContent
            isWaitingForDelivery={isWaitingForDelivery}
            isDateDeliveryScheduledToday={isDateDeliveryScheduledToday}
            isDateDeliveryScheduledInPast={isDateDeliveryScheduledInPast}
            isAtRisk={!!isAtSoldOutRisk}
        >
            <ItemHeader backgroundColor={backgroundColor} labelColor={labelColor}>
                <TotemCheckbox checked={isSelected} onChange={toggleSelection} />
                <HeaderTitle>
                    {supplier.name ?? 'Pas de fournisseur'}
                    {!!issues.length || !!description ? '❗️' : null}
                </HeaderTitle>
                <Link to={`/supplyOrder/${supplyOrderId}`}>
                    <EditIcon color={labelColor} size="15" />
                </Link>
            </ItemHeader>
            <ItemBody>
                <Details>
                    <Detail>
                        <DetailLabel>Prix HT</DetailLabel>
                        <DetailValue>{priceHT !== null ? renderAsPrice(priceHT) : 'Non calculé'}</DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Nº</DetailLabel>
                        <DetailValue>#{number || '?'}</DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Franco</DetailLabel>
                        <DetailValue isValid={orderingTermsValidity?.isValid}>
                            {franco
                                ? `Min ${
                                      getMinRequirementAsString(franco.minRequirements) !== ''
                                          ? getMinRequirementAsString(franco.minRequirements)
                                          : 'Pas de condition'
                                  }`
                                : 'N/A'}
                        </DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Min.</DetailLabel>
                        <DetailValue>
                            {minimumToOrder
                                ? `${
                                      getMinRequirementAsString(minimumToOrder.minRequirements) !== ''
                                          ? getMinRequirementAsString(minimumToOrder.minRequirements)
                                          : 'Pas de condition'
                                  }  (livraison à ${minimumToOrder.deliveryPrice} €)`
                                : 'N/A'}
                        </DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Min Produits</DetailLabel>
                        <DetailValue isValid={!numberOfUnsatisfiedProductTerms}>
                            {numberOfUnsatisfiedProductTerms
                                ? `${numberOfUnsatisfiedProductTerms} condition(s) non remplie(s)`
                                : 'OK'}
                        </DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Commandée</DetailLabel>
                        <DetailValue>
                            {orderDate
                                ? formatDateAsAnniversary({
                                      dateTime: dateFromString(orderDate),
                                      displayDay: true,
                                  })
                                : 'N/A'}
                        </DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Prévue</DetailLabel>
                        <DetailValue isValid={scheduledDeliveryDateValidity}>
                            {dateDeliveryScheduled
                                ? formatDateAsAnniversary({
                                      dateTime: dateFromString(dateDeliveryScheduled),
                                      displayDay: true,
                                  })
                                : 'N/A'}
                        </DetailValue>
                    </Detail>
                    <Detail>
                        <DetailLabel>Livrée</DetailLabel>
                        <DetailValue>
                            {dateDelivery
                                ? formatDateAsAnniversary({
                                      dateTime: dateFromString(dateDelivery),
                                      displayDay: true,
                                  })
                                : 'N/A'}
                        </DetailValue>
                    </Detail>
                </Details>
            </ItemBody>
        </ItemContent>
    );
};

const ItemContent = styled.div<{
    isDateDeliveryScheduledToday: boolean;
    isDateDeliveryScheduledInPast: boolean;
    isWaitingForDelivery: boolean;
    isAtRisk: boolean;
}>`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme, isDateDeliveryScheduledInPast, isDateDeliveryScheduledToday, isAtRisk }) =>
        isDateDeliveryScheduledInPast || isDateDeliveryScheduledToday || isAtRisk ? '5px' : theme.borderRadius};
    border: ${({
        theme,
        isDateDeliveryScheduledToday,
        isDateDeliveryScheduledInPast,
        isWaitingForDelivery,
        isAtRisk,
    }) =>
        isAtRisk
            ? `2px solid ${colors.pink}`
            : !isWaitingForDelivery
              ? `1px solid ${theme.lightBorderColor}`
              : isDateDeliveryScheduledInPast
                ? `2px solid ${theme.errorColor}`
                : isDateDeliveryScheduledToday
                  ? `2px solid ${theme.warningColor}`
                  : `1px solid ${theme.lightBorderColor}`};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    color: ${({ theme }) => theme.textColor};
    cursor: default;
`;

const ItemHeader = styled.div<{ backgroundColor: string; labelColor: string }>`
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: 3px 3px 0 0;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ labelColor }) => labelColor};
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const HeaderTitle = styled.div`
    margin: 5px 10px;
`;

const EditIcon = styled(IconEdit)`
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
`;

const ItemBody = styled.div`
    padding: 10px;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
`;

const Detail = styled.div<{ isHighlighted?: boolean }>`
    display: flex;
    justify-content: space-between;
    color: ${({ isHighlighted, theme }) => (isHighlighted ? theme.errorColor : 'inherit')};

    &:not(:first-child) {
        margin-top: 5px;
    }
`;

const DetailLabel = styled.span`
    font-size: 14px;
`;

const DetailValue = styled.span<{ isValid?: boolean }>`
    font-size: 14px;
    font-weight: 800;
    margin-left: 5px;
    text-align: right;
    color: ${({ isValid, theme }) =>
        isValid !== undefined ? (isValid ? theme.successColor : theme.warningColor) : theme.textColor};
`;
