import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { SYNC_PLANOGRAM_WITH_SENSEI_MUTATION } from 'data/mutations/microstoreColumn';
import {
    SYNC_PLANOGRAM_WITH_SENSEI,
    SYNC_PLANOGRAM_WITH_SENSEIVariables,
} from 'data/mutations/__generated__/SYNC_PLANOGRAM_WITH_SENSEI';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';
import { SynchronizePlanogramWithSenseiConfirmationPopup } from './Popups/SynchronizePlanogramWithSenseiConfirmationPopup';

export const SynchronizePlanogramWithSenseiButton = ({
    microstoreColumnId,
    disabled = false,
}: {
    microstoreColumnId: string;
    disabled: boolean;
}) => {
    const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState<boolean>(false);

    const [synchronizePlanogramWithSensei, { loading }] = useMutation<
        SYNC_PLANOGRAM_WITH_SENSEI,
        SYNC_PLANOGRAM_WITH_SENSEIVariables
    >(SYNC_PLANOGRAM_WITH_SENSEI_MUTATION);

    const onClick = useCallback(
        async (columnPhotoUrl: string | null) => {
            const { data } = await synchronizePlanogramWithSensei({
                variables: { microstoreColumnId, columnPhotoUrl },
            });
            if (!data?.synchronizePlanogramWithSensei?.success) {
                data?.synchronizePlanogramWithSensei?.errors.forEach((error) => toast.error(error));
                toast.error('Impossible de synchroniser le planograme avec Sensei');
                return;
            }
            toast.success('Le planograme a bien été envoyé à Sensei');
        },
        [microstoreColumnId],
    );

    return (
        <>
            <TotemPrimaryButton disabled={loading || disabled} onClick={() => setConfirmationPopupOpen(true)}>
                {loading ? <Loader size="20px" mode={LoaderModeType.Spin} /> : 'Sync Sensei'}
            </TotemPrimaryButton>
            <SynchronizePlanogramWithSenseiConfirmationPopup
                isOpen={isConfirmationPopupOpen}
                setIsOpen={setConfirmationPopupOpen}
                confirm={onClick}
            />
        </>
    );
};
