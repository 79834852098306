import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { FieldArrayWithId } from 'react-hook-form';

import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

import { colors } from 'constants/colors';
import { DEFAULT_RANGE_IDS } from '../../constants/prices';
import { ErrorType, FORM_ERRORS, MIN } from '../../constants/formErrors';

import { ProductPriceRangeType, SelectedProduct } from './ProductPriceRangeCreate';
import { renderAsPrice } from 'helpers/price';

import { TotemLabel } from 'components/TotemLabel';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { DetailLink } from 'components/DetailsView/DetailLink';

export type FieldErrorData = {
    index: number;
    fieldName: string;
    fieldTitle: string;
};

type ProductFields = FieldArrayWithId<ProductPriceRangeType, 'products', 'id'>[];

export const ProductsPriceTable = ({
    selectedProducts,
    fields,
    removeProduct,
    rangeId = '',
}: {
    selectedProducts: SelectedProduct[];
    fields: ProductFields;
    removeProduct: (index: number) => void;
    rangeId?: string;
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const isDefaultRange = DEFAULT_RANGE_IDS.includes(rangeId);
    const watchedProducts = useWatch({ name: 'products', defaultValue: fields });

    return (
        <Container>
            <TotemLabel>Produits dans la gamme de prix</TotemLabel>
            <ProductsTable>
                <thead>
                    <tr>
                        <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell isDataCentered>Prix de vente HT</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell isDataCentered>Prix barré HT</ProductsTableHeaderCell>
                        {!isDefaultRange ? (
                            <ProductsTableHeaderCell isDataCentered>Supprimer</ProductsTableHeaderCell>
                        ) : null}
                    </tr>
                </thead>
                <ProductsTableBody>
                    {fields.map((item, index) => {
                        const { id: fieldId, productId, price, crossedPrice } = item;
                        const name =
                            selectedProducts?.find(
                                ({ productId: selectedProductId }) => selectedProductId === productId,
                            )?.name || '';

                        // @ts-expect-error don't have time
                        const errorType: ErrorType | undefined = errors?.products?.[index]?.price?.type;
                        const numberValue = errorType === MIN ? '0.01' : '';
                        const watchedPrice = watchedProducts[index]?.price;

                        return (
                            <tr key={fieldId}>
                                <ProductsTableBodyCell>
                                    <DetailLink name={name} value={productId} path="product" />
                                </ProductsTableBodyCell>
                                <PriceTableBodyCell>
                                    <QuantityInputInnerContainer>
                                        <DetailFormValue
                                            placeholder="?"
                                            defaultValue={price}
                                            type="number"
                                            step=".01"
                                            width="100%"
                                            centerText={true}
                                            disabled={isDefaultRange}
                                            customError={errorType && FORM_ERRORS[errorType] + numberValue}
                                            {...register(`products.${index}.price`, {
                                                min: 0.01,
                                                required: true,
                                                valueAsNumber: true,
                                            })}
                                        />
                                    </QuantityInputInnerContainer>
                                </PriceTableBodyCell>
                                <PriceTableBodyCell>
                                    <QuantityInputInnerContainer>
                                        <DetailFormValue
                                            placeholder="?"
                                            defaultValue={crossedPrice}
                                            type="number"
                                            step=".01"
                                            width="100%"
                                            centerText={true}
                                            disabled={isDefaultRange}
                                            customError={errorType && FORM_ERRORS[errorType] + numberValue}
                                            {...register(`products.${index}.crossedPrice`, {
                                                required: false,
                                                setValueAs: (value) => (!value ? null : Number(value)),
                                                validate: (crossedPrice) =>
                                                    crossedPrice === 0 || (crossedPrice && !Number.isNaN(crossedPrice))
                                                        ? crossedPrice > watchedPrice ||
                                                          `Doit être > ${renderAsPrice(watchedPrice)}`
                                                        : true,
                                            })}
                                        />
                                    </QuantityInputInnerContainer>
                                </PriceTableBodyCell>
                                <ProductsTableBodyCell isDataCentered>
                                    {!isDefaultRange ? (
                                        <DeleteIconContainer onClick={() => removeProduct(index)}>
                                            <FaTrash data-test="trash-icon" size={20} color={colors.red} />
                                        </DeleteIconContainer>
                                    ) : null}
                                </ProductsTableBodyCell>
                            </tr>
                        );
                    })}
                </ProductsTableBody>
            </ProductsTable>
        </Container>
    );
};

const PriceTableBodyCell = styled.td`
    display: table-cell;
    text-align: -webkit-center;
    padding: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    overflow-y: scroll;
    min-height: 200px;

    & > :not(:first-child) {
        margin-top: 5px;
    }
`;

const ProductsTable = styled.table`
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    > td {
        padding-top: 5px;
    }
`;

const ProductsTableBody = styled.tbody`
    & > :not(:first-child) td {
        border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    }
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
    color: ${({ theme }) => theme.textColor};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
    color: ${({ theme }) => theme.textColor};
`;

const QuantityInputInnerContainer = styled.div`
    width: 80px;
`;

const DeleteIconContainer = styled.div`
    display: inline;
    padding: 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;
