import gql from 'graphql-tag';

// we don't fetch paymentMethod _id to avoid cache collisions
export const DETAILED_TRANSACTION_FRAGMENT = gql`
    fragment DetailedTransactionFragment on Transaction {
        _id
        basketId
        createdAt
        paymentMethods {
            amountUsed
            explanation
            name
            paymentMethodName
            provider
            providerTransactionId
            status
            type
        }
        priceHT
        productId
        productName
        refundReason
        siteId
        siteName
        microstoreColumnId
        microstoreColumnName
        newState
        mealPlan {
            discountRatio
        }
        stateHistory {
            state
            createdAt
            createdBy
        }
        tva
        userId
        userFullName
        nayaxMDBCode
    }
`;

export const PREVIEW_TRANSACTION_FRAGMENT = gql`
    fragment PreviewTransactionFragment on Transaction {
        _id
        createdAt
        priceHT
        productName
        siteId
        siteName
        microstoreColumnId
        microstoreColumnName
        newState
        tva
        userFullName
    }
`;
