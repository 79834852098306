import React from 'react';
import { DAYS } from '../constants';
import styled from 'styled-components';

interface DaySelectorProps {
    selectedDays: boolean[];
    onDaySelect: (index: number) => void;
}

export const DaySelector: React.FC<DaySelectorProps> = ({ selectedDays, onDaySelect }) => {
    return (
        <Container>
            {selectedDays.map((selected, dayIndex) => (
                <DayContainer key={dayIndex}>
                    <DayLabel>{DAYS[dayIndex]}</DayLabel>
                    <Checkbox checked={selected} onChange={() => onDaySelect(dayIndex)} />
                </DayContainer>
            ))}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
`;

const DayContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const DayLabel = styled.div``;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    cursor: pointer;
`;
