import React, { useState } from 'react';

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { FaClipboard, FaCheck } from 'react-icons/fa';
import { TotemLabel } from 'components/TotemLabel';

export const CopyValue = ({
    value,
    label,
    style,
}: {
    value: string;
    label?: string;
    style?: Record<string, string | number>;
}) => {
    const [isTextCopied, setIsTextCopied] = useState(false);
    return (
        <>
            {label ? <TotemLabel>{label}</TotemLabel> : null}
            <Container
                hasLabel={!!label}
                isTextCopied={isTextCopied}
                data-tip="Cliquez pour copier ce texte"
                onClick={() => {
                    navigator.clipboard.writeText(value).then(() => {
                        setIsTextCopied(true);
                        setTimeout(() => setIsTextCopied(false), 1000);
                    });
                }}
                style={style}
            >
                <Value>{value}</Value>
                <IconContainer>{isTextCopied ? <FaCheck size={13} /> : <FaClipboard size={13} />}</IconContainer>
            </Container>
            <ReactTooltip effect="solid" />
        </>
    );
};

const IconContainer = styled.div`
    margin-left: 5px;
`;

const Value = styled.div`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50vw;
`;

const Container = styled.div<{ isTextCopied: boolean; hasLabel: boolean }>`
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    font-size: 15px;
    padding: 10px;
    margin-top: ${({ hasLabel }) => (hasLabel ? 5 : 0)}px;
    cursor: pointer;
    color: ${({ theme }) => theme.textColor};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme, isTextCopied }) =>
        isTextCopied ? theme.ctaPrimaryHoveredColor : theme.backgroundColor};
    &:hover {
        background-color: ${({ theme, isTextCopied }) =>
            isTextCopied ? theme.ctaPrimaryHoveredColor : theme.darkBackgroundColor};
    }
    transition: background-color 0.3s ease;
`;
