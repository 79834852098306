import gql from 'graphql-tag';

export const SITE_FRAGMENT = gql`
    fragment SiteFragment on Site {
        _id
        accessTime
        unknownShrinkageBillingThreshold
        removalDate
        closingDatesRanges {
            start
            end
        }
        createdAt
        deliveryDays
        deliveryTimeWindow {
            start
            stop
        }
        estimatedUserCount
        hasExternalSetupProcess
        isSecured
        hasSensei
        hasCoffee
        launchDate
        name
        organizationId
        organizationName
        prepareBySupplierPackage
        reassortmentStockTargetRatios {
            monday
            tuesday
            wednesday
            thursday
            friday
        }
        reassortmentStockTargets {
            dessert
            main
            smallBites
            starter
        }
        reassortmentOnboardingStockTargets {
            dessert
            main
            smallBites
            starter
        }
        shouldAutomaticallyCreateTargetReassortment
        shouldAutomaticallyCreateManualReassortment
        shouldHaveTheftNotice
        shouldModulateForExpired
        shouldModulateForUnknownLosses
        state
        stateHistory {
            createdAt
            createdBy
            state
        }
        totemNumber
        type
        unwantedProductIds
        updatedAt
        urbantzMetadata {
            needsInventoryBoissons {
                evenWeek
                oddWeek
            }
            needsInventorySnack {
                evenWeek
                oddWeek
            }
            needsInventoryNonAlimentaire {
                evenWeek
                oddWeek
            }
            needsCleaningMachineNECTA {
                evenWeek
                oddWeek
            }
            needsSmallExpiryDateInventory {
                evenWeek
                oddWeek
            }
            replaceCompleteInventoryByZeroInventory {
                evenWeek
                oddWeek
            }
            needsPunctualExpiryDateInventory {
                evenWeek
                oddWeek
            }
        }
        useNayaxCard
        B2CPriceRange
        wantedProductIds
    }
`;
