import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
    GetMicrostoreColumnQuery,
    MicrostoreElementState,
    useDeleteMicrostoreColumnMutation,
} from 'data/__generated__';

import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView/StateHistory';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader, LoaderModeType } from 'components/Loader';
import { DetailFormStatusValue } from 'components/DetailsView/DetailFormStatusValue';
import { MICROSTORE_COLUMN_STATES_OPTIONS } from '../constants/states';

export const StatesSection = ({
    microstoreColumn,
    isDirty,
}: {
    microstoreColumn: GetMicrostoreColumnQuery['microstoreColumn'];
    isDirty: boolean;
}) => {
    const { state, stateHistory } = microstoreColumn;
    const [deleteMicrostoreColumn, { loading }] = useDeleteMicrostoreColumnMutation();

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <DetailFormStatusValue state={stateRecord.state} options={MICROSTORE_COLUMN_STATES_OPTIONS} />,
    }));

    async function archiveColumn() {
        const hasConfirmed = window.confirm('Êtes-vous sûr.e de vouloir archiver la colonne ?');

        if (hasConfirmed) {
            const { data } = await deleteMicrostoreColumn({
                variables: {
                    microstoreColumnId: microstoreColumn._id,
                },
            });
            if (data?.deleteMicrostoreColumnMutation.newSite) {
                toast.success('La colonne a bien été archivée !');
            } else if (data?.deleteMicrostoreColumnMutation.errors) {
                toast.error(data.deleteMicrostoreColumnMutation.errors, { autoClose: false });
            } else {
                throw Error("Une erreur inconnue s'est produite");
            }
        }
    }

    return (
        <SectionContainer title="Gestion des statuts" isInitiallyOpen>
            <ResponsiveContainer>
                <ContentColumn>
                    <StateHistory records={formattedStateHistory} />
                </ContentColumn>
                {loading ? (
                    <LoaderContainer>
                        <Loader mode={LoaderModeType.Spin} />
                    </LoaderContainer>
                ) : (
                    <ContentColumn>
                        {isDirty ? (
                            <WarningText>
                                ⚠️ Veuillez vous assurer d'avoir sauvegardé ou annulé vos ajustements avant de mettre à
                                jour le statut de la colonne
                            </WarningText>
                        ) : null}
                        <OverlaySectionColumn isDirty={isDirty}>
                            <TotemPrimaryButton
                                onClick={archiveColumn}
                                disabled={state === MicrostoreElementState.Archived || isDirty}
                            >
                                Archiver la colonne
                            </TotemPrimaryButton>
                        </OverlaySectionColumn>
                    </ContentColumn>
                )}
            </ResponsiveContainer>
        </SectionContainer>
    );
};

const WarningText = styled.div`
    color: ${({ theme }) => theme.textColor};
    font-weight: 800;
    word-wrap: break-word;
`;

const ResponsiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const ContentColumn = styled.div`
    flex: 1;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const OverlaySectionColumn = styled(SectionColumn)`
    opacity: ${({ isDirty }: { isDirty: boolean }) => (isDirty ? 0.4 : 1)};
`;

const LoaderContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
`;
