import { MicrostoreColumnTemplateType } from 'data/__generated__';
import { FridgeColumnImage, FridgeDrinksColumnImage, SnackColumnImage } from 'assets/images/column';

export function getColumnImage(microstoreColumn: MicrostoreColumnTemplateType) {
    switch (microstoreColumn) {
        case MicrostoreColumnTemplateType.Fridge:
            return FridgeColumnImage;
        case MicrostoreColumnTemplateType.Drinks:
            return FridgeDrinksColumnImage;
        default:
            return SnackColumnImage;
    }
}
