import { StatusOptions } from 'components/DetailsView/DetailFormStatusValue';
import { colors } from 'constants/colors';
import { ExternalColumnType } from 'data/__generated__';

export const MICROSTORE_COLUMN_EXTERNAL_TYPES_OPTIONS: Record<ExternalColumnType, StatusOptions> = {
    [ExternalColumnType.Default]: {
        backgroundColor: colors.lightGrey,
        labelColor: colors.black,
        label: 'Défaut',
    },
    [ExternalColumnType.Nayax]: {
        backgroundColor: colors.blue,
        labelColor: colors.black,
        label: 'Nayax café TOTEM',
    },
    [ExternalColumnType.NayaxNeocorner]: {
        backgroundColor: colors.blue,
        labelColor: colors.black,
        label: 'Nayax Neocorner',
    },
    [ExternalColumnType.Nu]: {
        backgroundColor: colors.green,
        labelColor: colors.black,
        label: 'Nu',
    },
    [ExternalColumnType.Sensei]: {
        backgroundColor: colors.red,
        labelColor: colors.black,
        label: 'Sensei',
    },
    [ExternalColumnType.Digitiz]: {
        backgroundColor: colors.pink,
        labelColor: colors.black,
        label: 'Digitiz',
    },
};
