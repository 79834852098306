import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { DetailDisplayValue } from 'components/DetailsView/DetailDisplayValue';
import { DetailFormDatePicker } from 'components/DetailsView/DetailFormDatePicker';
import { CopyValue } from 'components/CopyValue';
import { dateFromString, formatDateAsAnniversary, getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { DetailValue, DetailFormCheckbox } from 'components/DetailsView';

import { GetMicrostoreColumnQuery } from 'data/__generated__';
import { DetailFormStatusValue } from 'components/DetailsView/DetailFormStatusValue';
import { MICROSTORE_COLUMN_STATES_OPTIONS } from '../constants/states';
import { getColumnImage } from '../utils';

export const GeneralInfoSection = ({
    microstoreColumn,
}: {
    microstoreColumn: GetMicrostoreColumnQuery['microstoreColumn'];
}) => {
    const { register, watch, control } = useFormContext();
    const {
        _id,
        name,
        isPaused,
        shouldUpdateStockOnTransaction,
        shouldSendNayaxPrices,
        startingDate,
        removalDate,
        state,
        type,
        createdAt,
        updatedAt,
        siteName,
        templateName,
    } = microstoreColumn;

    const watchIsPaused = watch('isPaused', isPaused);
    const watchShouldUpdateStockOnTransaction = watch('shouldUpdateStockOnTransaction', shouldUpdateStockOnTransaction);
    const watchShouldSendNayaxPrices = watch('shouldSendNayaxPrices', shouldSendNayaxPrices);
    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <StyledColumnsSectionContainer>
                <SectionColumn>
                    <Row>
                        <ColumnImage src={getColumnImage(type)} alt={type} />
                        <ModificationInfo>
                            <Row>
                                <CopyValue value={_id} />
                            </Row>
                            <Row>
                                <DetailFormStatusValue
                                    state={state}
                                    options={MICROSTORE_COLUMN_STATES_OPTIONS}
                                    showHeading
                                />
                            </Row>
                            <Row>
                                <DetailValue
                                    label="Dernière mise à jour"
                                    value={getExtendedFormattedDatetime(new Date(updatedAt).getTime())}
                                />
                                <DetailValue
                                    label="Date de création"
                                    value={getExtendedFormattedDatetime(new Date(createdAt).getTime())}
                                />
                            </Row>
                            <Row>
                                <DetailFormCheckbox label="Pause" name="isPaused" defaultChecked={watchIsPaused} />
                            </Row>
                            <Row>
                                <DetailFormCheckbox
                                    label="Mettre à jour les stocks à chaque transactions"
                                    name="shouldUpdateStockOnTransaction"
                                    defaultChecked={watchShouldUpdateStockOnTransaction}
                                />
                            </Row>
                            <Row>
                                <DetailFormCheckbox
                                    label="Envoyer les prix à Nayax"
                                    name="shouldSendNayaxPrices"
                                    defaultChecked={watchShouldSendNayaxPrices}
                                />
                            </Row>
                        </ModificationInfo>
                    </Row>
                </SectionColumn>
                <SectionColumn>
                    <DetailFormValue
                        label="Nom"
                        defaultValue={name}
                        {...register('name', { required: true })}
                        width="100%"
                        data-test="microstore-column-name"
                    />
                    <DetailDisplayValue label="Site" value={siteName} />
                    <DetailDisplayValue label="Template" value={templateName} />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormDatePicker
                        label="Date de début"
                        name="startingDate"
                        defaultValue={startingDate}
                        control={control}
                        transformValueToDate={(value) => (value ? dateFromString(value, true) : null)}
                        transformDateToValue={(date) =>
                            date ? formatDateAsAnniversary({ dateTime: date, useNewFormat: true }) : null
                        }
                    />
                    <DetailFormDatePicker
                        label="Date de retrait"
                        name="removalDate"
                        defaultValue={removalDate}
                        control={control}
                        transformValueToDate={(value) => (value ? dateFromString(value, true) : null)}
                        transformDateToValue={(date) =>
                            date ? formatDateAsAnniversary({ dateTime: date, useNewFormat: true }) : null
                        }
                    />
                </SectionColumn>
            </StyledColumnsSectionContainer>
        </SectionContainer>
    );
};

const StyledColumnsSectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;

const ModificationInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    min-width: 200px;
`;

const IMAGE_WIDTH = 100;
const IMAGE_HEIGHT = 300;

const ColumnImage = styled.img`
    width: ${IMAGE_WIDTH}px;
    height: ${IMAGE_HEIGHT}px;
    object-fit: contain;
`;
