import {
    SupplyOrderUpdateInput,
    SupplyOrderProductUpdateInput,
    GetProductsToOrderOnceForSupplyOrderQuery,
    GetSupplyOrderWithSupplierProductsInfoQuery,
    SupplyOrderWithSupplierProductsInfoFragmentFragment,
} from 'data/__generated__';

export type SupplyOrderProductReceivedDetailWithoutTypeName = Omit<
    SupplyOrderWithSupplierProductsInfoFragmentFragment['products'][number]['productReceivedDetails'][number],
    '__typename'
>;

export type SupplyOrderProductWithoutTypeName = Omit<
    SupplyOrderWithSupplierProductsInfoFragmentFragment['products'][number],
    '__typename'
>;

export type ProductWithInfoType = Omit<SupplyOrderProductWithoutTypeName, 'productReceivedDetails'> & {
    availableStock: Readonly<number>;
    brand: Readonly<string>;
    conditionning: Readonly<number>;
    packagesPerBatch: Readonly<number>;
    isAtSoldoutRisk: boolean;
    isIncorrectSupplier?: boolean;
    productReceivedDetails: SupplyOrderProductReceivedDetailWithoutTypeName[];
    stock: Readonly<number>;
    unitPriceSupplier: Readonly<number>;
    volume: Readonly<string>;
    minSupplyOrderQuantity?: Readonly<number>;
};

export type SupplyOrderDetailsDisplayValues = Omit<
    SupplyOrderWithSupplierProductsInfoFragmentFragment,
    'temperature'
> & {
    productsWithInfo: ProductWithInfoType[];
    temperature: string;
};

export function mapSupplyOrderToDetailsDisplayValues(
    supplyOrder: SupplyOrderWithSupplierProductsInfoFragmentFragment & {
        soldOutRiskProductIds: string[];
        issues: GetSupplyOrderWithSupplierProductsInfoQuery['supplyOrderWithSupplierProductsInfo']['issues'];
    },
    productsToOrderOnce: GetProductsToOrderOnceForSupplyOrderQuery['productsToOrderOnceForSupplyOrder'],
): SupplyOrderDetailsDisplayValues {
    const supplierProducts = supplyOrder.supplier.productsInfoWithStock ?? [];
    // We should normally not have products in supplyOrder that are not primary ordered from the supplier
    // but as we can change primary supplier for products, we still need to show what has been ordered.
    const supplyOrderProductsWithInfoNotOrderedPrimaryWithSupplier: ProductWithInfoType[] = supplyOrder.products
        .filter(
            ({ _id: supplyOrderProductId }) =>
                !supplierProducts.find(({ _id: supplierProductId }) => supplyOrderProductId === supplierProductId),
        )
        .map((supplyOrderProduct) => {
            const { _id, brand, colis, quantity, stock, availableStock, supplierShortageInfo, unitPrice, volume } =
                supplyOrderProduct;

            const supplierProduct = supplierProducts.find(({ _id }) => _id === supplyOrderProduct._id);
            const productToOrderOnce = productsToOrderOnce.find(({ _id }) => _id === supplyOrderProduct._id);

            return {
                ...supplyOrderProduct,
                brand,
                conditionning: colis ? Math.floor(quantity / colis) : 1,
                packagesPerBatch:
                    supplierProduct?.packagesPerBatch ??
                    productToOrderOnce?.suppliers?.[0]?.prices?.[0]?.packagesPerBatch ??
                    -1,
                isAtSoldoutRisk: supplyOrder.soldOutRiskProductIds.includes(_id),
                isIncorrectSupplier: true,
                stock,
                availableStock,
                supplierShortageInfo,
                unitPriceSupplier: unitPrice ?? 0,
                volume,
            };
        });

    const supplierProductsWithInfo: ProductWithInfoType[] = supplierProducts.map((supplierProduct) => {
        const {
            _id: productId,
            availableStock,
            brand,
            conditionning,
            packagesPerBatch,
            isToBeArchived,
            name,
            stock,
            supplierShortageInfo,
            tva,
            unitPrice: supplierUnitPrice,
            volume,
        } = supplierProduct;

        const supplyOrderProduct = supplyOrder.products.find(({ _id }) => supplierProduct._id === _id) ?? {
            _id: productId,
            colis: 0,
            confirmedQuantity: null,
            confirmedQuantityHistory: [],
            name,
            price: null,
            productReceivedDetails:
                [] as SupplyOrderWithSupplierProductsInfoFragmentFragment['products'][number]['productReceivedDetails'],
            storedAt: null,
            supplierShortageInfo,
            quantity: 0,
            quantityGenerated: 0,
            quantityGeneratedForMenus: 0,
            supplyOrderId: supplyOrder._id,
            tva,
            unitPrice: supplierUnitPrice,
        };

        return {
            ...supplyOrderProduct,
            productReceivedDetails: supplyOrderProduct.productReceivedDetails.map(
                ({ brokenItems, colis, conditionning, expiryDate, quantity }) => ({
                    brokenItems,
                    colis,
                    conditionning,
                    expiryDate,
                    quantity,
                }),
            ),
            brand,
            conditionning: conditionning,
            packagesPerBatch,
            isAtSoldoutRisk: supplyOrder.soldOutRiskProductIds.includes(supplyOrderProduct._id),
            isToBeArchived,
            stock,
            availableStock,
            unitPriceSupplier: supplierUnitPrice,
            volume,
        };
    });

    const productsWithInfo = supplyOrderProductsWithInfoNotOrderedPrimaryWithSupplier
        .concat(supplierProductsWithInfo)
        .sort((product1, product2) => {
            // we put the product with quantity ordered on top of the list, and order by name after
            const nameComparison = product1.name > product2.name ? 1 : -1;
            return product1.quantity
                ? product2.quantity
                    ? nameComparison
                    : -1
                : !product2.quantity
                  ? nameComparison
                  : 1;
        });

    return { ...supplyOrder, temperature: supplyOrder.temperature?.toString() ?? '', productsWithInfo };
}

export function mapDetailsDisplayValuesToSupplyOrderUpdateInput({
    comment,
    productsWithInfo,
    orderDate,
    dateDeliveryScheduled,
    dateDelivery,
    description,
    hourDelivery,
    priceHTInvoice,
    priceTTCInvoice,
    temperature,
}: SupplyOrderDetailsDisplayValues): SupplyOrderUpdateInput {
    const products: SupplyOrderProductUpdateInput[] = productsWithInfo
        .filter((productWithInfo: ProductWithInfoType) => {
            return productWithInfo.colis || productWithInfo.quantity;
        })
        .map(
            ({
                _id,
                brand,
                confirmedQuantity,
                confirmedQuantityHistory,
                colis,
                name,
                price,
                productReceivedDetails,
                quantity,
                quantityGenerated,
                quantityGeneratedForMenus,
                storedAt,
                tva,
                unitPrice,
                volume,
            }) => {
                return {
                    _id,
                    colis,
                    confirmedQuantity,
                    confirmedQuantityHistory: confirmedQuantityHistory.map(({ __typename, ...rest }) => ({ ...rest })),
                    fullname: `${name} - ${brand} - ${volume}`, // todo remove storing of fullname on supply order products
                    name,
                    price,
                    productReceivedDetails,
                    quantity,
                    quantityGenerated,
                    quantityGeneratedForMenus,
                    storedAt,
                    tva,
                    unitPrice,
                };
            },
        );

    const parsedTemperature = parseFloat(temperature);
    const supplyOrderUpdateInputValues = {
        comment,
        orderDate,
        dateDeliveryScheduled,
        dateDelivery,
        description,
        hourDelivery,
        priceHTInvoice,
        priceTTCInvoice,
        temperature: temperature && !isNaN(parsedTemperature) ? parsedTemperature : null,
    };
    const supplyOrderUpdateInput: SupplyOrderUpdateInput = { ...supplyOrderUpdateInputValues, products };
    return supplyOrderUpdateInput;
}
