import React, { useState } from 'react';
import styled from 'styled-components';
import { ImEnlarge } from 'react-icons/im';
import { FcNeutralTrading, FcPrint } from 'react-icons/fc';
import { BiTargetLock } from 'react-icons/bi';
import { FaLongArrowAltDown } from 'react-icons/fa';

import { GetMicrostoreSiteQuery, GetMicrostoreSitesQuery } from 'data/__generated__';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TransferInfoPopup } from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/Popups/TransferInfoPopup';
import { PrintPopup } from './Popups/PrintPopup';
import { QuantityGenerationPopup } from './Popups/QuantityGenerationPopup';

import {
    MicrostoreColumnGroupFormValues,
    MicrostoreProductsStockInfoFormValues,
} from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/FormHelper/DataMapper';
import { TargetsInfo } from 'pages/MicrostoreColumnGroups/MicrostoreColumnGroupDetails/components/TargetsInfo';

export const ColumnSelector = ({
    changeSelectedColumnId,
    isFormDirty,
    microstoreProductsStockInfo,
    microstoreColumnGroupFormValuesArray,
    activeMicrostoreSites,
    nextMSDeliveryDate,
    setIsMicrostoreEditorOpen,
    selectedColumnGroupId,
    setSelectedColumnGroupId,
    selectedColumnId,
    site,
}: {
    changeSelectedColumnId: (columnId: string | null) => void;
    isFormDirty: boolean;
    microstoreColumnGroupFormValuesArray: MicrostoreColumnGroupFormValues[];
    microstoreProductsStockInfo: MicrostoreProductsStockInfoFormValues | null;
    activeMicrostoreSites: GetMicrostoreSitesQuery['microstoreSitesWithColumnGroups'] | null;
    nextMSDeliveryDate: string;
    selectedColumnGroupId: string | null;
    selectedColumnId: string | null;
    setSelectedColumnGroupId: (columnGroupId: string | null) => void;
    setIsMicrostoreEditorOpen: (value: boolean) => void;
    site: GetMicrostoreSiteQuery['siteWithLocationInfo'];
}) => {
    const [isPrintPopupOpen, setIsPrintPopupOpen] = useState<boolean>(false);
    const [isQuantityGenerationPopupOpen, setIsQuantityGenerationPopupOpen] = useState<boolean>(false);
    const [isTransferInfoPopupOpen, setIsTransferInfoPopupOpen] = useState<boolean>(false);

    const allSiteLocations = microstoreColumnGroupFormValuesArray.flatMap((columnGroup) =>
        columnGroup.columns.flatMap((column) =>
            column.shelves.flatMap((shelf) => shelf.locationTable.flatMap((locationRow) => locationRow)),
        ),
    );

    return (
        <Container>
            <ContentContainer>
                {isFormDirty ? (
                    <DirtyOverlay>
                        <OverlayText>
                            Veuillez valider ou réinitialiser les changements en cours avant d'effectuer une action
                        </OverlayText>
                    </DirtyOverlay>
                ) : null}
                <OpenButton size={20} onClick={() => setIsMicrostoreEditorOpen(true)} />
                <ColumnsContainer>
                    {site.microstoreColumnGroups.map((microstoreColumnGroup) => {
                        return (
                            <ColumnGroupContainer key={microstoreColumnGroup._id}>
                                <ColumnGroupName>{microstoreColumnGroup.name}</ColumnGroupName>
                                {microstoreColumnGroup.columns
                                    .filter(({ columnIdToReplace }) => !columnIdToReplace)
                                    .map((microstoreColumn) => {
                                        const isSelected =
                                            microstoreColumnGroup._id === selectedColumnGroupId &&
                                            microstoreColumn._id === selectedColumnId;
                                        const replacementColumn = microstoreColumnGroup.columns.find(
                                            ({ columnIdToReplace }) => columnIdToReplace === microstoreColumn._id,
                                        );
                                        const isReplacementSelected =
                                            microstoreColumnGroup._id === selectedColumnGroupId &&
                                            replacementColumn?._id === selectedColumnId;

                                        return (
                                            <ColumnContainer key={microstoreColumn._id}>
                                                <ColumnSelectorDisplay
                                                    onClick={() => {
                                                        setSelectedColumnGroupId(microstoreColumnGroup._id);
                                                        changeSelectedColumnId(microstoreColumn._id);
                                                    }}
                                                    isSelected={isSelected}
                                                >
                                                    <ColumnNumber isSelected={isSelected}>
                                                        {microstoreColumn.positionInColumnGroup + 1}
                                                    </ColumnNumber>
                                                    <ColumnName>{microstoreColumn.name}</ColumnName>
                                                </ColumnSelectorDisplay>
                                                {replacementColumn ? (
                                                    <>
                                                        <FaLongArrowAltDown />
                                                        <ColumnSelectorDisplay
                                                            key={replacementColumn._id}
                                                            onClick={() => {
                                                                setSelectedColumnGroupId(microstoreColumnGroup._id);
                                                                changeSelectedColumnId(replacementColumn._id);
                                                            }}
                                                            isSelected={isReplacementSelected}
                                                        >
                                                            <ColumnNumber isSelected={isReplacementSelected}>
                                                                {microstoreColumn.positionInColumnGroup + 1}
                                                            </ColumnNumber>
                                                            <ColumnName>{replacementColumn.name}</ColumnName>
                                                        </ColumnSelectorDisplay>
                                                    </>
                                                ) : null}
                                            </ColumnContainer>
                                        );
                                    })}
                            </ColumnGroupContainer>
                        );
                    })}
                </ColumnsContainer>
                <PrintContainer>
                    <span>Imprimer</span>
                    <Button type="button" title="Imprimer pour les livreurs" onClick={() => setIsPrintPopupOpen(true)}>
                        <FcPrint size={18} color="white" />
                    </Button>
                </PrintContainer>

                <StockTransferContainer>
                    <span>Générer Qté</span>
                    <Button
                        type="button"
                        title="Générer les quantités"
                        onClick={() => setIsQuantityGenerationPopupOpen(true)}
                    >
                        <BiTargetLock size={18} color="white" />
                    </Button>
                </StockTransferContainer>
                <StockTransferContainer>
                    <span>Transfert</span>
                    <Button
                        type="button"
                        title="Afficher les infos de transfert"
                        onClick={() => setIsTransferInfoPopupOpen(true)}
                    >
                        <FcNeutralTrading size={18} color="white" />
                    </Button>
                </StockTransferContainer>
            </ContentContainer>
            <ContentContainer>
                <TargetsInfo
                    microstoreProductsStockInfo={microstoreProductsStockInfo}
                    site={site}
                    allSiteLocations={allSiteLocations}
                />
            </ContentContainer>
            <PrintPopup
                isOpen={isPrintPopupOpen}
                microstoreSites={activeMicrostoreSites}
                nextMSDeliveryDate={nextMSDeliveryDate}
                setIsOpen={setIsPrintPopupOpen}
            />
            <TransferInfoPopup
                isOpen={isTransferInfoPopupOpen}
                nextMSDeliveryDate={nextMSDeliveryDate}
                microstoreSites={activeMicrostoreSites}
                setIsOpen={setIsTransferInfoPopupOpen}
                site={site}
            />
            <QuantityGenerationPopup
                isOpen={isQuantityGenerationPopupOpen}
                nextMSDeliveryDate={nextMSDeliveryDate}
                microstoreSites={activeMicrostoreSites}
                setIsOpen={setIsQuantityGenerationPopupOpen}
                site={site}
            />
        </Container>
    );
};

const Container = styled.div`
    color: ${({ theme }) => theme.textColor};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
`;

const ContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
    width: 100%;

    &:first-child {
        flex-grow: 1;
    }

    &:not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
    }
`;

const DirtyOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${({ theme }) => theme.popupOverlayColor};
`;

const OverlayText = styled.div`
    color: ${({ theme }) => theme.ctaPrimaryColor};
    background-color: ${({ theme }) => theme.backgroundColor};
    padding: 5px;
`;

const ColumnsContainer = styled.div`
    flex-grow: 1;
    margin-top: 15px;
    border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
`;

const ColumnGroupContainer = styled.div`
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: black dashed 1px;
    border-radius: 10px;
`;

const ColumnSelectorDisplay = styled.div<{ isSelected: boolean }>`
    margin-top: 15px;
    cursor: pointer;
    color: ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : 'inherit')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const ColumnNumber = styled.div<{ isSelected: boolean }>`
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.ctaPrimaryColor : theme.lightBorderColor)};
    box-shadow: ${({ theme, isSelected }) => (isSelected ? theme.boxShadow : 'none')};
    background-color: ${({ theme }) => theme.cardBackgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const ColumnGroupName = styled.div`
    font-weight: 600;
    text-align: center;
`;

const ColumnName = styled.div`
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
`;

const OpenButton = styled(ImEnlarge)`
    cursor: pointer;
`;

const StockTransferContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid ${({ theme }) => theme.darkBorderColor};

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const Button = styled(TotemPrimaryButton)`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PrintContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid ${({ theme }) => theme.darkBorderColor};
`;
