import React, { useState } from 'react';
import styled from 'styled-components';
import { useGetNayaxMdbTemplatesQuery } from 'data/__generated__';
import { TotemPopup } from '../../../components/TotemPopup';
import { TotemPrimaryButton } from '../../../components/TotemPrimaryButton';
import { NayaxMDBTemplateSelect } from '../../../components/Select/NayaxMDBTemplateSelect';
import { toast } from 'react-toastify';
type ImportNayaxMDBTemplateProps = {
    onImport: (template: Record<string, number>) => void;
};

export const ImportNayaxMDBTemplate: React.FC<ImportNayaxMDBTemplateProps> = ({ onImport }) => {
    const [isSelectionPopupOpen, setIsSelectionPopupOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

    const { data: templatesData } = useGetNayaxMdbTemplatesQuery();

    const handleTemplateSelect = (templateId: string | null) => {
        setSelectedTemplateId(templateId);
    };

    const handleConfirm = () => {
        if (selectedTemplateId && templatesData?.nayaxMDBTemplates) {
            const template = templatesData.nayaxMDBTemplates.find((t) => t._id === selectedTemplateId);
            if (template) {
                const templateMap: Record<string, number> = {};
                template.associations.forEach((association) => {
                    const key = `${association.positionInColumn}-${association.positionInShelf.row}-${association.positionInShelf.column}`;
                    templateMap[key] = association.nayaxMDBCode;
                });
                onImport(templateMap);
                toast.success('Template MDB importé avec succès');
            }
            setSelectedTemplateId(null);
            setIsSelectionPopupOpen(false);
        }
    };

    return (
        <>
            <TotemPrimaryButton type="button" onClick={() => setIsSelectionPopupOpen(true)}>
                Importer un template MDB
            </TotemPrimaryButton>

            <TotemPopup
                title="Sélectionner un template MDB"
                isOpen={isSelectionPopupOpen}
                setIsOpen={setIsSelectionPopupOpen}
            >
                <Content>
                    <p>Veuillez sélectionner un template MDB à importer.</p>
                    <SelectContainer>
                        <NayaxMDBTemplateSelect value={selectedTemplateId} onChange={handleTemplateSelect} />
                    </SelectContainer>
                    <ButtonContainer>
                        <TotemPrimaryButton type="button" onClick={handleConfirm}>
                            Confirmer
                        </TotemPrimaryButton>
                    </ButtonContainer>
                </Content>
            </TotemPopup>
        </>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;

const SelectContainer = styled.div`
    width: 100%;
`;
