import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { ExternalColumnType, GetMicrostoreColumnQuery } from 'data/__generated__';
import { SynchronizePlanogramWithNuButton } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/SynchronizePlanogramWithNuButton';
import { SynchronizePlanogramWithSenseiButton } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/SynchronizePlanogramWithSenseiButton';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { DetailFormSelect } from 'components/DetailsView/DetailFormSelect';
import { SectionContainer } from 'components/DetailsView/Section';
import { SynchronizePlanogramWithNayaxButton } from 'pages/MicrostoreColumns/MicrostoreColumnDetails/SynchronizePlanogramWithNuNayax';
export const IntegrationsSection = ({
    microstoreColumn,
    isDirty,
}: {
    microstoreColumn: GetMicrostoreColumnQuery['microstoreColumn'];
    isDirty: boolean;
}) => {
    const { register, watch } = useFormContext();
    const externalColumnType = watch('externalColumnType', microstoreColumn.externalColumnType);

    const defaultTypeOption = { value: ExternalColumnType.Default, label: 'Default' };
    const nuTypeOption = { value: ExternalColumnType.Nu, label: 'Nu' };
    const digitizTypeOption = { value: ExternalColumnType.Digitiz, label: 'Digitiz' };
    const senseiTypeOption = { value: ExternalColumnType.Sensei, label: 'Sensei' };
    const nayaxTypeOption = { value: ExternalColumnType.Nayax, label: 'Nayax café TOTEM' };
    const nayaxNeocornerTypeOption = { value: ExternalColumnType.NayaxNeocorner, label: 'Nayax Neocorner' };

    const extarnalColumnTypesOptions =
        microstoreColumn.isSiteSecured && microstoreColumn.canBeSecured
            ? [
                  defaultTypeOption,
                  nuTypeOption,
                  digitizTypeOption,
                  senseiTypeOption,
                  nayaxTypeOption,
                  nayaxNeocornerTypeOption,
              ]
            : [defaultTypeOption, nayaxTypeOption, nayaxNeocornerTypeOption];

    return (
        <SectionContainer title="Intégrations" isInitiallyOpen>
            <Content>
                <FormRow>
                    <DetailFormSelect<ExternalColumnType>
                        label="Type d'intégration externe"
                        name="externalColumnType"
                        defaultValue={microstoreColumn.externalColumnType}
                        options={extarnalColumnTypesOptions}
                    />
                    {externalColumnType != ExternalColumnType.Default && (
                        <DetailFormValue
                            label="ID de colonne externe"
                            {...register('externalColumnId')}
                            defaultValue={microstoreColumn.externalColumnId || ''}
                            placeholder="Entrez l'ID externe..."
                        />
                    )}
                    {externalColumnType === ExternalColumnType.Nu && !isDirty && (
                        <ButtonsContainer>
                            <SynchronizePlanogramWithNuButton
                                microstoreColumnId={microstoreColumn._id}
                                disabled={isDirty}
                            />
                        </ButtonsContainer>
                    )}
                    {externalColumnType === ExternalColumnType.Sensei && !isDirty && (
                        <ButtonsContainer>
                            <SynchronizePlanogramWithSenseiButton
                                microstoreColumnId={microstoreColumn._id}
                                disabled={isDirty}
                            />
                        </ButtonsContainer>
                    )}
                    {externalColumnType === ExternalColumnType.NayaxNeocorner && (
                        <ButtonsContainer>
                            <SynchronizePlanogramWithNayaxButton
                                microstoreColumnId={microstoreColumn._id}
                                disabled={isDirty}
                            />
                        </ButtonsContainer>
                    )}
                </FormRow>
            </Content>
        </SectionContainer>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const FormRow = styled.div`
    display: flex;
    gap: 20px;
    align-items: flex-start;

    & > * {
        flex: 1;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
`;
