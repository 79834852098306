import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Loader } from 'components/Loader';
import { SupplyOrderDetailsFormPage } from 'pages/SupplyOrders/SupplyOrderDetails/SupplyOrderDetailsFormPage';

import {
    useGetProductsToOrderOnceForSupplyOrderQuery,
    useGetSupplyOrderSupplierProductsStockVariationLazyQuery,
    useGetSupplyOrderWithSupplierProductsInfoQuery,
} from 'data/__generated__';

type ParamTypes = {
    supplyOrderId: string;
};

export const SupplyOrderDetails = () => {
    const { supplyOrderId = '' } = useParams<ParamTypes>();

    const {
        loading: supplyOrderLoading,
        data: supplyOrderData,
        error: supplyOrderError,
    } = useGetSupplyOrderWithSupplierProductsInfoQuery({
        variables: { supplyOrderId },
        onCompleted: () => null,
    });

    const {
        loading: productsToOrderOnceForSupplyOrderLoading,
        data: productsToOrderOnceForSupplyOrderData,
        error: productsToOrderOnceForSupplyOrderError,
    } = useGetProductsToOrderOnceForSupplyOrderQuery({
        variables: { supplyOrderId },
    });

    const [getSupplyOrderSupplierProductsStockVariation, productsStockVariationQueryState] =
        useGetSupplyOrderSupplierProductsStockVariationLazyQuery({ fetchPolicy: 'network-only' });

    useEffect(() => {
        getSupplyOrderSupplierProductsStockVariation({
            variables: { supplyOrderId },
        });
    }, [supplyOrderId, supplyOrderData, getSupplyOrderSupplierProductsStockVariation]);

    if (supplyOrderLoading || productsToOrderOnceForSupplyOrderLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        supplyOrderError ||
        !supplyOrderData ||
        productsToOrderOnceForSupplyOrderError ||
        !productsToOrderOnceForSupplyOrderData
    ) {
        throw new Error("Une erreur est survenue lors de la récupération de l'achat");
    }

    const { supplyOrderWithSupplierProductsInfo: supplyOrder } = supplyOrderData;
    const { productsToOrderOnceForSupplyOrder: productsToOrderOnce } = productsToOrderOnceForSupplyOrderData;

    if (!supplyOrder) {
        throw new Error(`Aucun achat n'a été trouvé avec l'id "${supplyOrderId}"`);
    }

    return (
        <SupplyOrderDetailsFormPage
            supplyOrder={supplyOrder}
            productsStockVariationQueryState={productsStockVariationQueryState}
            productsToOrderOnce={productsToOrderOnce}
        />
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;
