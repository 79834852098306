import {
    DESSERTS_CATEGORY_ID,
    MEALS_CATEGORY_ID,
    SMALL_BITES_CATEGORY_ID,
    STARTERS_CATEGORY_ID,
} from 'pages/Products/constants/categories';

export const MENU_CATEGORIES: Record<string, string> = {
    [STARTERS_CATEGORY_ID]: 'Entrées',
    [SMALL_BITES_CATEGORY_ID]: 'Petites faims',
    [MEALS_CATEGORY_ID]: 'Plats',
    [DESSERTS_CATEGORY_ID]: 'Desserts',
};

export const MENU_CATEGORY_IDS = Object.keys(MENU_CATEGORIES);
export const DAYS = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
