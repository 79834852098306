import React, { useEffect, useState } from 'react';

import {
    FcAlarmClock,
    FcCancel,
    FcDeployment,
    FcFilingCabinet,
    FcInTransit,
    FcOk,
    FcNeutralTrading,
} from 'react-icons/fc';
import styled from 'styled-components';
import { Link, useSearchParams } from 'react-router-dom';
import { apolloClient } from '../../App';

import { PAGES } from '../../constants/pages';
import { DELIVERY_PLURAL_STATES_LABELS } from './constants/states';

import { DeliveryState, GetDeliveriesQuery, useGetDeliveriesQuery } from 'data/__generated__';
import { DELIVERIES_UPDATED_SUBSCRIPTION } from 'data/subscriptions/delivery';

import { DeliveryTrelloItem } from './components/DeliveryTrelloItem';
import { ActionBar } from 'components/ActionBar';
import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TrelloView } from 'components/TrelloView/TrelloView';
import { AbortDeliveriesPopup } from './Popups/AbortDeliveriesPopup';
import { ArchiveDeliveriesPopup } from './Popups/ArchiveDeliveriesPopup';
import { CreateBundlesPopup } from './Popups/CreateBundlesPopup';
import { CreateDeliveryPopup } from './Popups/CreateDeliveryPopup';
import { MarkAsDeliveredPopup } from './Popups/MarkAsDeliveredPopup';
import { MarkAsInProgressPopup } from './Popups/MarkAsInProgressPopup';
import { MarkAsPackagingPopup } from './Popups/MarkAsPackagingPopup';
import { MarkAsReadyPopup } from './Popups/MarkAsReadyPopup';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { formatDateAsAnniversary } from 'helpers/dateTimes';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SiteFilter } from 'components/Filter/SiteFilter';

import { deliveriesUpdatedHandler } from './utils/deliverySubscriptionsHandlers';
import { parseUrlSearchParams, updateSearchParams } from 'helpers/SearchParams/utils';

type ParamTypes = {
    siteId?: string;
};

export const Deliveries = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [deliverySearchDate, setDeliverySearchDate] = useState<Date | null>(null);
    const params = parseUrlSearchParams<ParamTypes>(searchParams);
    const { siteId: siteFilter } = params;

    const [selectedDeliveryIds, setSelectedDeliveryIds] = useState<string[]>([]);
    const [isAbortDeliveriesPopupOpen, setIsAbortDeliveriesPopupOpen] = useState<boolean>(false);
    const [isArchiveDeliveriesPopupOpen, setIsArchiveDeliveriesPopupOpen] = useState<boolean>(false);
    const [isCreateDeliveryPopupOpen, setIsCreateDeliveryPopupOpen] = useState<boolean>(false);
    const [isMarkAsDeliveredPopupOpen, setIsMarkAsDeliveredPopupOpen] = useState<boolean>(false);
    const [isMarkAsInProgressPopupOpen, setIsMarkAsInProgressPopupOpen] = useState<boolean>(false);
    const [isMarkAsPackagingPopupOpen, setIsMarkAsPackagingPopupOpen] = useState<boolean>(false);
    const [isMarkAsReadyPopupOpen, setIsMarkAsReadyPopupOpen] = useState<boolean>(false);
    const [isCreateBundlesPopupOpen, setIsCreateBundlesPopupOpen] = useState<boolean>(false);

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: DELIVERIES_UPDATED_SUBSCRIPTION,
        });

        const subscription = observer.subscribe(({ data }) => {
            const { deliveriesUpdated } = data;
            deliveriesUpdatedHandler(deliveriesUpdated);
        });

        return () => subscription.unsubscribe();
    });

    const {
        data: deliveriesData,
        loading: deliveriesLoading,
        error: deliveriesError,
    } = useGetDeliveriesQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            searchDate: deliverySearchDate ? formatDateAsAnniversary({ dateTime: deliverySearchDate }) : null,
        },
    });

    if (deliveriesLoading && !deliveriesData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (deliveriesError || !deliveriesData) {
        throw new Error('Une erreur est survenue lors de la récupération des livraisons');
    }

    const deliveries = deliveriesData.deliveries.filter(
        (delivery) => !siteFilter || delivery.siteId === siteFilter?.[0],
    );

    function sortByDeliveryDate(
        deliveryA: GetDeliveriesQuery['deliveries'][number],
        deliveryB: GetDeliveriesQuery['deliveries'][number],
    ) {
        if ((deliveryA.deliveryDate && !deliveryB.deliveryDate) || deliveryA.deliveryDate > deliveryB.deliveryDate) {
            return 1;
        }
        if (deliveryA.deliveryDate === deliveryB.deliveryDate) {
            return deliveryA.updatedAt > deliveryB.updatedAt ? 1 : -1;
        }
        return -1;
    }

    function getDeliveriesByState({ state }: { state: DeliveryState }) {
        const sortedDeliveries = deliveries.filter((delivery) => delivery.state === state).sort(sortByDeliveryDate);
        if (
            [
                DeliveryState.Aborted,
                DeliveryState.Archived,
                DeliveryState.Delivered,
                DeliveryState.NotDelivered,
            ].includes(state)
        ) {
            sortedDeliveries.reverse();
        }
        return sortedDeliveries.map((delivery) => {
            return {
                _id: delivery._id,
                content: (
                    <DeliveryTrelloItem
                        delivery={delivery}
                        isSelected={selectedDeliveryIds.includes(delivery._id)}
                        toggleDeliverySelection={toggleDeliverySelection}
                    />
                ),
            };
        });
    }

    function toggleDeliverySelection(deliveryIdToToggle: string) {
        if (selectedDeliveryIds.includes(deliveryIdToToggle)) {
            setSelectedDeliveryIds(selectedDeliveryIds.filter((deliveryId) => deliveryId !== deliveryIdToToggle));
        } else {
            setSelectedDeliveryIds([...selectedDeliveryIds, deliveryIdToToggle]);
        }
    }

    const deliveriesByState = {
        [DeliveryState.Created]: getDeliveriesByState({ state: DeliveryState.Created }),
        [DeliveryState.Packaging]: getDeliveriesByState({ state: DeliveryState.Packaging }),
        [DeliveryState.Ready]: getDeliveriesByState({ state: DeliveryState.Ready }),
        [DeliveryState.InProgress]: getDeliveriesByState({ state: DeliveryState.InProgress }),
        [DeliveryState.Delivered]: getDeliveriesByState({ state: DeliveryState.Delivered }),
        [DeliveryState.NotDelivered]: getDeliveriesByState({ state: DeliveryState.NotDelivered }),
        [DeliveryState.Aborted]: getDeliveriesByState({ state: DeliveryState.Aborted }),
        [DeliveryState.Archived]: getDeliveriesByState({ state: DeliveryState.Archived }),
    };

    const STATE_COLUMNS_TO_DISPLAY = [
        DeliveryState.Created,
        DeliveryState.Packaging,
        DeliveryState.Ready,
        DeliveryState.InProgress,
        DeliveryState.Delivered,
        DeliveryState.NotDelivered,
        DeliveryState.Aborted,
        DeliveryState.Archived,
    ];

    const initialData = {
        columns: STATE_COLUMNS_TO_DISPLAY.map((state) => ({
            title: DELIVERY_PLURAL_STATES_LABELS[state],
            items: deliveriesByState[state],
        })),
    };

    const actionSections = [
        {
            title: 'Marquer comme',
            actions: [
                {
                    icon: <FcDeployment size={22} />,
                    label: 'en préparation',
                    onClick: () => setIsMarkAsPackagingPopupOpen(true),
                    hidden:
                        !selectedDeliveryIds.length ||
                        deliveries.some(
                            ({ _id, state }) => selectedDeliveryIds.includes(_id) && state !== DeliveryState.Created,
                        ),
                },
                {
                    icon: <FcAlarmClock size={22} />,
                    label: 'prête(s)',
                    onClick: () => setIsMarkAsReadyPopupOpen(true),
                    hidden:
                        !selectedDeliveryIds.length ||
                        deliveries.some(
                            ({ _id, state }) => selectedDeliveryIds.includes(_id) && state !== DeliveryState.Packaging,
                        ),
                },
                {
                    icon: <FcInTransit size={22} />,
                    label: 'en livraison',
                    onClick: () => setIsMarkAsInProgressPopupOpen(true),
                    hidden:
                        !selectedDeliveryIds.length ||
                        deliveries.some(
                            ({ _id, state }) => selectedDeliveryIds.includes(_id) && state !== DeliveryState.Ready,
                        ),
                },
                {
                    icon: <FcOk size={22} />,
                    label: 'livrée(s)',
                    onClick: () => setIsMarkAsDeliveredPopupOpen(true),
                    hidden:
                        !selectedDeliveryIds.length ||
                        deliveries.some(
                            ({ _id, state }) =>
                                selectedDeliveryIds.includes(_id) &&
                                [
                                    DeliveryState.Aborted,
                                    DeliveryState.Archived,
                                    DeliveryState.Created,
                                    DeliveryState.Delivered,
                                    DeliveryState.Packaging,
                                ].includes(state),
                        ),
                },
                {
                    icon: <FcCancel size={22} />,
                    label: 'annulée(s)',
                    onClick: () => setIsAbortDeliveriesPopupOpen(true),
                    hidden:
                        !selectedDeliveryIds.length ||
                        deliveries.some(
                            ({ _id, state }) =>
                                selectedDeliveryIds.includes(_id) &&
                                [
                                    DeliveryState.Aborted,
                                    DeliveryState.Archived,
                                    DeliveryState.Delivered,
                                    DeliveryState.InProgress,
                                ].includes(state),
                        ),
                },
                {
                    icon: <FcFilingCabinet size={22} />,
                    label: 'archivée(s)',
                    onClick: () => setIsArchiveDeliveriesPopupOpen(true),
                    hidden:
                        !selectedDeliveryIds.length ||
                        deliveries.some(
                            ({ _id, state }) =>
                                selectedDeliveryIds.includes(_id) &&
                                [
                                    DeliveryState.Archived,
                                    DeliveryState.Created,
                                    DeliveryState.InProgress,
                                    DeliveryState.NotDelivered,
                                    DeliveryState.Packaging,
                                    DeliveryState.Ready,
                                ].includes(state),
                        ),
                },
            ],
        },
        {
            title: 'Autres actions',
            actions: [
                {
                    icon: <FcFilingCabinet size={22} />,
                    label: 'Créer une livraison',
                    onClick: () => setIsCreateDeliveryPopupOpen(true),
                },
                {
                    icon: <FcNeutralTrading size={22} />,
                    label: 'Créer des bundles',
                    onClick: () => setIsCreateBundlesPopupOpen(true),
                },
            ],
        },
    ];

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.deliveries} />
                </HeaderTitle>
                <Filters>
                    <SiteFilter
                        value={siteFilter?.[0] || null}
                        onChange={(value) => updateSearchParams({ params, setSearchParams, key: 'siteId', value })}
                    />
                </Filters>
                <DateContainer>
                    <Link to="/deliveries/photos">
                        <TotemPrimaryButton>Photos du jour</TotemPrimaryButton>
                    </Link>
                    <TotemDatePicker
                        label="Date de livraison"
                        selected={deliverySearchDate}
                        onChange={(date) => {
                            setDeliverySearchDate(date as Date);
                        }}
                        isClearable={true}
                    />
                </DateContainer>
            </Header>
            <Content>
                <AbortDeliveriesPopup
                    deliveries={deliveries}
                    selectedDeliveryIds={selectedDeliveryIds}
                    isOpen={isAbortDeliveriesPopupOpen}
                    setIsOpen={setIsAbortDeliveriesPopupOpen}
                />
                <ArchiveDeliveriesPopup
                    deliveries={deliveries}
                    selectedDeliveryIds={selectedDeliveryIds}
                    isOpen={isArchiveDeliveriesPopupOpen}
                    setIsOpen={setIsArchiveDeliveriesPopupOpen}
                />
                <CreateDeliveryPopup isOpen={isCreateDeliveryPopupOpen} setIsOpen={setIsCreateDeliveryPopupOpen} />
                <MarkAsDeliveredPopup
                    deliveries={deliveries}
                    selectedDeliveryIds={selectedDeliveryIds}
                    isOpen={isMarkAsDeliveredPopupOpen}
                    setIsOpen={setIsMarkAsDeliveredPopupOpen}
                />
                <MarkAsInProgressPopup
                    deliveries={deliveries}
                    selectedDeliveryIds={selectedDeliveryIds}
                    isOpen={isMarkAsInProgressPopupOpen}
                    setIsOpen={setIsMarkAsInProgressPopupOpen}
                />
                <MarkAsPackagingPopup
                    deliveries={deliveries}
                    selectedDeliveryIds={selectedDeliveryIds}
                    isOpen={isMarkAsPackagingPopupOpen}
                    setIsOpen={setIsMarkAsPackagingPopupOpen}
                />
                <MarkAsReadyPopup
                    deliveries={deliveries}
                    selectedDeliveryIds={selectedDeliveryIds}
                    isOpen={isMarkAsReadyPopupOpen}
                    setIsOpen={setIsMarkAsReadyPopupOpen}
                />
                <CreateBundlesPopup isOpen={isCreateBundlesPopupOpen} setIsOpen={setIsCreateBundlesPopupOpen} />
                <TrelloViewContainer>
                    <TrelloView initialData={initialData} />
                </TrelloViewContainer>
                <ActionBar actionSections={actionSections} isInitiallyOpen={!!selectedDeliveryIds.length} />
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const TrelloViewContainer = styled.div`
    overflow-x: scroll;
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const DateContainer = styled.div`
    display: flex;
    min-width: 300px;
    justify-self: end;

    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;
