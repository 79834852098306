import {
    MicrostoreColumnTemplateWithAllInfo,
    MicrostoreColumnTemplateWithAllInfo_shelfTemplates,
    MicrostoreColumnTemplateWithAllInfo_shelfTemplates_locationTemplateTable,
} from 'data/fragments/__generated__/MicrostoreColumnTemplateWithAllInfo';
import { ProductArrangementWithProductsFragment } from 'data/fragments/__generated__/ProductArrangementWithProductsFragment';
import {
    MicrostoreColumnTemplateUpdateInput,
    MicrostoreLocationTemplateCreateOrUpdateInput,
    MicrostoreLocationTemplateSingleProductInfoInput,
    MicrostoreShelfTemplateCreateOrUpdateInput,
    ProductArrangementProductFragmentFragment,
} from 'data/__generated__';

export type MicrostoreLocationTemplateFormValues = Omit<
    MicrostoreLocationTemplateCreateOrUpdateInput,
    'productArrangementSingleProductInfo'
> & {
    productArrangement: ProductArrangementWithProductsFragment | null;
    productArrangementSingleProductInfo:
        | (Omit<MicrostoreLocationTemplateSingleProductInfoInput, 'productId'> & {
              product: ProductArrangementProductFragmentFragment;
          })
        | null;
};
export type MicrostoreShelfTemplateFormValues = Omit<
    MicrostoreShelfTemplateCreateOrUpdateInput,
    'locationTemplateTable'
> & {
    locationTemplateTable: MicrostoreLocationTemplateFormValues[][];
};
export type MicrostoreColumnTemplateFormValues = Omit<MicrostoreColumnTemplateUpdateInput, 'shelfTemplates'> & {
    shelfTemplates: MicrostoreShelfTemplateFormValues[];
};

// Fragment values to form values

function mapMicrostoreLocationTemplateToMicrostoreLocationTemplateFormValues({
    microstoreLocationTemplate: {
        _id,
        productArrangement,
        alternativeProductArrangementIds,
        productArrangementSingleProductInfo,
        shouldOrderProductIfNotInSite,
        shouldSmoothProductTransition,
        allowProductRotation,
    },
}: {
    microstoreLocationTemplate: MicrostoreColumnTemplateWithAllInfo_shelfTemplates_locationTemplateTable;
}): MicrostoreLocationTemplateFormValues {
    return {
        _id,
        productArrangement,
        alternativeProductArrangementIds,
        productArrangementSingleProductInfo,
        shouldOrderProductIfNotInSite,
        shouldSmoothProductTransition,
        allowProductRotation,
    };
}

function mapMicrostoreShelfTemplateToMicrostoreShelfTemplateFormValues({
    microstoreShelfTemplate: { _id, locationTemplateTable },
}: {
    microstoreShelfTemplate: MicrostoreColumnTemplateWithAllInfo_shelfTemplates;
}): MicrostoreShelfTemplateFormValues {
    return {
        _id,
        locationTemplateTable: locationTemplateTable.map((locationTemplateRow) =>
            locationTemplateRow.map((locationTemplate) =>
                mapMicrostoreLocationTemplateToMicrostoreLocationTemplateFormValues({
                    microstoreLocationTemplate: locationTemplate,
                }),
            ),
        ),
    };
}

export function mapMicrostoreColumnTemplateToMicrostoreColumnTemplateFormValues({
    microstoreColumnTemplate: { _id, name, type, shelfTemplates },
}: {
    microstoreColumnTemplate: MicrostoreColumnTemplateWithAllInfo;
}): MicrostoreColumnTemplateFormValues {
    return {
        _id,
        name,
        type,
        shelfTemplates: shelfTemplates.map((microstoreShelfTemplate) =>
            mapMicrostoreShelfTemplateToMicrostoreShelfTemplateFormValues({ microstoreShelfTemplate }),
        ),
    };
}

// Form values to input values

function mapMicrostoreLocationTemplateFormValuesToMicrostoreLocationTemplateUpdateInput({
    microstoreLocationTemplateFormValues: {
        _id,
        productArrangement,
        alternativeProductArrangementIds,
        productArrangementSingleProductInfo,
        shouldOrderProductIfNotInSite,
        shouldSmoothProductTransition,
        allowProductRotation,
    },
}: {
    microstoreLocationTemplateFormValues: MicrostoreLocationTemplateFormValues;
}): MicrostoreLocationTemplateCreateOrUpdateInput {
    return {
        _id,
        productArrangementId: productArrangement?._id ?? null,
        alternativeProductArrangementIds,
        productArrangementSingleProductInfo: productArrangementSingleProductInfo
            ? {
                  productId: productArrangementSingleProductInfo.product._id,
                  reassortmentType: productArrangementSingleProductInfo.reassortmentType,
                  stockMaxUnsecured: productArrangementSingleProductInfo.stockMaxUnsecured,
                  stockMaxSensei: productArrangementSingleProductInfo.stockMaxSensei,
              }
            : null,
        shouldOrderProductIfNotInSite,
        shouldSmoothProductTransition,
        allowProductRotation,
    };
}

function mapMicrostoreShelfTemplateFormValuesToMicrostoreShelfTemplateUpdateInput({
    microstoreShelfTemplateFormValues: { _id, locationTemplateTable },
}: {
    microstoreShelfTemplateFormValues: MicrostoreShelfTemplateFormValues;
}): MicrostoreShelfTemplateCreateOrUpdateInput {
    return {
        _id,
        locationTemplateTable: locationTemplateTable.map((locationTemplateRow) =>
            locationTemplateRow.map((locationTemplate) =>
                mapMicrostoreLocationTemplateFormValuesToMicrostoreLocationTemplateUpdateInput({
                    microstoreLocationTemplateFormValues: locationTemplate,
                }),
            ),
        ),
    };
}

export function mapMicrostoreColumnTemplateFormValuesToMicrostoreColumnTemplateUpdateInput({
    microstoreColumnTemplateFormValues: { _id, name, type, shelfTemplates },
}: {
    microstoreColumnTemplateFormValues: MicrostoreColumnTemplateFormValues;
}): MicrostoreColumnTemplateUpdateInput {
    return {
        _id,
        name,
        type,
        shelfTemplates: shelfTemplates.map((microstoreShelfTemplateFormValues) =>
            mapMicrostoreShelfTemplateFormValuesToMicrostoreShelfTemplateUpdateInput({
                microstoreShelfTemplateFormValues,
            }),
        ),
    };
}
