import React from 'react';
import { useGetMicrostoreColumnTemplatesQuery } from 'data/__generated__';
import { Option } from 'components/TotemSelect';
import { SelectFilter } from './SelectFilter';

interface TemplateFilterProps {
    value: string | null;
    onChange: (value: string | null) => void;
}

export const TemplateFilter: React.FC<TemplateFilterProps> = ({ value, onChange }) => {
    const { data: templatesData, loading } = useGetMicrostoreColumnTemplatesQuery();

    const options = React.useMemo(
        () =>
            (templatesData?.microstoreColumnTemplatesQuery || []).map((template) => ({
                label: template.name,
                value: template._id,
            })),
        [templatesData],
    );

    const selectedOption = React.useMemo(
        () => (value ? options.find((option) => option.value === value) || null : null),
        [value, options],
    );

    const handleChange = (option: Option<string> | null) => {
        onChange(option?.value || null);
    };

    return (
        <SelectFilter<string>
            placeholder="Template"
            value={selectedOption}
            options={options}
            onChange={handleChange}
            disabled={loading}
        />
    );
};
