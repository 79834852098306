import { gql } from '@apollo/client';
import { SITE_MENUS_FRAGMENT } from 'data/fragments/menus';

export const GET_SITE_MENUS_QUERY = gql`
    query GET_SITE_MENUS($siteId: String!, $date: String!) {
        siteMenus(siteId: $siteId, date: $date) {
            ...SiteMenusFragment
        }
    }
    ${SITE_MENUS_FRAGMENT}
`;

export const GET_SITE_COLUMNS_QUERY = gql`
    query GET_SITE_COLUMNS($siteId: String!) {
        siteWithLocationTemplates(siteId: $siteId) {
            _id
            microstoreColumnGroups {
                _id
                columns {
                    _id
                    templateName
                    type
                    shelves {
                        _id
                        positionInColumn
                        locationTable {
                            _id
                            positionInShelf {
                                column
                                row
                            }
                            microstoreLocationTemplate {
                                _id
                                productArrangementId
                                productArrangement {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_MENU_QUANTITIES_QUERY = gql`
    query GET_MENU_QUANTITIES($date: String!) {
        menuQuantities(date: $date) {
            site {
                _id
                name
            }
            columns {
                column {
                    _id
                    name
                }
                categoryQuantities {
                    category {
                        _id
                        title
                    }
                    dateQuantities {
                        date
                        quantity
                        quantityFacing
                        quantityInstruction
                        quantityFacingInstruction
                    }
                }
            }
        }
    }
`;

export const QUANTITIES_GENERATION_STATUS_QUERY = gql`
    query QUANTITIES_GENERATION_STATUS {
        quantitiesGenerationStatus
    }
`;

export const SITE_MENUS_GENERATION_STATUS_QUERY = gql`
    query SITE_MENUS_GENERATION_STATUS {
        siteMenusGenerationStatus
    }
`;
