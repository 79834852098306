import React from 'react';
import { TotemInput } from 'components/TotemInput';
import { MenuInstructionType } from 'data/__generated__';
import { CellData } from '../types';
import styled from 'styled-components';

interface QuantityInputsProps {
    cellData: CellData;
    isReadOnly?: boolean;
    microstoreColumnId?: string;
    categoryId?: string;
    onUpdateInstruction?: (instruction: {
        microstoreColumnId: string;
        categoryId?: string;
        date: string;
        type: MenuInstructionType;
        value: number | null;
    }) => void;
}

export const QuantityInputs: React.FC<QuantityInputsProps> = ({
    cellData,
    isReadOnly = false,
    microstoreColumnId,
    categoryId,
    onUpdateInstruction,
}) => {
    if (isReadOnly) {
        return (
            <Container>
                <span>
                    {cellData.quantity !== cellData.quantityInstruction && (
                        <>
                            {cellData.quantity} {'->'} {cellData.quantityInstruction}
                        </>
                    )}
                    {cellData.quantity === cellData.quantityInstruction && <>{cellData.quantity}</>}
                </span>
                <span>
                    {cellData.quantityFacing !== cellData.quantityFacingInstruction && (
                        <>
                            ({cellData.quantityFacing} {'->'} {cellData.quantityFacingInstruction})
                        </>
                    )}
                    ({cellData.quantityFacing === cellData.quantityFacingInstruction && <>{cellData.quantityFacing}</>})
                </span>
            </Container>
        );
    }

    return (
        <Container>
            <TotemInput
                type="number"
                placeholder={cellData.quantity.toString()}
                defaultValue={cellData.quantityInstruction ?? undefined}
                onChange={(value) => {
                    if (!onUpdateInstruction || !microstoreColumnId) return;
                    const newValue = value !== '' ? Number(value) : null;
                    onUpdateInstruction({
                        microstoreColumnId,
                        categoryId,
                        date: cellData.date,
                        value: newValue,
                        type: MenuInstructionType.SetupQuantities,
                    });
                }}
                min={0}
            />
            <TotemInput
                type="number"
                placeholder={cellData.quantityFacing.toString()}
                defaultValue={cellData.quantityFacingInstruction ?? undefined}
                onChange={(value) => {
                    if (!onUpdateInstruction || !microstoreColumnId) return;
                    const newValue = value !== '' ? Number(value) : null;
                    onUpdateInstruction({
                        microstoreColumnId,
                        categoryId,
                        date: cellData.date,
                        value: newValue,
                        type: MenuInstructionType.SetupFacing,
                    });
                }}
                min={0}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    input {
        width: 60px;
        padding: 4px;
        text-align: center;
    }
`;
