export const NEW_DAY_FORMAT = 'YYYY-MM-DD';
export const DAY_FORMAT = 'DD/MM/YYYY';

export function getDaysToNextWeekday(day: number): number {
    switch (day) {
        case 0: // Sunday to Thursday next weekday is day after (j+1)
        case 1:
        case 2:
        case 3:
        case 4:
            return 1;
        case 5:
            return 3; // On Friday, next weekday is Monday (j+3)
        case 6:
            return 2; // On Saturday, next weekday is Monday (j+2)
        default:
            throw new Error('Day input must be between 0-6');
    }
}

/**
 * This function returns the day of the week for a given date (for Monday, returns 0)
 * @param date Date (type can be Date or Dayjs)
 * @returns day of the week (0 = Monday, 7 = Sunday)
 */
export function getTotemDayOfTheWeek(date: Date): number {
    return (date.getDay() + 6) % 7;
}
/*
 ** Format YYYY-MM-DD
 */
export type BirthdayDate = string;
/*
 ** Format DD/MM/YYYY
 */
export type FrenchDate = string;

export const getExtendedFormattedDatetime = (dateTime: number) => {
    return new Date(dateTime).toLocaleString();
};

export const formatDateAsAnniversary = ({
    dateTime,
    displayDay = false,
    useNewFormat = false,
}: {
    dateTime: Date | BirthdayDate | number;
    displayDay?: boolean;
    useNewFormat?: boolean;
}) => {
    const dateTimeAsDate = new Date(dateTime);
    const date = dateTimeAsDate.getDate();
    const dateWithLeadingZero = date <= 9 ? '0' + date : date;
    const year = dateTimeAsDate.getFullYear();
    const month = dateTimeAsDate.getMonth() + 1;
    const monthWithLeadingZero = month <= 9 ? '0' + month : month;

    const formattedDate = useNewFormat
        ? `${year}-${monthWithLeadingZero}-${dateWithLeadingZero}`
        : `${dateWithLeadingZero}/${monthWithLeadingZero}/${year}`;
    if (!displayDay) {
        return formattedDate;
    }
    return `${dateTimeAsDate.toLocaleString('fr-FR', { weekday: 'short' })} ${formattedDate}`;
};

export const dateFromString = (dateString: string, isNewFormat?: boolean): Date => {
    if (isNewFormat) {
        const [year, month, day] = dateString.split('-');
        return new Date(Number(year), Number(month) - 1, Number(day));
    }

    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
};

export const addDaysToDate = ({ date, daysToAdd }: { date: Date; daysToAdd: number }): Date => {
    const updatedDate = new Date(date);
    updatedDate.setDate(updatedDate.getDate() + daysToAdd);
    return updatedDate;
};

export const removeDaysFromDate = ({ date, daysToRemove }: { date: Date; daysToRemove: number }): Date => {
    const updatedDate = new Date(date);
    updatedDate.setDate(updatedDate.getDate() - daysToRemove);
    return updatedDate;
};

export const dateFromHourString = (hourString: string, splitMarker = 'h') => {
    const [hours, minutes] = hourString.split(splitMarker);
    const date = new Date();
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));

    return date;
};

export function computeHourDeliveryFromDate(date: Date, splitMarker = 'h'): string {
    return `${date.getHours()}${splitMarker}${date.getMinutes().toString().padStart(2, '0')}`;
}

export const getTimeFromDatetime = (dateTime: Date) => {
    if (!dateTime) {
        return;
    }

    const dateTimeAsDate = new Date(dateTime);
    const hour = dateTimeAsDate.getHours();
    const hourWithLeadingZero = hour <= 9 ? '0' + hour : hour;
    const minute = dateTimeAsDate.getMinutes();
    const minuteWithLeadingZero = minute <= 9 ? '0' + minute : minute;

    return `${hourWithLeadingZero}:${minuteWithLeadingZero}`;
};

export const SHORT_MONTHS = [
    'Jan.',
    'Fév.',
    'Mar.',
    'Avr.',
    'Mai',
    'Juin',
    'Juil.',
    'Août',
    'Sept.',
    'Oct.',
    'Nov.',
    'Déc.',
];

function compareTimes(time1: string, time2: string): number {
    const [hour1, minute1] = time1.split(':').map(Number);
    const [hour2, minute2] = time2.split(':').map(Number);
    if (hour1 > hour2) {
        return 1;
    } else if (hour1 === hour2) {
        if (minute1 === minute2) {
            return 0;
        }
        return minute1 > minute2 ? 1 : -1;
    }
    return -1;
}

export function isFirstTimeLowerThanSecond(time1: string, time2: string): boolean {
    const timeComparison = compareTimes(time1, time2);

    return timeComparison <= 0;
}

export function getDifferenceInMinutes(time1: string, time2: string): number {
    const [time1Hours, time1Minutes] = time1.split(':').map(Number);
    const [time2Hours, time2Minutes] = time2.split(':').map(Number);

    return (time2Hours - time1Hours) * 60 + (time2Minutes - time1Minutes);
}

export function getNextMondayDate() {
    const today = new Date();
    today.setDate(today.getDate() + (7 - today.getDay() + 1));
    return today;
}

export function transformFrenchToBirthDayDate(date: FrenchDate): BirthdayDate {
    const [day, month, year] = date.split('/');
    return [year, month, day].join('-');
}

export function getWeekNumber(date?: Date): number {
    const dateToUse = date ?? new Date();
    const firstDayOfYear = new Date(dateToUse.getFullYear(), 0, 1);
    const pastDaysOfYear = (dateToUse.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}
