import React from 'react';
import { GetCatalogProductsQuery, useGetCatalogProductsQuery } from 'data/__generated__';
import { Option, TotemSelect } from 'components/TotemSelect';
//import debounce from 'lodash/debounce';

export type ProductSelected = GetCatalogProductsQuery['catalogProducts']['products'][number];

interface ProductSelectProps {
    dataTest?: string;
    value?: string | null;
    onChange: (product: ProductSelected | null) => void;
}

export const ProductSelect: React.FC<ProductSelectProps> = ({ value, onChange, dataTest }) => {
    const [searchTerm, setSearchTerm] = React.useState('');

    React.useEffect(() => {
        if (value && value !== selectedOption?.value) {
            setSearchTerm(value);
        }
    }, [value]);

    const { data: productsData } = useGetCatalogProductsQuery({
        variables: {
            offset: 0,
            filterString: searchTerm || undefined,
        },
        fetchPolicy: 'cache-and-network',
    });

    const options = React.useMemo(
        () =>
            (productsData?.catalogProducts?.products || []).map((product) => {
                let label = product.name;
                if (product.brand) {
                    label += ` - ${product.brand}`;
                }
                if (product.volume) {
                    label += ` - ${product.volume}`;
                }
                return {
                    label,
                    value: product._id,
                };
            }),
        [productsData],
    );

    const selectedOption = React.useMemo(
        () => (value ? options.find((option) => option.value === value) || null : null),
        [value, options],
    );

    const handleChange = (option?: Option<string> | null) => {
        const product = productsData?.catalogProducts?.products.find((product) => product._id === option?.value);
        onChange(product || null);
    };

    // const debouncedSearch = React.useCallback(
    //     debounce((inputValue: string) => {
    //         setSearchTerm(inputValue);
    //     }, 300),
    //     [],
    // );

    return (
        <TotemSelect
            dataTest={dataTest}
            label="Choisir un seul produit"
            placeholder="Sélectionner un produit (recherche par nom ou par _id)"
            value={selectedOption}
            options={options}
            onChange={handleChange}
            onInputChange={(inputValue: string) => {
                setSearchTerm(inputValue);
            }}
            isSearchable={true}
            isMulti={false}
        />
    );
};
