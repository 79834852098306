import React from 'react';

import { IconSearch, IconCalendarEvent } from '@tabler/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TotemCheckbox } from 'components/TotemCheckbox';

import { colors } from 'constants/colors';

import { formatDateAsAnniversary } from '../../../helpers/dateTimes';
import { GetDeliveriesQuery } from 'data/__generated__';

export const DeliveryTrelloItem = ({
    delivery,
    isSelected,
    toggleDeliverySelection,
}: {
    delivery: GetDeliveriesQuery['deliveries'][number];
    isSelected: boolean;
    toggleDeliverySelection: (deliveryId: string) => void;
}) => {
    const { _id: deliveryId, deliveryDate, label, organizationName, roundColor, roundName, siteName } = delivery;

    function toggleDelivery() {
        toggleDeliverySelection(deliveryId);
    }

    return (
        <ItemContent>
            <ItemHeader backgroundColor={roundColor || colors.pantinGrey}>
                <TotemCheckbox checked={isSelected} onChange={toggleDelivery} />
                {roundName || '-'}
                <Link to={`/delivery/${deliveryId}`}>
                    <IconSearch color={colors.pureWhite} size="15" />
                </Link>
            </ItemHeader>
            <ItemBody>
                <Title>{organizationName || siteName}</Title>
                <Details>
                    <Detail>{label}</Detail>
                    <Detail>
                        <IconCalendarEvent size="20" />
                        <DeliveryDate>
                            {formatDateAsAnniversary({ dateTime: deliveryDate, displayDay: true })}
                        </DeliveryDate>
                    </Detail>
                </Details>
            </ItemBody>
        </ItemContent>
    );
};

const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

type ItemHeaderProps = {
    backgroundColor: string;
};

const ItemHeader = styled.div<ItemHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${colors.pureWhite};
    padding: 10px;
    font-weight: 800;
    text-align: center;
`;

const ItemBody = styled.div`
    padding: 10px;
`;

const DeliveryDate = styled.span`
    margin-left: 5px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
`;

const Detail = styled.span`
    display: flex;
    align-items: center;
    font-size: 16px;
`;
