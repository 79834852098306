import { StatusOptions } from 'components/DetailsView/DetailFormStatusValue';
import { colors } from 'constants/colors';
import { MicrostoreColumnTemplateType } from 'data/__generated__';

export const MICROSTORE_COLUMN_TEMPLATE_TYPES_OPTIONS: Record<MicrostoreColumnTemplateType, StatusOptions> = {
    [MicrostoreColumnTemplateType.Cutlery]: {
        backgroundColor: colors.pink,
        labelColor: colors.black,
        label: 'Cutlery',
    },
    [MicrostoreColumnTemplateType.Drinks]: {
        backgroundColor: colors.blue,
        labelColor: colors.black,
        label: 'Drinks',
    },
    [MicrostoreColumnTemplateType.Essentials]: {
        backgroundColor: colors.orange,
        labelColor: colors.black,
        label: 'Essentials',
    },
    [MicrostoreColumnTemplateType.Fridge]: {
        backgroundColor: colors.emeraldBlue,
        labelColor: colors.black,
        label: 'Fridge',
    },
    [MicrostoreColumnTemplateType.HotDrinks]: {
        backgroundColor: colors.red,
        labelColor: colors.black,
        label: 'HotDrinks',
    },
    [MicrostoreColumnTemplateType.Microwave]: {
        backgroundColor: colors.pink,
        labelColor: colors.black,
        label: 'Microwave',
    },
    [MicrostoreColumnTemplateType.Snacks]: {
        backgroundColor: colors.green,
        labelColor: colors.black,
        label: 'Snacks',
    },
    [MicrostoreColumnTemplateType.Vrac]: {
        backgroundColor: colors.lightGrey,
        labelColor: colors.black,
        label: 'Vrac',
    },
};
