import React from 'react';
import { useQuery } from '@apollo/client';

import { useFormContext } from 'react-hook-form';

import {
    SupplyOrderProductIssueFragmentFragment,
    SupplyOrderProductIssueType,
    SupplyOrderState,
} from 'data/__generated__';
import { GET_PRODUCTS_BY_ID_QUERY } from 'data/queries/product';
import { GET_PRODUCTS_BY_ID, GET_PRODUCTS_BY_IDVariables } from 'data/queries/__generated__/GET_PRODUCTS_BY_ID';
import { BadExpiryDateIssueFragment } from 'data/fragments/__generated__/BadExpiryDateIssueFragment';
import { SUPPLY_ORDER_ISSUE_LABELS } from 'pages/SupplyOrders/constants/issueLabels';

import { DetailFormDatePicker } from 'components/DetailsView/DetailFormDatePicker';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';

import { DetailFormTextArea } from 'components/DetailsView/DetailFormTextArea';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { TotemLabel } from 'components/TotemLabel';
import { DetailTable } from 'components/DetailsView/DetailTable';

import {
    dateFromHourString,
    dateFromString,
    formatDateAsAnniversary,
    computeHourDeliveryFromDate,
} from '../../../helpers/dateTimes';
import { SupplyOrderDetailsDisplayValues } from './supplyOrderDetailsFormHelper';
import { BrokenQuantityIssueFragment } from 'data/fragments/__generated__/BrokenQuantityIssueFragment';
import { DifferentPackagingIssueFragment } from 'data/fragments/__generated__/DifferentPackagingIssueFragment';
import { DifferentQuantityIssueFragment } from 'data/fragments/__generated__/DifferentQuantityIssueFragment';
import { NewBarcodeIssueFragment } from 'data/fragments/__generated__/NewBarcodeIssueFragment';
import { WrongProductIssueFragment } from 'data/fragments/__generated__/WrongProductIssueFragment';
import { UnknownProductIssueFragment } from 'data/fragments/__generated__/UnknownProductIssueFragment';

export const ReceiptInfoSection = ({
    supplyOrderDetailsDisplayValues,
}: {
    supplyOrderDetailsDisplayValues: SupplyOrderDetailsDisplayValues;
}) => {
    const { control, register } = useFormContext();
    const { dateDelivery, description, hourDelivery, priceHTInvoice, priceTTCInvoice, state, temperature, issues } =
        supplyOrderDetailsDisplayValues;

    const productIds = issues.map((issue) => ('productId' in issue ? issue.productId : '')).filter(Boolean);

    const {
        data: productsData,
        loading: productsLoading,
        error: productsError,
    } = useQuery<GET_PRODUCTS_BY_ID, GET_PRODUCTS_BY_IDVariables>(GET_PRODUCTS_BY_ID_QUERY, {
        variables: {
            productIds,
        },
    });

    if (productsLoading || productsError || !productsData) {
        return null;
    }

    const { getProductsById: products } = productsData;

    function getIssuesDescription(issues: SupplyOrderProductIssueFragmentFragment[]) {
        if (!issues.length) {
            return;
        }

        const issueType = issues[0].type;

        if (issueType !== SupplyOrderProductIssueType.UnknownProduct) {
            const productNames = issues
                .map((issue) => {
                    if ('productId' in issue) {
                        return products.find(({ _id }) => _id === issue.productId)?.fullname ?? issue.productId;
                    }
                })
                .filter((name): name is string => !!name);
            if (issueType === SupplyOrderProductIssueType.NotReceived) {
                return <DetailTable columnNames={['Produit']} items={productNames.map((name) => [name])} />;
            }
            if (issueType === SupplyOrderProductIssueType.BadExpiryDate) {
                const items = (issues as BadExpiryDateIssueFragment[]).map(
                    ({ productId, quantity, expiryDate, minSupplierExpiryDays }, index) => [
                        productNames?.[index] ?? productId,
                        quantity,
                        expiryDate,
                        minSupplierExpiryDays,
                    ],
                );
                return (
                    <DetailTable columnNames={['Produit', 'Unité(s)', 'DLC', 'Garantie fournisseur']} items={items} />
                );
            }
            if (issueType === SupplyOrderProductIssueType.BrokenQuantity) {
                const items = (issues as BrokenQuantityIssueFragment[]).map(({ productId, brokenQuantity }, index) => {
                    const supplyOrderProduct = supplyOrderDetailsDisplayValues.products.find(
                        ({ _id }) => _id === productId,
                    );

                    const receivedQuantity =
                        supplyOrderProduct?.productReceivedDetails.reduce(
                            (acc, productDetail) => (acc += productDetail.quantity),
                            0,
                        ) ?? 0;

                    return [
                        productNames?.[index] ?? productId,
                        receivedQuantity,
                        brokenQuantity,
                        receivedQuantity - brokenQuantity,
                    ];
                });
                return (
                    <DetailTable
                        columnNames={['Produit', 'Unité(s) totales reçue(s)', 'Unité(s) cassée(s)', 'Vrai stock reçu']}
                        items={items}
                    />
                );
            }
            if (issueType === SupplyOrderProductIssueType.DifferentPackaging) {
                const items = (issues as DifferentPackagingIssueFragment[]).map(
                    ({ productId, expectedPackaging, receivedPackaging }, index) => [
                        productNames?.[index] ?? productId,
                        expectedPackaging,
                        receivedPackaging,
                    ],
                );
                return <DetailTable columnNames={['Produit', 'Collisage attendu', 'Collisage reçu']} items={items} />;
            }
            if (issueType === SupplyOrderProductIssueType.DifferentQuantity) {
                const items = (issues as DifferentQuantityIssueFragment[]).map(
                    ({ productId, expectedQuantity, receivedQuantity }, index) => [
                        productNames?.[index] ?? productId,
                        expectedQuantity,
                        receivedQuantity,
                    ],
                );
                return <DetailTable columnNames={['Produit', 'Quantité attendue', 'Quantité reçue']} items={items} />;
            }
            if (issueType === SupplyOrderProductIssueType.NewBarcode) {
                const items = (issues as NewBarcodeIssueFragment[]).map(({ productId, barcode }, index) => [
                    productNames?.[index] ?? productId,
                    barcode,
                ]);
                return <DetailTable columnNames={['Produit', 'Nouveau code barre']} items={items} />;
            }
            if (issueType === SupplyOrderProductIssueType.WrongProduct) {
                const items = (issues as WrongProductIssueFragment[]).map(({ productId, barcode }, index) => [
                    productNames?.[index] ?? productId,
                    productId,
                    barcode,
                ]);
                return <DetailTable columnNames={['Produit', 'ID', 'Code barre']} items={items} />;
            }
        } else {
            const items = (issues as UnknownProductIssueFragment[]).map(({ description, barcode }) => [
                description,
                barcode,
            ]);
            return <DetailTable columnNames={['Description', 'Code barre']} items={items} />;
        }
    }
    // DetailInputTable
    const isSupplyOrderInRack = state === SupplyOrderState.InRack;

    return (
        <SectionContainer title="Réception de l’achat">
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <DetailFormDatePicker
                        label="Livré le"
                        placeholder="Date de livraison"
                        defaultValue={dateDelivery}
                        name="dateDelivery"
                        control={control}
                        transformValueToDate={(value) => (value ? dateFromString(value) : null)}
                        transformDateToValue={(date) => (date ? formatDateAsAnniversary({ dateTime: date }) : null)}
                        isClearable={!isSupplyOrderInRack}
                        isDisabled={isSupplyOrderInRack}
                    />

                    <DetailFormDatePicker
                        label="Heure de livraison"
                        placeholder="Heure de livraison"
                        defaultValue={hourDelivery}
                        name="hourDelivery"
                        control={control}
                        transformValueToDate={(value) => (value ? dateFromHourString(value) : null)}
                        transformDateToValue={(date) => (date ? computeHourDeliveryFromDate(date) : null)}
                        isClearable={!isSupplyOrderInRack}
                        isDisabled={isSupplyOrderInRack}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Heure"
                        dateFormat="HH:mm"
                    />
                    <DetailFormValue
                        type="number"
                        label="Température à la réception"
                        defaultValue={temperature}
                        step=".01"
                        {...register('temperature')}
                    />
                    <DetailFormValue
                        type="number"
                        label="Prix HT facture"
                        defaultValue={priceHTInvoice}
                        step=".01"
                        {...register('priceHTInvoice', { valueAsNumber: true })}
                    />
                    <DetailFormValue
                        type="number"
                        label="Prix TTC facture"
                        defaultValue={priceTTCInvoice}
                        step=".01"
                        {...register('priceTTCInvoice', { valueAsNumber: true })}
                    />
                </SectionColumn>
                <SectionColumn>
                    {Object.keys(SUPPLY_ORDER_ISSUE_LABELS).map((issueType) => {
                        const matchingIssues = issues.filter(({ type }) => type === issueType);

                        if (matchingIssues.length) {
                            return (
                                <>
                                    <TotemLabel>
                                        {SUPPLY_ORDER_ISSUE_LABELS[issueType as SupplyOrderProductIssueType]}
                                    </TotemLabel>
                                    {getIssuesDescription(matchingIssues)}
                                </>
                            );
                        }
                    })}
                </SectionColumn>
                <SectionColumn>
                    <DetailFormTextArea
                        label="Commentaires"
                        defaultValue={description}
                        {...register('description')}
                        rows={15}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};
