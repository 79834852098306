import React from 'react';
import styled from 'styled-components';
import { Option, TotemSelect } from 'components/TotemSelect';

interface SelectFilterProps<T extends string | number> {
    placeholder: string;
    value: Option<T> | null;
    options: Option<T>[];
    onChange: (option: Option<T> | null) => void;
    onInputChange?: (inputValue: string) => void;
    disabled?: boolean;
    isSearchable?: boolean;
}

export function SelectFilter<T extends string | number>({
    placeholder,
    value,
    options,
    onChange,
    onInputChange,
    disabled = false,
    isSearchable = true,
}: SelectFilterProps<T>) {
    const handleChange = (option: Option<T> | null | undefined) => {
        onChange(option || null);
    };

    return (
        <SelectContainer>
            <TotemSelect
                isClearable
                placeholder={placeholder}
                value={value}
                options={options}
                onChange={handleChange}
                onInputChange={onInputChange}
                disabled={disabled}
                isSearchable={isSearchable}
            />
        </SelectContainer>
    );
}

const SelectContainer = styled.div`
    width: 250px;
`;
