import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { SHORT_FIELD_WIDTH } from 'constants/detailsView';
import { UNAVAILABLE_PRODUCT_STATES } from 'pages/Products/constants/states';
import { FRUITS_CATEGORY_ID, HOT_DRINKS_CATEGORY_ID } from 'pages/Products/constants/categories';
import { TVAs } from 'pages/Products/constants/tva';
import { DefaultProductType } from 'pages/Products/constants/defaultProduct';

import { ProductPrices } from './ProductPrices';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { DetailFormCheckbox } from 'components/DetailsView/DetailFormCheckbox';
import { DetailFormArrayValueWithSelect, DetailFormSelect } from 'components/DetailsView';
import { WatchedArrayOfSelectFields } from '../types';
import {
    GetActiveOrganizationsQuery,
    GetDetailedProductQuery,
    GetProductPriceRangesQuery,
    GetProductPricesQuery,
} from 'data/__generated__';

export const SalesInfoSection = ({
    organizations,
    product,
    productPrices,
    productPriceRanges,
    isIncomingFromCreation = false,
}: {
    organizations: GetActiveOrganizationsQuery['activeOrganizations'];
    product: NonNullable<GetDetailedProductQuery['detailedProduct']> | DefaultProductType;
    productPrices?: GetProductPricesQuery['productPrices'];
    productPriceRanges: GetProductPriceRangesQuery['productPriceRanges'];
    isIncomingFromCreation?: boolean;
}) => {
    const { control, register } = useFormContext();
    const {
        conditionningTotem,
        displayKg,
        isFreefood,
        isMicrostore,
        shouldBeHiddenInStore,
        isPunctual,
        isScannable,
        isTitreRestaurantAllowed,
        onboardedForSensei,
        portion,
        proportion,
        proportionMax,
        state,
        suppliers,
        tva,
        weightWithoutPackaging,
    } = product;

    const updatedPortion: number = useWatch({
        control,
        name: 'portion',
        defaultValue: portion,
    });

    const watchedAccess: WatchedArrayOfSelectFields = useWatch({
        control,
        name: 'access',
    });

    const organizationOptions = organizations.map(({ _id, name }: { _id: string; name: string }) => ({
        value: _id,
        label: name,
        isDisabled: watchedAccess?.some(({ value }) => value === _id),
    }));

    const supplierPrice = suppliers?.[0]?.prices[0]?.value;
    const tvaOptions = TVAs.map((value) => ({ value: value / 100, label: value.toString() }));

    const portionsPerUnit =
        weightWithoutPackaging && (displayKg ? updatedPortion / weightWithoutPackaging : updatedPortion);
    const unitMessage = ` portions pour 1 ${displayKg ? 'kg' : 'pièce'}`;
    const portionSublabel =
        portionsPerUnit && portionsPerUnit > 1 ? { sublabel: `${portionsPerUnit} ${unitMessage}` } : {};

    const categoryId = 'createdAt' in product ? product.category._id : product.categoryId;

    return (
        <SectionContainer title="Informations de vente" isInitiallyOpen={isIncomingFromCreation}>
            <>
                <ColumnsSectionContainer numberOfColumns={1}>
                    <SectionColumn>
                        <ProductPrices
                            productPrices={productPrices}
                            productPriceRanges={productPriceRanges}
                            tva={tva}
                            supplierPrice={supplierPrice}
                        />
                    </SectionColumn>
                </ColumnsSectionContainer>
                <ColumnsSectionContainer numberOfColumns={3}>
                    <SectionColumn>
                        <DetailFormSelect<number>
                            label="TVA"
                            placeholder="Sélectionner une TVA"
                            defaultValue={tva}
                            name="tva"
                            options={tvaOptions}
                            width={SHORT_FIELD_WIDTH}
                            required={true}
                        />
                        <DetailFormValue
                            label="Portions par unité"
                            defaultValue={portion}
                            type="number"
                            step="1"
                            {...register('portion', {
                                min: 1,
                                required: !UNAVAILABLE_PRODUCT_STATES.includes(state),
                                valueAsNumber: true,
                            })}
                            {...portionSublabel}
                        />
                        <DetailFormValue
                            label="Colisage TOTEM"
                            sublabel="Notre colisage en portions"
                            defaultValue={conditionningTotem}
                            type="number"
                            step="1"
                            {...register('conditionningTotem', {
                                min: 1,
                                required: !UNAVAILABLE_PRODUCT_STATES.includes(state),
                                valueAsNumber: true,
                            })}
                        />
                        {[HOT_DRINKS_CATEGORY_ID, FRUITS_CATEGORY_ID].includes(categoryId) ? (
                            <>
                                <DetailFormValue
                                    label="Proportion Initiale"
                                    defaultValue={proportion}
                                    type="number"
                                    step=".01"
                                    {...register('proportion', { min: 0, valueAsNumber: true })}
                                />
                                <DetailFormValue
                                    label="Proportion Maximale"
                                    defaultValue={proportionMax}
                                    type="number"
                                    step=".01"
                                    {...register('proportionMax', { min: 0, valueAsNumber: true })}
                                />
                            </>
                        ) : null}
                    </SectionColumn>
                    <SectionColumn>
                        <DetailFormArrayValueWithSelect
                            name="access"
                            label="Entreprises ayant un accès privé à ce produit"
                            options={organizationOptions}
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <DetailFormCheckbox
                            name="isScannable"
                            label="Code-barres scannable"
                            defaultChecked={isScannable}
                        />
                        <DetailFormCheckbox
                            name="isPunctual"
                            label="Disponible en ponctuel"
                            defaultChecked={isPunctual}
                        />

                        <DetailFormCheckbox
                            name="isFreefood"
                            label="Disponible en freefood"
                            defaultChecked={isFreefood}
                        />
                        <DetailFormCheckbox
                            name="isMicrostore"
                            label="Disponible en microstore"
                            defaultChecked={isMicrostore}
                        />
                        <DetailFormCheckbox
                            name="onboardedForSensei"
                            label="Produit onboarded pour Sensei"
                            defaultChecked={onboardedForSensei}
                            disabled={onboardedForSensei}
                        />
                        <DetailFormCheckbox
                            name="shouldBeHiddenInStore"
                            label="Doit être caché sur l'app mobile"
                            defaultChecked={shouldBeHiddenInStore}
                        />
                        <DetailFormCheckbox
                            name="isTitreRestaurantAllowed"
                            defaultChecked={isTitreRestaurantAllowed}
                            label="Titres Restaurant autorisé"
                        />
                    </SectionColumn>
                </ColumnsSectionContainer>
            </>
        </SectionContainer>
    );
};
