import React from 'react';
import { useQuery } from '@apollo/client';
import { useFormContext, useWatch } from 'react-hook-form';

import { GET_BADGES_QUERY } from 'data/queries/badge';
import { GET_BADGES } from 'data/queries/__generated__/GET_BADGES';
import { TagState, BadgeType, GetTagsQuery } from 'data/__generated__';

import { DetailFormArrayValueWithSelect } from 'components/DetailsView/DetailFormArrayValueWithSelect';
import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';

import { WatchedArrayOfSelectFields } from '../types';

export const TagsAndBadgesSection = ({
    tags,
    isIncomingFromCreation = false,
}: {
    tags: GetTagsQuery['tags'];
    isIncomingFromCreation?: boolean;
}) => {
    const { control } = useFormContext();
    const selectedTags: WatchedArrayOfSelectFields = useWatch({
        control,
        name: 'tagOptions',
    });
    const selectedPreferences: WatchedArrayOfSelectFields = useWatch({
        control,
        name: 'preferences',
    });
    const selectedDiets: WatchedArrayOfSelectFields = useWatch({
        control,
        name: 'diets',
    });

    const { loading: loadingBadges, data: dataBadges, error: errorBadges } = useQuery<GET_BADGES>(GET_BADGES_QUERY);

    if (loadingBadges || errorBadges || !dataBadges) {
        return null;
    }

    const preferenceBadges = dataBadges.badges.filter((badge) => badge.type === BadgeType.Preference);
    const dietBadges = dataBadges.badges.filter((badge) => badge.type === BadgeType.Diet);

    const activeTags = tags.filter(({ state }) => state === TagState.Regular);

    return (
        <SectionContainer title="Tags et badges" isInitiallyOpen={isIncomingFromCreation}>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <DetailFormArrayValueWithSelect
                        name="tagOptions"
                        label="Tags"
                        options={activeTags.map(({ name, _id }) => {
                            return {
                                label: name,
                                value: _id,
                                isDisabled: selectedTags?.some(({ value }) => _id === value),
                            };
                        })}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormArrayValueWithSelect
                        name="preferences"
                        label="Préferences"
                        options={preferenceBadges.map((badge) => {
                            return {
                                label: badge.name,
                                value: badge._id,
                                isDisabled: selectedPreferences?.some(({ value }) => badge._id === value),
                            };
                        })}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormArrayValueWithSelect
                        name="diets"
                        label="Régimes"
                        options={dietBadges.map((badge) => {
                            return {
                                label: badge.name,
                                value: badge._id,
                                isDisabled: selectedDiets?.some(({ value }) => badge._id === value),
                            };
                        })}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};
