import React, { useState } from 'react';

import { useSubscription } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

import {
    GetTransferableProductsQuery,
    SiteType,
    useCreateStockTransferMutation,
    useGetSitesQuery,
    useGetTransferableProductsFromCsvLazyQuery,
} from 'data/__generated__';
import { STOCK_TRANSFERS_UPDATED_SUBSCRIPTION } from 'data/subscriptions/stockTransfer';
import { colors } from 'constants/colors';
import { PAGES } from '../../constants/pages';

import { Footer } from 'components/Footer';
import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { SelectedOption } from 'components/TotemSelect';
import { ProductsToTransferTable } from './components/ProductsToTransferTable';
import { SitesSelector } from './components/SitesSelector';
import { PageTitle } from 'components/PageTitle';

import { dateFromString, formatDateAsAnniversary, FrenchDate } from '../../helpers/dateTimes';
import { stockTransfersUpdatedHandler } from './utils/stockTransfersUpdatedHandler';
import { checkStockCsvImport } from '../../helpers/checkStockCsvImport';
import { TotemCsvInputButton, CsvDataRow } from 'components/TotemCsvInputButton';

type FormType = {
    transferDate: FrenchDate;
    originSiteOption: SelectedOption<string>;
    destinationSiteOption: SelectedOption<string>;
    products: GetTransferableProductsQuery['transferableProducts'][number][];
};

export const StockTransferCreate = () => {
    const initialFormData = {
        transferDate: formatDateAsAnniversary({ dateTime: new Date() }),
        originSiteOption: null,
        destinationSiteOption: null,
        products: [],
    };
    const [formData, setFormData] = useState<FormType>(initialFormData);
    const [csvData, setCsvData] = useState<Array<CsvDataRow>>([]);
    const [isDestinationQuantity, setIsDestinationQuantity] = useState<boolean>(false);
    const { loading: sitesLoading, data: sitesData, error: sitesError } = useGetSitesQuery();
    const [getCsvProducts, { loading: loadingCsv }] = useGetTransferableProductsFromCsvLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted({ transferableProductsFromCsv }) {
            const { transferableProducts, idsNotFound, idsArchived } = transferableProductsFromCsv;
            if (transferableProducts?.length) {
                setFormData({ ...formData, products: [...formData.products, ...transferableProducts] });
            }
            if (idsNotFound?.length || idsArchived?.length) {
                let message = "Impossible d'ajouter les ids suivants =>";
                if (idsNotFound?.length) {
                    message += 'Ids non trouvés: ';
                    idsNotFound.forEach((id, index) => (message += `${index === 0 ? '' : ', '}${id}`));
                    message += <br />;
                }
                if (idsArchived?.length) {
                    message += 'Ids produits Archived: ';
                    idsArchived.forEach((id, index) => (message += `${index === 0 ? '' : ', '}${id}`));
                }

                toast.warn(message, { closeOnClick: false, autoClose: false });
            }
        },
    });
    const [createStockTransfer, { loading: creationLoading }] = useCreateStockTransferMutation();
    const navigate = useNavigate();

    useSubscription(STOCK_TRANSFERS_UPDATED_SUBSCRIPTION, {
        onSubscriptionData({
            subscriptionData: {
                data: { stockTransferUpdated },
            },
        }) {
            stockTransfersUpdatedHandler(stockTransferUpdated);
        },
    });

    if (sitesLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (sitesError || !sitesData) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    const sitesOptions = [...sitesData.sites]
        .sort((siteA, siteB) => siteA.name.toLowerCase().localeCompare(siteB.name.toLowerCase()))
        .sort((siteA, siteB) => (siteA.type === SiteType.Warehouse && siteB.type !== SiteType.Warehouse ? -1 : 1))
        .map((site) => ({ value: site._id, label: site.name }));

    const handleOriginSiteSelect = (option: SelectedOption<string>) => {
        if (option) {
            const originSiteOption = option;
            setFormData({ ...formData, originSiteOption });
        }
    };

    const handleDestinationSiteSelect = (option: SelectedOption<string>) => {
        if (option) {
            const destinationSiteOption = option;
            setFormData({ ...formData, destinationSiteOption });
        }
    };

    const handleChangedAvailableQuantitiesAfterDateChange = (
        transferableProducts: GetTransferableProductsQuery['transferableProducts'],
    ) => {
        const productsWithUpdatedAvailableStocks = formData.products.map((product) => {
            const transferableProduct = transferableProducts.find(({ productId }) => product.productId === productId);

            return {
                ...product,
                originStockAvailable: transferableProduct?.originStockAvailable ?? 0,
                destinationStockAvailable: transferableProduct?.destinationStockAvailable ?? 0,
            };
        });

        setFormData({
            ...formData,
            products: productsWithUpdatedAvailableStocks,
        });
    };

    const handleProductSelect = (product: GetTransferableProductsQuery['transferableProducts'][number]) => {
        if (product) {
            if (!formData.products.map((product) => product.productId).includes(product.productId))
                setFormData({
                    ...formData,
                    products: [product, ...formData.products],
                });
        }
    };

    const handleQuantityToTransferUpdate = (
        value: string,
        conditionningCrossPackagesPerBatch: number,
        product: GetTransferableProductsQuery['transferableProducts'][number],
    ) => {
        const { productId } = product;
        // Check that the string only contains digits
        if (/^(\d+)?$/.test(value)) {
            setFormData({
                ...formData,
                products: formData.products.map((formProduct) => {
                    if (formProduct.productId !== productId) {
                        return formProduct;
                    }
                    return {
                        ...product,
                        quantity: Number(value) * conditionningCrossPackagesPerBatch,
                    };
                }),
            });
        }
    };

    const handleCSVImport = async () => {
        const { success, error } = checkStockCsvImport({ csvData });
        if (!success) {
            setCsvData([]);
            toast.error(error, { autoClose: false });
            return;
        }
        const { originSiteOption, destinationSiteOption } = formData;
        if (!originSiteOption?.value || !destinationSiteOption?.value) {
            toast.error("L'un des champs n'a pas été renseigné");
            return;
        }
        const [header, ...data] = csvData;
        const productIdIndex = header.findIndex((elem) => elem === 'productId');
        const quantityIndex = header.findIndex((elem) => elem === 'quantity');

        const csvDataFormatted = data.map((rowArray) => {
            const quantity = Number(rowArray[quantityIndex].replace(',', '.'));
            return { productId: rowArray[productIdIndex], quantity };
        });

        await getCsvProducts({
            variables: {
                originSiteId: originSiteOption.value,
                destinationSiteId: destinationSiteOption.value,
                csvData: csvDataFormatted,
                isDestinationQuantity,
            },
        });
    };

    const handleProductDelete = (product: GetTransferableProductsQuery['transferableProducts'][number]) => {
        const { productId } = product;
        setFormData({
            ...formData,
            products: formData.products.filter((formProduct) => formProduct.productId !== productId),
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { transferDate, originSiteOption, destinationSiteOption, products } = formData;
        if (!transferDate || !originSiteOption || !destinationSiteOption || !products) {
            toast.error("L'un des champs n'a pas été renseigné");
            return;
        }
        const { value: originSiteId, label: originSiteLabel } = originSiteOption;
        const { value: destinationSiteId, label: destinationSiteLabel } = destinationSiteOption;
        const formattedProducts = products.map((product) => ({
            expiryDates: [], //for now we don't modify expiry dates when creating ST manually
            productId: product.productId,
            quantity: product.quantity,
        }));
        const result = await createStockTransfer({
            variables: {
                transferDate,
                originSiteId,
                destinationSiteId,
                products: formattedProducts,
            },
        });
        const { data } = result;
        if (data) {
            const {
                createStockTransfer: { success, stockTransfer },
            } = data;
            if (success && stockTransfer) {
                toast.success(
                    `Le transfert de stock de "${originSiteLabel}" vers "${destinationSiteLabel}" a bien été créé !`,
                );
                const { _id: stockTransferId } = stockTransfer;
                navigate('/stockTransfer/' + stockTransferId);
                setFormData(initialFormData);
            } else {
                const {
                    createStockTransfer: { error },
                } = data;
                if (error) {
                    toast.error(`L'erreur suivante est survenue : ${error}`);
                } else {
                    throw Error("Une erreur inconnue s'est produite");
                }
            }
        } else {
            throw Error("Une erreur inconnue s'est produite");
        }
    };

    const handleToggle = () => {
        setIsDestinationQuantity(!isDestinationQuantity);
    };
    const hasSitesSet = !!formData?.originSiteOption?.value && !!formData?.destinationSiteOption?.value;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.stockTransferCreate} />
                </HeaderTitle>
                {hasSitesSet ? (
                    <>
                        {csvData.length ? (
                            <ButtonContainer>
                                <OptionLabel>
                                    Quantité à destination ?
                                    <input type="checkbox" checked={isDestinationQuantity} onClick={handleToggle} />
                                </OptionLabel>
                                <TotemPrimaryButton onClick={() => setCsvData([])}>
                                    <FaTrash data-test="trash-icon" size={12} color={colors.pureWhite} />
                                </TotemPrimaryButton>
                                <TotemPrimaryButton minWidth="105px" onClick={handleCSVImport}>
                                    {loadingCsv ? <Loader size="18px" mode={LoaderModeType.Spin} /> : 'Import CSV'}
                                </TotemPrimaryButton>
                            </ButtonContainer>
                        ) : (
                            <TotemCsvInputButton onCsvDataUpload={setCsvData} />
                        )}
                    </>
                ) : null}
                <Link to="/stockTransfers">
                    <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                </Link>
            </Header>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <ContentScrollable>
                        <Fields>
                            <TotemDatePicker
                                label="Date du transfert"
                                selected={dateFromString(formData.transferDate)}
                                onChange={(date) =>
                                    date &&
                                    setFormData({
                                        ...formData,
                                        transferDate: formatDateAsAnniversary({ dateTime: date }),
                                    })
                                }
                            />
                            {!sitesLoading && sitesData ? (
                                <SitesSelector
                                    sitesOptions={sitesOptions}
                                    originSiteOption={formData.originSiteOption}
                                    destinationSiteOption={formData.destinationSiteOption}
                                    handleOriginSiteSelect={handleOriginSiteSelect}
                                    handleDestinationSiteSelect={handleDestinationSiteSelect}
                                />
                            ) : null}
                            {!!formData?.originSiteOption?.value && !!formData?.destinationSiteOption?.value ? (
                                <ProductsToTransferTable
                                    handleProductSelect={handleProductSelect}
                                    originSiteId={formData.originSiteOption?.value}
                                    destinationSiteId={formData.destinationSiteOption?.value}
                                    productsToTransfer={formData.products}
                                    handleQuantityToTransferUpdate={handleQuantityToTransferUpdate}
                                    handleChangedAvailableQuantitiesAfterDateChange={
                                        handleChangedAvailableQuantitiesAfterDateChange
                                    }
                                    handleProductDelete={handleProductDelete}
                                    transferDate={formData.transferDate}
                                />
                            ) : null}
                        </Fields>
                    </ContentScrollable>
                    <Footer>
                        <TotemPrimaryButton disabled={creationLoading} data-test="stock-transfer-create_submit-button">
                            Créer le transfert
                        </TotemPrimaryButton>
                    </Footer>
                </Form>
            </Content>
        </Container>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    width: max-content;
    align-items: center;

    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const OptionLabel = styled.div`
    font-size: 16px;
    color: ${({ theme }) => theme.textColor};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    flex: 1;
    overflow: hidden;
`;

const Form = styled.form`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentScrollable = styled.div`
    padding: 15px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;
