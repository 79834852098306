import { toast } from 'react-toastify';
import { GetTransferableProductsQuery } from 'data/__generated__';
import { CsvDataRow } from 'components/TotemCsvInputButton';

interface HandleCSVImportParams {
    csvData: CsvDataRow[];
    transferableProducts: GetTransferableProductsQuery['transferableProducts'];
    setSelectedProductsToRemove: React.Dispatch<
        React.SetStateAction<GetTransferableProductsQuery['transferableProducts']>
    >;
}

export const handleCSVImport = ({
    csvData,
    transferableProducts,
    setSelectedProductsToRemove,
}: HandleCSVImportParams) => {
    try {
        const [header, ...data] = csvData;
        const productIdIndex = header.findIndex((elem) => elem === 'productId');
        const quantityIndex = header.findIndex((elem) => elem === 'quantity');
        const palletsIndex = header.findIndex((elem) => elem === 'pallets');
        const colisIndex = header.findIndex((elem) => elem === 'colis');

        if (!data.length) {
            toast.error('Veuillez vérifier le fichier CSV. Il semble être vide');
            return;
        }

        // Check if at least one quantity column exists
        if (productIdIndex === -1 || (quantityIndex === -1 && palletsIndex === -1 && colisIndex === -1)) {
            toast.error(
                'Le fichier CSV doit contenir "productId" et au moins une colonne parmi: "quantity", "pallets", "colis"',
            );
            return;
        }

        //if extra line in 2 column file, corrups all data, last line - ['']
        if (data[data.length - 1].length === 1 && data[data.length - 1][0] === '') {
            toast.error(
                "Fichier CSV mal formaté. Veuillez vérifier que vous n'avez pas laissé de lignes ou de colonnes vides",
            );
            return;
        }

        const numberOfFields = header.length;
        const products: GetTransferableProductsQuery['transferableProducts'] = [];

        for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
            const row = data[rowIndex];

            // Check number of columns
            if (row.length !== numberOfFields) {
                toast.error(
                    `Veuillez vérifier le fichier CSV. Mauvais nombre de colonnes sur la ligne ${rowIndex + 2}`,
                );
                return;
            }

            const productId = row[productIdIndex];
            if (!productId) {
                toast.error(`Veuillez vérifier le fichier CSV. ProductId manquant sur la ligne ${rowIndex + 2}`);
                return;
            }

            const product = transferableProducts.find((p) => p.productId === productId);
            if (!product) {
                toast.error(`Produit non trouvé avec l'ID: ${productId} sur la ligne ${rowIndex + 2}`);
                continue;
            }

            // Check for duplicates
            if (products.find((p) => p.productId === productId)) {
                toast.error(
                    `Vous avez rajouté plusieurs fois le même id de produit ${productId} sur la ligne ${rowIndex + 2}`,
                );
                return;
            }

            let newTotal: number | null = null;

            // Try each column in priority order: quantity > pallets > colis
            if (quantityIndex !== -1 && row[quantityIndex]) {
                const quantity = Number(row[quantityIndex]);
                if (Number.isNaN(quantity) || !Number.isInteger(quantity)) {
                    toast.error(`Veuillez vérifier le fichier CSV. Quantité non valide sur la ligne ${rowIndex + 2}`);
                    return;
                }
                newTotal = quantity;
            }

            if (newTotal === null && palletsIndex !== -1 && row[palletsIndex] && product.packagesPerBatch > 1) {
                const pallets = Number(row[palletsIndex]);
                if (Number.isNaN(pallets) || pallets < 0) {
                    toast.error(
                        `Veuillez vérifier le fichier CSV. Nombre de palettes non valide sur la ligne ${rowIndex + 2}`,
                    );
                    return;
                }
                newTotal = Math.round(pallets * product.conditionning * product.packagesPerBatch);
            }

            if (newTotal === null && colisIndex !== -1 && row[colisIndex] && product.conditionning > 1) {
                const colis = Number(row[colisIndex]);
                if (Number.isNaN(colis) || colis < 0) {
                    toast.error(
                        `Veuillez vérifier le fichier CSV. Nombre de colis non valide sur la ligne ${rowIndex + 2}`,
                    );
                    return;
                }
                newTotal = Math.round(colis * product.conditionning);
            }

            if (newTotal === null) {
                toast.error(
                    `Veuillez vérifier le fichier CSV. Aucune quantité valide trouvée sur la ligne ${rowIndex + 2}`,
                );
                return;
            }

            products.push({ ...product, quantity: newTotal });
        }

        if (products.length === 0) {
            toast.error('Aucun produit valide trouvé dans le fichier CSV');
            return;
        }

        setSelectedProductsToRemove((prev) => {
            const updatedProducts = [...prev];

            // Update existing products and add new ones
            products.forEach((csvProduct) => {
                const existingIndex = updatedProducts.findIndex((p) => p.productId === csvProduct.productId);
                if (existingIndex !== -1) {
                    // Only update the quantity field
                    updatedProducts[existingIndex] = {
                        ...updatedProducts[existingIndex],
                        quantity: csvProduct.quantity,
                    };
                } else {
                    // Add new product
                    updatedProducts.push(csvProduct);
                }
            });

            return updatedProducts;
        });

        toast.success(`Import réussi de ${products.length} produits`);
    } catch (error) {
        toast.error("Erreur lors de l'import du fichier CSV");
    }
};
