import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import {
    FcAssistant,
    FcCalendar,
    FcCancel,
    FcDataBackup,
    FcDeployment,
    FcInvite,
    FcInspection,
    FcAddRow,
    FcAddDatabase,
} from 'react-icons/fc';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { SUPPLY_ORDER_PLURAL_STATES_LABELS } from './constants/states';

import { GET_SUPPLY_ORDERS_QUERY } from 'data/queries/supplyOrder';
import { SUPPLY_ORDERS_UPDATED_OR_CREATED_SUBSCRIPTION } from 'data/subscriptions/supplyOrder';
import {
    GET_SUPPLY_ORDERS,
    GET_SUPPLY_ORDERSVariables,
    GET_SUPPLY_ORDERS_supplyOrders,
} from 'data/queries/__generated__/GET_SUPPLY_ORDERS';
import { SupplyOrderState } from 'data/__generated__';

import { ActionBar } from 'components/ActionBar';
import { SupplyOrderTrelloItem } from './components/SupplyOrderTrelloItem';
import { Loader } from 'components/Loader';
import { TrelloView } from 'components/TrelloView/TrelloView';
import { CreateSupplyOrderPopup } from './Popups/CreateSupplyOrderPopup';
import { GenerateSupplyOrdersNeededPopup } from './Popups/GenerateSupplyOrdersNeededPopup';
import { MarkAsConfirmedPopup } from './Popups/MarkAsConfirmedPopup';
import { MarkAsOrderedPopup } from './Popups/MarkAsOrderedPopup';
import { MarkAsReadyPopup } from './Popups/MarkAsReadyPopup';
import { MarkAsReceivedPopup } from './Popups/MarkAsReceivedPopup';
import { MarkAsAbortedPopup } from 'pages/SupplyOrders/Popups/MarkAsAbortedPopup';

import { apolloClient } from 'App';

import { dateFromString, formatDateAsAnniversary } from '../../helpers/dateTimes';
import { supplyOrdersUpdatedOrCreatedHandler } from 'pages/SupplyOrders/cacheHandlers/supplyOrdersCacheHandler';
import { RelaunchPassSupplyOrdersToOrderedPopup } from 'pages/SupplyOrders/Popups/RelaunchPassSupplyOrdersToOrderedPopup';

export const SupplyOrdersTrelloView = ({
    displayLimitDate,
    supplierIds = [],
    filterDelayed = false,
}: {
    displayLimitDate: Date;
    supplierIds?: string[];
    filterDelayed?: boolean;
}) => {
    const now = new Date();
    const [selectedSupplyOrderIds, setSelectedSupplyOrderIds] = useState<string[]>([]);
    const [isCreateSupplyOrderPopupOpen, setIsCreateSupplyOrderPopupOpen] = useState<boolean>(false);
    const [isGenerateSupplyOrdersNeededOpen, setIsGenerateSupplyOrdersNeededOpen] = useState<boolean>(false);
    const [isRelaunchPassToOrderedPopupOpen, setIsRelaunchPassToOrderedPopupOpen] = useState<boolean>(false);
    const [isMarkAsConfirmedPopupOpen, setIsMarkAsConfirmedPopupOpen] = useState<boolean>(false);
    const [isMarkAsOrderedPopupOpen, setIsMarkAsOrderedPopupOpen] = useState<boolean>(false);
    const [isMarkAsReadyPopupOpen, setIsMarkAsReadyPopupOpen] = useState<boolean>(false);
    const [isMarkAsReceivedPopupOpen, setIsMarkAsReceivedPopupOpen] = useState<boolean>(false);
    const [isMarkAsAbortedPopupOpen, setIsMarkAsAbortedPopupOpen] = useState<boolean>(false);

    const {
        data: supplyOrdersData,
        loading: supplyOrdersLoading,
        error: supplyOrdersError,
    } = useQuery<GET_SUPPLY_ORDERS, GET_SUPPLY_ORDERSVariables>(GET_SUPPLY_ORDERS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            displayLimitDateFormatted: formatDateAsAnniversary({ dateTime: displayLimitDate, useNewFormat: true }),
        },
    });

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: SUPPLY_ORDERS_UPDATED_OR_CREATED_SUBSCRIPTION,
        });

        const subscription = observer.subscribe(({ data }) => {
            const { supplyOrdersUpdatedOrCreated } = data;
            supplyOrdersUpdatedOrCreatedHandler(supplyOrdersUpdatedOrCreated);

            toast.warning("Quelqu'un vient de modifier un ou plusieurs achat(s), la vue a été mise à jour !");
        });

        return () => subscription.unsubscribe();
    });

    if (supplyOrdersLoading && !supplyOrdersData) {
        return (
            <Content>
                <Loader />
            </Content>
        );
    }

    if (supplyOrdersError || !supplyOrdersData) {
        throw new Error('Une erreur est survenue lors de la récupération des achats');
    }

    const supplyOrders = supplyOrdersData.supplyOrders.filter(
        (supplyOrder) =>
            (supplierIds.length === 0 || supplierIds.includes(supplyOrder.supplier._id)) &&
            (!filterDelayed ||
                (supplyOrder.dateDeliveryScheduled &&
                    !supplyOrder.dateDelivery &&
                    dateFromString(supplyOrder.dateDeliveryScheduled) < now)),
    );

    type SortableField = 'orderDate' | 'dateDeliveryScheduled' | 'dateDelivery';

    function toggleSupplyOrderSelection(supplyOrderIdToToggle: string) {
        if (selectedSupplyOrderIds.includes(supplyOrderIdToToggle)) {
            setSelectedSupplyOrderIds(
                selectedSupplyOrderIds.filter((supplyOrderId) => supplyOrderId !== supplyOrderIdToToggle),
            );
        } else {
            setSelectedSupplyOrderIds([...selectedSupplyOrderIds, supplyOrderIdToToggle]);
        }
    }

    function sortByField(field: SortableField) {
        return (supplyOrderA: GET_SUPPLY_ORDERS_supplyOrders, supplyOrderB: GET_SUPPLY_ORDERS_supplyOrders) => {
            if (!supplyOrderA[field]) {
                return -1;
            }
            if (!supplyOrderB[field]) {
                return 1;
            }
            if (dateFromString(supplyOrderA[field] as string) > dateFromString(supplyOrderB[field] as string)) {
                return 1;
            }
            return -1;
        };
    }

    function sortByPrice(supplyOrderA: GET_SUPPLY_ORDERS_supplyOrders, supplyOrderB: GET_SUPPLY_ORDERS_supplyOrders) {
        return (supplyOrderA.priceHT ?? 0) - (supplyOrderB.priceHT ?? 0);
    }

    function getSupplyOrdersByState({ state }: { state: SupplyOrderState }) {
        return supplyOrders
            .filter((supplyOrder) => supplyOrder.state === state)
            .sort((supplyOrderA, supplyOrderB) => {
                if (!supplyOrderA.dateDeliveryScheduled) {
                    return 1;
                }
                if (!supplyOrderB.dateDeliveryScheduled) {
                    return -1;
                }
                if (
                    dateFromString(supplyOrderA.dateDeliveryScheduled) >
                    dateFromString(supplyOrderB.dateDeliveryScheduled)
                ) {
                    return -1;
                }
                return 1;
            });
    }

    function mapSupplyOrdersToTrelloItems(supplyOrdersToMap: GET_SUPPLY_ORDERS_supplyOrders[]) {
        return supplyOrdersToMap.map((supplyOrder) => ({
            _id: supplyOrder._id,
            content: (
                <SupplyOrderTrelloItem
                    supplyOrder={supplyOrder}
                    isSelected={selectedSupplyOrderIds.includes(supplyOrder._id)}
                    toggleSelection={() => toggleSupplyOrderSelection(supplyOrder._id)}
                />
            ),
        }));
    }

    const actionSections = [
        {
            title: 'Actions',
            actions: [
                {
                    icon: <FcAddRow size={22} />,
                    label: 'Créer',
                    onClick: () => {
                        setIsCreateSupplyOrderPopupOpen(true);
                    },
                    hidden: false,
                },
                {
                    icon: <FcAddDatabase size={22} />,
                    label: 'Générer les achats nécessaires',
                    onClick: () => {
                        setIsGenerateSupplyOrdersNeededOpen(true);
                    },
                    hidden: false,
                },
                {
                    icon: <FcDataBackup size={22} />,
                    label: 'Relancer le passage auto en "Commandé"',
                    onClick: () => {
                        setIsRelaunchPassToOrderedPopupOpen(true);
                    },
                    hidden: false,
                },
                {
                    icon: <FcInspection size={22} />,
                    label: 'Tout désélectionner',
                    onClick: () => {
                        setSelectedSupplyOrderIds([]);
                    },
                    hidden: !selectedSupplyOrderIds.length,
                },
            ],
        },
        {
            title: 'Marquer comme',
            actions: [
                {
                    icon: <FcInvite size={22} />,
                    label: 'à envoyer',
                    onClick: () => {
                        setIsMarkAsReadyPopupOpen(true);
                    },
                    hidden:
                        !selectedSupplyOrderIds.length ||
                        supplyOrders.some(
                            ({ _id, state }) =>
                                selectedSupplyOrderIds.includes(_id) && state !== SupplyOrderState.Created,
                        ),
                },
                {
                    icon: <FcAssistant size={22} />,
                    label: 'commandée(s)',
                    onClick: () => {
                        setIsMarkAsOrderedPopupOpen(true);
                    },
                    hidden:
                        !selectedSupplyOrderIds.length ||
                        supplyOrders.some(
                            ({ _id, state }) =>
                                selectedSupplyOrderIds.includes(_id) && state !== SupplyOrderState.Ready,
                        ),
                },
                {
                    icon: <FcCalendar size={22} />,
                    label: 'confirmée(s)',
                    onClick: () => {
                        setIsMarkAsConfirmedPopupOpen(true);
                    },
                    hidden:
                        !selectedSupplyOrderIds.length ||
                        supplyOrders.some(
                            ({ _id, state }) =>
                                selectedSupplyOrderIds.includes(_id) && state !== SupplyOrderState.Ordered,
                        ),
                },
                {
                    icon: <FcDeployment size={22} />,
                    label: 'reçues(s)',
                    onClick: () => {
                        setIsMarkAsReceivedPopupOpen(true);
                    },
                    hidden:
                        !selectedSupplyOrderIds.length ||
                        supplyOrders.some(
                            ({ _id, state }) =>
                                selectedSupplyOrderIds.includes(_id) &&
                                ![SupplyOrderState.Ordered, SupplyOrderState.Confirmed].includes(state),
                        ),
                },
                {
                    icon: <FcCancel size={22} />,
                    label: 'annulée(s)',
                    onClick: () => {
                        setIsMarkAsAbortedPopupOpen(true);
                    },
                    hidden:
                        !selectedSupplyOrderIds.length ||
                        supplyOrders.some(
                            ({ _id, state }) =>
                                selectedSupplyOrderIds.includes(_id) &&
                                ![
                                    SupplyOrderState.Created,
                                    SupplyOrderState.Ready,
                                    SupplyOrderState.Ordered,
                                    SupplyOrderState.Confirmed,
                                    SupplyOrderState.OnHold,
                                    SupplyOrderState.Aborted,
                                ].includes(state),
                        ),
                },
            ],
        },
    ];

    const initialData = {
        columns: [
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.Created],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({ state: SupplyOrderState.Created })
                        .sort(sortByPrice)
                        .sort(sortByField('orderDate')),
                ),
            },
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.Ready],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({ state: SupplyOrderState.Ready }).sort(sortByField('orderDate')),
                ),
            },
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.Ordered],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({
                        state: SupplyOrderState.Ordered,
                    }).sort(sortByField('orderDate')),
                ),
            },
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.Confirmed],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({
                        state: SupplyOrderState.Confirmed,
                    }).sort(sortByField('dateDeliveryScheduled')),
                ),
            },
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.OnHold],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({
                        state: SupplyOrderState.OnHold,
                    }).sort(sortByField('dateDeliveryScheduled')),
                ),
            },
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.Received],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({ state: SupplyOrderState.Received }).sort(sortByField('dateDelivery')),
                ),
            },
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.Checked],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({ state: SupplyOrderState.Checked }).sort(sortByField('dateDelivery')),
                ),
            },
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.InRack],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({ state: SupplyOrderState.InRack })
                        .sort(sortByField('dateDelivery'))
                        .reverse(),
                ),
            },
            {
                title: SUPPLY_ORDER_PLURAL_STATES_LABELS[SupplyOrderState.Aborted],
                items: mapSupplyOrdersToTrelloItems(
                    getSupplyOrdersByState({
                        state: SupplyOrderState.Aborted,
                    }).sort(sortByField('dateDeliveryScheduled')),
                ),
            },
        ],
    };

    return (
        <Content>
            <TrelloViewContainer>
                <TrelloView
                    initialData={initialData}
                    selectedItemIds={selectedSupplyOrderIds}
                    setSelectedItemIds={setSelectedSupplyOrderIds}
                />
            </TrelloViewContainer>
            <ActionBar actionSections={actionSections} isInitiallyOpen={!!selectedSupplyOrderIds.length} />
            <CreateSupplyOrderPopup isOpen={isCreateSupplyOrderPopupOpen} setIsOpen={setIsCreateSupplyOrderPopupOpen} />
            <GenerateSupplyOrdersNeededPopup
                isOpen={isGenerateSupplyOrdersNeededOpen}
                setIsOpen={setIsGenerateSupplyOrdersNeededOpen}
            />
            <RelaunchPassSupplyOrdersToOrderedPopup
                isOpen={isRelaunchPassToOrderedPopupOpen}
                setIsOpen={setIsRelaunchPassToOrderedPopupOpen}
            />
            <MarkAsConfirmedPopup
                supplyOrders={supplyOrders}
                selectedSupplyOrderIds={selectedSupplyOrderIds}
                setSelectedSupplyOrderIds={setSelectedSupplyOrderIds}
                isOpen={isMarkAsConfirmedPopupOpen}
                setIsOpen={setIsMarkAsConfirmedPopupOpen}
            />
            <MarkAsOrderedPopup
                supplyOrders={supplyOrders}
                selectedSupplyOrderIds={selectedSupplyOrderIds}
                setSelectedSupplyOrderIds={setSelectedSupplyOrderIds}
                isOpen={isMarkAsOrderedPopupOpen}
                setIsOpen={setIsMarkAsOrderedPopupOpen}
            />
            <MarkAsReadyPopup
                supplyOrders={supplyOrders}
                selectedSupplyOrderIds={selectedSupplyOrderIds}
                setSelectedSupplyOrderIds={setSelectedSupplyOrderIds}
                isOpen={isMarkAsReadyPopupOpen}
                setIsOpen={setIsMarkAsReadyPopupOpen}
            />
            <MarkAsReceivedPopup
                supplyOrders={supplyOrders}
                selectedSupplyOrderIds={selectedSupplyOrderIds}
                setSelectedSupplyOrderIds={setSelectedSupplyOrderIds}
                isOpen={isMarkAsReceivedPopupOpen}
                setIsOpen={setIsMarkAsReceivedPopupOpen}
            />
            <MarkAsAbortedPopup
                supplyOrders={supplyOrders}
                selectedSupplyOrderIds={selectedSupplyOrderIds}
                setSelectedSupplyOrderIds={setSelectedSupplyOrderIds}
                isOpen={isMarkAsAbortedPopupOpen}
                setIsOpen={setIsMarkAsAbortedPopupOpen}
            />
        </Content>
    );
};

const TrelloViewContainer = styled.div`
    overflow-x: scroll;
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
`;
