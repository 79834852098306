import React, { useState } from 'react';
import styled from 'styled-components';

import { SupplyOrderProductStockVariationDetailsPopup } from './Popups/SupplyOrderProductStockVariationDetailsPopup';
import { ProductsInfoStockVariationTable } from './ProductsInfoStockVariationTable';

import { ProductWithInfoType } from './supplyOrderDetailsFormHelper';

import { getDayLabels } from './utils';
import {
    GetProductGroupsContainingProductsQuery,
    GetAllProductsQuery,
    GetSupplyOrdersQuery,
    SupplyOrderProductStockVariationFragmentFragment,
} from 'data/__generated__';

export const ProductsInfoStockVariation = ({
    isComputed,
    orderingTimes,
    productInfo,
    productStockVariation,
    stock,
    startDate,
    shouldDisplayTooltip,
}: {
    isComputed: boolean;
    orderingTimes?: GetSupplyOrdersQuery['supplyOrders'][number]['supplier']['orderingTimes'];
    productInfo:
        | ProductWithInfoType
        | GetAllProductsQuery['getAllProducts'][number]
        | GetProductGroupsContainingProductsQuery['getProductGroupsContainingProducts'][number]['product'];
    productStockVariation: SupplyOrderProductStockVariationFragmentFragment;
    stock: number;
    startDate?: string | null;
    shouldDisplayTooltip: boolean;
}) => {
    const {
        computedStockThreshold,
        stockVariation,
        forecasts: { forecastsStoreDry },
    } = productStockVariation;

    const dayLabels = getDayLabels({
        isComputed,
        startDate,
        timeWindow: forecastsStoreDry.length,
        orderingTimes,
    });

    const [displayDetails, toggleDetails] = useState(false);

    return (
        <ProductsInfoStockVariationTableContainer
            isComputed={isComputed}
            onClick={() => toggleDetails(!displayDetails)}
        >
            <ProductsInfoStockThreshold>
                <div>Réserve</div>
                <div>{computedStockThreshold ?? 'Erreur'}</div>
            </ProductsInfoStockThreshold>
            {/* need this condition to not prerender the popup, as the tooltip is expensive to render */}
            {displayDetails ? (
                <SupplyOrderProductStockVariationDetailsPopup
                    dayLabels={dayLabels}
                    isOpen={displayDetails}
                    isComputed={isComputed}
                    productStockVariation={productStockVariation}
                    setIsOpen={toggleDetails}
                    stock={stock}
                    stockVariation={stockVariation}
                    productInfo={productInfo}
                />
            ) : null}
            <ProductsInfoStockVariationTable
                isComputed={isComputed}
                productStockVariation={productStockVariation}
                startDate={startDate}
                orderingTimes={orderingTimes}
                precalculatedDayLabels={dayLabels}
                shouldDisplayTooltip={shouldDisplayTooltip}
            />
        </ProductsInfoStockVariationTableContainer>
    );
};

const ProductsInfoStockVariationTableContainer = styled.div<{ isComputed: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: ${({ isComputed }) => isComputed && 'italic'};
    &:hover {
        cursor: pointer;
    }
`;

const ProductsInfoStockThreshold = styled.div`
    font-size: 12px;
    text-align: center;
    margin-right: 15px;
`;
