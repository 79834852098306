import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { SHORT_FIELD_WIDTH } from 'constants/detailsView';

import { FLOWS } from '../constants/flows';
import { DELIVERY_DAYS } from '../constants/weekdays';
import { DefaultProductType } from 'pages/Products/constants/defaultProduct';
import { FOOD_CATEGORIES } from 'pages/Products/constants/categories';
import { UNAVAILABLE_PRODUCT_STATES } from 'pages/Products/constants/states';
import {
    GetDetailedProductQuery,
    GetSuppliersQuery,
    GetLocationsForSelectionQuery,
    LocationState,
    ProductFlow,
    ProductState,
} from 'data/__generated__';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import {
    DetailFormCheckbox,
    DetailFormDatePicker,
    DetailFormValue,
    DetailFormSelect,
    DetailSwitchTable,
    DocumentationLink,
} from 'components/DetailsView';

import { TotemLabel } from 'components/TotemLabel';
import { Suppliers } from 'pages/Products/ProductDetails/Suppliers';
import { dateFromString, formatDateAsAnniversary, getExtendedFormattedDatetime } from 'helpers/dateTimes';

export const StockAndSupplierInfoSection = ({
    product,
    locations,
    suppliers,
    isIncomingFromCreation = false,
}: {
    product: NonNullable<GetDetailedProductQuery['detailedProduct']> | DefaultProductType;
    locations?: GetLocationsForSelectionQuery['locationSelectOptions'];
    suppliers: GetSuppliersQuery['suppliers'];
    isIncomingFromCreation?: boolean;
}) => {
    const {
        allowNegativeStock,
        canExpire,
        checkSwollenLid,
        deliveryDays,
        flow,
        hasShortExpiryDate,
        indexDisplay,
        isFromNayax,
        isWithoutStock,
        minSupplierExpiryDays,
        scan,
        shouldOrderOnce,
        state,
        stockThreshold,
        stockThresholdMinimum,
        minSupplierOrderQuantity,
        suppliers: productSuppliers,
        trackExpiryDate,
    } = product;

    const { register, control, watch, setValue } = useFormContext();
    const watchFlow = watch('flow', flow);

    const [previousFlow, setPreviousFlow] = useState<ProductFlow>(watchFlow);

    const watchInShortage = watch(
        'supplierShortageInfo.inShortage',
        'supplierShortageInfo' in product ? product.supplierShortageInfo.inShortage : false,
    );

    const categoryId = 'createdAt' in product ? product.category._id : product.categoryId;
    const updatedCategoryId = useWatch({
        control,
        name: 'categoryId',
        defaultValue: categoryId,
    });

    useEffect(() => {
        if (previousFlow !== watchFlow) {
            if (watchFlow === ProductFlow.Fresh) {
                setValue('trackExpiryDate', true);
            } else {
                setValue('trackExpiryDate', false);
            }
        }
        setPreviousFlow(watchFlow);
    }, [watchFlow]);

    useEffect(() => {
        if ('supplierShortageInfo' in product && !watchInShortage) {
            setValue('supplierShortageInfo.returnDate', null);
        }
    }, [watchInShortage]);

    const getDeliveryDayValues = (deliveryDayName: string) => {
        switch (deliveryDayName) {
            case 'monday':
                return deliveryDays.monday;
            case 'tuesday':
                return deliveryDays.tuesday;
            case 'wednesday':
                return deliveryDays.wednesday;
            case 'thursday':
                return deliveryDays.thursday;
            case 'friday':
                return deliveryDays.friday;
            case 'saturday':
                return deliveryDays.saturday;
            default:
                return false;
        }
    };

    const isFoodProduct = FOOD_CATEGORIES.includes(updatedCategoryId);
    const formattedDeliveryDays = DELIVERY_DAYS.map((item) => ({ ...item, value: getDeliveryDayValues(item.name) }));
    const flowOptions = FLOWS.map((value) => ({ value, label: value.toUpperCase() }));
    const today = new Date();

    const flowAbbreviation = watchFlow?.substring(0, 3).toUpperCase();
    const filteredLocations = locations?.filter(({ datamatrix }) => datamatrix.includes(flowAbbreviation)) ?? [];

    return (
        <SectionContainer title="Informations de stock et fournisseur" isInitiallyOpen={isIncomingFromCreation}>
            <ColumnsSectionContainer numberOfColumns={3}>
                <SectionColumn>
                    <DetailFormValue
                        label="Index d'affichage sur l'app"
                        defaultValue={indexDisplay}
                        type="number"
                        step="1"
                        {...register('indexDisplay', {
                            required: !UNAVAILABLE_PRODUCT_STATES.includes(state),
                            valueAsNumber: true,
                        })}
                    />
                    <DetailFormSelect<string>
                        label="Flux"
                        defaultValue={flow}
                        placeholder="Flux"
                        name="flow"
                        options={flowOptions}
                        width={SHORT_FIELD_WIDTH}
                        required={!UNAVAILABLE_PRODUCT_STATES.includes(state)}
                        dataTest="product-flow"
                    />
                    <DetailFormValue
                        label="Coefficient de réapprovisionnement"
                        sublabel="Pris en compte dans le calcul du stock de réserve"
                        defaultValue={stockThreshold}
                        {...register('stockThreshold', {
                            required: !UNAVAILABLE_PRODUCT_STATES.includes(state),
                            valueAsNumber: true,
                        })}
                    />
                    <DetailFormValue
                        label="Stock de réserve minimal"
                        sublabel="Valeur minimal du stock de réserve"
                        defaultValue={stockThresholdMinimum}
                        {...register('stockThresholdMinimum', {
                            valueAsNumber: true,
                        })}
                    />
                    <DetailFormValue
                        label="Quantité minimale de commande"
                        sublabel="Valeur minimal du stock de réserve"
                        defaultValue={minSupplierOrderQuantity}
                        {...register('minSupplierOrderQuantity', {
                            valueAsNumber: true,
                        })}
                    />
                    <DocumentationLink
                        src="https://www.notion.so/thetotem/Stock-de-R-serve-d2c491f605ff44a1a1a8db55305881ec"
                        name="comprendre le stock de réserve"
                    />
                    {'createdAt' in product && filteredLocations.length ? (
                        <DetailFormSelect<string>
                            label="Emplacement"
                            defaultValue={product?.location?._id || ''}
                            placeholder="Pas d'emplacement"
                            name="locationId"
                            options={filteredLocations.map(({ _id, datamatrix, productId, state }) => ({
                                label: datamatrix,
                                value: _id,
                                isDisabled: !!(
                                    (productId && productId !== product._id) ||
                                    state === LocationState.Hidden
                                ),
                            }))}
                            width={SHORT_FIELD_WIDTH}
                            isSearchable
                            isClearable
                            isDisabled={state === ProductState.Archived}
                        />
                    ) : null}
                    <DetailFormCheckbox name="scan" label="Emplacement scannable" defaultChecked={scan} />
                    <DetailFormCheckbox
                        label="Ne pas vérifier le stock disponible dans l'entrepôt"
                        sublabel="Permettre au client de commander autant de produit qu'il le veut"
                        name="allowNegativeStock"
                        defaultChecked={allowNegativeStock ? allowNegativeStock : undefined}
                    />
                    <DetailFormCheckbox
                        label="Vérifier si opercule non gonflé"
                        sublabel="Lors de l'inventaire chambre froide et sur site"
                        name="checkSwollenLid"
                        defaultChecked={checkSwollenLid}
                    />
                    <DetailFormCheckbox
                        name="isWithoutStock"
                        defaultChecked={isWithoutStock || false}
                        label="Totem ne gère pas le stock de ce produit"
                    />
                    <DetailFormCheckbox
                        name="isFromNayax"
                        defaultChecked={isFromNayax || false}
                        label="Ce produit est distribué par la machine à café"
                    />
                </SectionColumn>
                <SectionColumn>
                    <Suppliers suppliers={suppliers} productSuppliers={productSuppliers} />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormCheckbox
                        label="Commander une fois"
                        sublabel="Quantité la plus petite commandable de ce produit sera rajoutée au prochain achat fournisseur. Ce champ va automatiquement repasser en 'faux' après que l'achat est rangé"
                        name="shouldOrderOnce"
                        defaultChecked={shouldOrderOnce}
                    />
                    {'supplierShortageInfo' in product ? (
                        <>
                            <DetailFormCheckbox
                                label="En rupture chez fournisseur"
                                name="supplierShortageInfo.inShortage"
                                defaultChecked={product.supplierShortageInfo.inShortage}
                            />
                            {watchInShortage ? (
                                <DetailFormDatePicker
                                    label="Date de retour chez fournisseur"
                                    sublabel="Ce champ peut être vide si la date de retour de produit chez fournisseur est inconnue"
                                    defaultValue={product.supplierShortageInfo.returnDate}
                                    transformValueToDate={(value) => (value ? dateFromString(value, true) : null)}
                                    transformDateToValue={(date) =>
                                        date ? formatDateAsAnniversary({ dateTime: date, useNewFormat: true }) : null
                                    }
                                    name="supplierShortageInfo.returnDate"
                                    control={control}
                                    isClearable
                                    minDate={today}
                                />
                            ) : null}
                        </>
                    ) : null}
                    {'supplierShortageHistory' in product && product.supplierShortageHistory.length ? (
                        <TableContainer>
                            <TotemLabel>Historique de rupture fournisseur</TotemLabel>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Commencé le</th>
                                        <th>Fini le</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...product.supplierShortageHistory]
                                        .reverse()
                                        .map(({ createdAt, endedAt }, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{getExtendedFormattedDatetime(createdAt)}</td>
                                                    <td>{endedAt ? getExtendedFormattedDatetime(endedAt) : '-'}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </TableContainer>
                    ) : null}
                    <DetailFormValue
                        label="Nombre de jours avant DLC (garantie fournisseur)"
                        placeholder={isFoodProduct ? 'Veuillez préciser un DLC ⚠️' : '?'}
                        defaultValue={minSupplierExpiryDays}
                        {...register('minSupplierExpiryDays', { valueAsNumber: true })}
                        width="100%"
                    />
                    <DetailFormCheckbox
                        label="DLC courte"
                        name="hasShortExpiryDate"
                        sublabel="Si activée, la fréquence fournisseur utilisée sera de 1 pour les achats"
                        defaultChecked={hasShortExpiryDate}
                    />
                    <DetailFormCheckbox
                        label="Monitoring des DLCs"
                        name="trackExpiryDate"
                        defaultChecked={trackExpiryDate}
                    />
                    <DetailFormCheckbox
                        label="Peut expirer"
                        sublabel="Si oui, que le produit se trouve dans une colonne snack ou boisson et qu'il n'est pas à DLC semi-courte (monitoring des DLCs) alors le produit sera checké lors de l'inventaire DRY store"
                        name="canExpire"
                        defaultChecked={canExpire}
                    />
                    <DetailSwitchTable
                        label="Jours de livraison freefood possibles"
                        tableName="deliveryDays"
                        items={formattedDeliveryDays}
                    />
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        margin-top: 5px;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        color: ${({ theme }) => theme.textColor};

        th {
            white-space: nowrap;
        }
        th,
        td {
            text-align: left;
            border: 1px solid ${({ theme }) => theme.lightBorderColor};
            border-spacing: 1px;
        }

        th {
            padding: 10px;
        }

        td {
            padding: 5px 10px;
        }
    }
`;
